<template>
  <component class="category-icon" :is="comp" v-bind="colors"></component>
</template>

<script>
export default {
  props: ["category", "colors_arr"],

  created() {
    this.setColors()
    this.comp = this.getComponent()
  },

  watch: {
    widget: {
      handler() {
        this.comp = this.getComponent()
      },
    },

    colors_arr: {
      handler() {
        this.setColors()
      },
      deep: true,
    },
  },

  data: () => {
    return {
      colors: {
        color1: null,
        color2: null,
        background: null,
      },

      comp: null,
    }
  },

  methods: {
    setColors() {
      this.colors = {
        color1: this.colors_arr[0],
        color2: this.colors_arr[1],
        background: this.colors_arr[2],
      }
    },

    getComponent() {
      return () => import(`./icons/${this.category}.vue`)
    },
  },
}
</script>

<style scoped>
.category-icon {
  margin-right: 5%;
  width: 21px;
}
</style>
