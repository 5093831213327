<template>
  <div class="center-contact-div">
    <h1 class="heading1 black-text">{{ firstname }} {{ lastname }}</h1>
    <h1 class="heading2">{{ email }}</h1>
  </div>
</template>

<script>
export default {
  props: ["firstname", "lastname", "email"],
}
</script>
