<template>
  <div class="contact-list">
    <div v-for="(contact, index) in contacts" :key="contact.name">
      <Contact :contact="contact" :date_object="getDate(contact.created_date)" :percentage="percentages[index]" />
    </div>
  </div>
</template>

<script>
import Contact from "@/general/Elements/2.0/FriendsOf/Contact.vue"

export default {
  components: {
    Contact
  },
  data: () => {
    return {
      dates_sorted: [],
      percentages: [],
    };
  },

  async created() {
    this.dates_sorted = this.contacts
      .sort((x1, x2) => this.getDate(x2.created_date) - this.getDate(x1.created_date));

    await this.rankDates(this.dates_sorted);
  },

  computed: {
    store: function () {
      return this.$store.state;
    },
    contacts: {
      get() {
        return this.store.friends.contacts
      },
      set(value) {
        this.store.friends.contacts = value;
      }
    }
  },

  methods: {
    rankDates(dates) {
      var diff = [0];
      var total = 0;
      dates.forEach((_, i) => {
        if (i + 1 > dates.length - 1) {
          return;
        }
        var x1 = this.getDate(dates[i].created_date);
        var x2 = this.getDate(dates[i + 1].created_date);
        total += x1 - x2;
        diff.push(total);
      });
      var max = diff[diff.length - 1];

      this.percentages = [];
      diff.forEach((x) => {
        if (x == 0) {
          this.percentages.push(0);
        } else {
          this.percentages.push(x / max);
        }
      });
    },

    getDate(date) {
      return new Date(
        date.split("/")[2],
        parseInt(date.split("/")[1]) - 1,
        parseInt(date.split("/")[0]) + 1
      );
    },
  },
};
</script>
