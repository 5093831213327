<template>
  <div @click="setWidget" class="largewidget list">
    <div class="div-block-249">
      <Icon
        :widget="widget.name"
        :colors_arr="widget_colors"
        :classInput="'add-button-account large'"
      />
      <div>
        <div class="heading1 black-text">{{ getType(widget.type) }}</div>
        <template v-if="widget.title != 0">
          <div class="heading2 black-text">{{ widget.title }}</div>
        </template>

        <div class="heading2">{{ widget.name }}</div>
      </div>
    </div>
    <img src="/images/backbutton.svg" loading="lazy" class="image-122 reverse" />
  </div>
</template>

<script>
import Icon from "@/general/Elements/2.0/OtherElements/Widgets/WidgetIcon2.vue"

export default {
  components: {
    Icon,
  },
  props: ["widget"],
  created() {},
  methods: {
    setWidget() {
      this.$emit("returnWidget", this.widget)
    },
    getType(type) {
      if (type == "small_tile_collection") {
        return "Small Widgets"
      } else if (type == "tile_collection") {
        return "Normal Widgets"
      } else if (type == "image_collection") {
        return "Image Widgets"
      } else if (type == "banner_collection") {
        return "Banner Widgets"
      }
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    widget_colors: function () {
      return this.store.client_data.color[2].icons
    },
  },
}
</script>
