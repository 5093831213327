import store from "@/vuex/setup";
import { IGroupsRepository } from "@/general/Interfaces/Groups.interface";
import ArrayRepository from "./ArrayRepository";
import GenericObject from "./GenericObject";

import db_group from "@/db/2.0/TableManagement/GroupPublic.js"
import db_table from "@/db/2.0/TableManagement/TablePublic.js"

const tablesGroupState = async () => {
  return store.state.groups.groupCollection;
};

const tablesState = async () => {
  return store.state.tables.tableCollection;
};

export default class GroupsRepository implements IGroupsRepository {
  id: string;

  constructor(id: string) {
    this.id = id;
  }


  async addTables(ids: string[]): Promise<Object[] | null> {
    if (
      !ids ||
      ids == null ||
      ids.length <= 0 ||
      store.state.tables.tableCollection == null
    ) {
      return null;
    }

    const isCheck = await this.check();
    if (!isCheck) {
      return null;
    }

    const tableRepo = new ArrayRepository(store.state.tables.tableCollection);
    await tableRepo.setProperties(ids, [
      {
        key: "group",
        value: [this.id],
      },
    ]);

    var allItemsInGroup = await this.getAllTables();
    if (allItemsInGroup != null) {
      // db connection
      for (var i = 0; i < ids.length; i++) {
        await db_table.updateTable(store.state.user!['uid'], "b1", ids[i], "group", [this.id])
      }



      return await new ArrayRepository(allItemsInGroup).getAllItemsById();
    } else {
      return null;
    }
  }

  async getAllTables(): Promise<GenericObject[] | null> {
    if (!this.id || this.id == null || typeof this.id != "string") {
      return null;
    }

    var tablesOfTableGroup: any[] | null = [];
    var y = (await tablesState()).filter((x) => {
      if (x.group == null || x.group == 0) {
        return;
      }

      const group = Array.isArray(x.group) ? x.group.find((item: any) => item == this.id) : null
      if (group == null) {
        return;
      }
      if (group != null) {
        tablesOfTableGroup!.push(x);
      }

    });
    if (
      tablesOfTableGroup == null ||
      tablesOfTableGroup.length < 0
    ) {
      return null;
    }


    return tablesOfTableGroup;
  }

  async getGroupInfo(): Promise<Object | null> {
    if (typeof this.id != "string" || store.state.tables.tableCollection == null) {
      return null;
    }

    const groupRepo = new ArrayRepository(store.state.groups.groupCollection);
    const groupInfo = groupRepo.getItems([this.id]);

    return groupInfo != null ? groupInfo[0] : null;
  }

  async removeTables(ids: string[]): Promise<Object[] | null> {
    if (ids == null || !ids || ids.length <= 0 || ids.length <= 0) {
      return null;
    }

    const isCheck = await this.check();
    if (!isCheck) {
      return null;
    }

    var tables = await this.getAllTables();
    if (tables != null && tables.length > 0) {
      for (var i = 0; i < tables.length; i++) {
        for (var j = 0; j < ids.length; j++) {
          if (tables[i].group != null && tables[i].id == ids[j]) {
            const group = tables[i].group;

            const groupRepo = new ArrayRepository(group);
            tables[i].group = await groupRepo.removeItems([this.id], null)!;

            // db: updateTable(id, "group", [])
            await db_table.updateTable(store.state.user!['uid'], "b1", ids[j], "group", [])
          }
        }
      }



      return this.getAllTables();
    }

    return null;
  }

  async removeAllTables(): Promise<boolean | null> {
    const isCheck = await this.check();
    if (!isCheck) {
      return null;
    }

    const tablesInGroup = await this.getAllTables();

    if (tablesInGroup != null) {
      const tableRepo = new ArrayRepository(tablesInGroup);
      const ids = await tableRepo.getAllItemsById();

      if (ids != null) {
        if (ids.length <= 0) {
          return true;
        }
        const result = await this.removeTables(ids);

        if (result != null) {

          return true;
        }
      }
      return false;
    }


    return false;
  }

  async doesTableExist(ids: string[]): Promise<boolean | null> {
    const tablesInGroup = await this.getAllTables();
    if (tablesInGroup != null) {
      const tableRepo = new ArrayRepository(tablesInGroup);
      return await tableRepo.doesExist(ids);
    }

    return false;
  }

  async setActive(value: boolean): Promise<boolean | null> {
    if (store.state.groups.groupCollection[0].active == null) {
      return false;
    }
    if (tablesGroupState == null || !tablesGroupState) {
      return false;
    }
    const findGroup = (await tablesGroupState())
      .filter((x) => x)
      .find((x) => x.id == this.id);
    if (!findGroup) {
      return false;
    }
    if (findGroup) {
      findGroup.active = value;

      // db: updateGroup (this.id, "active", value)
      await db_group.updateGroup(store.state.user!['uid'], "b1", this.id[0], "active", value)

      return value;
    }
    return null;
  }

  async changeGroupName(name: string): Promise<boolean | null> {
    if (name == null || !name || name == "") {
      return false;
    }

    const isCheck = await this.check();
    if (!isCheck) {
      return null;
    }

    const currentGroup: GenericObject = (await this.getGroupInfo())!

    if (name == currentGroup.groupName) {
      return true;
    }

    const groupRepo = new ArrayRepository(store.state.groups.groupCollection);
    await groupRepo.setProperties(
      [this.id],
      [{ key: "groupName", value: name }]
    );
    await db_group.updateGroup(store.state.user!['uid'], "b1", this.id, "groupName", name)

    return true;
  }

  async getAllTableIds(): Promise<string[] | null> {
    const tablesInGroup = await this.getAllTables();


    if (tablesInGroup) {
      const tableRepo = new ArrayRepository(tablesInGroup);
      const ids = await tableRepo.getAllItemsById();
      return ids;
    }
    return null;
  }

  async check() {
    if (store.state.tables.tableCollection == null) {
      return false;
    }
    for (var i = 0; i < store.state.tables.tableCollection.length; i++) {
      if (
        store.state.tables.tableCollection[i] == null ||
        store.state.tables.tableCollection[i].group == null
      ) {
        return false;
      }
    }

    return true;
  }
}
