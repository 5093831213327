<template>
  <div class="full-width">
    <template v-if="input_widgets[index] != undefined">
      <Widgets :item="input_widgets[index]" :index="index - 1" type="image_collection" />
    </template>
  </div>
</template>

<script>
import Widgets from "@/general/Elements/2.0/OtherElements/Widgets/ImageWidgets.vue"

export default {
  components: {
    Widgets,
  },
  props: ["index", "input_widgets"],
  computed: {},
  created() {},
  data: () => {
    return {
      drag: false,
    }
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    config: function () {
      return this.store.environment.config
    },
    // numberPerPage: function() {
    //   if (this.input_widgets.length == 0 && !this.config) {
    //     return null;
    //   } else {
    //     return 1;
    //   }
    // }
  },
  methods: {},
}
</script>

<style scoped>
.horizontal-list {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.full-width {
  width: 100%;
}

.w-layout-grid {
  grid-column-gap: 2%;
}
</style>
