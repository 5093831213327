<template>
  <div class="slider-nav">
    <!-- <div class="left-slider-arrow">
      <img
        src="images/arrow-ios-forward-fill_1.svg"
        loading="lazy"
        alt=""
        class="image-33"
      />
    </div> -->
    <div v-for="index in numberOfSlides" :key="index" class="nav-circle-div">
      <div class="bullet" :class="isFirst(index)" @click="isClicked(index)"></div>
    </div>
    <!-- <div class="right-slider-arrow">
      <img
        src="images/arrow-ios-forward-fill-1.svg"
        loading="lazy"
        alt=""
        class="image-33"
      />
    </div> -->
  </div>
</template>

<script>
export default {
  props: ["numberOfSlides", "current"],
  components: {},
  created() {
  },

  data: () => {
    return {
    };
  },
  methods: {
    isFirst(index) {
      if (index == this.current) {
        return "focused";
      } else {
        return "";
      }
    },
    isClicked(value) {
      this.$emit("isClicked", value)
    }
  },

  
};
</script>

<style scoped>
.bullet {
  cursor:pointer;
}
</style>


