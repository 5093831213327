<template>
  <div class="section-wcategories wf-section">
    <BackgroundLayer :color1="colors[0]" :color2="colors[1]" />
    <div class="front-layer">
      <LogoHeader />
      <div class="white-background2">
        <div v-if="isLoaded" class="default-width full-length center">
          <div class="default-width center">
            <Title :showback="'SetWidget'" :text="'Add ' + widget_title_placeholder" />
            <WidgetImage :widget_name="widget.name" />

            <div v-for="(data, index) in account.data" :key="index" class="full-width">
              <InputField
                :label="data.name"
                :input="data.field"
                :index="index"
                @setInput="setInput"
              />
            </div>
          </div>
          <Button :opacity="opacity" :text="button_text" @isClicked="saveAccount" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "@/general/Components/2.0/CategoryManagement/General/Title.vue"
import InputField from "@/general/Components/2.0/CategoryManagement/General/InputField.vue"
import Button from "@/general/Components/2.0/CategoryManagement/General/Button.vue"
import BackgroundLayer from "@/general/Components/2.0/General/BackgroundLayer.vue"
import WidgetImage from "@/general/Components/2.0/CategoryManagement/General/WidgetImage.vue"
import InputField2 from "@/general/Components/2.0/CategoryManagement/General/InputField2.vue"

import WidgetRepository from "@/general/Objects/Widget"
import WidgetCollection from "@/general/Objects/WidgetCollection"

import LogoHeader from "@/general/Components/2.0/CategoryManagement/General/LogoHeader.vue"

import ArrayRepository from "@/general/Objects/ArrayRepository"

export default {
  components: {
    Title,
    InputField,
    Button,
    BackgroundLayer,
    WidgetImage,
    InputField2,
    LogoHeader,
  },
  props: ["return_path"],
  data: () => {
    return {
      accounts: [],
      selectedAccount: new ArrayRepository([]),
      opacity: "opacitylow",
      isLoaded: false,
      button_text: "Create Account",
      widget_title_placeholder: "",
    }
  },

  computed: {
    store: function () {
      return this.$store.state
    },
    colors: function () {
      return this.store.client_data.color
    },
    widget: {
      get() {
        return this.store.environment.setWidget.item
      },
      set(value) {
        this.store.environment.setWidget.item = value
      },
    },
    account: {
      get() {
        return this.store.environment.setAccount.item
      },
      set(value) {
        this.store.environment.setAccount.item = value
      },
    },
  },

  async created() {
    const wObject = new WidgetRepository()
    const widget_legend = await wObject.getWidgetByName(this.widget.name)
    this.widget_title_placeholder = widget_legend.title
    this.widget.options = widget_legend.options

    await this.setAccount()

    if (this.account.action == "update") {
      this.opacity = ""
      this.button_text = "Modify account"
    }

    this.isLoaded = true
  },

  methods: {
    async saveAccount() {
      const data = []
      if (this.selectedAccount != null && this.selectedAccount != "") {
        const account = this.selectedAccount.getAllItems()

        await account.forEach(async (x, index) => {
          data.push({
            index: index,
            field: x.field,
            name: x.name,
          })
        })

        const wCol = new WidgetCollection()
        if (this.account.action == "create") {
          await wCol.addAccount(this.widget.name, data)
        } else if (this.account.action == "update") {
          await wCol.editAccount(this.account.uid, this.widget.name, data)
        }

        this.account.action = "saved"

        if (this.return_path) {
          this.$router.push({ name: this.return_path })
        } else {
          this.$router.push({ name: "SetWidget" })
        }
      }
    },
    async setInput(value, option, index) {
      const data = {
        field: value,
        name: option,
        index: index,
      }
      await this.selectedAccount.updateItems([index], data, "index")

      if (value != null && value != "") {
        this.opacity = ""
      } else {
        this.opacity = "opacitylow"
      }
    },

    async setAccount() {
      if (this.account.action == "update") {
        const wCol = new WidgetCollection()
        const account = await wCol.getAccount(this.account.uid)
        this.account.data = account.data
        this.selectedAccount.addItems(account.data)
      } else {
        this.account["data"] = []
        await this.widget.options.forEach((option, index) => {
          this.account.data.push({
            name: option[0],
            field: "",
            index: index,
          })
        })
        this.selectedAccount.addItems(this.account.data)
      }
    },
  },
}
</script>

<style scoped>
.full-width {
  width: 100%;
}
</style>
