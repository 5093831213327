<template>
  <draggable
    class="full-width"
    v-bind="dragOptions"
    @start="drag = true"
    @end="drag = false"
    group="group1"
    :list="input_widgets"
  >
    <transition-group
      class="w-layout-grid nd-apps"
      type="transition"
      :name="!drag ? 'flip-list' : null"
    >
      <template v-if="borderColor">
        <div class="full-width" v-for="j in getNumberOfWidgets() + 1" :key="j">
          <template v-if="checkIndex(j)">
            <div
              class="weblinks customiconstyle"
              :style="
                'background-color:' +
                store.client_data.color[0] +
                ';border-color:' +
                borderColor
              "
            >
              <Widgets
                :item="input_widgets[getStartValue() + j]"
                :index="getStartValue() + j"
                :text="true"
                type="tile_collection"
              />
            </div>
          </template>
          <template v-else>
            <div class="weblinks">
              <AddWidget type="tile_collection" />
            </div>
          </template>
        </div>
      </template>
    </transition-group>
  </draggable>
</template>

<script>
// in elements 2.0
import Widgets from "@/general/Elements/2.0/OtherElements/Widgets/Widgets.vue"
import AddWidget from "@/general/Elements/2.0/OtherElements/Widgets/AddWidget.vue"

import methods from "@/general/HelperMethods/General"

// external package
import draggable from "vuedraggable"

export default {
  components: {
    draggable,
    Widgets,
    AddWidget,
  },
  props: ["index", "input_widgets", "config", "widgetsPerPage"],
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      }
    },
    store: function () {
      return this.$store.state
    },
  },

  created() {
    this.borderColor = methods.hexToRgb(this.store.client_data.color[2].icons[0], 0.3)

    // this.list = methods.deepcopy(this.input_widgets);
    // console.log(this.input_widgets)
    // console.log(this.getNumberOfWidgets())
  },
  data: () => {
    return {
      drag: false,
      borderColor: null,
    }
  },
  watch: {
    drag: {
      handler() {
        if (!this.drag) {
          this.saveModifications()
        }
      },
    },
  },
  methods: {
    saveModifications() {
      this.store.client_data.widgetSlider = this.input_widgets
    },
    checkIndex(index) {
      var numberOfWidgets = this.getNumberOfWidgets()
      if (index <= numberOfWidgets) {
        return true
      } else {
        return false
      }
    },

    async getRgb(color, opacity) {
      console.log(methods.hexToRgb(color, opacity / 100))
      return await methods.hexToRgb(color, opacity / 100)
    },

    getNumberOfWidgets() {
      var temp = this.input_widgets.length - this.index * this.widgetsPerPage

      if (temp < this.widgetsPerPage) {
        return temp
      } else {
        return this.widgetsPerPage
      }
    },

    handleChange() {
      this.drag = false
      this.$emit("returnWidgets", this.input_widgets)
    },

    getStartValue() {
      return this.index * this.widgetsPerPage - 1
    },
  },
}
</script>

<style scoped>
.full-width {
  width: 100%;
  max-width: 550px;
}

.weblinks.customiconstyle {
  border-color: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  background-color: white;
  padding-left:5%;

}
</style>
