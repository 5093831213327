
import json from '@/general/GlobalValues.json'
import { db } from '@/db/Firebase'

import data from './data.js'


export default {

    //Data
    async setData(uid, data) {
        await db.collection(json['collections'].users).doc(uid).set(data)
            .catch((err) => {
                throw err.message;
            });
    },

    async deleteData(uid) {
        return await db.collection(json['collections'].users).doc(uid).delete()
            .catch((err) => {
                throw err.message;
            })
    },

    async getData(uid) {
        return await data.getData(uid, 'public')
            .then(result => {

                return result;
            })
            .catch(() => {
                throw "It seems that your account is not available"
            });

    },




    async getUrl(uid) {
        return await data.getData(uid, 'public')
            .then((result) => {
                return result.url;
            })
            .catch(() => {
                throw "Sorry can't find url"
            })
    },

    async updateData(uid, input, group) {
        return await data.updateData(uid, input, group)
            .catch(() => {
                throw "Sorry couldn't update your files"
            });
    },

}