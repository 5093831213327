<template>
  <div
    class="grey-body"
    :style="setBackground(client_data.color[0], client_data.color[1])"
  >
    <Header :header_title="'All Tables'" :showLock="false" />
    <div class="white-background">
      <div @click="closeWindow()" class="close-add-tile">
        <img src="images/close.png" loading="lazy" class="image-12" />
      </div>
      <div style="cursor: pointer" @click="goBack">go back</div>
      <h1 id="name" class="heading6 black-font">Edit Widget</h1>
      <template v-if="isLoaded">
        <SelectField
          :label="'Replace widget'"
          :items="widgetNames"
          :default="default_field"
          :property="'name'"
          @setInput="replaceWidget"
        />
      </template>
      <template v-if="showAddField && isLoaded">
        <AddNeWidget
          :widget_uid="widget_uid"
          :premium="client_data.premium"
          :view="client_data.view"
          :color="client_data.color"
          :itemType="itemType"
          :numberOfWidgets="oItemsLength"
          @mode="setAddWidget"
          @item="setNewItem"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Header from "@/general/Components/2.0/TableManagement/General/Header.vue"

import SelectField from "@/general/Components/2.0/TableManagement/General/SelectField.vue"
import Button from "@/general/Components/2.0/TableManagement/General/Button.vue"

import SetWidget from "@/general/Components/2.0/TableManagement/EditWidget/setWidgetData.vue"

import AddNewWidget from "@/general/Components/2.0/TableManagement/EditWidget/AddWidget.vue"

import ArrayRepository from "@/general/Objects/ArrayRepository"
import TablesRepository from "@/general/Objects/TablesRepository"

import methods from "@/general/HelperMethods/General.js"

export default {
  components: {
    Header,
    SelectField,
    Button,
    SetWidget,
    AddNewWidget,
  },

  props: ["table"],

  data: () => {
    return {
      selected_widget: "url",
      options: ["link"],
      color: [
        "#11d1ad",
        "#116c5b",
        {
          icons: ["#116c5b", "#116c5b", "#ffffff"],
        },
      ],
      widgetNames: [{ id: "default", name: "Choose an widget..", type: null }],
      itemType: null,
      widget_uid: null,
      showAddField: false,
      default_field: "Choose an widget..",
      isLoaded: false,
    }
  },

  async created() {
    await this.addNameToArray(
      this.store.widgets.small_tile_collection,
      this.widgetNames,
      "small_tile_collection"
    )
    await this.addNameToArray(
      this.store.widgets.image_collection,
      this.widgetNames,
      "image_collection"
    )
    await this.addNameToArray(
      this.store.widgets.tile_collection,
      this.widgetNames,
      "tile_collection"
    )
    await this.addNameToArray(
      this.store.widgets.banner_collection,
      this.widgetNames,
      "banner_collection"
    )

    if (this.changeWidget != null) {
      const array = new ArrayRepository(this.widgetNames)
      const widget = (await array.getItems([this.changeWidget]))[0]

      this.default_field = widget.name
      await this.replaceWidget({ type: widget.type, id: widget.id, name: widget.name })
      this.state = 2
    }

    this.isLoaded = true
  },

  computed: {
    store: function () {
      return this.$store.state
    },
    widgets: function () {
      return this.store.widgets
    },

    changeWidget: {
      get() {
        return this.store.environment.editTableWidget
      },
      set(value) {
        this.store.environment.editTableWidget = value
      },
    },
    setWidget: {
      get() {
        return this.store.environment.addTableWidget
      },
      set(value) {
        this.store.environment.addTableWidget = value
      },
    },
    client_data: function () {
      return this.store.client_data
    },
    oItemsLength() {
      return this.widgets[this.itemType].length
    },

    tableID: function () {
      return this.store.environment.tableID
    },

    state: {
      get() {
        return this.store.environment.stateTableWidget
      },
      set(value) {
        this.store.environment.stateTableWidget = value
      },
    },
  },

  methods: {
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2)
    },

    closeWindow() {
      this.setWidget = false
      this.changeWidget = null
    },
    replaceWidget(value) {
      this.showAddField = false
      this.itemType = value.type
      this.widget_uid = value.id

      if (this.itemType != null) {
        this.showAddField = true
      }
    },

    goBack() {
      if (this.state == 0 || this.state == 1) {
        this.changeWidget = null
        this.setWidget = false
        return
      }

      this.state = this.state - 1
    },

    async setAddWidget(value) {},

    async setNewItem(value) {
      // console.log(value);
    },

    async addNameToArray(elements, i_array, type) {
      const table = new TablesRepository()
      const widgets = (await table.getTables([this.tableID]))[0].widgets
      const arrayRepo = new ArrayRepository(widgets)
      for (var i = 0; i < elements.length; i++) {
        if (this.changeWidget != null) {
          i_array.push({
            name: elements[i]["title"] + " - " + elements[i]["widget"] + " " + type,
            id: elements[i].uid,
            type: type,
          })
        } else if (!(await arrayRepo.doesExist([elements[i].uid], "uid"))) {
          i_array.push({
            name: elements[i]["title"] + " - " + elements[i]["widget"] + " " + type,
            id: elements[i].uid,
            type: type,
          })
        }
      }

      return i_array
    },
  },
}
</script>

<style scoped>
.white-background {
  padding: 5% 10%;
  height: 130vh;
}

.grey-body {
  min-height: 150vh !important;
}
</style>
