const tableCollection = [
    {
        id: "t_1",
        tableName: "table1",
        tableNumber: 1,
        position: 0,
        group: 0,
        other: [
            {
                key: "123",
                value: "value1",
            },
            {
                key: "124",
                value: "value2",
            },
        ],
    },
    {
        id: "t_2",
        tableName: "table2",
        tableNumber: 2,
        position: 1,
        group: ["g_1"],
        other: [
            {
                key: "125",
                value: "value1",
            },
            {
                key: "126",
                value: "value2",
            },
        ],
    },
    {
        id: "t_4",
        tableName: "table3",
        tableNumber: 3,
        position: 2,
        group: [],
        other: [
            {
                key: "125",
                value: "value1",
            },
            {
                key: "126",
                value: "value2",
            },
        ],
    },
]

export default { tableCollection }