import { IWidget } from "@/general/Interfaces/Widget"
import store from "@/vuex/setup"
import { general, hospitality, maps_var, WidgetModel } from "@/general/Legends/widgets"
import email_request from "@/general/Legends/email_request";

export default class WidgetRepository implements IWidget {


    getWidgetByName(name: string) {
        var combined_data: Array<WidgetModel> = [...general, ...hospitality]
        var result = combined_data.find((item) => item.name === name)
        return result
    }

    getTitle(name: string): string | null {
        const combined_data: Array<WidgetModel> = [...general, ...hospitality]
        var result = combined_data.find((item) => item.name === name)

        return result != null ? result.title : null
    }

    checkOptions(someObject: any, option: string) {
        var merged: string[] = [].concat.apply([], someObject.options)
        return merged.includes(option)
    }

    getLastIndex(x: string) {
        return x.slice(-1)
    }

    async getField(widget_name: string, input: string, option: string): Promise<any> {
        if (widget_name == null || input == null || option == null) {
            return null
        }

        var link = ""
        var widget_ob = await this.getWidgetByName(widget_name)

        var options = await this.checkOptions(widget_ob, option)

        var lastCharacter = ""
        if (await this.getLastIndex(input) == "/") {
            lastCharacter = ""
        }

        if (!options) {
            return input
        }

        if (options) {
            if (option === "link") {
                if (
                    /^https?:\/\//.test(input.toLowerCase()) ||
                    /^http?:\/\//.test(input.toLowerCase())
                ) {
                    link = input + lastCharacter
                } else {
                    link = widget_ob?.path + input + lastCharacter
                }
            } else if (option === "username") {
                if (widget_name === "instagram") {
                    link = widget_ob?.path + input + lastCharacter
                } else if (widget_name === "linkedin") {
                    link = widget_ob?.path + input + lastCharacter
                }
            } else if (option === "number") {
                if (widget_name === "phone") {
                    link = widget_ob?.path + input
                }
            } else if (option === "mail") {
                link = widget_ob?.path + input
            } else if (option === "adress") {
                link = widget_ob?.path + input + lastCharacter
            } else if (option === "country") {
                link = widget_ob?.path + input + lastCharacter
            } else if (option === "zip code") {
                link = widget_ob?.path + input + lastCharacter
            } else if (option === "city") {
                link = widget_ob?.path + input + lastCharacter
            } else {
                link = widget_ob?.path + input + lastCharacter
            }

            return link
        }
    }

    async getEmailInstructions(widget_name: string) {
        return await email_request.find((item) => item.widget_name === widget_name)
    }
}
