<template>
  <div>
    <div class="header-container" style="height: 50px">
      <template v-if="menu">
        <AccountMenu />
      </template>
    </div>
    <div class="background mozo" :style="setBackground(color[0], color[1])">
      <Header :header_title="'Profile QR code'" :showLock="false" />
      <div class="page-body _80vh center">
        <div class="center qr">
          <div class="max-height">
            <div
              style="
                border: 2px solid white;
                padding: 10%;
                background-color: white;
                width: 100vw;
                display: flex;
                justify-content: center;
                max-width: 500px;
              "
            >
              <VueQRCodeComponent
                :text="url"
                bg-color="#fff"
                color="#000"
              ></VueQRCodeComponent>
            </div>
          </div>
        </div>
        <div data-v-5c9e0926="" class="back-button-container">
          <div class="back-button" @click="goBack">
            <img
              :src="require('@/assets/images/arrow-back.svg')"
              loading="lazy"
              alt=""
              class="back-button-icon"
            />
            <h1 class="heading-nd">Back to Adam & Co</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Header from "@/general/Components/2.0/TableManagement/General/Header2.vue";
import VueQRCodeComponent from "vue-qr-generator";
import AccountMenu from "@/views/Menu/LoggedIn.vue";

import methods from "@/general/HelperMethods/General";

export default {
  components: {
    Header,
    VueQRCodeComponent,
    AccountMenu,
  },

  data: () => {
    return {
      url: "",
    };
  },

  computed: {
    store: function () {
      return this.$store.state;
    },
    menu: function () {
      return this.store.environment.menu;
    },
    showQR: {
      get() {
        return this.store.environment.showQR;
      },
      set(value) {
        this.store.environment.showQR = value;
      },
    },
    client_data: function () {
      return this.store.client_data;
    },
    color: function () {
      return this.client_data.color;
    },
  },

  created() {
    this.url = this.$store.state.user.uid;
  },

  methods: {
    goBack() {
      this.showQR = false;
      this.$router.push("/adamandco");
    },
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2);
    },
  },
};
</script>
<style scoped>
.page-body.center {
  min-height: 0px;
}

@media screen and (min-width: 479px) and (max-width: 767px) {
  .header-column-tm {
    padding: 0% 5%;
  }
}

.max-height {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.back-button-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.back-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 28px;
  background-color: rgb(210, 40, 40);
  cursor: pointer;
}

.back-button-icon {
  width: 20px; /* Adjust size as needed */
  height: auto;
  margin-right: 10px; /* Space between the icon and text */
}

.heading-nd {
  margin: 0;
  color: white;
}
</style>
