<template>
  <component :class="classInput" :is="comp" v-bind="colors"></component>
</template>

<script>
export default {
  props: ["widget", "colors_arr", "classInput"],

  created() {
    this.setColors()
    this.comp = this.getComponent()
  },

  watch: {
    widget: {
      handler() {
        this.comp = this.getComponent()
      },
    },

    colors_arr: {
      handler() {
        this.setColors()
      },
      deep: true,
    },
  },

  data: () => {
    return {
      colors: {
        color1: null,
        color2: null,
        background: null,
      },

      comp: null,
    }
  },

  methods: {
    setColors() {
      this.colors = {
        color1: this.colors_arr[0],
        color2: this.colors_arr[1],
        background: this.colors_arr[2],
      }
    },

    getComponent() {
      return () => import(`./WidgetIcons_COMPONENTS/${this.widget}.vue`)
    },
  },
}
</script>

<style scoped>
.component_class {
  box-shadow: none;
  padding: 0px;
}
.social-icon {
  width: 40px !important;
  height: 40px !important;
}
</style>
