<template>
  <div class="content-frame" id="divframe">
    <div v-if="!hideLottie" id="icon-container" class="lottie-animation inback">
      <lottie-animation path="logo_animation.json" />
    </div>
    <iframe
      allowfullscreen
      :src="link"
      width="100%"
      height="100%"
      frameBorder="0"
      class="iframe"
    />
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"
export default {
  components: {
    LottieAnimation,
  },
  props: ["link"],
  data() {
    return {
      loaded: false,
      hideLottie: false,
    }
  },
  created() {
    window.setTimeout(() => {
      this.hideLottie = true
    }, 2000)
  },
  methods: {},
}
</script>

<style scoped>
.iframe {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  -ms-zoom: 1;
  -moz-transform: scale(1);
  -moz-transform-origin: 0 0;
  -o-transform: scale(1);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(1);
  -webkit-transform-origin: 0 0;
}

.content-frame:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  box-shadow: inset 1px 1px 14px 0 #dadada;
  pointer-events: none;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.inback {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 20%;
}
</style>
