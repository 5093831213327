<template>
  <div @click="editWidget()" class="large-widget-div">
    <Icon :widget="widget.name" :colors_arr="color_array" />
    <h1 class="paragraph black-text">{{ widget.title }}</h1>
  </div>
</template>

<script>
import Icon from "@/general/Elements/2.0/OtherElements/Widgets/WidgetIcon.vue"
export default {
  components: {
    Icon,
  },
  props: ["widget"],
  data: () => {
    return {
      color_array: ["#000000", "#000000", "#ffffff"],
    }
  },
  created() {},
  computed: {
    store: function () {
      return this.$store.state
    },
    edit: {
      get() {
        return this.store.environment.editTableWidget
      },
      set(value) {
        this.store.environment.editTableWidget = value
      },
    },
  },
  methods: {
    editWidget() {
      this.edit = this.widget
      this.widget.action = "update"

      this.$router.push({ name: "EditTableWidget" })
    },
  },
}
</script>

<style scoped>
.large-widget-div {
  box-shadow: none;
}
</style>
