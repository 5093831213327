<template>
  <div>
    <template v-if="!next">
      <div class="background mozo nologo">
        <Header />

        <div class="header-logout">
          <div class="columns-2 w-row">
            <div class="w-col w-col-6">
              <div class="text-div _1">
                <a @click="goBack" class="button small w-inline-block">
                  <img
                    src="images/go-back.png"
                    class="image-18"
                    loading="lazy"
                    sizes="(max-width: 991px) 20px, (max-width: 1279px) 2vw, (max-width: 1439px) 20px, 1vw"
                    srcset="images/go-back-p-500.png 500w, images/go-back.png 512w"
                /></a>
              </div>
            </div>
            <div class="column w-col w-col-6">
              <div class="button-block logout"></div>
            </div>
          </div>
        </div>

        <div class="page-body-2 without-footer">
          <div v-if="state === 1" class="start_screen">
            <img
              src="images/illustration-step-10-purplebg.svg"
              loading="lazy"
              width="318"
              alt=""
              class="image-4"
            />
            <div class="text-block-slides slide1">
              <h1 class="heading1">{{ title1 }}</h1>
              <p class="paragraph">{{ description1 }}</p>
            </div>
            <div class="slider-footer">
              <div class="columns-4 w-row">
                <div class="column-4 w-col w-col-4">
                  <a @click="next = true" class="paragraph">{{ skip_btn }}</a>
                </div>
                <div class="column-5 w-col w-col-4">
                  <div class="dot-div">
                    <div class="dot clicked"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                  </div>
                </div>
                <a @click="state = 2" class="column-6 w-col w-col-4">
                  <img
                    src="images/right-arrow.png"
                    loading="lazy"
                    class="arrow_slide_1"
                  />
                </a>
              </div>
            </div>
          </div>
          <div v-else-if="state === 2" class="start_screen">
            <img
              src="images/illustration-step-20-purplebg.svg"
              loading="lazy"
              width="318"
              class="image-4"
            />
            <div class="text-block-slides">
              <h1 class="heading1">{{ title2 }}</h1>
              <p class="paragraph">{{ description2 }}</p>
            </div>
            <div class="slider-footer">
              <div class="columns-4 w-row">
                <div class="column-4 w-col w-col-4">
                  <a @click="next = true" class="paragraph">{{ skip_btn }}</a>
                </div>
                <div class="column-5 w-col w-col-4">
                  <div class="dot-div">
                    <div class="dot"></div>
                    <div class="dot clicked"></div>
                    <div class="dot"></div>
                  </div>
                </div>
                <a @click="state = 3" class="column-6 w-col w-col-4">
                  <img
                    src="images/right-arrow.png"
                    loading="lazy"
                    alt=""
                    class="arrow_slide_1"
                  />
                </a>
              </div>
            </div>
          </div>
          <div v-else-if="state === 3" class="start_screen">
            <img
              src="images/illustration-step-30-purplebg-1.svg"
              loading="lazy"
              width="421"
              alt=""
              class="image-4"
            />
            <div class="text-block-slides">
              <h1 class="heading1">{{ title3 }}</h1>
              <p class="paragraph">{{ description3 }}</p>
            </div>
            <div>
              <a @click="next = true" class="submit-button small w-button">{{ btn }}</a>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <Register :activationcode="activationcode" :p_url="p_url" :c_url="c_url" />
    </template>
  </div>
</template>

<script>
// in folder
import Register from "./Register1.vue"

// in elements 1.0
import Header from "@/general/Elements/2.0/Headers/Header1.vue"

export default {
  components: {
    Register,
    Header,
  },
  props: ["activationcode", "p_url", "c_url"],
  data() {
    return {
      next: false,
      state: 1,
      title1: "Welcome to mozo",
      title2: "Show who you are!",
      title3: "Go go go!",
      description1:
        "First register yourself with your own username and password. Start off with a brand new online mozo profile!",
      description2:
        "We help you to show your best online self! On our platform mozo you can prioritize your favourite widgets, websites and news about you or everything that you like to share",
      description3:
        "Discover mozo, share your profile, and don’t forgot about your loved ones.",
      skip_btn: "Skip intro",
      btn: "GO mozo!",
    }
  },

  methods: {
    goBack() {
      this.$emit("back", false)
    },
  },
}
</script>
