import { IArrayRepository } from "@/general/Interfaces/Array.interface"

import methods from "@/general/HelperMethods/General"

import GenericObject from "@/general/Objects/GenericObject"

export default class ArrayRepository implements IArrayRepository {
  // id: any;
  // anyStore: any;
  constructor(array: Array<any>) {
    this.array = array
  }

  private array: Array<any>

  addItems(items: Object[]): any[] | null {
    if (this.array == null) {
      return null
    }
    if (items == null || items.length <= 0) {
      return this.array
    }

    for (var i = 0; i < items.length; i++) {
      this.array.push(items[i])
    }

    return this.array
  }
  getItems(ids: string[], byProperty: string = "id"): any[] | null {
    if (this.array == null) {
      return null
    }
    if (ids == null || ids.length <= 0) {
      return this.array
    }

    var items: any[]
    items = []

    for (var i = 0; i < ids.length; i++) {
      for (var j = 0; j < this.array.length; j++) {
        if (ids[i] == this.array[j][byProperty]) {
          items.push(this.array[j])
          j = this.array.length
        }
      }
    }

    return items
  }

  updateItems(ids: string[], input: string, byProperty: string = "id"): boolean | null {
    if (this.array == null) {
      return null
    }
    if (ids == null || ids.length <= 0) {
      return false
    }

    var items: any[] = []



    for (var i = 0; i < ids.length; i++) {
      for (var j = 0; j < this.array.length; j++) {
        if (ids[i] == this.array[j][byProperty]) {
          this.array[j] = input
          j = this.array.length
        }
      }
    }

    return true
  }

  updateSpecificItems(
    ids: string[],
    input: string,
    byProperty: string = "id"
  ): boolean | null {
    if (this.array == null) {
      return null
    }
    if (ids == null || ids.length <= 0) {
      return false
    }

    var items: any[] = []

    for (var i = 0; i < ids.length; i++) {
      for (var j = 0; j < this.array.length; j++) {
        if (ids[i] == this.array[j][byProperty]) {
          this.array[j][byProperty] = input
          j = this.array.length
        }
      }
    }

    return true
  }

  getAllItems(): any[] | null {
    return this.array
  }

  getAllItemsById(): string[] | null {
    var ids: any[]
    ids = []
    for (var i = 0; i < this.array.length; i++) {
      ids.push(this.array[i].id)
    }
    return ids
  }
  removeItems(ids: string[], byProperty: string | null): any[] | null {
    if (this.array == null) {
      return null
    }
    if (ids == null || ids.length <= 0) {
      return null
    }

    for (var i = 0; i < ids.length; i++) {
      for (var j = 0; j < this.array.length; j++) {
        if (byProperty == null && ids[i] == this.array[j]) {
          this.array.splice(j, 1)
          j = this.array.length
        } else if (byProperty != null && ids[i] == this.array[j][byProperty]) {
          this.array.splice(j, 1)
          j = this.array.length
        }
      }
    }

    return this.array
  }
  removeAllItems(): boolean {
    if (this.array == null) {
      return false
    }

    this.array = []
    return true
  }
  doesExist(ids: string[], property: string = "id"): boolean | null {
    if (this.array == null) {
      return null
    }
    if (ids == null || ids.length <= 0) {
      return false
    }

    var count = 0

    for (var i = 0; i < ids.length; i++) {
      for (var j = 0; j < this.array.length; j++) {
        if (ids[i] == this.array[j][property]) {
          count += 1
          j = this.array.length
        }
      }
    }

    return count == ids.length ? true : false
  }

  async setProperties(
    items: string[],
    properties: GenericObject[],
    property: string = "id"
  ): Promise<null | boolean | Object[]> {
    if (this.array == null) {
      return null
    }
    if (properties == null || properties.length <= 0) {
      return false
    }
    if (items == null || items.length <= 0) {
      return false
    }



    for (var i = 0; i < this.array.length; i++) {
      for (var z = 0; z < items.length; z++) {
        for (var j = 0; j < properties.length; j++) {
          if (this.array[i][property] == items[z]) {
            var key = properties[j].key
            var value = properties[j].value

            if (methods.isArray(this.array[i][key])) {
              for (var y = 0; y < value.length; y++) {

                this.array[i][key].push(value[y])

              }
            } else {
              this.array[i][key] = value
            }
          }
        }
      }
    }

    var allChangedItems = await this.getItems(items)

    return allChangedItems
  }

  async updateProperties(
    items: string[],
    properties: GenericObject[],
    property: string = "id"
  ): Promise<null | boolean | Object[]> {
    if (this.array == null) {
      return null
    }
    if (properties == null || properties.length <= 0) {
      return false
    }
    if (items == null || items.length <= 0) {
      return false
    }

    for (var i = 0; i < this.array.length; i++) {
      for (var z = 0; z < items.length; z++) {
        for (var j = 0; j < properties.length; j++) {
          if (this.array[i][property] == items[z]) {
            var key = properties[j].key
            var value = properties[j].value


            this.array[i][key] = value


          }
        }
      }
    }

    var allChangedItems = await this.getItems(items)

    return allChangedItems
  }

  async removeProperties(
    item_ids: string[],
    properties: string[],
    property: string = "id"
  ): Promise<null | boolean | Object[]> {
    if (this.array == null) {
      return null
    }
    if (properties == null || properties.length <= 0) {
      return false
    }
    if (item_ids == null || item_ids.length <= 0) {
      return false
    }

    for (var i = 0; i < this.array.length; i++) {
      for (var z = 0; z < item_ids.length; z++) {
        for (var j = 0; j < properties.length; j++) {
          if (this.array[i][property] == item_ids[z]) {
            var key = properties[j]

            if (key == "id") {
              return false
            } else {
              delete this.array[i][key]
            }
          }
        }
      }
    }

    var allChangedItems = await this.getItems(item_ids)

    return allChangedItems
  }

  async getArrayOf(key: string): Promise<any[] | null> {
    var elements = []
    for (var i = 0; i < this.array.length; i++) {
      elements.push(this.array[i][key])
    }
    return elements
  }

  isEmpty() {
    if (methods.isArray(this.array) && this.array.length <= 0) {
      return true
    } else {
      return false
    }
  }

  getIndex(index: number) {
    if (this.array == null || this.array.length <= 0) {
      return -1
    }
    return this.array[index]
  }
}
