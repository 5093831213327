<template>
    <div class="popup-bg">
        <div class="popup-block">
        <h1 class="popup-txt">{{ alertText }}</h1>
        </div>
    </div>
</template>


<script>

export default {
  props: ["text1", "text2", "copy"],
  data() {
    return {
        alertText: null,
    };
  },

  created(){
    
    if (this.copy) {
      this.doCopy();
    } else {
      this.doGeneralAlert();
    }
     
  },

  methods: {
    doCopy() {
      this.$copyText(this.text2)
      .then( () => {
          this.alertText = this.text1 + this.text2;
          setTimeout( () => (this.hideCopy()), 2000);
        })
        .catch( () => {
          this.alertText = "Error: Copy did not work!";
          setTimeout( () => (this.hideCopy()), 2000);
        }
      );
    },

    doGeneralAlert() {
      this.alertText = this.text1 + this.text2;
      setTimeout( () => (this.hideCopy()), 2000);
    },

    hideCopy() {
        this.$emit('back', true)
    }
  },
};
</script>