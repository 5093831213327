<template>
  <div @click="clicked" class="button-popup transparent w-inline-block">
    <img :src="img" loading="lazy" alt="mozo hospitality" class="vv-login-logo" />
    <div class="text-block-light">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: ["img", "text"],
  methods: {
    clicked() {
      this.$emit("clicked", true);
    },
  },
};
</script>

<style scoped>
.button-popup {
  cursor: pointer;
}
</style>
