import store from "@/vuex/setup"
import { ITablesRepository } from "@/general/Interfaces/Tables.interface"

import ArrayRepository from "./ArrayRepository"

import db_table from "@/db/2.0/TableManagement/TablePublic.js"
import GenericObject from "./GenericObject"

export default class TablesRepository implements ITablesRepository {
  id?: string
  tableName?: string
  tableNumber?: number
  position?: number
  group?: Array<string>
  other?: [id: string, value: string]

  constructor() { }

  async getAllTables(): Promise<Object[] | null> {
    if (
      store.state.tables.tableCollection == null ||
      store.state.tables.tableCollection.length <= 0
    ) {
      return null
    }

    var allTables = new ArrayRepository(store.state.tables.tableCollection)

    return await allTables.getAllItems()
  }

  async getTables(ids: string[]): Promise<Object[] | null> {
    if (
      store.state.tables.tableCollection == null ||
      store.state.tables.tableCollection.length <= 0
    ) {
      return null
    }

    if (!ids || ids == null) {
      return null
    }

    const tableRepo = new ArrayRepository(store.state.tables.tableCollection)
    const tables = await tableRepo.getItems(ids)

    if (tables == null || tables.length <= 0) {
      return null
    }

    return tables
  }

  async doesExist(ids: string[]): Promise<boolean | null | undefined> {
    if (store.state.tables.tableCollection == null || ids == null) {
      return null
    }

    if (ids.length <= 0) {
      return false
    }

    for (var i = 0; i < ids.length; i++) {
      if (ids[i] == null || typeof ids[i] != "string") {
        return null
      }
    }
    const tableRepo = new ArrayRepository(store.state.tables.tableCollection)
    const response = await tableRepo.doesExist(ids)

    return response
  }

  async addProperties(ids: string[], properties: Object[]): Promise<boolean | null> {
    if (
      store.state.tables.tableCollection == null ||
      ids == null ||
      properties == null ||
      properties.length <= 0 ||
      properties[0] == null
    ) {
      return null
    }

    if (ids.length <= 0) {
      return false
    }

    var allTables = new ArrayRepository(store.state.tables.tableCollection)
    var allTables_groupsAdded = await allTables.setProperties(ids, properties)

    const tables = await this.getTables(ids)

    if (tables == null) return false

    for (var i = 0; i < ids.length; i++) {
      await db_table.updateTableAllData(store.state.user!["uid"], "b1", ids[i], tables[i])
    }

    return allTables_groupsAdded != false ? true : false
  }

  async updateProperties(
    ids: string[],
    properties: GenericObject[],
    byProperty: string = "id"
  ): Promise<boolean> {
    if (
      store.state.tables.tableCollection == null ||
      ids == null ||
      ids.length <= 0 ||
      byProperty == null
    ) {
      return false
    }

    var allTables = new ArrayRepository(store.state.tables.tableCollection)
    var allTables_groupsAdded = await allTables.updateProperties(ids, properties)

    const tables = await this.getTables(ids)

    if (tables == null) return false


    for (var i = 0; i < ids.length; i++) {
      await db_table.updateTableAllData(store.state.user!["uid"], "b1", ids[i], tables[i])
    }

    return allTables_groupsAdded != false ? true : false
  }

  async removeWidget(table_id: string, widget_uid: string): Promise<boolean> {
    if (table_id == null) return false
    if (widget_uid == null) return false

    const tables: GenericObject = (await this.getTables([table_id]))![0]
    const widgets: any[] = tables.widgets

    const new_array: any[] = []

    await widgets.forEach((widget) => {
      if (widget.uid != widget_uid) {
        new_array.push(widget)
      }
    })

    var allTables = new ArrayRepository(store.state.tables.tableCollection)
    const result = await allTables.updateProperties(
      [table_id],
      [{ key: "widgets", value: new_array }]
    )

    const tables2 = await this.getTables([table_id])

    if (tables2 == null) return false

    for (var i = 0; i < [table_id].length; i++) {
      await db_table.updateTableAllData(
        store.state.user!["uid"],
        "b1",
        [table_id][i],
        tables2[i]
      )
    }

    return result != false ? true : false
  }

  async removeProperties(
    ids: string[],
    property_keys: string[]
  ): Promise<boolean | null> {
    if (
      store.state.tables.tableCollection == null ||
      ids == null ||
      property_keys == null ||
      property_keys.length <= 0 ||
      property_keys[0] == null
    ) {
      return null
    }

    if (ids.length <= 0) {
      return false
    }

    var allTables = new ArrayRepository(store.state.tables.tableCollection)
    var allTables_groupsAdded = await allTables.removeProperties(ids, property_keys)

    const tables = await this.getTables(ids)

    if (tables == null) return false

    for (var i = 0; i < ids.length; i++) {
      await db_table.updateTableAllData(store.state.user!["uid"], "b1", ids[i], tables[i])
    }

    return allTables_groupsAdded != false ? true : false
  }
}
