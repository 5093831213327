import Vue from "vue"

// contacts
// import Contacts from '@/views/Contacts/Overview.vue'

// journey
import Activation from "@/views/StartJourney/2.0/Activation.vue"
import NoProduct from "@/views/StartJourney/2.0/NoProduct.vue"
import weblink from "@/views/StartJourney/2.0/WebsiteLink.vue"

// other
import Reset from "@/views/Other/ResetPass.vue"
import VerifyPass from "@/views/Other/NewPassword.vue"
import Login from "@/views/Other/Login.vue"

// account
import Account from "@/views/Account/Main.vue"
import QR from "@/views/Other/QR.vue"

// general
import main from "@/views/Main.vue"

import Friends from "@/views/Friends/Overview.vue"
import AddFriend from "@/views/Friends/AddFriend.vue"
import LoginFriend from "@/views/Friends/Login.vue"

import EmailSent from "@/views/HandleEmailRequest/EmailSent.vue"
import EmailResponse from "@/views/HandleEmailRequest/EmailResponse.vue"

import EmptyPage from "@/views/Other/404.vue"

import AllGroups from "@/views/TableManagement/AllGroups.vue"
import AllTables from "@/views/TableManagement/AllTables.vue"
import EditTable from "@/views/TableManagement/EditTable.vue"
import EditGroup from "@/views/TableManagement/EditGroup.vue"
import EditTableWidget from "@/views/TableManagement/EditWidget.vue"

import WidgetCategories from "@/views/Configuration/3.0/Categories.vue"
import Widget from "@/views/Configuration/3.0/WidgetsPerCategory.vue"
import SetWidget from "@/views/Configuration/3.0/SetWidget.vue"
import SetAccount from "@/views/Configuration/3.0/SetAccount.vue"
import AreYouSure from "@/views/Configuration/3.0/AreYouSure.vue"

import Finished from "@/general/Components/2.0/CategoryManagement/SetWidget/IsRequested.vue"

import VueRouter from "vue-router"

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

const paths = [
  { path: "/login", component: Login },
  { path: "/account", component: Account, name: "Account" },
  // { path: '/added', component: Remember },
  { path: "/activation*", component: Activation },
  { path: "/register", component: NoProduct, name: "Register" },
  { path: "/reset-password", component: Reset, name: "ResetPassword" },
  { path: "/verification-reset*", component: VerifyPass },
  { path: "/qr-code", component: QR },
  { path: "/register2*", component: weblink },
  { path: "/friends*", component: Friends, name: "friends", props: true },
  { path: "/addfriend*", component: AddFriend, name: "addFriend", props: true },
  { path: "/loginfriend*", component: LoginFriend, name: "loginFriend", props: true },

  { path: "/allgroups", component: AllGroups, name: "AllGroups", props: true },
  { path: "/alltables", component: AllTables, name: "AllTables", props: true },
  { path: "/group*", component: EditGroup, name: "group", props: true },
  { path: "/table*", component: EditTable, name: "table", props: true },
  {
    path: "/edittablewidget",
    component: EditTableWidget,
    name: "EditTableWidget",
    props: true,
  },

  { path: "/emailsent*", component: EmailSent, name: "EmailSent" },
  { path: "/emailresponse*", component: EmailResponse, name: "EmailReceived" },
  { path: "/404*", component: EmptyPage },

  { path: "/widget", component: Widget, name: "Widget", props: true },
  {
    path: "/widgetcategory",
    component: WidgetCategories,
    name: "WidgetCategories",
    props: true,
  },
  {
    path: "/setwidget",
    component: SetWidget,
    name: "SetWidget",
    props: true,
  },
  { path: "/setaccount", component: SetAccount, name: "SetAccount", props: true },
  { path: "/areyousure", component: AreYouSure, name: "AreYouSure" },
  { path: "/waitpage", component: Finished, name: "Finished" },

  { path: "/*", component: main, name: "Main", props: true },
]

const router = new VueRouter({
  mode: "history",
  hash: false,
  routes: paths,
})

import DataLoading from "@/router/dataLoading"
router.beforeEach(async (to, from, next) => {
  if (to.name == "Main") {
    next()
  }

  const loader = new DataLoading()
  const nextPath = await loader.import_data(to.name, to.path)

  if (nextPath == to.path) {
    next()
  } else if (nextPath == '/login') {
    window.location.href = '/login'
  } else {
    history.pushState({}, null, nextPath)
  }
})

export default router
