<template>
  <div class="account-column-right">
    <template v-if="!showBox">
      <div class="option-button-account" @click="setButtonClicked()">
        <img src="/images/setting.png" class="wheelicon" />
      </div>
    </template>
    <template v-else-if="showBox">
      <div class="option-button-account">
        <img src="/images/setting.png" class="wheelicon" />
        <div class="popup-edit-account">
          <div class="change-account-button" @click="editClick">
            <img src="/images/edit-button-white.png" class="pencil-2" />
            <h1 class="subheading-small">Edit profile</h1>
          </div>

          <div class="line"></div>

          <div class="change-account-button" @click="deleteClick">
            <img src="/images/close-01.svg" loading="lazy" alt="" class="delete" />
            <h1 class="subheading-small">Delete profile</h1>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["image", "bgClicked"],
  created() {},
  data: () => {
    return {
      showBox: false,
      boxClicked: false,
    }
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    widget: {
      get() {
        return this.store.environment.setWidget.item
      },
      set(value) {
        this.store.environment.setWidget.item = value
      },
    },
    account: {
      get() {
        return this.store.environment.setAccount.item
      },
      set(value) {
        this.store.environment.setAccount.item = value
      },
    },
  },
  methods: {
    editClick() {
      this.account.action = "update"
      this.account.data = this.widget.data

      this.$router.push({
        name: "SetAccount",
      })
    },
    async deleteClick() {
      this.$emit("deleteAccount", true)
    },

    setButtonClicked() {
      this.boxClicked = true
    },
  },
  watch: {
    bgClicked: {
      handler() {
        if (this.bgClicked && !this.boxClicked) {
          this.showBox = false
        } else if (this.bgClicked && this.boxClicked) {
          this.showBox = true
          this.boxClicked = false
        }
        this.$emit("compClicked", false)
        this.$emit("showBox", this.showBox)
      },
    },
    deep: true,
  },
}
</script>

<style scoped>
.popup-edit-account {
  z-index: 5;
}

.change-account-button {
  cursor: pointer;
}

.subheading-small {
  color: grey;
}
</style>
