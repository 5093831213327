<template>
  <div class="tablechoice-div">
    <div @click="clickTable()" class="extrapadding-custom">
      <h1 class="heading3 left">{{ table.tableName }}</h1>
    </div>
    <div class="html-embed-4 w-embed">
      <ActiveButton :input_active="table.active" @switched="switchButton" />
    </div>
  </div>
</template>

<script>
import ActiveButton from "@/general/Components/2.0/TableManagement/AllTables/ActiveButton.vue"

import TablesRepository from "@/general/Objects/TablesRepository"

export default {
  components: {
    ActiveButton,
  },
  props: ["table"],
  created() {},
  computed: {
    store: function () {
      return this.$store.state
    },
    table_info: {
      get() {
        return this.table
      },
      set(value) {
        this.table = value
      },
    },
    tableID: function () {
      return this.store.environment.tableID
    },
  },

  methods: {
    async switchButton(response) {
      const tableRepo = new TablesRepository()

      const isSet = await tableRepo.updateProperties(
        [this.table_info.id],
        [{ key: "active", value: response }]
      )
    },
    clickTable() {
      this.$emit("clickTable", this.table.position)
    },
  },
}
</script>

<style scoped>
.extrapadding-custom {
  padding: 4% 5%;
  cursor: pointer;
}
</style>
