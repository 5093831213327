<template>
  <div class="background mozo" :style="setBackground(colors[0], colors[1])">
    <div class="page-body add-social">
      <div class="white-div mobile">
        <Header heading="" @state="close" />
        <div class="configapp-div">
          <WidgetTitle :data="data" :color="colors" />

          <div v-for="(field, index) in fields" in :key="index">
            <WidgetField
              button="copy"
              :data="field"
              :widget="data.widget"
              :index="index"
              @copy="doCopy(field)"
            />
          </div>
        </div>
      </div>
    </div>

    <template v-if="copy">
      <Copy text1="You copied: " :text2="field" :copy="true" @back="copy = false" />
    </template>
  </div>
</template>

<script>
// in folder
import WidgetTitle from "@/general/Elements/2.0/OtherElements/Widgets/Title.vue"
import WidgetField from "@/general/Elements/2.0/OtherElements/Widgets/Field.vue"

//in elements 1.0
import Header from "@/general/Elements/2.0/Headers/PopUpHeader.vue"
import Copy from "@/general/Elements/2.0/OtherElements/Popups/Alert.vue"

// @ src
import methods from "@/general/HelperMethods/General.js"

export default {
  components: {
    Header,
    WidgetTitle,
    WidgetField,
    Copy,
  },
  props: ["data"],

  data() {
    return {
      copy: false,
      fields: [],
      field: null,
    }
  },

  computed: {
    store: function () {
      return this.$store.state
    },
    colors: function () {
      return this.store.client_data.color
    },
  },

  created() {
    this.fields = methods.dictToArray(this.data.data, "field")
  },

  methods: {
    close() {
      this.$emit("back", true)
    },
    doCopy(field) {
      this.field = field
      this.copy = true
    },
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2)
    },
  },
}
</script>
