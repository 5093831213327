<template>
  <div class="fullwidth">
    <!-- <MenuHeader /> -->
    <div class="div-block-90">
      <div class="div-block-buttons">
        <Header />
        <Buttons />
        <Email />
      </div>
    </div>
  </div>


</template>

<script>
import MenuHeader from "@/general/Elements/2.0/Headers/Header-FriendsOf.vue";
import methods from "@/general/HelperMethods/General.js";

import Header from "@/general/Elements/2.0/FriendsOf/Header.vue";
import Buttons from "@/general/Elements/2.0/FriendsOf/Buttons.vue";
import Email from "@/general/Elements/2.0/FriendsOf/Email.vue";

export default {
  components: {
    MenuHeader,
    Header,
    Buttons,
    Email,
  },

  props: [
  ],
  created() {

  },

  computed: {
    store: function () {
      return this.$store.state;
    },
    client_data: {
      get() {
        return this.store.client_data;
      },
      set(value) {
        this.store.client_data = value;
      },
    },
  },



  methods: {
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2);
    },
  },
};
</script>

<style scoped>
.div-block-90 {
  height: 100%;
  justify-content: center;
  min-height: 90vh;
}

.fullwidth {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
