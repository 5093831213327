<template>
  <!-- REMARK why is this (div-block-172) one different from Row -->
  <div class="div-block-172">
    <div class="div-block-162">
      <div class="div-block-159 short">
        <div class="div-block-160">
          <h1 class="heading-21">{{ group.tableName }}</h1>
        </div>
      </div>
      <img
        @click="clickDelete"
        src="/images/delete.svg"
        loading="lazy"
        alt=""
        class="image-58"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["group"],
  computed: {},

  methods: {
    clickDelete() {
      this.$emit("clickDelete", this.group.position)
    },
  },
}
</script>

<style scoped>
.div-block-160 {
  padding: 5%;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  cursor: pointer;
}

.image-58 {
  cursor: pointer;
}

.image-57 {
  cursor: pointer;
}

.div-block-159 {
  margin-left: 0%;
}
</style>
