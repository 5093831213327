import json from "@/general/GlobalValues.json"
import { firebase } from "@/db/Firebase"

export default {
    async addProfilePicture(uid, image, type, extra, table = null) {
        if (image === "") {
            return "../images/pf.jpg"
        }

        var image_url = ""

        var storage = firebase.storage()


        var storageRef
        if (table == null) {
            storageRef = storage.ref(uid + "/" + json["db_path"][type] + extra)
        } else {
            storageRef = storage.ref(
                uid +
                "/" +
                json["db_path"]["tables"] +
                table +
                "/" +
                json["db_path"][type] +
                extra
            )
        }

        await storageRef.put(image).catch((err) => {
            throw err.message
        })
        await storageRef.getDownloadURL().then((x) => {
            image_url = x
        })
        return image_url
    },

    async deleteProfilePicture(uid, type, extra, table = null) {
        var storage = firebase.storage()
        var storageRef
        if (table == null) {
            storageRef = storage.ref(uid + "/" + json["db_path"][type] + extra)
        } else {
            storageRef = storage.ref(
                uid +
                "/" +
                json["db_path"]["tables"] +
                table +
                "/" +
                json["db_path"][type] +
                extra
            )
        }

        await storageRef.delete().catch(() => {
            // do nothing
        })
    },

    async updateProfilePicture(uid, image, type, extra, table = null) {
        var image_url = ""

        var storage = firebase.storage()
        var storageRef
        if (table == null) {
            storageRef = storage.ref(uid + "/" + json["db_path"][type] + extra)
        } else {
            storageRef = storage.ref(
                uid +
                "/" +
                json["db_path"]["tables"] +
                table +
                "/" +
                json["db_path"][type] +
                extra
            )
        }

        await storageRef.put(image).catch((err) => {
            throw err.message
        })
        await storageRef.getDownloadURL().then((x) => {
            image_url = x
        })
        return image_url
    },
}
