<template>
  <div :class="['inputfield-div', { marginbottom: !state }]">
    <h1 :style="`color:${inputColorLabel}`" :class="['label', { red: !state }]">
      {{ label }}
    </h1>

    <div class="password-input">
      <input
        ref="field"
        :placeholder="placeholder"
        v-model="name"
        :class="['inputfield', { 'red-borders': !state }]"
        @keyup="setfield"
        @keyup.enter="pressedEnter"
        @input="setfield"
        :type="inputType"
        :autocomplete="type == 'password' ? 'current-password' : ''"
      />
      <button
        v-if="type == 'password'"
        type="button"
        class="password-toggle"
        @click="toggleVisibility"
      >
        {{ showPassword ? "Hide" : "Show" }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: ["label", "type", "input_name", "placeholder", "input_color_label"],
  data() {
    return {
      name: this.input_name || "",
      state: true,
      showPassword: false,
    }
  },
  computed: {
    inputType() {
      return this.showPassword ? "text" : this.type
    },
    inputColorLabel() {
      return this.input_color_label ? this.input_color_label : "black"
    },
  },
  methods: {
    setfield() {
      this.$emit("returnfield", this.name, this.label)
    },
    pressedEnter() {
      this.$emit("pressEnter")
    },
    toggleVisibility() {
      this.showPassword = !this.showPassword
    },
  },
}
</script>
<style scoped>
.inputfield {
  outline: 0;
  color: black !important;
  height: 50px;
  margin-bottom: 10px;
  border: 4px solid transparent;
  font-size: 14px;
}
.inputfield-error {
  border: none;
  outline: none;
}
.red {
  color: red;
}
.red-borders {
  border: 2px solid red;
}
.marginbottom {
  margin-bottom: 10px;
}
.password-input {
  position: relative;
}
.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 0.8em;
  cursor: pointer;
}
</style>
