import Widget from "@/general/Objects/Widget";
import { ICategoryCollection } from "@/general/Interfaces/Category";
import category from "@/general/Legends/categories";
import ArrayRepository from "@/general/Objects/ArrayRepository"
import CategoryLegend from "./CategoryLegend";
export default class CategoryCollection implements ICategoryCollection {

  async getAll(type: string): Promise<CategoryLegend[] | null> {
    if (type == null) return null
    if (type == "all") return category;

    const selected_categories: CategoryLegend[] = [];
    for (var i = 0; i < category.length; i++) {
      for (var j = 0; j < category[i].type.length; j++) {
        if (category[i].type[j] == type) {
          selected_categories.push(category[i])
          j = category[i].type.length
        }
      }
    }

    return selected_categories
  }

  checkCategory(image_name: string) {
    let check = category.filter(val => val.image.includes(image_name.toLowerCase()));
    return check
  }

  async search(name: string, type: string = "all"): Promise<Widget | null | any> {
    if (name == null) {
      return []
    } else if (name == "" && type == "all") {
      const array = new ArrayRepository(category);
      return await array.getAllItems();
    } else if (name == "" && type != "all") {
      return await this.getAll(type)
    } else if (name) {
      var categoryArr: { id: number; name: string; image: string; }[] = []
      category.forEach(o => {
        if (o.name && o.name.includes(name.toLowerCase()) && o.type.includes(type)) {
          categoryArr.push(o)
        }
      });
      return categoryArr
    } else {
      return [];
    }
  }
}