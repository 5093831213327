import json from "@/general/GlobalValues.json";
import { db } from "@/db/Firebase";

export default {

    async addGroup(uid, branch_id, group_id, data) {
        return await db
            .collection(json["collections"].users)
            .doc(uid)
            .collection(json["collections"].branches)
            .doc(branch_id)
            .collection(json["collections"].groupsPublic)
            .doc(group_id)
            .set(data)
            .catch((err) => {
                throw err.message;
            });
    },

    async removeGroup(uid, branch_id, group_id) {
        return await db
            .collection(json["collections"].users)
            .doc(uid)
            .collection(json["collections"].branches)
            .doc(branch_id)
            .collection(json["collections"].groupsPublic)
            .doc(group_id)
            .delete()
            .catch((err) => {
                throw err.message;
            });
    },

    async updateGroup(uid, branch_id, group_id, property, value) {
        return await db
            .collection(json["collections"].users)
            .doc(uid)
            .collection(json["collections"].branches)
            .doc(branch_id)
            .collection(json["collections"].groupsPublic)
            .doc(group_id)
            .update({
                [property]: value,
            })
            .catch((err) => {
                console.log(err)
                throw err.message;
            });
    },


}