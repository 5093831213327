<template>
  <div class="abs-div">
    <div class="popup-div">
      <h1 class="popup-heading white">{{ title }}</h1>
      <div class="text-block-div marge-bottom">
        {{ text }}
      </div>
      <template v-if="button">
        <Button img="images/eyes.svg" :text="button" @clicked="next()" />
      </template>

      <div v-if="showCloseButton" @click="close()" class="close-button-div-popup">
        <img src="images/close.png" loading="lazy" alt="mozo hospitality" class="cross-image white" />
      </div>
    </div>
  </div>

</template>

<script>
import Button from "@/general/Elements/2.0/FriendsOf/ButtonImg.vue";

export default {
  components: {
    Button
  },
  data: () => {
    return {
      // title: "Saved!",
      // text: "Healthy Restaurant is now saved to your profile",
    };
  },

  props: [
    'title',
    'text',
    'showCloseButton',
    'button'
  ],

  methods: {
    async next() {
      this.$emit('next', true);
    },

    close() {
      this.$emit('close', true);
    }
  },
};
</script>

<style scoped>
.text-block-div {
  font-size: 20px;
}

.abs-div {
  position: absolute;
  z-index: 99999999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

}

.popup-div {
  background-color: rgba(0, 0, 0, 0.80)
}
</style>
