import store from "@/vuex/setup"

import connectWidgets from "@/vuex/widgets-watcher.js"
import connectUserdata from "@/vuex/variable-watcher.js"

import GenericObject from "@/general/Objects/GenericObject";

import db_accounts from "@/db/2.0/accounts";
import db_user from "@/db/2.0/user/main.js";

import DataLayer from "@/general/Objects/DataLayer"
import User from "@/general/Objects/User"
import View from "@/general/Objects/View"

interface DataModel {
  uid: string | null
  url: string | null
  branch: string | null
  group: string | null
  table: string | null
  client_name: string | null
}

export default class Main {

  constructor() {

  }

  async setPage(): Promise<string> {


    var user = null;
    if (store.state.user == null) {
      user = await this.checkAuthState()
    } else {
      user = store.state.user;
    }

    const v: any = await db_accounts.getAllData(user.uid);
    const w: any = await this.initialize(user.uid, "tile_collection");
    const x: any = await this.initialize(user.uid, "small_tile_collection");
    const y: any = await this.initialize(user.uid, "image_collection");
    const z: any = await this.initialize(user.uid, "banner_collection");

    store.state.saved_widgets = v;
    store.state.widgets.tile_collection = w;
    store.state.widgets.small_tile_collection = x;
    store.state.widgets.image_collection = y;
    store.state.widgets.banner_collection = z;



    await connectWidgets.runWatchers(store.state.client_data.url)

    return "/setwidget"
  }

  async initialize(uid: string, name: string) {
    var widgetData = await db_user.vWidgets.getWidgets(uid, name);
    if (widgetData!.data == 0) {
      widgetData!.data = [];
    }

    var sItems = widgetData!.data;

    var items = [...Array(sItems.length)].map(() => null);
    if (sItems[0] === null) {
      return items;
    }

    items = await [...Array(sItems.length)].map((el, i) => {
      let item = sItems.find((item: any) => item.position === i);
      if (item) {
        return item;
      }
      return null;
    });

    return items;
  }

  async checkAuthState(): Promise<User> {
    const userClass = new User()

    await userClass.setUser()
    var user = userClass.getUser()

    userClass.storeUser(store.state)

    return user
  }

  async getData(db: DataLayer, user: any) {
    var isExistingProfile = await this.checkUrl(db)

    var view = new View()

    if (user) {
      await this.loggedInRoute(view, db, user, isExistingProfile)
    } else {
      await this.loggedOutRoute(view, db, isExistingProfile)
    }

    return view
  }

  async loggedInRoute(
    view: View,
    db: DataLayer,
    user: any,
    isExistingProfile: boolean
  ) {
    store.state.environment.loggedIn = true
    var url = null


    await db.setPublicProfile(db.data.uid)


  }

  async loggedOutRoute(
    view: View,
    db: DataLayer,
    isExistingProfile: boolean
  ) {
    store.state.environment.loggedIn = false
    var url = null


    await view.setName("login")
    await view.setEnvironment(null)
    await view.setUrl("login")


  }

  async checkUrl(db: DataLayer): Promise<boolean> {
    const data = (await db.getUrlData()) as DataModel | null

    return data != null ? true : false
  }

  async checkUid(db: DataLayer, input_uid: string): Promise<boolean> {
    const data = db.data as DataModel | null

    if (data == null) {
      return false
    }

    if (data.uid == null) {
      return false
    }

    return data.uid == input_uid ? true : false
  }
}
