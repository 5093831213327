<template>
  <div>
    <label class="switch">
      <input @click="switchButton()" :checked="active" type="checkbox" />
      <span class="slider round" :style="style"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: ["input_active"],
  data: () => {
    return {
      style: "",
      active: false,
    }
  },
  created() {
    this.active = this.input_active
    this.getColor()
  },

  methods: {
    switchButton() {
      this.active = !this.active
      this.$emit("switched", this.active)
      this.getColor()
    },
    getColor() {
      if (this.active) {
        this.style = "background-color: " + this.client_data.color[0] + ";"
      } else {
        this.style = ""
      }
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    client_data: function () {
      return this.store.client_data
    },
  },
}
</script>

<style>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: transparent;
}

input:focus + .slider {
  /* box-shadow: 0 0 1px #000; */
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
