<template>
  <div class="section-wcategories">
    <BackgroundLayer :color1="colors[0]" :color2="colors[1]" />
    <div class="front-layer">
      <LogoHeader />
      <div class="white-background2 top">
        <div class="default-width center _400 margin-bottom">
          <Title
            :showback="'WidgetCategories'"
            :showCross="'Main'"
            :text="widget_category"
          />
          <SearchBlock @return="setSearch" />
        </div>
        <div class="wb-bottom-div padding-top">
          <div class="default-width _400">
            <WidgetBLockLarge :widgets="widgets_featured" />
            <WidgetBlockSmall :widgets="widgets_notfeatured" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackgroundLayer from "@/general/Components/2.0/General/BackgroundLayer.vue"
import SearchBlock from "@/general/Components/2.0/CategoryManagement/General/SearchBlock.vue"
import WidgetBLockLarge from "@/general/Components/2.0/CategoryManagement/Widgets/WidgetBLockLarge.vue"
import WidgetBlockSmall from "@/general/Components/2.0/CategoryManagement/Widgets/WidgetBlockSmall.vue"
import Title from "@/general/Components/2.0/CategoryManagement/General/Title.vue"

import LogoHeader from "@/general/Components/2.0/CategoryManagement/General/LogoHeader.vue"

import WidgetCollection from "@/general/Objects/WidgetCollection"

import WidgetLarge from "@/general/Components/2.0/CategoryManagement/Widgets/WidgetLarge.vue"
import WidgetSmall from "@/general/Components/2.0/CategoryManagement/Widgets/WidgetSmall.vue"

export default {
  components: {
    BackgroundLayer,
    SearchBlock,
    WidgetBLockLarge,
    WidgetBlockSmall,
    Title,
    LogoHeader,
    WidgetLarge,
    WidgetSmall,
  },

  data: () => {
    return {
      widgets: [],
    }
  },

  computed: {
    store: function () {
      return this.$store.state
    },
    colors: function () {
      return this.store.client_data.color
    },

    widget_category: {
      get() {
        return this.store.environment.setWidget.item.category
      },
      set(value) {
        this.store.environment.setWidget.item.category = value
      },
    },

    widgets_featured: function () {
      return this.widgets.filter((widget) => widget.featured == true)
    },
    widgets_notfeatured: function () {
      return this.widgets.filter((widget) => widget.featured == false)
    },
  },

  async created() {
    const wCollection = new WidgetCollection()
    this.widgets = await wCollection.getAll(this.widget_category)
  },

  methods: {
    async setSearch(searchTerm) {
      const wCollection = new WidgetCollection()
      this.widgets = await wCollection.search(searchTerm, this.widget_category)
    },
  },
}
</script>

<style scoped>
.w-layout-grid.large-widget-grid {
  padding-bottom: 10%;
}
</style>
