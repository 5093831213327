<template>
  <div v-if="widgets.length > 0" class="w-layout-grid small-widget-grid">
    <div v-for="(widget, index) in widgets" :key="index" class="full">
      <template v-if="!widget.featured">
        <WidgetSmall :widget_name="widget.name" :widget_title="widget.title" />
      </template>
    </div>
  </div>
</template>

<script>
import WidgetSmall from "./WidgetSmall.vue"

export default {
  components: {
    WidgetSmall,
  },
  props: ["widgets"],
  data: () => {
    return {
      // widgets: [
      //   {
      //     title: "Facebook",
      //     image: "/images/games.svg",
      //   },
      //   {
      //     title: "Instagram",
      //     image: "/images/games.svg",
      //   },
      // ],
    }
  },
}
</script>

<style scoped>
.full {
  height: 100%;
  width: 100%;
  margin-bottom: 5%;
}
</style>
