import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import firebase from "firebase/compat/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getAuth } from "firebase/auth";

// Config
import config from "./config.js";

// Initializing firebase app
const app = firebase.initializeApp(config);

const db = app.firestore();
const auth = app.auth();

const auth2 = getAuth(app);

var analytics: any = null;
var perf = null;

if (typeof window !== "undefined") {
  analytics = getAnalytics(app);
  perf = getPerformance(app);
}

export { auth, auth2, db, analytics, perf, logEvent, firebase };
