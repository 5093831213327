<template>
  <div class="section-wcategories">
    <BackgroundLayer :color1="colors[0]" :color2="colors[1]" />
    <div class="front-layer">
      <LogoHeader />
      <div class="white-background padding">
        <div class="wm-frame center">
          <Title
            :input="'Widget'"
            :showCross="'Main'"
            :text="'Add ' + widget.title"
            @return="titleOutput"
          />
          <!-- <div class="icon-grey-div"></div> -->
          <div v-if="isLoaded" class="extra-space">
            <Counter :request_date="request_date" :total_days="days" :colors="colors" />
          </div>
          <h1 class="heading3 extra-space">
            Thank you for your request.<br />It will cost in total {{ days }} days to
            prepare your widget!
          </h1>
        </div>

        <Button
          :opacity="''"
          :text="visibility_widget"
          :inputColor="visibility_widget == 'Delete Widget' ? 'orange' : null"
          @isClicked="changeVisibilityWidget"
        />
        <Button
          :opacity="''"
          :text="'Cancel Request'"
          :inputColor="'red'"
          @isClicked="cancelWidget"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonBlock from "@/general/Components/2.0/CategoryManagement/General/ButtonBlock.vue"
import BackgroundLayer from "@/general/Components/2.0/General/BackgroundLayer.vue"
import Title from "@/general/Components/2.0/CategoryManagement/General/Title2.vue"
import LogoHeader from "@/general/Components/2.0/CategoryManagement/General/LogoHeader.vue"

import WidgetRepository from "@/general/Objects/Widget"
import WidgetCollection from "@/general/Objects/WidgetCollection"

import Button from "@/general/Components/2.0/CategoryManagement/General/Button.vue"

import Counter from "@/general/Elements/2.0/OtherElements/Counter.vue"

export default {
  components: {
    ButtonBlock,
    BackgroundLayer,
    Title,
    LogoHeader,
    Button,
    Counter,
  },
  data: () => {
    return {
      days: -1,
      visibility_widget: "Delete Widget",
      request_date: 0,
      isLoaded: false,
    }
  },
  async created() {
    const wObject = new WidgetRepository()
    const widget_legend = await wObject.getWidgetByName(this.widget.name)
    this.days = widget_legend.days

    const wCol = new WidgetCollection()
    this.request_date = (await wCol.getAllAccounts(this.widget.name))[0].request_date

    if (this.widget.account_uid == null) {
      this.widget.title = widget_legend.title
      this.visibility_widget = "Show Widget"
    }

    this.isLoaded = true
  },
  methods: {
    titleOutput(item) {
      if (item == "cross") {
        this.$router.push({ name: "Main" })
      }
    },

    async changeVisibilityWidget() {
      const wCol = new WidgetCollection()
      if (this.visibility_widget == "Delete Widget") {
        // delete widget from profile
        await wCol.delete(this.widget.type, this.widget.widget_uid)
      } else if (this.visibility_widget == "Show Widget") {
        // add widget to profile
        const account = (await wCol.getAllAccounts(this.widget.name))[0]

        const widget = await wCol.add(
          this.widget.type,
          account.uid,
          this.widget.name,
          this.widget.title,
          this.widget.image
        )
      }

      this.$router.push({ name: "Main" })
    },

    async cancelWidget() {
      // send cancel email
      await this.sendEmail()

      // delete account
      const wObject = new WidgetCollection()
      await wObject.deleteAccount(this.widget.account_uid)

      console.log(this.widget)

      // delete widget
      await wObject.delete(this.widget.type, this.widget.widget_uid)

      this.$router.push({ name: "Main" })
    },
    async sendEmail() {
      var response = await this.axios.post(
        "https://api.themozo.app/general/email/sendEmail",
        {
          auth_token: "12345",
          email: "widget-request@trymozo.com",
          title:
            "CANCELED: #" +
            this.widget.widget_uid +
            " Widget Request - " +
            this.widget.name +
            " - " +
            this.store.client_data.url,
          message:
            "Ohhww!<br>" +
            "The feature " +
            this.widget.name +
            " with id: #" +
            this.widget.widget_uid +
            " is canceled for user: " +
            this.store.client_data.url +
            "</b><br><br>" +
            "If there are any questions, just sent an email to gijs@trymozo.com." +
            "<br><br>Yours faithfully,<br>Gijs de Leeuw",
        }
      )
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    colors: function () {
      return this.store.client_data.color
    },
    widget: function () {
      return this.store.environment.setWidget.item
    },
  },
}
</script>

<style scoped>
.extra-space {
  margin-bottom: 5%;
}
</style>
