<template>
  <div>
    <div class="div-margin-topbottom">
      <div @click="isClicked" class="paragraph black margin-top">Forgot password?</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data: () => {
    return {}
  },
  methods: {
    isClicked() {
      this.$router.push({ name: "ResetPassword" })
    },
  },
  computed: {},
}
</script>

<style scoped>
.div-margin-topbottom {
  margin-bottom: 0px !important;
}
.paragraph.black.margin-top {
  cursor: pointer;
}
</style>
