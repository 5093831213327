<template>

    <div class="popup-div lightgreen">
        <h1 class="small-popup-heading white">Algemene voorwaarden</h1>
        <h1 class="subheading-small white">Notice</h1>
        <div class="text-block-div left bottommarge-div">We and selected third parties use cookies or similar
            technologies for technical purposes and, with your consent, for other purposes as specified in the cookie
            policy. Denying consent may make related features unavailable. You can consent to the use of such
            technologies by using the “Accept” button.
            By closing this notice, you continue without accepting..
        </div>
        <h1 class="subheading-small white">Newsletter</h1>
        <div class="text-block-div left bottommarge-div">To receive a voucher, please subscribe to our newsletter and
            daily news. Don’t worry, you can unsubscribe at any time.</div>
        <div @click="next()" class="button-popup white">
            <div class="text-block-light green">Accept</div>
        </div>
        <div @click="close()" class="close-button-div-popup">
            <img src="images/close.png" loading="lazy" class="cross-image white">
        </div>
    </div>


</template>

<script>
export default {
    created() {

    },
    methods: {
        close() {
            this.$emit('close', true)
        },
        next() {
            this.$emit('next', true)
        }
    }

}

</script>

<style>
.button-popup {
    cursor: pointer;
}
</style>