<template>
  <div>
    <h2 class="label">Table names</h2>
    <div class="selectfield border">
      <div v-for="(table, index) in tables" :key="index" class="tablecheckbox">
        <Row
          :tableName="table.tableName"
          :id="table.id"
          :i_active="getValue(index, table)"
          @switched="setStatus"
        />
      </div>
    </div>
    <p style="color: red" v-if="error.show">{{ error.message }}</p>
  </div>
</template>

<script>
import Row from "@/general/Components/2.0/TableManagement/NewGroup/Row.vue"
import ArrayRepository from "@/general/Objects/ArrayRepository"

export default {
  props: ["tables", "selectedTables", "error"],
  data: () => {
    return {
      ids: new ArrayRepository([]),
    }
  },

  components: {
    Row,
  },
  computed: {
    store: function () {
      return this.$store.state
    },
  },
  async created() {
    await this.ids.addItems(this.selectedTables)
  },
  methods: {
    async setStatus(id, add) {
      if (add) {
        await this.ids.addItems([id])
      } else {
        await this.ids.removeItems([id])
      }

      const tables = await this.ids.getAllItems()
      this.$emit("selected_tables", tables)
    },

    getValue(index, table) {
      return this.selectedTables.includes(table.id)
    },
  },
}
</script>
