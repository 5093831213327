<template>
  <div class="center" v-if="isLoaded">
    <div class="nd-img-apps">
      <slider ref="slider" @slide="slide" :options="options" class="q-slider-mask">
        <slideritem v-for="index in chunkedArr()" :key="index" class="slide q-slide">
          <template v-if="!config">
            <CollectionWidgets :index="index" :input_widgets="input_widgets" />
          </template>
          <template v-else>
            <CollectionWidgetsConfig
              :index="index"
              :input_widgets="input_widgets"
              @returnWidgets="changeWidgets"
            />
          </template>
        </slideritem>
      </slider>
    </div>

    <SliderNav :numberOfSlides="numberOfSlides()" :current="currentPage" @isClicked="setSlide" />
  </div>
</template>

<script>
import { slider, slideritem } from "vue-concise-slider"

import CollectionWidgets from "@/general/Elements/2.0/NoConfig/CollectionImgWidgets.vue"
import CollectionWidgetsConfig from "@/general/Elements/2.0/Config/CollectionImgWidgets.vue"
import SliderNav from "@/general/Elements/2.0/OtherElements/SliderNav.vue"

export default {
  components: {
    SliderNav,

    slider,
    slideritem,

    CollectionWidgets,
    CollectionWidgetsConfig,
  },
  props: [],

  created() {},

  data: () => {
    return {
      options: {
        autoplay: false,
        loop: true,
        speed: 1000,
        pagination: false,
        slidesToScroll: 1,
        loopedSlides: 1,
        centeredSlides: false,
        preventRebound: false,
        preventDocumentMove: false,
        currentPage: 0,
      },
      currentPage: 0,
      isLoaded:true
    }
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    config: function () {
      var x = this.store.environment.config
      if (x && this.input_widgets.length == 1) {
        this.options.loop = false
      } else {
        this.options.loop = true
      }
      return this.store.environment.config
    },
    input_widgets: {
      get() {
        return this.store.widgets.image_collection
      },
      set(value) {
        this.store.widgets.image_collection = value
      },
    },
  },
  watch: {
    config: {
      handler() {},
    },
  },

  methods: {
    slide(data) {
      this.currentPage = data.currentPage + 1
    },

    setSlide(value) {
      this.$refs.slider.$emit('slideTo', value-1)
    },

    changeWidgets(value) {
      this.input_widgets = value
    },

    round(value) {
      return Math.ceil(value)
    },

    chunkedArr() {
      const result = []
      for (let i = 0; i < this.numberOfSlides(); i += 1) result.push(i)
      return result
    },

    numberOfSlides() {
      if (!this.config) {
        return this.input_widgets.length
      }
      return this.input_widgets.length + 1
    },

    getStyle() {
      return "transform: translate3d(0px, 0px, 0px)"
    },
  },
}
</script>

<style scoped>
.center {
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.nd-img-apps {
  width: 100%;
}

.nd-imgapps {
  width: 100%;
}

.slide {
  width: 45%;
  margin-right: 5%;
  padding: 15px 0px 15px 0px;
}

.w-slider-mask {
  width: 100%;
}

.slider-container {
  max-width: 550px;
}

.full-width {
  width: 100%;
  max-width: 550px;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .slider-container {
    max-width: 100%;
    padding-left: 5%;
  }

  .slide {
    width: 70%;
  }

  .full-width {
    max-width: none;
  }

  .nd-img-apps {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
