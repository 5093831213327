<template>
  <div class="contact-popup">
    <div class="popup-scr">
      <div class="div-block-70">
        <div class="logo-pop-up border">
          <img :src="brand.logoblack_square" loading="lazy" alt="mozo hospitality" class="logo-img" />
        </div>
      </div>
      <div class="div-block-71">
        <p class="text black-txt">{{ title }}</p>
        <p class="paragraph black">
          {{ text }}
        </p>
      </div>
      <div @click="doCopy" class="submit-button w-button">{{ button }}</div>
      <div @click="close" class="close-add-tile rightmarge">
        <img src="images/close.png" loading="lazy" alt="mozo hospitality" class="image-12" />
      </div>
    </div>
    <template v-if="copy">
      <Copy text1="" :text2="link" :copy="true" @back="close()" />
    </template>
  </div>
</template>


<script>
// in folder
import Copy from "./Alert.vue";

import json from "@/general/GlobalValues.json"

export default {
  components: {
    Copy,
  },
  props: ["link", "title", "text", "button"],
  data() {
    return {
      // title: 'Sorry..',
      // text: 'We have a small issue with Chrome and iOS. You can download the contact in your safari browser. <br><br>Go to Safari and paste the link in your url',
      // button: 'Copy link',
      brand: json['brand'],
      copy: false,
    };
  },

  created() {
    document.getElementsByClassName("body")[0].style.overflow = "hidden";
    document.getElementsByClassName("body")[0].style.height = "100vh";
  },


  methods: {
    doCopy() {
      this.copy = true;
    },

    close() {
      document.getElementsByClassName("body")[0].style.overflow = "visible";
      document.getElementsByClassName("body")[0].style.height = "auto";
      this.$emit("close", true);
    },
  },
};
</script>
<style>
.logo-img {
  max-width: 100px
}

.logo-pop-up {
  padding: 30px;
}
</style>