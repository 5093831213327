<template>
  <div
    class="background menu height"
    :style="
      'background:' +
      'linear-gradient(311deg, ' +
      color[0] +
      ',' +
      color[1] +
      ');'
    "
  >
    <div class="content">
      <div>
        <div class="header-column w-row">
          <div class="left-col menu w-col w-col-9">
            <LeftHeader />
          </div>
          <div class="right-col close-add-tile w-col w-col-3">
            <div class="settings-div">
              <a @click="handleClose()" class="link-block w-inline-block">
                <img
                  src="images/close-white.png"
                  loading="lazy"
                  alt=""
                  class="settings-image"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="menu-upper">
          <template v-if="!hideLogo">
            <a
              href="https://trymozo.com"
              target="_blank"
              class="menu-items w-inline-block"
              ><img
                src="/images/information.svg"
                loading="lazy"
                alt=""
                class="menu-icon"
              />
              <h1 class="heading-3 white left">Information</h1>
            </a>
          </template>
          <template v-if="hideLogo">
            <a
              href="https://adamand.co/over-ons/"
              class="menu-items"
              target="_blank"
            >
              <img
                src="/images/information.svg"
                loading="lazy"
                class="menu-icon"
              />
              <h1 class="heading-3 white left">About us</h1>
            </a>

            <a
              href="mailto:info@adamandco.nl"
              class="menu-items"
              target="_blank"
            >
              <img src="/images/mail.svg" loading="lazy" class="menu-icon" />
              <h1 class="heading-3 white left">Contact</h1>
            </a>
          </template>
        </div>
      </div>
      <div class="menu-bottom">
        <a
          v-if="!store.client_data.loginNeeded"
          href="/login"
          class="menu-items _2"
        >
          <img
            src="images/icon-log-out.svg"
            loading="lazy"
            alt=""
            class="menu-icon small"
          />
          <h1 class="heading6 white left">Login</h1>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// in elements 2.0
import LeftHeader from "@/general/Elements/2.0/Headers/LeftHeader.vue";

export default {
  components: {
    LeftHeader,
  },
  data() {
    return {};
  },
  computed: {
    store: function () {
      return this.$store.state;
    },
    menu: {
      get() {
        return this.store.environment.menu;
      },
      set(menu_state) {
        this.store.environment.menu = menu_state;
      },
    },
    color: function () {
      return this.store.client_data.color;
    },
    hideLogo: function () {
      return this.store.client_data.hideMozoLogo;
    },
  },
  methods: {
    handleClose() {
      this.menu = false;
    },
  },
};
</script>

<style scoped>
.header-column {
  padding-right: 0%;
  padding-left: 0%;
  display: flex;
}
</style>
