import json from "@/general/GlobalValues.json";
import { db } from "@/db/Firebase";

export default {
  async getAllPublicTables(uid, branch) {
    return await db
      .collection(json["collections"].users)
      .doc(uid)
      .collection(json["collections"].branches)
      .doc(branch)
      .collection(json["collections"].tablesPublic)
      .get()
      .then((snapshot) => {
        return snapshot.docs.map((doc) => {
          var data = doc.data();
          data["id"] = doc.id;
          return data;
        });
      })
      .catch((err) => {
        throw err.message;
      });
  },

  async getAllPublicGroups(uid, branch) {
    return await db
      .collection(json["collections"].users)
      .doc(uid)
      .collection(json["collections"].branches)
      .doc(branch)
      .collection(json["collections"].groupsPublic)
      .get()
      .then((snapshot) => {
        return snapshot.docs.map((doc) => {
          var data = doc.data();
          data["id"] = doc.id;
          return data;
        });
      })
      .catch((err) => {
        throw err.message;
      });
  },

  async setPublicTable(uid, branch, table, data) {
    return await db
      .collection(json["collections"].users)
      .doc(uid)
      .collection(json["collections"].branches)
      .doc(branch)
      .collection(json["collections"].tablesPublic)
      .doc(table)
      .set(data)
      .catch((err) => {
        throw err.message;
      });
  },

  async setPublicGroup(uid, branch, table, group) {
    return await db
      .collection(json["collections"].users)
      .doc(uid)
      .collection(json["collections"].branches)
      .doc(branch)
      .collection(json["collections"].groupsPublic)
      .doc(group)
      .set(data)
      .catch((err) => {
        throw err.message;
      });
  },

  async getPrivateTable(uid, branch, table) {
    return await db
      .collection(json["collections"].users)
      .doc(uid)
      .collection(json["collections"].branches)
      .doc(branch)
      .collection(json["collections"].tablesPrivate)
      .doc(table)
      .get()
      .then((doc) => {
        if (doc.exists) {
          var data = doc.data();
          data.id = doc.id;
          return data;
        }
      })
      .catch((err) => {
        throw err.message;
      });
  },

  async getPublicTable(uid, branch, table) {
    return await db
      .collection(json["collections"].users)
      .doc(uid)
      .collection(json["collections"].branches)
      .doc(branch)
      .collection(json["collections"].tablesPublic)
      .doc(table)
      .get()
      .then((doc) => {
        if (doc.exists) {
          var data = doc.data();
          data.id = doc.id;
          return data;
        }
      })
      .catch((err) => {
        throw err.message;
      });
  },

  async getPrivateGroup(uid, branch, group) {
    return await db
      .collection(json["collections"].users)
      .doc(uid)
      .collection(json["collections"].branches)
      .doc(branch)
      .collection(json["collections"].groupsPrivate)
      .doc(group)
      .get()
      .then((doc) => {
        if (doc.exists) {
          var data = doc.data();
          data.id = doc.id;
          return data;
        }
      })
      .catch((err) => {
        throw err.message;
      });
  },

  async getPublicGroup(uid, branch, group) {
    return await db
      .collection(json["collections"].users)
      .doc(uid)
      .collection(json["collections"].branches)
      .doc(branch)
      .collection(json["collections"].groupsPublic)
      .doc(group)
      .get()
      .then((doc) => {
        if (doc.exists) {
          var data = doc.data();
          data.id = doc.id;
          return data;
        }
      })
      .catch((err) => {
        throw err.message;
      });
  },
};
