var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown",class:_vm.foldout,attrs:{"id":"menu"}},[_c('h1',{staticClass:"heading-nd",attrs:{"id":"name"}},[_vm._v(_vm._s(_vm.client_data.nickname))]),_c('div',{staticClass:"txt-bio"},[_vm._v(_vm._s(_vm.client_data.bio1))]),_c('div',{staticClass:"menu-div-dropdown"},[(!_vm.hideLogo)?_c('div',{staticClass:"w-layout-grid fold-out-menu max-650px"},[_c('DownloadButton',{attrs:{"text":"Save in contacts","client_data":_vm.client_data,"items":Array.prototype.concat(
            ...[
              this.client_data.shortWidgets,
              this.client_data.imgSlider,
              this.client_data.widgetSlider,
            ]
          )}}),_c('RememberButton')],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }