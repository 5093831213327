<template>
  <div @click="create()" class="large-widget-div">
    <div class="plus-sign add-widget" :style="getColor()">+</div>
  </div>
</template>

<script>
export default {
  props: ["type"],
  computed: {
    store: function () {
      return this.$store.state
    },

    isShortWidgets: function () {
      return this.type == "small_tile_collection" ? "smaller-icon" : ""
    },

    color: function () {
      return this.store.client_data.color[0]
    },

    widget: {
      get() {
        return this.store.environment.editTableWidget
      },
      set(value) {
        this.store.environment.editTableWidget = value
      },
    },
  },

  methods: {
    create() {
      const new_widget = {
        accound_uid: null,
        action: "create",
        date_added: null,
        name: null,
        position: null,
        title: null,
        type: null,
        uid: null,
      }
      this.widget = new_widget
      this.$router.push({ name: "EditTableWidget" })
    },

    getColor() {
      return "color:" + this.color
    },
  },
}
</script>

<style scoped>
.tile.nd-small {
  background-image: none;
  font-size: 45px;
  font-weight: semi-bold;
  color: green;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin-top: 5%;
}

.tile.nd-small.smaller-icon {
  min-width: 70px;
  min-height: 70px;
  width: 70px;
  height: 70px;
}

@media only screen and (max-width: 479px) {
  .tile.nd-small.smaller-icon {
    min-width: 60px;
    min-height: 60px;
    width: 60px;
    height: 60px;
  }
}

.tile {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

.tile_newsocial.nd-apps {
  cursor: pointer;
}
</style>
