<template>
  <div class="_100 nobg">
    <div class="form-block no-margin w-form">
      <div v-on:submit.prevent class="field-collection">
        <div class="field-div horizontal">
          <select
            :value="setOption()"
            @change="changeOption"
            ref="option"
            class="link-type w-select"
          >
            <option v-for="(option, index) in options" :key="index">
              {{ option }}
            </option>
          </select>

          <template>
            <input
              v-on:keyup.enter="doSubmit"
              @change="changeField"
              @keyup="changeField"
              ref="field"
              :value="field.field"
              :placeholder="placeholder"
              :maxlength="length"
              required="true"
              class="text-field url w-input"
              type="text"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// general
import json from "@/general/GlobalValues.json"

// helper
import methods from "@/general/HelperMethods/General.js"

export default {
  props: ["widget", "field_input", "options", "isChange", "index"],

  created() {},

  data() {
    return {
      field_names: json["field_names"],
      input_length: json["input_length"],
      placeholders: json["placeholders"],
    }
  },

  computed: {
    field() {
      return this.field_input
    },

    length() {
      return this.input_length[this.field.name]
    },

    placeholder() {
      return methods.setPlaceholder(this.field.name, this.widget, this.placeholders)
    },
  },

  methods: {
    changeOption() {
      var variable = {
        index: this.index,
        name: this.$refs.option.value,
      }

      this.$emit("changeOption", variable)
    },

    changeField() {
      var variable = {
        index: this.index,
        field: this.$refs.field.value,
        option: this.$refs.option.value,
      }

      this.$emit("changeField", variable)
    },

    setOption() {
      if (this.isChange) {
        this.field.field = methods.split_for_input(this.field.name, this.field.field)

        return this.field.name
      } else {
        return this.field.name
      }
    },

    async doSubmit() {
      await this.changeOption()

      this.$refs.field.value = await methods.getLink(
        this.widget,
        this.$refs.field.value,
        this.field.name
      )

      if (this.field.name == "link") {
        var rawResponse = await this.axios.post(
          "https://api.themozo.app/general/v2/isIframeCompatible.php",
          {
            url: this.$refs.field.value,
          }
        )

        // this.field.isLocal = rawResponse;
      }

      // await this.changeField();
      // this.$emit("submit", true);
    },
  },
}
</script>
