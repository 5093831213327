<template>
  <div @click="isClicked" class="default-width center" :class="opacity">
    <div :class="'button-' + this.color + ' wm'">
      <img v-if="image" :src="'/images/' + image" class="button-icon" />
      <h1 v-if="text" class="button-text2 white">{{ text }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  props: ["text", "image", "opacity", "inputColor"],
  data: () => {
    return {
      color: "green",
    }
  },
  created() {
    if (this.inputColor != null) {
      this.color = this.inputColor
    }
  },
  methods: {
    isClicked() {
      this.$emit("isClicked", true)
    },
  },
}
</script>

<style scoped>
.button-div {
  cursor: pointer;
}

.opacitylow {
  opacity: 80%;
  cursor: default;
}

.button-red.wm {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0%;
  margin-bottom: 5%;
  padding: 4% 3%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: rgb(254, 108, 108);
  cursor: pointer;
}

.button-orange.wm {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0%;
  margin-bottom: 5%;
  padding: 4% 3%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: rgb(233, 123, 72);
  cursor: pointer;
}
</style>
