const general = [
  { name: 'URL', widget: 'url', options: [['link']] },
  { name: 'Webshop', widget: 'webshop', options: [['link']] },
  { name: 'Image', widget: 'image', options: [['link']] },
  { name: 'Phone', widget: 'phone', options: [['number']] },
  { name: 'Email', widget: 'mail', options: [['mail']] },
  { name: 'Maps', widget: 'maps', options: [['adress']] },
  { name: 'Company', widget: 'company', options: [['company'], ['job role'], ['location'], ['phone'], ['email']] },
  { name: 'Login', widget: 'login', options: [['link']] },
  { name: 'Register', widget: 'register', options: [['link']] },
  { name: 'File', widget: 'file', options: [['link']] },

  { name: 'BOB', widget: 'bob', options: [['link']] },
  { name: 'Twitter', widget: 'twitter', options: [['username', 'link']] },
  { name: 'Instagram', widget: 'instagram', options: [['username', 'link']] },
  { name: 'Linkedin', widget: 'linkedin', options: [['link']] },
  { name: 'TikTok', widget: 'tiktok', options: [['username', 'link']] },
  { name: 'Snapchat', widget: 'snapchat', options: [['username', 'link']] },
  { name: 'Facebook', widget: 'facebook', options: [['username', 'link']] },
  { name: 'Pinterest', widget: 'pinterest', options: [['link', 'username']] },
  { name: 'YouTube', widget: 'youtube', options: [['link']] },
  { name: 'Spotify', widget: 'spotify', options: [['link']] },
  { name: 'SoundCloud', widget: 'soundcloud', options: [['username', 'link']] },
  { name: 'Music', widget: 'applemusic', options: [['link']] },
  { name: 'Deezer', widget: 'deezer', options: [['link']] },
  { name: 'Tidal', widget: 'tidal', options: [['link']] },

  { name: 'Forms', widget: 'google-forms', options: [['link']] },
]

const standard_acc = [
  { name: 'Vinted', widget: 'vinted', options: [['link']] },
  { name: 'Speakrs', widget: 'speakrs', options: [['link']] },
  { name: 'Polarsteps', widget: 'polarsteps', options: [['link']] },

  { name: 'Telegram', widget: 'telegram', options: [['link']] },
  { name: 'WeChat', widget: 'wechat', options: [['username']] },
  { name: 'Skype', widget: 'skype', options: [['link']] },

  { name: 'Twitch', widget: 'twitch', options: [['username', 'link']] },
  { name: 'Amazon', widget: 'amazon', options: [['link']] },
  { name: 'Bol.com', widget: 'bol-com', options: [['link']] },

  { name: 'GitHub', widget: 'github', options: [['username', 'link']] },
  { name: 'Tikkie', widget: 'tikkie', options: [['link']] },

  { name: 'Payment', widget: 'ing', options: [['link']] },
  { name: 'Payment', widget: 'rabobank', options: [['link']] },
  { name: 'Payment', widget: 'snsbank', options: [['link']] },
  { name: 'Payment', widget: 'bunq', options: [['link']] },

  { name: 'Blinkist', widget: 'blinkist', options: [['link']] },

  { name: 'OnlyFans', widget: 'onlyfans', options: [['username', 'link']] },

  { name: 'Datepicker', widget: 'datumprikker', options: [['link']] },
  { name: 'Behance', widget: 'behance', options: [['username', 'link']] },

  { name: 'Patreon', widget: 'patreon', options: [['username', 'link']] },
  { name: 'Discord', widget: 'discord', options: [['link']] },
  { name: 'Gamertag', widget: 'xbox', options: [['gamertag']] },
  { name: 'Gamertag', widget: 'playstation', options: [['gamertag']] },


]



const premium_widgets = [
  { name: 'Shoes', widget: 'shoes', options: [['link']] },
  { name: 'Shirt', widget: 'shirt', options: [['link']] },
  { name: 'Trousers', widget: 'trousers', options: [['link']] },

  { name: 'Zuyd', widget: 'zuyd', options: [['link']] },
  { name: 'Week van Respect', widget: 'weekvanrespect', options: [['link']] },
  { name: 'VWO', widget: 'vwo', options: [['link']] },
  { name: 'Studie Xtra', widget: 'studie_xtra', options: [['link']] },
  { name: 'SSP', widget: 'ssp', options: [['link']] },
  { name: 'Spreekuur', widget: 'spreekuur', options: [['link']] },
  { name: 'Pestweb', widget: 'pestweb', options: [['link']] },
  { name: 'Mavo', widget: 'mavo', options: [['link']] },
  { name: 'Lesrooster', widget: 'lesrooster', options: [['link']] },
  { name: 'KrapNL', widget: 'krapnl', options: [['link']] },
  { name: 'Koning Willem 1', widget: 'koningwillemIcollege', options: [['link']] },
  { name: 'Ideeen Box', widget: 'ideeenbox', options: [['link']] },
  { name: 'ICT', widget: 'ict', options: [['link']] },
  { name: 'Huis73', widget: 'huis73', options: [['link']] },
  { name: 'Havo', widget: 'havo', options: [['link']] },
  { name: 'Halt', widget: 'halt', options: [['link']] },
  { name: 'Gevonden Voorwerpen', widget: 'gevonden_voorwerpen', options: [['link']] },
  { name: 'Feedback', widget: 'feedback', options: [['link']] },
  { name: 'Een Tegen Eenzaamheid', widget: 'eentegeneenzaamheid', options: [['link']] },
  { name: 'Deadline', widget: 'deadline', options: [['link']] },
  { name: 'Challenge', widget: 'challenge', options: [['link']] },
  { name: 'Brugklas', widget: 'brugklas', options: [['link']] },
  { name: 'Bijbaan', widget: 'bijbaan', options: [['link']] },
  { name: 'Aanvraag Buddy', widget: 'aanvraag_buddy', options: [['link']] },
  { name: 'Aanvraag Anoniem', widget: 'aanvraag_anoniem', options: [['link']] },


]


const hospitality = [
  { name: 'Tripadvisor', widget: 'tripadvisor', options: [['link']] },
  { name: 'Google Reviews', widget: 'googlereviews', options: [['link']] },
  { name: 'Yelp', widget: 'yelp', options: [['link']] },
  { name: 'Gevonden Voorwerpen', widget: 'gevonden_voorwerpen', options: [['link']] },
  { name: 'Thuisbezorgd', widget: 'thuisbezorgd', options: [['link']] },
  { name: 'Uber Eats', widget: 'ubereats', options: [['link']] },
  { name: 'Deliveroo', widget: 'deliveroo', options: [['link']] },
  { name: 'Untappd', widget: 'untappd', options: [['link']] },

  { name: 'WiFi', widget: 'wifi', options: [['WiFi name'], ['password']] },
  { name: 'Meatloverz', widget: 'meatloverz', options: [['link']] },
  { name: 'Favourites', widget: 'favourites', options: [['link']] },
  { name: 'Gluten Free', widget: 'glutenfree', options: [['link']] },
  { name: 'Halal', widget: 'halal', options: [['link']] },
  { name: 'Vegan', widget: 'vegan', options: [['link']] },

  { name: 'Originates', widget: 'whereitcomesfrom', options: [['link']] },
  { name: 'Area Map', widget: 'areamap', options: [['link']] },
  { name: 'Reservation', widget: 'reservation', options: [['link']] },
  { name: 'Park', widget: 'park', options: [['link']] },
  { name: 'Public transport', widget: 'publictransport', options: [['link']] },
  { name: 'Business hours', widget: 'businesshours', options: [['link']] },
  { name: 'Ticket', widget: 'ticket', options: [['link']] },
  { name: 'Gift', widget: 'gift', options: [['link']] },
  { name: 'Deal', widget: 'deal', options: [['link']] },
  { name: 'Food', widget: 'food', options: [['link']] },
  { name: 'Event', widget: 'event', options: [['link']] },
  { name: 'Drinks', widget: 'drinks', options: [['link']] },
  { name: 'Text', widget: 'textbox', options: [['title'], ['description'], ['image']] },
  { name: 'Jobs', widget: 'jobs', options: [['link']] },

  { name: 'Car', widget: 'car', options: [['link']] },
  { name: 'Covid-19', widget: 'covid', options: [['link']] },
  { name: 'Calendar', widget: 'calendar', options: [['link']] },
  { name: 'Feedback', widget: 'feedback', options: [['link']] },

  { name: 'Menu', widget: 'menu', options: [['link']] },
  { name: 'Menu Breakfast', widget: 'menu_breakfast', options: [['link']] },
  { name: 'Menu Lunch', widget: 'menu_lunch', options: [['link']] },
  { name: 'Menu Dinner', widget: 'menu_dinner', options: [['link']] },
  { name: 'Menu Child', widget: 'menu_child', options: [['link']] },
  { name: 'Menu Dessert', widget: 'menu_dessert', options: [['link']] },

  { name: 'Fietsroute', widget: 'fietsroute', options: [['link']] },
  { name: 'Scover', widget: 'scover', options: [['link']] },

  { name: 'Beer', widget: 'beer', options: [['link']] },
  { name: 'Book', widget: 'book', options: [['link']] },

  { name: 'mozo_30seconds', social: 'mozo_30seconds', options: [['link']] },
  { name: 'mozo_friends', social: 'mozo_friends', options: [['link']] },
  { name: 'mozo_order', social: 'mozo_order', options: [['link']] },
  { name: 'mozo_pay', social: 'mozo_pay', options: [['link']] },
  { name: 'mozo_wof', social: 'mozo_wof', options: [['link']] },

  { name: 'tipzy', social: 'tipzy', options: [['link']] },
  { name: 'typeform', social: 'typeform', options: [['link']] },
  { name: 'wof', social: 'wof', options: [['link']] },
]




const username_addons =
{
  twitter: '/',
  instagram: '/',
  linkedin: '/in/',
  behance: '/',
  tiktok: '/@',
  snapchat: '/add/',
  facebook: '/',
  youtube: '/',
  github: '/',
  pinterest: '/',
  twitch: '/',
  onlyfans: '/',
  soundcloud: '/',
  patreon: '/'
}



const local_widgets =
  [
    'xbox',
    'playstation',
    'company',
    'wifi'
  ]


const maps_var = ['adress', 'country', 'zip code', 'city']

const not_local =
  [
    'phone',
    'mail',
    'file',
    'twitter',
    'instagram',
    'linkedin',
    'tiktok',
    'snapchat',
    'facebook',
    'pinterest',
    'soundcloud',
    'spotify',
    'applemusic',
    'deezer',
    'tidal'
  ]




export { standard_acc, general, premium_widgets, hospitality, username_addons, local_widgets, maps_var, not_local }