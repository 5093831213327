<template>
  <div>
    <template v-if="!next">
      <div class="background mozo">
        <Header />
        <div class="page-body-2 without-footer center">
          <div class="form-div url">
            <h1 class="heading1">{{ title }}</h1>
            <p class="paragraph">{{ description }}</p>
            <div class="bottom-margin-div">
              <div class="form-block marge w-form">
                <div class="form-3">
                  <input
                    disabled
                    type="text"
                    class="text-field nofocusborder left w-input"
                    maxlength="15"
                    placeholder="https://themozo.app/"
                  />
                  <div class="typewriter-div">
                    <input
                      v-on:keyup.enter="getUrl"
                      @click="checkWriter()"
                      @change="checkWriter()"
                      ref="url"
                      type="text"
                      class="typewriter-input w-input"
                      :maxlength="max_url_length"
                      required="true"
                      autocorrect="off"
                      autocapitalize="off"
                    />
                    <div
                      v-if="writer"
                      @click="checkWriter()"
                      class="typewriter"
                      data-period="2000"
                      :data-type="autotyper"
                    >
                      <div class="typewriter-para"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <a @click="getUrl" class="submit-button w-button">{{ btn }}</a>
            </div>
            <template v-if="error !== ''">
              <div>
                <div class="error-message">{{ error }}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <Register :activationcode="null" :p_url="null" :c_url="custom_url" />
    </template>
  </div>
</template>

<script>
// in folder
import Register from "./Register1.vue"

// in
import Header from "@/general/Elements/2.0/Headers/Header1.vue"

export default {
  components: {
    Register,
    Header,
  },

  data() {
    return {
      title: "Choose your personal url!",
      description:
        "Welcome to the mozo platform. Creating an account is completely free. You can discover the platform just go through the steps or registering and discover the world of mozo.",
      btn: "Claim url",
      error: "",
      max_url_length: 15,
      autotyper:
        '[ "Enter your name", "Enter your company name", "Your playlist", "Your dogs name" ]',
      custom_url: "",
      next: false,
      writer: true,
    }
  },

  methods: {
    getUrl() {
      if (this.$refs.url.value !== "") {
        this.custom_url = this.$refs.url.value.toLowerCase()
        this.next = true
      } else {
        this.error = "You need to choose a url!"
      }
    },

    checkWriter() {
      if (this.writer === true) {
        this.writer = false
      }
      // else if (this.$refs.url.value === '') {
      //     this.writer = true;
      // }
    },
  },

  mounted() {
    var TxtType = function (el, toRotate, period) {
      this.toRotate = toRotate
      this.el = el
      this.loopNum = 0
      this.period = parseInt(period, 10) || 2000
      this.txt = ""
      this.tick()
      this.isDeleting = false
    }

    TxtType.prototype.tick = function () {
      var i = this.loopNum % this.toRotate.length
      var fullTxt = this.toRotate[i]

      if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1)
      } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1)
      }

      this.el.innerHTML = '<div class="typewriter-para">' + this.txt + "</div>"

      var that = this
      var delta = 200 - Math.random() * 100

      if (this.isDeleting) {
        delta /= 2
      }

      if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period
        this.isDeleting = true
      } else if (this.isDeleting && this.txt === "") {
        this.isDeleting = false
        this.loopNum++
        delta = 500
      }

      setTimeout(function () {
        that.tick()
      }, delta)
    }

    var elements = document.getElementsByClassName("typewriter")
    for (var i = 0; i < elements.length; i++) {
      var toRotate = elements[i].getAttribute("data-type")
      var period = elements[i].getAttribute("data-period")
      if (toRotate) {
        new TxtType(elements[i], JSON.parse(toRotate), period)
      }
    }
    // INJECT CSS
    var css = document.createElement("style")
    css.type = "text/css"
    css.innerHTML = ".typewriter > .typewriter-para { border-right: 0.08em solid #808080}"
    document.body.appendChild(css)
  },
}
</script>

<style scoped>
.text-field.nofocusborder.left {
  width: 300px !important;
  padding: 0% 5%;
}
</style>
