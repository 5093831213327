<template>
  <div>
    <h2 class="heading-32">Set Widget</h2>

    <template v-if="state === 1">
      <Step1
        heading="Share an widget!"
        button="Select widget"
        :widgets="selected_widget"
        :itemType="itemType"
        :color="color"
        :numberOfWidgets="numberOfWidgets"
        @selected="setSelectedWidget"
        @state="setState"
      />
    </template>
    <template v-else-if="state === 2 && isLoaded">
      <Step2
        heading="Set Widget"
        button="Add Widget"
        :widget="selected_widget"
        :options="options"
        :widget_exists="false"
        :color="color"
        @returnItem="doSubmit"
        @state="setState"
        @delete="doNothing"
      />
    </template>
  </div>
</template>

<script>
//in folder
import Step1 from "@/general/Components/2.0/TableManagement/EditWidget/SelectWidget_old.vue"
import Step2 from "@/general/Components/2.0/TableManagement/EditWidget/setWidgetData.vue"

// in elements 1.0
import Header from "@/general/Elements/2.0/Headers/HeaderAMIGO.vue"

// general
import methods from "@/general/HelperMethods/General.js"
import ArrayRepository from "@/general/Objects/ArrayRepository"
import TablesRepository from "@/general/Objects/TablesRepository"

export default {
  components: {
    Header,
    Step1,
    Step2,
  },
  props: ["widget_uid", "premium", "view", "itemType", "numberOfWidgets", "widget"],
  computed: {
    store: function () {
      return this.$store.state
    },
    changeWidget: {
      get() {
        return this.store.environment.editTableWidget
      },
      set(value) {
        this.store.environment.editTableWidget = value
      },
    },
    setWidget: {
      get() {
        return this.store.environment.addTableWidget
      },
      set(value) {
        this.store.environment.addTableWidget = value
      },
    },
    color: function () {
      return this.store.client_data.color
    },
    state: {
      get() {
        return this.store.environment.stateTableWidget
      },
      set(value) {
        this.store.environment.stateTableWidget = value
      },
    },
    tableID: function () {
      return this.store.environment.tableID
    },
  },
  data: () => {
    return {
      selected_widget: {
        index: null,
        item: {
          data: [
            {
              field: null,
              index: 0,
              name: "link",
            },
          ],
          position: null,
          name: null,
          title: null,
          image: null,
          isLocal: false,
          uid: "",
        },
        type: null,
      },

      options: null,

      changeTitle: false,

      doRedirect: false,

      isLoaded: false,
    }
  },

  async created() {
    // this.selected_widget = await methods.getAccountWidgets(this.premium, this.view, this.itemType);
    await this.setState(this.state)
  },
  watch: {
    itemType: {
      async handler() {
        this.state = 1
      },
      deep: true,
    },
    state: {
      async handler() {
        if (this.state == 1) {
          await this.setState(this.state)
        }
      },
      deep: true,
    },
  },

  methods: {
    async setState(state) {
      if (state === 0) {
        this.setWidget = {
          item: {
            type: null,
          },
        }
        this.$emit("mode", this.setWidget)
      } else if (this.changeWidget != null && state === 2) {
        const table = new TablesRepository()
        const arr = new ArrayRepository(
          (await table.getTables([this.tableID]))[0].widgets
        )
        const widget = (await arr.getItems([this.changeWidget], "uid"))[0]

        this.selected_widget.type = this.itemType
        this.selected_widget.item = {
          widget: widget.widget,
          title: widget.title,
          image: widget.image,
          data: widget.data,
        }

        var widgets = await methods.getAccountWidgets(this.premium, this.view, "no")

        this.options = await methods.getOptions2(
          { item: widget, type: this.itemType },
          widgets
        )

        await this.setSelectedWidget({
          widget: this.selected_widget,
          options: this.options,
        })
      } else if (state === 1) {
        this.selected_widget = await methods.getAccountWidgets(
          this.premium,
          this.view,
          this.itemType
        )
      }
    },

    async setSelectedWidget(selected_widget) {
      this.selected_widget = selected_widget.widget
      this.options = selected_widget.options
      this.selected_widget.item.uid = this.widget_uid

      this.state = 2
      if (this.changeWidget == null) {
        await this.setState(this.state)
      }

      this.isLoaded = true
    },

    async doSubmit(value) {
      // this.$emit("item", item);
      this.state = 0
      await this.setState(this.state)

      this.changeWidget = null
      this.setWidget = false
    },

    doNothing() {
      // method that runs in case something goes wrong, however doens't do anything
    },

    getBackground() {
      var color1 = this.color[0]
      var color2 = this.color[1]
      return (
        "background-image: repeating-radial-gradient(circle farthest-corner at 100% 0%, " +
        color1 +
        ", " +
        color2 +
        ");" +
        "background-position: 50% 30%, 0px 0px;" +
        "background-repeat: repeat, repeat;" +
        "background-attachment: fixed, scroll;"
      )
    },
  },
}
</script>

<style>
.white-div {
  padding: 0%;
  margin: 0%;
  width: 100% !important;
}

.white-div.large {
  height: auto;
  width: auto;
}

.page-body {
  padding: 0%;
}
</style>
