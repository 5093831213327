<template>
  <div class="header-card">
    <div class="_100 w-row">
      <div class="left-col direction w-col w-col-6">
        <div class="tile small">
          <Icon :widget="data.widget" :colors_arr="color[2].icons" />
        </div>
      </div>
      <div class="right-col left w-col w-col-6">
        <div class="div-block-52">
          <div class="open-app-txt marge">{{ data.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/general/Elements/2.0/OtherElements/Widgets/WidgetIcon.vue"

export default {
  components: {
    Icon,
  },

  props: ["data", "color"],
}
</script>
