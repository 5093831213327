
<template>
    <div>
        <template v-if="(!goToRegister) && (!goToLogin)">
            <div class="background mozo">
                    <Header/>
                    <div class="page-body-2 without-footer center">
                        <div class="form-div marge-up">
                            <h1 class="heading1">{{title}}</h1>
                            <p class="paragraph">{{description}}</p>
                            <div class="marge">
                                <a @click="goToRegister=true" class="submit-button w-button">{{btn1}}</a>
                            </div>
                            <div class="marge">
                                <a @click="goToLogin=true" class="submit-button _2 w-button">{{btn2}}</a>
                            </div>
                        </div>
                    </div>
                </div>
        </template>
        <template v-else-if="goToRegister">
            <Start
            :activationcode="activationcode"
            :p_url="p_url"
            :c_url="null"
            @back="goToRegister=false"/>
        </template>
        <template v-else-if="goToLogin">
            <Login
            :activationcode="activationcode"
            :product_url="p_url"
            @back="goToLogin=false"/>
           
        </template>
    </div>
</template>


<script>
// in folder
import Start from './Start.vue'
import Login from './Login.vue'

// General
import Header from '@/general/Elements/2.0/Headers/Header1.vue'

export default {
    components: {
        Start,
        Login,
        Header,
    },
    
    props: [
      'activationcode',
      'p_url',
    ],


    
    data() { 
        return {
            goToRegister: false,
            goToLogin: false,
            title: "New account or link to existing account?",
            description: "You could link your account to an existing mozo account, however it is also possible to create a new account for your mozo product",
            btn1: "New account",
            btn2: "Login",
        };
    },
  
  
};
</script>
