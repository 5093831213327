<template>
  <div>
    <template v-if="isLocal">
      <LocalWidget :data="item" @back="setLocal(false)" />
    </template>

    <template v-else>
      <template v-if="local.view == 'basic'">
        <LocalWidget :data="local.item" :color="client_data.color" @back="localBack" />
      </template>

      <template v-if="local.view == 'inapp'">
        <LocalWidget2 />
      </template>
      <template v-else>
        <ConfigScreen
          @setWidget="setAddWidget"
          @changeWidget="editTile"
          @localWidget="editLocal"
        />
      </template>
    </template>

    <template v-if="alertios">
      <Alert
        :link="'https://themozo.app/' + client_data.url"
        title="Sorry.."
        text="Apple blocks this function on Chrome 😒. You can use this function in your standard Apple browser: Safari"
        button="Copy link"
        @close="turnAlertOff"
      />
    </template>
  </div>
</template>

<script>
//in folder
import ConfigScreen from "./3.0/ConfigView.vue"

import LocalWidget from "@/general/Elements/2.0/OtherElements/Popups/WidgetPopUp.vue"
import LocalWidget2 from "@/general/Elements/2.0/OtherElements/Widgets/LocalWidgetIframe.vue"

// helper
import methods from "@/general/HelperMethods/General.js"

import db_storage from "@/db/2.0/storage.js"

import Alert from "@/general/Elements/2.0/OtherElements/Popups/Title_Text_CopyButton.vue"

export default {
  components: {
    ConfigScreen,
    LocalWidget,
    LocalWidget2,
    Alert,
  },

  data: () => {
    return {
      // client_data: {
      //   image: "",
      //   nickname: "",
      //   bio: "",
      //   score: 0,
      //   uid: "",
      //   color: [],
      //   premium: false,
      // },

      editorMode: true,
      setWidget: {
        item: {
          type: null,
        },
      },

      isLocal: false,
      isEditWidget: false,
      item: null,

      selectedWidget: "",

      cursor: "default",

      itemType: null,
    }
  },
  async created() {
    // this.hidePopUp(true);
  },

  computed: {
    store: function () {
      return this.$store.state
    },
    client_data: function () {
      return this.store.client_data
    },

    widgets: function () {
      return this.store.widgets
    },
    uid: function () {
      return this.store.user.uid
    },

    oItemsLength() {
      return this.widgets[this.itemType].length
    },

    local: function () {
      return this.store.environment.localWidget
    },

    menu: {
      get() {
        return this.store.environment.menu
      },
      set(value) {
        this.store.environment.menu = value
      },
    },

    popup: {
      get() {
        return this.store.environment.hidePopUp
      },
      set(popup) {
        this.store.environment.hidePopUp = popup
      },
    },

    alertios: {
      get() {
        return this.store.environment.alertios
      },
      set(value) {
        this.store.environment.alertios = value
      },
    },
  },

  methods: {
    setItemType(item) {
      if (item.type == "small_tile_collection") {
        this.itemType = "small_tile_collection"
      } else if (item.type == "tile_collection") {
        this.itemType = "tile_collection"
      } else if (item.type == "image_collection") {
        this.itemType = "image_collection"
      } else if (item.type == "banner_collection") {
        this.itemType = "banner_collection"
      }
    },

    setState(value) {
      this.editorMode = value

      if (value) {
        this.cursor = "grab"
      } else {
        this.cursor = "default"
      }
    },

    setMenu(value) {
      this.menu = value
    },

    hidePopUp(value) {
      this.popup = value
    },

    setEdit(value) {
      // if (value) {
      //   methods.setScrolling("hidden", "fixed", "100%")
      // } else {
      //   methods.setScrolling("visible", "static", "auto")
      // }
      this.isEditWidget = value
    },

    setLocal(value) {
      // if (value) {
      //   methods.setScrolling("hidden", "fixed", "100%")
      // } else {
      //   methods.setScrolling("visible", "static", "auto")
      // }
      this.isLocal = value
    },

    async setAddWidget(value) {
      // if (value.item.type != null) {
      //   await this.setItemType(value.item)

      //   methods.setScrolling("hidden", "fixed", "100%")
      // } else {
      //   methods.setScrolling("visible", "static", "auto")
      // }

      this.setWidget = value
    },

    async handleDelete(widget) {
      await this.setItemType(widget)

      var temp = await methods.deepcopy(this.widgets[this.itemType])
      this.deleteImage(widget.item.position)
      temp.splice(widget.item.position, 1, null)

      this.widgets[this.itemType] = await methods.sortPosition(temp, temp.length - 1)
      this.setEdit(false)
    },

    async deleteImage(index) {
      if (this.itemType == "image_widgets") {
        await db_storage.deleteProfilePicture(this.uid, "image_widgets", index)
      } else if (this.itemType == "banner") {
        await db_storage.deleteProfilePicture(this.uid, "banner", index)
      }
    },

    async changeItem(widget) {
      await this.setItemType(widget)

      this.widgets[this.itemType][widget.item.position].title = widget.item.title
      this.widgets[this.itemType][widget.item.position].data = widget.item.data
      this.widgets[this.itemType][widget.item.position].image = widget.item.image

      this.setEdit(false)
    },

    async setNewItem(submittedWidget) {
      await this.setItemType(submittedWidget.item.name)
      this.widgets[this.itemType].splice(this.oItemsLength, 1, submittedWidget.item)

      this.setEdit(false)
    },

    editTile(item) {
      this.item = item
      this.setEdit(true)
    },

    editLocal(item) {
      this.item = item
      this.setLocal(true)
    },

    turnAlertOff() {
      this.alertios = false
    },
  },
}
</script>
