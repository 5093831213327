<template>
  <div v-if="isLoaded">
    <template v-if="!store.client_data.loginNeeded || isUserB2C">
      <ScreenV2
        :client_data="client_data && user"
        :numberOfItems="9"
        :user="user"
        @setMenu="setMenu"
        :hidePopUp="setPopUp"
      />
    </template>
    <template v-else>
      <PrivateAccount />
    </template>
  </div>
</template>

<script>
import ScreenV2 from "./2.0 Hospitality/Main.vue"
import PrivateAccount from "./2.0 Hospitality/PrivateAccount.vue"

import db_b2c from "@/db/2.0/b2c"

export default {
  components: {
    ScreenV2,
    PrivateAccount,
  },
  data: () => {
    return {
      isLoaded: false,
    }
  },

  async created() {
    if (this.store.client_data.loginNeeded == null) {
      this.store.client_data.loginNeeded = false
    }
    let response

    if (this.user) {
      response = await db_b2c.isUserB2C(this.client_data.uid, this.user.uid)
    }

    if (response) {
      this.isUserB2C = true
    } else {
      this.isUserB2C = false
    }

    this.isLoaded = true
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    client_data: function () {
      return this.store.client_data
    },
    user: function () {
      return this.store.user
    },
    menu: {
      get() {
        return this.store.environment.menu
      },
      set(menu_state) {
        this.store.environment.menu = menu_state
      },
    },
    alertios: {
      get() {
        return this.store.environment.alertios
      },
      set(value) {
        this.store.environment.alertios = value
      },
    },
    hidePopUp: {
      get() {
        return this.store.environment.hidePopUp
      },
      set(value) {
        this.store.environment.hidePopUp = value
      },
    },
    isUserB2C: {
      get() {
        return this.store.environment.isUserB2C
      },
      set(value) {
        this.store.environment.isUserB2C = value
      },
    },
  },

  methods: {
    setMenu() {
      this.menu = true
    },

    setPopUp() {
      this.hidePopUp = true
    },

    setAlert() {
      this.alertios = true
    },
  },
}
</script>
