<template>
  <div class="nd-img-apps _2">
    <slider ref="slider" @slide="slide" :options="options" class="w-slider-mask">
      <slideritem v-for="index in chunkedArr()" :key="index" class="slide w-slide">
        <template v-if="!config">
          <CollectionWidgets
            :index="index"
            :input_widgets="input_widgets"
            :config="config"
            :widgetsPerPage="widgetsPerPage"
          />
        </template>
        <template v-else>
          <CollectionWidgetsConfig
            :index="index"
            :input_widgets="input_widgets"
            :config="config"
            :widgetsPerPage="widgetsPerPage"
            @returnWidgets="changeWidgets"
          />
        </template>
      </slideritem>
    </slider>
    <template v-if="chunkedArr().length != 1">
      <SliderNav
        :numberOfSlides="chunkedArr().length"
        :current="currentPage"
        @isClicked="setSlide"
      />
    </template>
  </div>
</template>

<script>
import { slider, slideritem } from "vue-concise-slider"

import SliderNav from "@/general/Elements/2.0/OtherElements/SliderNav.vue"
import CollectionWidgets from "@/general/Elements/2.0/NoConfig/CollectionWidgets.vue"
import CollectionWidgetsConfig from "@/general/Elements/2.0/Config/CollectionWidgets.vue"

export default {
  components: {
    CollectionWidgets,
    SliderNav,
    CollectionWidgetsConfig,

    slider,
    slideritem,
  },
  props: [],
  created() {},

  data: () => {
    return {
      options: {
        autoplay: false,
        loop: false,
        speed: 1000,
        pagination: false,
        slidesToScroll: 1,
        loopedSlides: 1,
        centeredSlides: false,
        preventDocumentMove: false,
        preventRebound: true,
      },
      currentPage: 1,
      widgetsPerPage: 5,
    }
  },
  methods: {
    slide(data) {
      this.currentPage = data.currentPage + 1
    },

    setSlide(value) {
      this.$refs.slider.$emit("slideTo", value - 1)
    },
    changeWidgets(value) {
      this.input_widgets = value
    },
    numberOfSlides() {
      if (this.input_widgets.length == 0) {
        return 1
      }
      return Math.round((this.input_widgets.length + 1) / this.widgetsPerPage)
    },
    chunkedArr() {
      const result = []
      for (let i = 0; i < this.numberOfSlides(); i += 1) result.push(i)
      return result
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    config: function () {
      return this.store.environment.config
    },
    input_widgets: {
      get() {
        return this.store.widgets.tile_collection
      },
      set(value) {
        this.store.widgets.tile_collection = value
      },
    },
  },
}
</script>

<style scoped>
.banner-slider {
  padding: 5%;
}

.slider-wrapper {
}

.nd-img-apps {
  padding-left: 5%;
  padding-right: 5%;
}

.slide {
  width: 100%;
}
</style>
