import store from "@/vuex/setup";

import db_friend from "@/db/2.0/user/friends.js";

import methods from "@/general/HelperMethods/General.js";

export default class Friend {
  //   findFriendships() {
  //     const user = store.state.user;
  //     const uid = user.uid;
  //     db_friend.getAllData;
  //   }

  async deleteFriends(selected) {
    var contacts = store.state.friends.contacts;
    var user = store.state.user;

    selected.forEach(async (doc) => {
      await db_friend.deleteData(user.uid, doc.uid);
    });

    store.state.friends.contacts = await methods.removeFromArray(
      contacts,
      selected
    );

    store.state.environment.contacts.selected = [];
    store.state.environment.contacts.clicked = false;
    store.state.environment.contacts.state = null;

    return store.state.friends.contacts;
  }
}
