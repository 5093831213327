<template>
  <div>
    <template v-if="menu">
      <AccountMenu />
    </template>
    <template v-else-if="view == 'load'">
      <WaitPage />
    </template>
    <template v-else-if="getView()">
      <template v-if="deleteAccount">
        <DeleteUser :url="client_data.url" :user="user" @getCheckValue="setDelete" />
      </template>

      <template v-if="changePassword">
        <ChangePassword
          :url="client_data.url"
          :user="user"
          @goAccount="changePassword = false"
        />
      </template>
      <template v-else>
        <div
          class="background mozo account"
          :style="setBackground(color_dict.bg1, color_dict.bg2)"
        >
          <div class="page-body-2 without-footer account">
            <div
              style="
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <Header :header_title="'Profile QR code'" :showLock="false" />
            </div>
            <div class="transp-body data-fillin account">
              <div class="form-div step1 margin-top">
                <template v-if="client_data.premium == true">
                  <div class="field-collection">
                    <div class="div-block-79">
                      <div class="field-div vertical">
                        <div class="paragraph left marge-down">Background color:</div>
                        <div class="div-block-75">
                          <div class="div-block-77">
                            <input
                              type="color"
                              class="colors"
                              :value="color_dict.bg1"
                              ref="bg1"
                              @input="setColor(0, null, $refs.bg1.value)"
                            />
                          </div>
                          <div class="div-block-77">
                            <input
                              type="color"
                              class="colors"
                              :value="color_dict.bg2"
                              ref="bg2"
                              @input="setColor(1, null, $refs.bg2.value)"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="div-block-80">
                        <a @click="resetBG()" class="link-block-10 w-inline-block"
                          ><img
                            src="images/undo-arrow.svg"
                            loading="lazy"
                            alt="mozo"
                            class="image-32"
                        /></a>
                      </div>
                    </div>
                  </div>
                  <div class="field-collection">
                    <div class="div-block-78">
                      <div class="tile_newsocial customize click">
                        <Icon
                          :widget="'webshop'"
                          :colors_arr="[
                            color_dict.icon1,
                            color_dict.icon2,
                            color_dict.icon3,
                          ]"
                        />
                      </div>
                      <div class="field-div vertical">
                        <div class="paragraph left marge-down">Icon colors:</div>
                        <div class="div-block-75 _2">
                          <div class="div-block-77">
                            <input
                              type="color"
                              class="colors"
                              :value="color_dict.icon1"
                              ref="icon1"
                              @input="setColor(2, 0, $refs.icon1.value)"
                            />
                          </div>
                          <div class="div-block-77">
                            <input
                              type="color"
                              class="colors"
                              :value="color_dict.icon2"
                              ref="icon2"
                              @input="setColor(2, 1, $refs.icon2.value)"
                            />
                          </div>
                          <div class="div-block-77">
                            <input
                              type="color"
                              class="colors"
                              :value="color_dict.icon3"
                              ref="icon3"
                              @input="setColor(2, 2, $refs.icon3.value)"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="div-block-80">
                        <a @click="resetIconColor()" class="link-block-10 w-inline-block"
                          ><img
                            src="images/undo-arrow.svg"
                            loading="lazy"
                            alt="mozo"
                            class="image-32"
                        /></a>
                      </div>
                    </div>
                  </div>
                </template>

                <div class="form-block w-form">
                  <!-- <div class="field-collection">
                    <Url :url="client_data.url" :url_type="'internet'" :withPassword="true" :email="client_data.email"
                      :uid="user.uid" @url="setUrl" @update="updateUrl" />
                    <div v-if="update.email" class="direct-link hide-account" />
                  </div> -->

                  <div class="field-collection">
                    <Email
                      :email="user.email"
                      :user="user"
                      @email="setEmail"
                      @update="updateEmail"
                    />

                    <div class="sub-div">
                      <div class="field-div vertical">
                        <div class="account-column w-row">
                          <div class="left-account-column w-col w-col-6">
                            <div>
                              <div class="paragraph left">
                                {{ field_names.firstname }}
                              </div>
                              <input
                                ref="firstname"
                                :value="client_data.firstname"
                                :maxlength="input_length.firstname"
                                :placeholder="field_names.firstname"
                                required="true"
                                class="text-field w-input"
                                type="text"
                                autocorrect="off"
                                autocapitalize="off"
                              />
                            </div>
                          </div>
                          <div class="right-account-column w-col w-col-6">
                            <div class="paragraph left">
                              {{ field_names.lastname }}
                            </div>
                            <input
                              ref="lastname"
                              :value="client_data.lastname"
                              :maxlength="input_length.lastname"
                              :placeholder="field_names.lastname"
                              required="true"
                              class="text-field w-input"
                              type="text"
                              autocorrect="off"
                              autocapitalize="off"
                            />
                          </div>
                        </div>
                        <div class="div-full-length">
                          <div class="paragraph left">
                            {{ field_names.nickname }}
                          </div>

                          <input
                            ref="nickname"
                            :value="client_data.nickname"
                            :maxlength="input_length.nickname"
                            :placeholder="field_names.nickname"
                            required="true"
                            class="text-field w-input"
                            type="text"
                            autocorrect="off"
                            autocapitalize="off"
                          />
                        </div>
                        <div class="div-full-length">
                          <div class="paragraph left">
                            {{ field_names.bio1 }}
                          </div>

                          <input
                            ref="bio1"
                            :value="client_data.bio1"
                            :maxlength="input_length.bio1"
                            :placeholder="field_names.bio1"
                            required="true"
                            class="text-field w-input"
                            type="text"
                            autocorrect="off"
                            autocapitalize="off"
                          />
                        </div>
                        <div class="div-full-length">
                          <div class="paragraph left">
                            {{ field_names.bio2 }}
                          </div>

                          <input
                            ref="bio2"
                            :value="client_data.bio2"
                            :maxlength="input_length.bio2"
                            :placeholder="field_names.bio2"
                            required="true"
                            class="text-field w-input"
                            type="text"
                            autocorrect="off"
                            autocapitalize="off"
                          />
                        </div>
                      </div>
                      <div id="form-footer">
                        <div class="button-div">
                          <a @click="updateVariables()" class="submit-button w-button">{{
                            btn_update
                          }}</a>
                        </div>

                        <div>
                          <div v-if="error !== ''" class="error-message">
                            {{ error }}
                          </div>
                          <div
                            v-if="(error === '') & (success !== '')"
                            class="success-message"
                          >
                            {{ success }}
                          </div>
                        </div>

                        <a
                          @click="setPassword(true)"
                          class="account-button w-inline-block"
                        >
                          <div class="arrow-txt adjustm">
                            {{ btn_changePass }}
                          </div>
                          <img
                            src="images/right-arrow.png"
                            loading="lazy"
                            class="arrow"
                          />
                        </a>
                        <!-- <a
                          @click="setDelete(true)"
                          class="account-button w-inline-block"
                        >
                          <div class="arrow-txt adjustm">{{ btn_delete }}</div>
                          <img
                            src="images/right-arrow.png"
                            loading="lazy"
                            class="arrow"
                          />
                        </a> -->
                      </div>
                      <div v-if="update.email" class="direct-link hide-account" />
                    </div>

                    <div v-if="update.url" class="direct-link hide-account" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import AccountMenu from "@/views/Menu/LoggedIn.vue"

import ChangePassword from "./ChangePassword.vue"
import DeleteUser from "./DeleteAccount.vue"

import json from "@/general/GlobalValues.json"
import methods from "@/general/HelperMethods/General.js"

import Email from "@/general/Elements/2.0/OtherElements/InputFields/Email.vue"
import Url from "@/general/Elements/2.0/OtherElements/InputFields/setUrl.vue"

import HeaderAMIGO from "@/general/Elements/2.0/Headers/Header1.vue"
import Header from "@/general/Components/2.0/TableManagement/General/Header2.vue"

import Icon from "@/general/Elements/2.0/OtherElements/Widgets/WidgetIcon.vue"
import WaitPage from "@/general/Elements/2.0/OtherElements/Popups/WaitPage.vue"

export default {
  components: {
    ChangePassword,
    DeleteUser,
    Email,
    Url,
    HeaderAMIGO,
    Header,
    Icon,
    WaitPage,
    AccountMenu,
  },

  props: [],
  computed: {
    store: function () {
      return this.$store.state
    },
    menu: function () {
      return this.store.environment.menu
    },

    view: function () {
      return this.store.environment.view
    },

    client_data: {
      get() {
        return this.store.client_data
      },
      set(input) {
        this.store.client_data = input
      },
    },

    color: {
      get() {
        return this.client_data.color
      },
      set(input) {
        this.client_data.color = input
        this.isColorChange = true
        this.isColorChange = false
      },
    },

    isColorChange: {
      get() {
        return this.store.environment.colorChanged
      },
      set(input) {
        this.store.environment.colorChanged = input
      },
    },

    user: {
      get() {
        return this.store.user
      },
      set(user) {
        this.store.user = user
      },
    },
  },
  data() {
    return {
      field_names: json["field_names"],
      input_length: json["input_length"],

      page_name: "Account",
      back_btn: "Profile",

      btn_update: "Update",

      btn_changePass: "Change Password",
      btn_delete: "Delete Account",

      changePassword: false,
      deleteAccount: false,

      error: "",
      success: "",

      update: {
        url: false,
        email: false,
      },

      color_dict: {
        bg1: null,
        bg2: null,
        icon1: null,
        icon2: null,
        icon3: null,
      },

      initialcolor: null,
    }
  },

  async created() {
    // await db_auth.userState().then((user) => {
    //   if (user) {
    //     this.user = user;
    //     this.initColors();
    //   } else {
    //     this.$router.push("/login");
    //   }
    // });
  },

  methods: {
    async getView() {
      if (this.view == "account") {
        await this.initColors()
        return true
      }
      return false
    },
    initColors() {
      this.temp_color = methods.deepcopy(this.color)
      this.setBackgroundColor(0, this.color[0])
      this.setBackgroundColor(1, this.color[1])
      this.setIconColor(0, this.color[2].icons[0])
      this.setIconColor(1, this.color[2].icons[1])
      this.setIconColor(2, this.color[2].icons[2])
    },
    async updateVariables() {
      const data = await this.setVariables()
      if (methods.objectIsEmpty(data)) {
        this.success = "All your personal data is up-to-date"
      } else {
        this.success = "You successfully updated your profile!"
      }
    },

    async setColor(index1, index2, value) {
      var color = this.color
      if (index2 == null) {
        await this.setBackgroundColor(index1, value)
        color[index1] = value
      } else {
        await this.setIconColor(index2, value)
        color[index1].icons[index2] = value
      }

      this.color = color
    },
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2)
    },
    setBackgroundColor(index, value) {
      if (index == 0) {
        this.color_dict.bg1 = value
      } else if (index == 1) {
        this.color_dict.bg2 = value
      }
    },
    setIconColor(index, value) {
      if (index == 0) {
        this.color_dict.icon1 = value
      } else if (index == 1) {
        this.color_dict.icon2 = value
      } else if (index == 2) {
        this.color_dict.icon3 = value
      }
    },

    setVariables() {
      var x = {}
      if (this.client_data.firstname !== this.$refs.firstname.value) {
        x["firstname"] = this.$refs.firstname.value
        this.client_data.firstname = this.$refs.firstname.value
      }
      if (this.client_data.lastname !== this.$refs.lastname.value) {
        x["lastname"] = this.$refs.lastname.value
        this.client_data.lastname = this.$refs.lastname.value
      }
      if (this.client_data.nickname !== this.$refs.nickname.value) {
        x["nickname"] = this.$refs.nickname.value
        this.client_data.nickname = this.$refs.nickname.value
      }
      if (this.client_data.bio1 !== this.$refs.bio1.value) {
        x["bio1"] = this.$refs.bio1.value
        this.client_data.bio1 = this.$refs.bio1.value
      }
      if (this.client_data.bio2 !== this.$refs.bio2.value) {
        x["bio2"] = this.$refs.bio2.value
        this.client_data.bio2 = this.$refs.bio2.value
      }

      return x
    },

    setDelete(value) {
      this.deleteAccount = value
    },

    setPassword(value) {
      this.changePassword = value
    },

    goBack() {
      this.$router.push({ name: "Main" })
    },

    async setUrl(url) {
      this.client_data.url = url
      this.update.url = false
    },

    async setEmail(email) {
      this.client_data.email = email
      this.update.email = false
    },

    updateUrl(value) {
      this.update.url = value
    },
    updateEmail(value) {
      this.update.email = value
    },

    async resetBG() {
      this.color = this.temp_color
    },

    async resetIconColor() {
      this.color = this.temp_color
    },
  },
}
</script>

<style scoped>
.div-full-length {
  width: 100%;
}

.page-body-2.without-footer.account {
  width: 100%;
}
@media screen and (min-width: 479px) and (max-width: 767px) {
  .header-column-tm {
    padding: 0% 5%;
  }
}
</style>
