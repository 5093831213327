

import json from '@/general/GlobalValues.json'
import { db } from '@/db/Firebase'




export default {

    //public widgets
    async setData(uid, data, group) {
        await db.collection(json['collections'].users).doc(uid).collection(json['collections'].widgets).doc(group).set({
            data: data,
        })
            .catch((err) => {
                throw err.message;
            })
    },

    async updateData(uid, widgets, group) {
        await db.collection(json['collections'].users).doc(uid).collection(json['collections'].widgets).doc(group).update({
            data: widgets
        })
            .catch((err) => {
                throw err.message;
            })
    },

    async resetData(uid, group) {
        await db.collection(json['collections'].users).doc(uid).collection(json['collections'].widgets).doc(group).update({ data: [] })
            .catch(() => {
                throw "Sorry couldn't reset your folder"
            });
    },

    async deleteData(uid, group) {
        await db.collection(json['collections'].users).doc(uid).collection(json['collections'].widgets).doc(group).delete()
            .catch(() => {
                throw "Sorry couldn't delete your folder"
            });
    },

    async getData(uid, group) {
        return await db.collection(json['collections'].users).doc(uid).collection(json['collections'].widgets).doc(group).get()
            .then(doc => {
                return doc.data();
            })
            .catch((err) => {
                console.log(err)
                throw "It seems that you don't have any widgets!"
            });
    },

}