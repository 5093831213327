<template>
  <div class="div-block-88">
    <div class="text-block-light title">Or send link to your E-mail</div>
    <div class="w-form">
      <label for="name" class="text-block-light">E-mail</label>
      <input type="text" class="text-field-2 w-input" :maxlength="input_length.email" placeholder="" ref="email" />
      <Button @clicked="submit" />
      <p v-if="error != ''" class="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import json from "@/general/GlobalValues.json";
import methods from "@/general/HelperMethods/General.js";

import Button from "@/general/Elements/2.0/OtherElements/Button.vue";

import db_auth from "@/db/2.0/auth.js"

export default {
  data: () => {
    return {
      input_length: json["input_length"],
      error: "",
    }

  },
  computed: {
    store: function () {
      return this.$store.state;
    },
    addUser: {
      get() {
        return this.store.environment.addUser;
      },
      set(value) {
        this.store.environment.addUser = value;
      }
    },

    contacts: {
      get() {
        return this.store.friends.contacts;
      },
      set(value) {
        this.store.friends.contacts = value;
      }
    }
  },
  components: {
    Button,
  },

  methods: {
    async submit() {

      if (!await methods.checkEmailExists(this.contacts, this.$refs.email.value)) {
        // this.addUser.user = {
        //   email: this.$refs.email.value
        // }
        this.addUser.view = 'toc'

        await db_auth.registerWithEmailLink(this.$refs.email.value)

        this.$router.push({
          name: 'EmailSent'
        })

      } else {
        this.error = "You are already friends!"
      }

    },

  },
};
</script>

<style scoped>
.error {
  color: #116c5b;
  font-weight: 500;
  padding: 6px 20px 6px 20px;
  background-color: white;
  border-radius: 6px;
  margin-top: 5%;

}
</style>
