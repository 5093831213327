import store from "@/vuex/setup"

import connectWidgets from "@/vuex/widgets-watcher.js"
import connectUserdata from "@/vuex/variable-watcher.js"

import GenericObject from "@/general/Objects/GenericObject";

import db_accounts from "@/db/2.0/accounts";
import db_user from "@/db/2.0/user/main.js";

import DataLayer from "@/general/Objects/DataLayer"
import User from "@/general/Objects/User"
import View from "@/general/Objects/View"

interface DataModel {
  uid: string | null
  url: string | null
  branch: string | null
  group: string | null
  table: string | null
  client_name: string | null
}

export default class Main {

  constructor() {

  }

  async setPage(): Promise<string> {


    var user = null;
    if (store.state.user == null) {
      user = await this.checkAuthState()
    } else {
      user = store.state.user;
    }

    const v: any = await db_accounts.getAllData(user.uid);


    store.state.saved_widgets = v;


    return "/edittablewidget"
  }



  async checkAuthState(): Promise<User> {
    const userClass = new User()

    await userClass.setUser()
    var user = userClass.getUser()

    userClass.storeUser(store.state)

    return user
  }
}
