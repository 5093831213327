<template>
  <div
    class="background mozo"
    :style="setBackground(client_data.color[0], client_data.color[1])"
  >
    <Header />
    <div style="width: 100%; min-height: 65vh">
      <div style="display: flex; justify-content: center; align-items: center">
        <Heading1
          :text="client_data.bio2"
          :color="client_data.color[2].icons[0]"
          @isClicked="goCommunity"
        />
      </div>
      <template v-if="!hideLogo">
        <ShortWidgets />
      </template>
      <ImgWidgets />
      <Widgets />
      <template v-if="hideLogo">
        <ShortWidgets />
      </template>
      <Banner />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/general/Components/2.0/Header.vue";
import ShortWidgets from "@/general/Components/2.0/ShortWidgets.vue";
import ImgWidgets from "@/general/Components/2.0/ImgWidgets.vue";
import Widgets from "@/general/Components/2.0/Widgets.vue";
import Banner from "@/general/Components/2.0/Banner.vue";
import Footer from "@/general/Components/2.0/Footer.vue";
import Heading1 from "@/general/Elements/2.0/OtherElements/HeadingFillin.vue";

import methods from "@/general/HelperMethods/General.js";

export default {
  name: "App",
  components: {
    Header,
    ShortWidgets,
    ImgWidgets,
    Widgets,
    Banner,
    Footer,
    Heading1,
  },
  props: [],
  computed: {
    store: function () {
      return this.$store.state;
    },
    client_data: {
      get() {
        return this.store.client_data;
      },
      set(value) {
        this.store.client_data = value;
      },
    },
    config: {
      get() {
        return this.store.environment.config;
      },
      set(value) {
        this.store.environment.config = value;
      },
    },
    setWidget: {
      get() {
        return this.store.environment.setWidget;
      },
      set(value) {
        this.store.environment.setWidget = value;
      },
    },
    changeWidget: {
      get() {
        return this.store.environment.changeWidget;
      },
      set(value) {
        this.store.environment.changeWidget = value;
      },
    },
    localWidget: {
      get() {
        return this.store.environment.localWidget;
      },
      set(value) {
        this.store.environment.localWidget = value;
      },
    },
    menu: function () {
      return this.store.environment.menu;
    },
    hideLogo: function () {
      return this.store.client_data.hideMozoLogo;
    },
  },

  created() {},

  methods: {
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2);
    },

    async goCommunity() {
      const axios = require("axios");

      const data = {
        user_id: this.store.user.uid,
      };



      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://apiv2.themozo.app/mini/create-session",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      try {
        const response = await axios.request(config);

        const token = response.data.session_uuid;

        window.open(
          "https://community.themozo.app/" +
            "i4AH0ci2IfYZ61FcCIWe2kZA3mh2" +
            "?token=" +
            token,
          "_self"
        );
      } catch (error) {
        console.log(error);
      }
    },
  },

  watch: {
    menu: {
      handler() {
        this.$emit("setMenu", this.menu);
      },
    },
    setWidget: {
      handler() {
        this.$emit("setWidget", this.setWidget);
      },
    },
    changeWidget: {
      handler() {
        this.$emit("changeWidget", this.changeWidget);
      },
    },
    localWidget: {
      handler() {
        this.$emit("localWidget", this.localWidget);
      },
    },
  },
};
</script>
