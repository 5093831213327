<template>
  <div>
    <template v-if="!isChromeIOS">
      <a :href="link" :download="download" class="button nd-button">
        <img
          src="images/contact-book.svg"
          loading="lazy"
          width="20"
          alt="mozo hospitality"
          class="image-17"
        />
        <div class="button-txt">{{ text }}</div>
      </a>
    </template>
    <template v-else>
      <div @click="setAlert()" class="button nd-button">
        <img
          src="images/contact-book.svg"
          loading="lazy"
          width="20"
          alt="mozo hospitality"
          class="image-17"
        />
        <div class="button-txt">{{ text }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import card from "@/general/Objects/VCFCard.js"

import methods from "@/general/HelperMethods/General.js"

export default {
  props: ["client_data", "items", "text"],
  components: {},

  data: () => {
    return {
      bnt_txt: "Add contact",
      link: null,
      download: null,
    }
  },
  async created() {
    await this.setProfileDownload()
  },

  computed: {
    isChromeIOS() {
      return methods.isChromeIOS()
    },
    store: function () {
      return this.$store.state
    },
    iosalert: {
      get() {
        return this.store.environment.alertios
      },
      set(value) {
        this.store.environment.alertios = value
      },
    },
  },

  methods: {
    setAlert() {
      this.iosalert = true
    },

    async setProfileDownload() {
      var text = await card.setCard(
        this.client_data,
        this.items.filter((a) => a)
      )

      const blob = new Blob([text], { type: "text/vcard" })

      this.link = "data:text/vcard;charset=utf-8," + encodeURIComponent(text)
      this.download = this.client_data.nickname + ".vcf"

      if (methods.isChromeIOS()) {
        this.link = "safari-https:" + URL.revokeObjectURL(blob)
      }
    },
  },
}
</script>
