
import { db } from "@/db/Firebase/index"
import json from "@/general/GlobalValues"
export default {
    async isUserB2C(b2b_uid, b2c_uid) {
        return await db
            .collection(json["collections"].users)
            .doc(b2b_uid)
            .collection("b2c")
            .doc(b2c_uid)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    var data = doc.data();
                    data.id = doc.id;
                    return data;
                }
            })
            .catch(() => {

            });
    }

}
