<template>
  <div @click="switchButton()" class="row">
    <div class="check">
      <div class="checkbox-green" :class="active ? 'checked' : ''"></div>
    </div>
    <h1 class="paragraph black-text _250px-width">{{ tableName }}</h1>
  </div>
</template>

<script>
export default {
  props: ["tableName", "id", "i_active"],
  data: () => {
    return {
      active: false,
    }
  },
  created() {
    if (this.i_active) {
      this.active = this.i_active
    }
  },
  methods: {
    switchButton() {
      this.active = !this.active
      this.$emit("switched", this.id, this.active)
    },
  },
}
</script>

<style scoped>
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.checkbox-green {
  width: 80%;
  height: 80%;
  border-radius: 4px;
}

.checkbox-green.checked {
  margin: 10%;
  background-color: #70bcae;
}

.div-block-121 {
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  padding-top: 0.5%;
  padding-right: 0.5%;
  padding-left: 0.5%;
  padding-bottom: 0.5%;
}
</style>
