import store from "@/vuex/setup";
import { IBranchRepository } from "@/general/Interfaces/Branch.interface";

import ArrayRepository from "./ArrayRepository";

import methods from "@/general/HelperMethods/General";
import TablesRepository from "./TablesRepository";
import GroupsRepository from "./GroupsRepository";

import db_group from "@/db/2.0/TableManagement/GroupPublic.js";

export default class TableGroupsRepository implements IBranchRepository {
  private branchID: string;

  constructor(branchID: string) {
    this.branchID = branchID;
  }
  async getAllGroups(): Promise<Object[] | null> {
    return store.state.groups.groupCollection;
  }

  async addGroup(
    groupName: string,
    tables: Array<string>
  ): Promise<Object[] | null> {
    if (store.state.groups.groupCollection == null) {
      return null;
    }

    if (groupName == null || tables == null) {
      return null;
    }

    if (groupName == "" || (methods.isArray(tables) && tables.length == 0)) {
      return store.state.groups.groupCollection;
    }

    var position = store.state.groups.groupCollection.length;
    var newGroup = {
      id: "g_" + methods.randomGenerator(5) + position,
      groupName: groupName,
      active: true,
      position: position,
    };

    var groups = new ArrayRepository(store.state.groups.groupCollection);
    var groups_addedItem = await groups.addItems([newGroup]);

    await db_group.addGroup(store.state.user!['uid'], "b1", newGroup.id,
      { groupName: newGroup.groupName, active: newGroup.active, position: newGroup.position })




    var t_repo = new TablesRepository();
    var allTables_groupsAdded = await t_repo.addProperties(tables, [
      { key: "group", value: [newGroup.id] },
    ]);


    return groups_addedItem;
  }
  async removeGroups(ids: string[]): Promise<Object[] | null> {
    if (store.state.groups.groupCollection == null) {
      return null;
    }

    if (ids == null || ids.length <= 0) {
      return null;
    }

    if (methods.isArray(ids) && ids.length == 0) {
      return store.state.groups.groupCollection;
    }



    for (var i = 0; i < ids.length; i++) {
      var t_repo = new GroupsRepository(ids[i]);
      await t_repo.removeAllTables();

      // db: remove group from GroupsPublic -> id
      // removeGroup(id)
      await db_group.removeGroup(store.state.user!['uid'], "b1", ids[i])
    }

    // db: remove in all tables the group
    // for (var i=0;i<ids.length;i++) { db: updateTable(ids[i], "group", []) }

    var groups = new ArrayRepository(store.state.groups.groupCollection);
    var remaining_groups = await groups.removeItems(ids, "id");

    return remaining_groups;
  }
}
