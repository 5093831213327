import { render, staticRenderFns } from "./WidgetBLockLarge.vue?vue&type=template&id=3de38ce4&scoped=true&"
import script from "./WidgetBLockLarge.vue?vue&type=script&lang=js&"
export * from "./WidgetBLockLarge.vue?vue&type=script&lang=js&"
import style0 from "./WidgetBLockLarge.vue?vue&type=style&index=0&id=3de38ce4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3de38ce4",
  null
  
)

export default component.exports