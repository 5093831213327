<template>
  <div>
    <h2 class="label">{{ label }}</h2>
    <div class="select-div">
      <select @change="changeInput()" ref="field" class="inputfield margin-bottom">
        <option v-for="(item, index) in items" :key="index" :value="item[property]">
          {{ item[property] }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: ["label", "items", "default", "property"],
  data: () => {
    return {}
  },
  created() {},
  mounted() {
    this.$refs["field"].value = this.default
  },
  methods: {
    changeInput() {
      var index = this.$refs["field"].selectedIndex
      this.$emit("setInput", this.items[index])
    },
  },
}
</script>

<style scoped>
.select {
  border: none;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  max-width: 650px;
  padding: 12px 10px 12px 10px;
  border-radius: 12px;
  border-color: rgb(183, 183, 183);
}
</style>
