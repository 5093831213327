import Widget from "@/general/Objects/Widget"
import {
    AccountDataModel,
    IWidgetCollection,
} from "@/general/Interfaces/WidgetCollection"

import ArrayRepository from "@/general/Objects/ArrayRepository"
import { general, hospitality, WidgetModel } from "@/general/Legends/widgets"
import methods from "@/general/HelperMethods/General"
import GenericObject from "@/general/Objects/GenericObject"

import store from "@/vuex/setup"

import db_accounts from "@/db/2.0/accounts"
import db_storage from "@/db/2.0/storage.js"

type WidgetObject = {
    account_uid: string
    name: string
    title: string
    date_added: number
    uid: string
    position: number
    image: string
}

export default class WidgetCollection implements IWidgetCollection {
    async addAccount(widget: string, data: AccountDataModel[]): Promise<string | null> {
        if (widget == "" || widget == null) return null
        if (Object.keys(data).length === 0) return null

        const WidgetRepo = new Widget()
        const widget_legend = WidgetRepo.getWidgetByName(widget)!

        var status = "active"
        if (widget_legend.days > 0) {
            status = "requested"
        }

        var position = store.state.saved_widgets.length
        var new_account = {
            data: data,
            widget: widget,
            request_date: methods.getDataInSeconds(),
            status: status,
            uid: "a_" + methods.randomGenerator(5) + position,
            position: position,
        }

        var saved_widget = new ArrayRepository(store.state.saved_widgets)
        await saved_widget.addItems([new_account])

        await db_accounts.setData(store.state.user!["uid"], new_account)

        return new_account.uid
    }
    async editAccount(
        uid: string,
        widget: string,
        data: AccountDataModel[]
    ): Promise<boolean> {
        if (uid == "" || uid == null) return false
        if (widget == "" || widget == null) return false
        if (Object.keys(data).length === 0) return false

        const saved_widgets = new ArrayRepository(store.state.saved_widgets)
        var account = (await saved_widgets.getItems([uid], "uid"))![0]
        if (account == null) return false

        account.data = data
        account.widget = widget
        account.uid = uid

        if (account.bgColor) {
            delete account.bgColor
        }

        await saved_widgets.updateItems([uid], account, "uid")
        await db_accounts.updateData(store.state.user!["uid"], account)

        return true
    }

    async accountIsInUse(uid: string): Promise<any[] | null> {
        if (uid == "" || uid == null) {
            return null
        }

        const widgets: GenericObject[] = []

        const allWidgets = await this.getAllUsedWidgets("all")
        allWidgets.forEach((widget: GenericObject) => {
            if (widget.account_uid == uid) {
                widgets.push(widget)
            }
        })

        return widgets
    }

    async deleteAccount(uid: string): Promise<boolean | null | any> {
        if (uid == "" || uid == null) {
            return false
        }

        const remove_widget = new ArrayRepository(store.state.saved_widgets)
        await remove_widget.removeItems([uid], "uid")
        await db_accounts.deleteData(store.state.user!["uid"], uid)
        store.state.environment.setWidget.item.account_uid = null;
        return true
    }

    async getAccount(uid: string): Promise<any> {
        const accounts = new ArrayRepository(store.state.saved_widgets)
        const account = (await accounts.getItems([uid], "uid"))![0]

        return account
    }

    async getAccountStatus(account_uid: string): Promise<any | null> {
        if (account_uid == null || account_uid == "") return null

        const account = await this.getAccount(account_uid)

        if (account.status == "active") {
            return {
                request_date: account.request_date,
                status: account.status,
                days_before_activated: 0,
            }
        }

        const WidgetRepo = new Widget()
        const widget_legend = WidgetRepo.getWidgetByName(account.widget)!

        var days_left = 0
        if (widget_legend.days > 0) {
            const currentdate = new Date()
            const request_date = new Date(Number(account.request_date) * 1000)
            var oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
            days_left = Math.round(
                Math.abs((currentdate.getTime() - request_date.getTime()) / oneDay)
            )
        }

        return {
            request_date: account.request_date,
            status: account.status,
            days_before_activated: days_left,
        }
    }

    async deleteAccountProperty(property: string) {
        const accounts: GenericObject[] = store.state.saved_widgets
        await accounts.forEach((account) => {
            delete account[property]
        })
    }

    set_accounts(
        sort: boolean,
        accounts: GenericObject[],
        currentNumber: number
    ): GenericObject[] {
        const new_array = []
        if (sort) {
            for (var i = 0; i < accounts.length; i++) {
                if (i == currentNumber) {
                    accounts[i].bgColor = "green"
                    new_array.push(accounts[i])
                    break
                }
            }
        }
        for (var i = 0; i < accounts.length; i++) {
            if (i != currentNumber) {
                accounts[i].bgColor = ""
                new_array.push(accounts[i])
            } else if (!sort && i == currentNumber) {
                accounts[i].bgColor = "green"
                new_array.push(accounts[i])
            }
        }

        return new_array
    }

    async get(widget_uid: string, widget_type: string): Promise<Widget | null> {
        if (widget_uid == null || widget_uid == "") return null
        if (widget_type == null || widget_type == "") return null

        var widgets: GenericObject = store.state.widgets

        const widget_arrays = new ArrayRepository(widgets[widget_type])
        const widget = (await widget_arrays.getItems([widget_uid], "uid"))![0]

        return widget
    }

    isExistCategory(searchTerm: string, categoryName: string) {
        const legend = [...general, ...hospitality]
        let check = legend.filter((cls) => {
            if (categoryName && searchTerm == "") {
                return cls.category_id.includes(categoryName.toLowerCase())
            } else if (categoryName == "" && searchTerm) {
                return cls.name.includes(searchTerm.toLowerCase())
            } else if (categoryName && searchTerm) {
                if (
                    cls.category_id.includes(categoryName.toLowerCase()) &&
                    cls.name.includes(searchTerm.toLowerCase())
                ) {
                    return cls
                }
            }
        })
        return check
    }

    async search(
        widget_name: string,
        category_name: string = "all"
    ): Promise<Widget | null | any> {
        if (widget_name == null || category_name == null) return null

        const legend = [...general, ...hospitality]

        if (widget_name == "" && category_name == "all") {
            const array = new ArrayRepository(legend)
            const allWidgets = await array.getAllItems()
            return allWidgets
        } else if (
            category_name !== "all" &&
            this.isExistCategory(widget_name, category_name) &&
            widget_name == "" &&
            this.isExistCategory(widget_name, category_name).length > 0
        ) {
            return this.isExistCategory(widget_name, category_name)
        } else if (
            category_name !== "all" &&
            this.isExistCategory(widget_name, category_name) &&
            widget_name &&
            this.isExistCategory(widget_name, category_name).length > 0
        ) {
            return this.isExistCategory(widget_name, category_name)
        } else if (widget_name && category_name == "all") {
            var category_val: Array<WidgetModel> = []
            legend.forEach((o) => {
                if (o.name && o.name.includes(widget_name.toLowerCase())) {
                    category_val.push(o)
                }
            })
            return category_val
        } else {
            return []
        }
    }

    async getAll(category_name: string): Promise<Widget[] | null | any> {
        if (category_name == null || category_name == "") return null

        const legend = [...general, ...hospitality]

        if (category_name === "all") {
            const getArray = new ArrayRepository(legend)
            const all = await getArray.getAllItems()
            return all
        }

        if (category_name) {
            var category: Array<WidgetModel> = []

            legend.forEach((val) => {
                if (val.category_id === category_name.toLowerCase()) {
                    category.push(val)
                }
            })

            return category
        }
    }

    async getAllUsedWidgets(widget_type: string) {
        if (widget_type == null || widget_type == "") return null

        const widgetObject = await this.setType()

        var widget_arrays: any = []

        if (widget_type == "all") {
            widget_arrays = new ArrayRepository([])
            await widget_arrays.addItems(widgetObject["small_tile_collection"])
            await widget_arrays.addItems(widgetObject["image_collection"])
            await widget_arrays.addItems(widgetObject["tile_collection"])
            await widget_arrays.addItems(widgetObject["banner_collection"])

            return await widget_arrays.getAllItems()
        } else {
            return widgetObject[widget_type]
        }
    }

    setType() {
        var widgetObject: GenericObject = store.state.widgets

        widgetObject["small_tile_collection"].forEach((x: any) => {
            x.type = "small_tile_collection"
        })
        widgetObject["image_collection"].forEach((x: any) => {
            x.type = "image_collection"
        })
        widgetObject["tile_collection"].forEach((x: any) => {
            x.type = "tile_collection"
        })
        widgetObject["banner_collection"].forEach((x: any) => {
            x.type = "banner_collection"
        })

        return widgetObject
    }

    async getAllAccounts(widget_name: string): Promise<Widget[] | null | any> {
        if (widget_name == "" || widget_name == null) return false

        return store.getters.getWidgetById(widget_name)
    }

    async add(
        widget_type: string,
        account_uid: string,
        name: string,
        title: string,
        image: string
    ): Promise<WidgetObject | null> {
        if (widget_type == null || widget_type == "") return null
        if (account_uid == null || account_uid == "") return null
        if (name == null || name == "") return null
        if (title == null || title == "") return null
        if (image == null) {
            image = ""
        }

        const widgets: GenericObject = store.state.widgets

        const position: number = widgets[widget_type].length

        var new_widget = {
            account_uid: account_uid,
            name: name,
            title: title,
            date_added: methods.getDataInSeconds(),
            uid: "w_" + methods.randomGenerator(5) + position,
            position: position,
            image: image,
        }

        const widget_array = new ArrayRepository(widgets[widget_type])
        const new_array = await widget_array.addItems([new_widget])

        return new_widget
    }

    async addImageToWidget(user_uid: string, widget_type: string, widget_uid: string, file: any): Promise<string | null> {

        if (user_uid == null || user_uid == "") return null
        if (widget_type == null || widget_type == "") return null
        if (widget_uid == null || widget_uid == "") return null
        if (file == null || file == "") return null

        let image_url;

        if (widget_type == "temp") {
            image_url = await db_storage.addProfilePicture(user_uid, file, widget_type, widget_uid)
            return image_url;
        }

        const widgets: GenericObject = store.state.widgets
        const widget_array = new ArrayRepository(widgets[widget_type])
        let widget = (await widget_array.getItems([widget_uid], "uid"))![0]
        const temp_image = widget.image

        try {
            image_url = await db_storage.addProfilePicture(user_uid, file, widget_type, widget_uid)
            widget.image = image_url

            const isModified = await widget_array.updateItems([widget_uid], widget, "uid")
            return isModified ? image_url : null
        } catch {
            widget.image = temp_image
            return null
        }



    }
    //check widget type
    async checkWidgetType(widget_type: string) {
        const types = Object.keys(store.state.widgets).map((item) =>
            item.replace("_collection", "")
        )
        const checkTypeExist = types.includes(widget_type)
        return checkTypeExist
    }
    //check widget uid
    async checkWidgetUid(obj: Object, widget_uId: string) {
        let uidExist = Object.values(obj).some((key) => key.uid === widget_uId)
        return uidExist
    }

    async modify(
        widget_type: string,
        widget_uid: string,
        account_uid: string,
        name: string,
        title: string,
        image: string
    ): Promise<Widget[] | null> {
        if (widget_type == null || widget_type == "") return null
        if (widget_uid == null || widget_uid == "") return null
        if (account_uid == null || account_uid == "") return null
        if (name == null || name == "") return null
        // if (title == null || title == "") return null

        // TODO input checks

        const widgets: GenericObject = store.state.widgets

        const widget_array = new ArrayRepository(widgets[widget_type])
        var widget = (await widget_array.getItems([widget_uid], "uid"))![0]
        widget.name = name
        widget.title = title
        widget.account_uid = account_uid
        widget.image = image

        const isModified = await widget_array.updateItems([widget_uid], widget, "uid")

        return isModified ? widget : null
    }

    async delete(widget_type: string, widget_uid: string): Promise<Widget[] | null> {
        if (widget_type == null || widget_type == "") return null
        if (widget_uid == null || widget_uid == "") return null

        var widgets: GenericObject = store.state.widgets

        const widget_array = new ArrayRepository(widgets[widget_type])

        const removed_item = await widget_array.removeItems([widget_uid], "uid")

        return removed_item
    }

    async isActive(account_uid: string) {
        const account = await this.getAccount(account_uid)
        if (account.status == "active") {
            return true
        }

        const wObject = new Widget()
        const days_left = (await wObject.getWidgetByName(account.widget))!.days

        const currentTime = methods.getDataInSeconds()
        const max_installation_time = currentTime - days_left * 24 * 60 * 60

        return max_installation_time > account.request_date ? true : false
    }
}
