<template>
  <div v-if="widgets.length > 0" class="w-layout-grid large-widget-grid">
    <div v-for="(widget, index) in widgets" :key="index" class="full">
      <WidgetLarge :widget_name="widget.name" :widget_title="widget.title" />
    </div>
  </div>
</template>

<script>
import WidgetLarge from "./WidgetLarge.vue"

export default {
  components: {
    WidgetLarge,
  },
  props: ["widgets"],
  data: () => {
    return {}
  },
  created() {},
}
</script>

<style scoped>
.full {
  width: 100%;
  height: 100%;
}
</style>
