<template>
  <div class="heading-grid-wc">
    <div>
      <img
        v-if="showback"
        src="/images/arrow-ios-forward-fill_1.svg"
        @click="isClicked('showback')"
        class="arrow-small"
      />
    </div>
    <div class="heading-div center">
      <h1 class="heading-1 black center capitalize">{{ text }}</h1>
    </div>
    <div class="column-right">
      <img
        v-if="showCross"
        src="/images/cross-black.svg"
        @click="isClicked('showCross')"
        class="cross-img"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  props: ["showback", "showCross", "text"],
  methods: {
    isClicked(item) {
      this.$router.push({ name: this[item] })
    },
  },
}
</script>

<style scoped>
.arrow-small {
  cursor: pointer;
}

.cross-img {
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.capitalize {
  text-transform: capitalize;
}
</style>
