<template>
  <div @click="isClicked" class="largewidget">
    <div class="div-block-249">
      <div class="add-button-account large">
        <Icon
          :widget="widget.name"
          :colors_arr="widget_colors"
          :classInput="'social-icon'"
        />
      </div>
      <div>
        <div class="heading1 black-text">{{ getType(widget.type) }}</div>
        <template v-if="widget.title != 0">
          <div class="heading2 black-text">{{ widget.title }}</div>
        </template>

        <div class="heading2">{{ widget.name }}</div>
      </div>
    </div>
    <img src="/images/check_1.svg" loading="lazy" alt="" class="image-122 inverted" />
  </div>
</template>

<script>
import Icon from "@/general/Elements/2.0/OtherElements/Widgets/WidgetIcon2.vue"

export default {
  components: {
    Icon,
  },
  props: ["widget"],
  created() {
    this.widget
  },
  data: () => {
    return {}
  },
  methods: {
    isClicked() {
      this.$emit("isClicked", true)
    },
    getType(type) {
      if (type == "small_tile_collection") {
        return "Small Widgets"
      } else if ("tile_collection") {
        return "Normal Widgets"
      } else if ("image_collection") {
        return "Image Widgets"
      } else if ("banner_collection") {
        return "Banner Widgets"
      }
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    widget_colors: function () {
      return this.store.client_data.color[2].icons
    },
  },
}
</script>
