import store from "@/vuex/setup";

export default class LocalWidget {

    forgetLocalWidget() {
        store.state.environment.localWidget = {
            item: null,
            view: null
        };
    }
}