<template>
  <div
    class="w-layout-grid nd-grid-shortapps"
    :style="getGridStyle(input_widgets.length)"
  >
    <div v-for="(i, index) in input_widgets" :key="index">
      <Widgets :item="i" :index="index" :text="false" type="small_tile_collection" />
    </div>
  </div>
</template>

<script>
// in elements 2.0
import Widgets from "@/general/Elements/2.0/OtherElements/Widgets/Widgets.vue"

export default {
  components: {
    Widgets,
  },
  props: ["input_widgets", "index"],
  created() {},
  data: () => {
    return {}
  },
  methods: {
    getGridStyle(numberOfItems) {
      var frs = "grid-template-columns: "
      for (var i = 0; i < numberOfItems; i++) {
        frs = frs + "1fr "
      }
      return frs
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    config: function () {
      return this.store.environment.config
    },
  },
}
</script>

<style scoped>
@media screen and (max-width: 479px) {
  .nd-grid-shortapps {
    max-width: 220px;
    width: auto;
  }
}
</style>
