<template>
  <div>
    <Background />
    <div class="set-div-down">
      <div class="full withheader login">
        <div class="scroll-area no-mobile">
          <div class="footer-div absolute">
            <div class="inputfield-div marginbottom">
              <InputField
                :label="'Login'"
                :placeholder="'Login'"
                @pressEnter="login"
                @returnfield="setValue('username', $event)"
              />
              <InputField
                :label="'Password'"
                :type="'password'"
                placeholder="Password"
                @pressEnter="login"
                @returnfield="setValue('password', $event)"
              />

              <template v-if="error != ''">
                <Error :error="error" />
              </template>
            </div>
            <Button
              :color="client_data.color[2].icons[0]"
              :title="'Login'"
              @isClicked="login"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Background from "@/general/Components/2.0/PrivateProfile/Background.vue";
import InputField from "@/general/Components/2.0/PrivateProfile/InputField.vue";
import Error from "@/general/Components/2.0/PrivateProfile/Error.vue";
import Button from "@/general/Components/2.0/PrivateProfile/GreenButton.vue";
import ForgotPassword from "@/general/Components/2.0/PrivateProfile/ForgotPassword.vue";
import Register from "@/general/Components/2.0/PrivateProfile/Register.vue";

import { auth } from "@/db/Firebase/index";
import db_auth from "@/db/2.0/auth.js";

export default {
  components: {
    Background,
    InputField,
    Error,
    Button,
    ForgotPassword,
    Register,
  },
  async created() {
    await db_auth.checkState2(this.$router, "/" + this.store.client_data.url);
  },

  data: () => {
    return {
      error: "",
      username: null,
      password: null,
    };
  },
  computed: {
    store: function () {
      return this.$store.state;
    },
    url: function () {
      return this.store.client_data.url;
    },
    user: {
      get() {
        return this.store.user;
      },
      set(value) {
        return (this.store.user = value);
      },
    },
    isUserB2C: {
      get() {
        return this.store.environment.isUserB2C;
      },
      set(value) {
        this.store.environment.isUserB2C = value;
      },
    },
    client_data: function () {
      return this.store.client_data;
    },
  },

  methods: {
    setValue(key, value) {
      this[key] = value;
    },
    async login() {
      console.log(this.username);
      console.log(this.password);
      await auth
        .signInWithEmailAndPassword(this.username, this.password)
        .then(async (response) => {
          this.user = response.user;

          this.user
            .getIdToken()
            .then(function (idToken) {
              console.log("idToken", idToken);
              localStorage.setItem("idToken", idToken);

              console.log(localStorage.getItem("idToken"));
            })
            .catch(function (error) {
              // Handle errors
              console.error("Error getting ID token:", error);
            });

          window.open("/" + this.client_data.url, "_self");
        })
        .catch((err) => {
          let errorCode = err.code;

          if (errorCode === "auth/invalid-email") {
            this.error = "Sorry you have used the wrong username or password";
          } else if (errorCode === "auth/user-disabled") {
            this.error = "User account has been disabled";
          } else if (errorCode == "auth/too-many-requests") {
            this.error =
              "Sorry you have too many login attempts try it again over 10 minutes.";
          } else if (errorCode === "auth/user-not-found") {
            this.error = "Sorry you have used the wrong username or password";
          } else if (errorCode === "auth/wrong-password") {
            this.error = "Sorry you have used the wrong username or password";
          } else {
            this.error = "Sorry you have used the wrong username or password";
          }
        });
    },
  },
};
</script>

<style scoped>
.scroll-area.no-mobile {
  width: 90vw;
}

.background.mozo._100vh {
  height: 100vh;
}

.background-layer {
  min-height: 0px;
}

.set-div-down {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
}

.full.withheader {
  height: 100%;
}
</style>
