<template>
  <div class="background mozo">
    <template v-if="view == 'popup'">
      <PopUp
        title="You are already friends!"
        text="Please logout if you want be friends with another account!"
        :showCloseButton="true"
        button="Logout"
        @close="setClose()"
        @next="logout()"
      />
    </template>
    <template v-else-if="view == 'name'">
      <FillInName />
    </template>
    <template v-else-if="view == 'toc'">
      <TOC @close="setClose()" @next="setNext()" />
    </template>
  </div>
</template>

<script>
import FillInName from "@/general/Components/2.0/FriendsOf/FillInName.vue";
import TOC from "@/general/Components/2.0/FriendsOf/TermsOfConditions.vue";
import EmailResponse from "@/db/2.0/EmailResponse (auth).js";

import PopUp from "@/general/Components/2.0/FriendsOf/PopUp.vue";

import methods from "@/general/HelperMethods/General.js";

import db_auth from "@/db/2.0/auth.js";
import db_friend from "@/db/2.0/user/friends.js";

export default {
  components: {
    TOC,
    FillInName,
    PopUp,
  },
  props: ["input_user", "eResponse"],
  data: () => {
    return {
      showPopup: false,
      uid: null,
    };
  },

  async created() {
    if (this.input_user) {
      this.addUser = this.input_user;
      localStorage.setItem("addUser", JSON.stringify(this.input_user));
      methods.changeUrlWithoutReload("/addfriend");
    } else {
      this.addUser = await JSON.parse(localStorage.getItem("addUser"));
      this.user = await db_auth.userState();
    }

    if (this.eResponse) {
      var emailResponse = new EmailResponse();

      var confirmed = await emailResponse.confirmLink();

      if (confirmed) {
        if (await this.docExists()) {
          this.view = "popup";
        } else {
          this.view = "toc";
        }
        await db_auth.logout();
      } else {
        await db_auth.logout();
        this.$router.push("/" + this.addUser.profile_url);
      }
    } else {
      if (await this.docExists()) {
        this.view = "popup";
      } else {
        this.view = "name";
      }
    }
  },
  computed: {
    store: function () {
      return this.$store.state;
    },
    view: {
      get() {
        return this.store.environment.addUser.view;
      },
      set(value) {
        this.store.environment.addUser.view = value;
      },
    },
    addUser: {
      get() {
        return this.store.environment.addUser;
      },
      set(value) {
        this.store.environment.addUser = value;
      },
    },
    user: {
      get() {
        return this.store.user;
      },
      set(value) {
        this.store.user = value;
      },
    },
  },
  methods: {
    setClose() {
      this.addUser = {
        user: null,
        properties: {
          firstname: null,
          lastname: null,
        },
        view: "login",
        profile_url: this.addUser.profile_url,
        remembered: false,
        color: [],
        name: null,
        popup: false,
      };
      this.$router.push("/" + this.addUser.profile_url);
    },
    setNext() {
      this.view = "name";
    },
    async logout() {
      this.user = await db_auth.logout();
      this.view = "login";
      this.$router.push({
        name: "loginFriend",
        params: {
          input_user: this.addUser,
        },
      });
    },
    async docExists() {
      return await db_friend.docExists(this.addUser.user.uid, this.user.uid);
    },
  },
};
</script>
