<template>
  <div class="logo-header">
    <div class="logo-div">
      <template v-if="!hideLogo">
        <img class="logo-img" :src="brand.logo" loading="lazy" />
      </template>
      <!-- <div class="logo-text">{{brand.name}}</div> -->
    </div>
    <!-- <div class="slogan-text">experience</div> -->
  </div>
</template>

<script>
import json from "@/general/GlobalValues.json";

export default {
  components: {},
  created() {},
  props: {},
  data: () => {
    return {
      brand: json["brand"],
    };
  },
  methods: {},
  computed: {
    store: function () {
      return this.$store.state;
    },
    hideLogo: function () {
      return this.store.client_data.hideMozoLogo;
    },
  },
};
</script>

