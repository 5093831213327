<template>
  <div>
    <div class="background mozo shor">
      <div class="header-column-tm">
        <div class="logo-header">
          <div class="logo-div">
            <img src="/images/mozo_logo.png" loading="lazy" alt="" class="image-54" />
          </div>
        </div>
        <div class="header-div"></div>
      </div>
    </div>
    <div class="div-block-208">
      <div class="div-block-210">
        <h1 class="heading-38">You will delete your account permanently</h1>
        <div class="text-block-27">Are you sure?.</div>
        <DeleteButton />
      </div>
    </div>
  </div>
</template>
<script>
import DeleteButton from "@/general/Components/2.0/CategoryManagement/AreYouSure/DeleteButton.vue"

export default {
  components: {
    DeleteButton,
  },
}
</script>
