<template>
  <div class="fullwidth">
    <template v-if="!showAdd(index)">
      <BannerItem :item="input_widgets[index]" :index="index" type="banner_collection" />
    </template>
    <template v-else>
      <AddBanner type="banner_collection" />
    </template>
  </div>
</template>

<script>
import BannerItem from "@/general/Elements/2.0/OtherElements/BannerItem.vue"
import AddBanner from "@/general/Elements/2.0/OtherElements/Widgets/AddWidget.vue"

export default {
  components: {
    BannerItem,
    AddBanner,
  },
  props: ["index", "input_widgets"],
  computed: {},
  created() {},

  data: () => {
    return {}
  },
  computed: {
    numberOfWidgets: function () {
      return this.input_widgets.length
    },
  },

  methods: {
    showAdd(index) {
      if (index < this.numberOfWidgets) {
        return false
      } else {
        return true
      }
    },
  },
}
</script>

<style scoped>
.fullwidth {
  width: 100%;
}
</style>
