var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isSuccess && !_vm.isFailure)?[_c('div',{staticClass:"background mozo",style:(_vm.setBackground())},[_c('Header'),_c('div',{staticClass:"page-body-2 center"},[_c('div',{staticClass:"form-div"},[_c('h1',{staticClass:"heading1"},[_vm._v(_vm._s(_vm.title))]),_c('p',{staticClass:"paragraph"},[_vm._v(_vm._s(_vm.description))]),_c('div',{staticClass:"form-block marge w-form"},[_c('div',[_c('div',{staticClass:"paragraph left"},[_vm._v(_vm._s(_vm.field_names.password))]),_c('input',{ref:"password1",staticClass:"text-field marge-between w-input",style:({ 'border-color': _vm.border.password1 }),attrs:{"type":"password","maxlength":_vm.input_length.password,"placeholder":_vm.placeholders.password,"required":"true","autocorrect":"off","autocapitalize":"off"},on:{"change":function($event){return _vm.checkPassword()}}}),_c('div',{staticClass:"div-block-59"},[_c('div',{staticClass:"div-block-60"},[(
                      (_vm.error.password_min === '') &
                      (_vm.success.password_min === '')
                    )?_c('img',{staticClass:"image-26",attrs:{"src":"images/check-white.svg","loading":"lazy"}}):_vm._e(),(_vm.error.password_min !== '')?_c('img',{staticClass:"image-26",attrs:{"src":"images/close-01.svg","loading":"lazy"}}):_vm._e(),(_vm.success.password_min !== '')?_c('img',{staticClass:"image-26",attrs:{"src":"images/check.svg","loading":"lazy"}}):_vm._e(),_c('div',{staticClass:"message"},[_vm._v("at least 8 characters")])]),_c('div',{staticClass:"div-block-60"},[(
                      (_vm.error.password_digit === '') &
                      (_vm.success.password_digit === '')
                    )?_c('img',{staticClass:"image-26",attrs:{"src":"images/check-white.svg","loading":"lazy"}}):_vm._e(),(_vm.error.password_digit !== '')?_c('img',{staticClass:"image-26",attrs:{"src":"images/close-01.svg","loading":"lazy"}}):_vm._e(),(_vm.success.password_digit !== '')?_c('img',{staticClass:"image-26",attrs:{"src":"images/check.svg","loading":"lazy"}}):_vm._e(),_c('div',{staticClass:"message"},[_vm._v("contains one digit")])]),_c('div',{staticClass:"div-block-60"},[(
                      (_vm.error.password_lower === '') &
                      (_vm.success.password_lower === '')
                    )?_c('img',{staticClass:"image-26",attrs:{"src":"images/check-white.svg","loading":"lazy"}}):_vm._e(),(_vm.error.password_lower !== '')?_c('img',{staticClass:"image-26",attrs:{"src":"images/close-01.svg","loading":"lazy"}}):_vm._e(),(_vm.success.password_lower !== '')?_c('img',{staticClass:"image-26",attrs:{"src":"images/check.svg","loading":"lazy"}}):_vm._e(),_c('div',{staticClass:"message"},[_vm._v("contains one lower character")])]),_c('div',{staticClass:"div-block-60"},[(
                      (_vm.error.password_upper === '') &
                      (_vm.success.password_upper === '')
                    )?_c('img',{staticClass:"image-26",attrs:{"src":"images/check-white.svg","loading":"lazy"}}):_vm._e(),(_vm.error.password_upper !== '')?_c('img',{staticClass:"image-26",attrs:{"src":"images/close-01.svg","loading":"lazy"}}):_vm._e(),(_vm.success.password_upper !== '')?_c('img',{staticClass:"image-26",attrs:{"src":"images/check.svg","loading":"lazy"}}):_vm._e(),_c('div',{staticClass:"message"},[_vm._v("contains one UPPER case character")])])]),_c('div',{staticClass:"paragraph left"},[_vm._v(" "+_vm._s(_vm.field_names.repeat_password)+" ")]),_c('input',{ref:"password2",staticClass:"text-field marge-between w-input",style:({ 'border-color': _vm.border.password2 }),attrs:{"type":"password","maxlength":_vm.input_length.password,"placeholder":_vm.placeholders.password,"required":"true","autocorrect":"off","autocapitalize":"off"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.next_page.apply(null, arguments)},"change":function($event){return _vm.checkPass2()}}}),_c('div',{staticClass:"div-block-59"},[_c('div',{staticClass:"div-block-60"},[(
                      (_vm.error.password_equal === '') &
                      (_vm.success.password_equal === '')
                    )?_c('img',{staticClass:"image-26",attrs:{"src":"images/check-white.svg","loading":"lazy","height":""}}):_vm._e(),(_vm.error.password_equal !== '')?_c('img',{staticClass:"image-26",attrs:{"src":"images/close-01.svg","loading":"lazy","height":""}}):_vm._e(),(_vm.success.password_equal !== '')?_c('img',{staticClass:"image-26",attrs:{"src":"images/check.svg","loading":"lazy","height":""}}):_vm._e(),_c('div',{staticClass:"message"},[_vm._v("passwords are equal")])])])])]),_c('div',[_c('Button',{attrs:{"color":_vm.client_data.color[2].icons[0],"title":'Save password!'},on:{"isClicked":_vm.savePassword}})],1)])])],1)]:(_vm.isSuccess)?[_c('div',{staticClass:"background mozo pop-up succeeded"},[_c('Header'),_vm._m(0)],1)]:(!_vm.isSuccess)?[_c('div',{staticClass:"background mozo pop-up failed"},[_c('Header'),_c('div',{staticClass:"page-body-2 center"},[_c('div',{staticClass:"pop-up-block width-cap"},[_c('img',{staticClass:"image-10",attrs:{"src":"images/sad-face.png","loading":"lazy"}}),_c('div',{staticClass:"text-block pop-up"},[_c('p',{staticClass:"text"},[_vm._v("Oh something went wrong")]),_vm._m(1),_c('div',{staticClass:"div-block-67"},[_c('a',{staticClass:"submit-button orange w-button",on:{"click":function($event){return _vm.tryAgain()}}},[_vm._v("Reset password")])])])])])],1)]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-body-2 center"},[_c('div',{staticClass:"pop-up-block width-cap"},[_c('img',{staticClass:"image-10",attrs:{"src":"images/thumbs-up.png","loading":"lazy"}}),_c('div',{staticClass:"text-block pop-up"},[_c('p',{staticClass:"text"},[_vm._v("Succeeded!")]),_c('p',{staticClass:"paragraph"},[_vm._v(" You have succesfully reset your password."),_c('br'),_c('br'),_vm._v("From now on use this password to login to your mozo account. ")]),_c('div',{staticClass:"div-block-67",staticStyle:{"margin-top":"5%"}},[_c('a',{staticClass:"submit-button w-button",attrs:{"href":"/login"}},[_vm._v("Go back to login")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"paragraph"},[_vm._v(" Check if you copied the correct link!"),_c('br'),_vm._v("Otherwise , try to reset your password again"),_c('br')])
}]

export { render, staticRenderFns }