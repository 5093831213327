<template>
  <ChangePicture
    :image="profile_picture"
    :color="color"
    @returnImage="setImage"
    @error="setError"
  />
</template>

<script>
import ChangePicture from "@/general/Elements/2.0/Config/ChangePicture.vue"

// DB
import db_storage from "@/db/2.0/storage.js"

export default {
  components: {
    ChangePicture,
  },

  computed: {
    store: function () {
      return this.$store.state
    },
    profile_picture: {
      get() {
        return this.store.client_data.image
      },
      set(value) {
        this.store.client_data.image = value
      },
    },
    color: function () {
      return this.store.client_data.color[0]
    },
    uid: function () {
      return this.store.user.uid
    },
  },
  created() {},
  methods: {
    async setImage(image) {
      this.profile_picture = await db_storage.addProfilePicture(
        this.uid,
        image,
        "profile_picture",
        "header"
      )
    },
    setError(error) {
      this.$emit("error", error)
    },
  },
}
</script>
