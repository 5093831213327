<template>
  <div @click="isClicked()" class="button-3 dark">
    <h2 class="paragraph-5">{{ text }}</h2>
  </div>
</template>

<script>
export default {
  props: ["text"],
  methods: {
    isClicked() {
      this.$emit("isClicked", true)
    },
  },
}
</script>

<style scoped>
.div-block-168 {
  cursor: pointer;
}
</style>
