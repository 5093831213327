<template>
  <div class="shortapps-nd">
    <template v-if="!config">
      <Collection :input_widgets="input_widgets" :config="config" />
    </template>
    <template v-else>
      <CollectionConfig
        :input_widgets="input_widgets"
        :config="config"
        @returnWidgets="changeWidgets"
      />
    </template>
  </div>
</template>

<script>
import Collection from "@/general/Elements/2.0/NoConfig/CollectionShortWidgets.vue"
import CollectionConfig from "@/general/Elements/2.0/Config/CollectionShortWidgets.vue"

export default {
  created() {},
  components: {
    Collection,
    CollectionConfig,
  },
  computed: {
    store: function () {
      return this.$store.state
    },

    input_widgets: {
      get() {
        return this.store.widgets.small_tile_collection
      },
      set(value) {
        this.store.widgets.small_tile_collection = value
      },
    },
    config: function () {
      return this.store.environment.config
    },
  },
  props: [],
  created() {},
  data: () => {
    return {}
  },
  methods: {
    changeWidgets(value) {
      this.input_widgets = value
    },
  },
}
</script>
