<template>
  <div @click="pressed()" class="contact-person" :class="(clicked) ? 'clicked' : ''"
    :style="(clicked) ? 'background-color:' + color : ''">
    <div class="contactinfo-grid">
      <Selector :isSelected="false" />

      <Center :firstname="contact.firstname" :lastname="contact.lastname" :email="contact.email" />

      <DateDiv :date_object="date_object" :percentage="percentage" :clicked="clicked" />
    </div>
  </div>
</template>

<script>
import DateDiv from "@/general/Elements/2.0/FriendsOf/DateDiv.vue";
import Center from "@/general/Elements/2.0/FriendsOf/Center.vue";
import Selector from "@/general/Elements/2.0/FriendsOf/Selector.vue";

export default {
  components: {
    DateDiv,
    Center,
    Selector,
  },
  props: ["contact", "date_object", "percentage"],

  data: () => {
    return {
      clickvalue: false
    };
  },
  created() {

  },

  computed: {
    store: function () {
      return this.$store.state;
    },
    selectedContacts: {
      get() {
        return this.store.environment.contacts;
      },
      set(value) {
        this.store.environment.contacts = value;
      },
    },
    contacts: function () {
      return this.store.friends.contacts
    },
    color: function () {
      return this.store.client_data.color[0]
    },

    clicked: function () {
      if (this.selectedContacts.selected.find(x => x.email == this.contact.email)) {
        return true;
      } else {
        return false;
      }

    }
  },
  methods: {
    pressed() {
      if (!this.clicked) {
        this.selectedContacts.selected.push(this.contact);
      } else {
        this.unselect(this.selectedContacts.selected, this.contact);
      }

    },
    unselect(arr, value) {
      var index = arr.indexOf(value);
      if (index > -1) {
        arr.splice(index, 1);
      }
      return arr;
    },
  },
  // watch: {
  //   clickedG: {
  //     handler() {
  //       this.clicked = this.clickedG;
  //     },
  //     deep: true
  //   },
  //   clicked: {
  //     handler() {
  //       if (this.selectedContacts.selected.length == this.contacts.length) {
  //         this.clickedG = true;
  //       }

  //     },
  //     deep: true
  //   },


  // }
};
</script>

<style>
.contact-person {
  cursor: pointer;
  z-index: 99;
}
</style>
