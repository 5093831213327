<template>
  <div>
    <template v-if="view == 'load'">
      <WaitPage />
    </template>
    <template v-else-if="menu">
      <template v-if="loggedIn">
        <AccountMenu />
      </template>
      <template v-else>
        <PublicMenu />
      </template>
    </template>
    <template v-else-if="showQR">
      <QR />
    </template>
    <template v-else-if="view == 'config'">
      <template v-if="addUser.popup">
        <PopUpAddUser
          title="Saved!"
          :text="popup_text"
          :showCloseButton="true"
          button=""
          @close="closePopup()"
        />
      </template>
      <ConfigPage />
    </template>

    <template v-else-if="view == 'public'">
      <template v-if="addUser.popup">
        <PopUpAddUser
          title="Saved!"
          :text="popup_text"
          :showCloseButton="true"
          button=""
          @close="closePopup()"
        />
      </template>
      <Public />
    </template>
  </div>
</template>

<script>
// menu
import AccountMenu from "@/views/Menu/LoggedIn.vue"
import PublicMenu from "@/views/Menu/LoggedOut.vue"

import PopUpAddUser from "@/general/Components/2.0/FriendsOf/PopUp.vue"

// elements 1.0
import WaitPage from "@/general/Elements/2.0/OtherElements/Popups/WaitPage.vue"

// configuration
import ConfigPage from "@/views/Configuration/Controller.vue"

// Public
import Public from "@/views/PublicProfile/Controller.vue"

import QR from "@/views/Other/QR.vue"

export default {
  components: {
    AccountMenu,
    PublicMenu,
    WaitPage,
    ConfigPage,
    Public,
    QR,
    PopUpAddUser,
  },

  async created() {},
  computed: {
    store: function () {
      return this.$store.state
    },
    view: function () {
      return this.store.environment.view
    },

    menu: function () {
      return this.store.environment.menu
    },

    addUser: {
      get() {
        return this.store.environment.addUser
      },
      set(value) {
        this.store.environment.addUser = value
      },
    },

    loggedIn: {
      get() {
        return this.store.environment.loggedIn
      },
      set(user_state) {
        this.store.environment.loggedIn = user_state
      },
    },

    showQR: function () {
      return this.store.environment.showQR
    },

    popup_text: function () {
      return "You have a new friendship with " + this.addUser.name
    },
  },
  methods: {
    closePopup() {
      this.addUser = {
        user: null,
        properties: {
          firstname: null,
          lastname: null,
        },
        view: "login",
        profile_url: null, //"/test",
        remembered: false,
        color: [],
        name: null,
        popup: false,
      }
    },
  },
}
</script>
