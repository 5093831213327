<template>
    <div class="vv-buttons">
      <img :src="src" loading="lazy" alt="mozo" class="image-35" />
      <div class="text-block-div black small">{{title}}</div>
    </div>
</template>

<script>
export default {
    props:['src','title']
}
</script>

<style>
.vv-buttons{
  cursor: pointer
}
</style>