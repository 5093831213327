<template>
  <div @click="isClicked" class="button" :style="'background-color:' + color">
    <h1 class="paragraph">{{ title }}</h1>
  </div>
</template>

<script>
export default {
  props: ["title", "color"],
  methods: {
    isClicked() {
      this.$emit("isClicked", true)
    },
  },
}
</script>
