<template>
  <div class="full left">
    <h2 class="label">Widgets</h2>
    <div class="widgetblock grid-3-widgets">
      <div v-for="(item, index) in widgets" :key="index">
        <Widget :widget="item" />
      </div>
      <div>
        <AddWidget />
      </div>
    </div>
  </div>
</template>

<script>
import Widget from "@/general/Components/2.0/TableManagement/EditTable/Widget.vue"
import AddWidget from "@/general/Components/2.0/TableManagement/EditTable/AddWidget.vue"
import TablesRepository from "@/general/Objects/TablesRepository"

export default {
  components: {
    Widget,
    AddWidget,
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    tableID: function () {
      return this.store.environment.tableID
    },
  },
  data: () => {
    return {
      widgets: [],
    }
  },
  async created() {
    const tableRepo = new TablesRepository()
    this.widgets = (await tableRepo.getTables([this.tableID]))[0].widgets
  },
}
</script>

<style scoped>
.app-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>
