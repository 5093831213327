<template>
  <div class="vv-top-heading">
    <div @click="goBack" class="button-popup left w-inline-block">
      <img src="images/arrow-ios-forward-fill_1.svg" loading="lazy" class="vv-login-logo _7x7" />
      <div class="text-block-light">Terug</div>
    </div>
    <div class="text-block-light">Become {{ client_name }} new friend !</div>
    <div class="text-block-light title">Please login</div>
  </div>
</template>

<script>
export default {
  computed: {
    store: function () {
      return this.$store.state;
    },
    client_name: function () {
      return this.store.environment.addUser.name;
    },
    profile_url: function () {
      return this.store.environment.addUser.profile_url;
    },
    addUser: {
      get() {
        return this.store.environment.addUser;
      },
      set(value) {
        this.store.environment.addUser = value
      }
    }

  },

  created() {
    // if (!this.addUser.remembered && localStorage.getItem('addUser')) {

    // } else if (!this.addUser.remembered) {
    //   localStorage.setItem('addUser', this.addUser);
    // }
    // localStorage.setItem('addUser', this.addUser);

  },
  methods: {
    goBack() {
      this.$router.push(this.profile_url);
    }
  }
}
</script>
