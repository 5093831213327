<template>
  <div
    class="background mozo"
    :style="setBackground(client_data.color[0], client_data.color[1])"
  >
    <Header />
    <div style="width: 100%; min-height: 65vh">
      <div style="display: flex; justify-content: center; align-items: center">
        <Heading1 :text="client_data.bio2" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/general/Components/2.0/Header.vue";
import Heading1 from "@/general/Elements/2.0/OtherElements/HeadingFillin.vue";

import methods from "@/general/HelperMethods/General";

export default {
  components: {
    Header,
    Heading1,
  },
  methods: {
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2);
    },
  },
  computed: {
    store: function () {
      return this.$store.state;
    },
    client_data: {
      get() {
        return this.store.client_data;
      },
      set(value) {
        this.store.client_data = value;
      },
    },
  },
};
</script>
