<template>
  <div class="account-div-wm" @click="isClicked">
    <div class="add-button-account">
      <div class="plus-sign">+</div>
    </div>
    <div class="paragraph black-text">{{ button_txt }}</div>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => {
    return {
      button_txt: "Add Profile or Single Link",
    }
  },
  methods: {
    isClicked() {
      this.$emit("next", true)
    },
  },
}
</script>

<style scoped>
.account-div-wm {
  cursor: pointer;
}

.account-div-wm:hover {
  background-color: #11d1ad;
  color: white;
}

.plus-sign {
  color: #333;
}
</style>
