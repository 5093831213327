<template>
    <div @click="submit" class="vv-submit-email custom-button w-button">Submit</div>
</template>

<script>
export default {
    methods: {
        submit() {
            this.$emit('clicked', true)
        },


    }

}
</script>

<style scoped>
.custom-button {
    padding: 16px 32px 16px 32px
}
</style>