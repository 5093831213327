<template>
  <div class="right-contact-div">
    <div class="date-div-block" :style="'background-color:' + backgroundColor">
      <h1 class="subheading-small green" :style="'color:' + color">
        {{ date }}
      </h1>
    </div>
  </div>

</template>

<script>
import methods from "@/general/HelperMethods/General.js";
export default {
  props: ["date_object", "percentage", "clicked"],

  created() {
  },

  computed: {
    color: function () {
      return methods.makeGradientColor("#00c9aa", "FFA500", this.percentage * 100);
    },

    backgroundColor: function () {
      if (this.clicked) {
        return '#ffffff';
      }
      return methods.hexToRgb(this.color, 0.1);
    },

    day: function () {
      var day = this.date_object.getUTCDate();
      if (day.toString().length == 1) {
        return "0" + day;
      } else {
        return day;
      }
    },

    month: function () {
      var month = this.date_object.getUTCMonth() + 1;
      if (month.toString().length == 1) {
        return "0" + month;
      } else {
        return month;
      }
    },

    date: function () {
      var year = this.date_object.getUTCFullYear();

      return this.day + "/" + this.month + "/" + year;
    },
  },
  methods: {

  },
};
</script>
