<template>
  <div class="contact-menu-div">
    <template v-if="allSelected">
      <div @click="selectAll()">
        <Button src="images/unselectfiles.svg" title="Unselect All" />
      </div>
    </template>
    <template v-else>
      <div @click="selectAll()">
        <Button src="images/selectfiles.svg" title="Select All" />
      </div>
    </template>
    <template v-if="isContactSelected">
      <div @click="exportContacts()">
        <Button src="images/export.svg" title="Export" />
      </div>
      <div @click="deleteContacts()">
        <Button src="images/delete.svg" title="Delete" />
      </div>
    </template>
  </div>
</template>

<script>
import methods from "@/general/HelperMethods/General.js"
import Button from "@/general/Elements/2.0/FriendsOf/ButtonsOverview.vue";
import CSV from "@/general/Objects/CSV.js";

export default {
  components: {
    Button,
  },
  data: () => {
    return {};
  },
  computed: {
    store: function () {
      return this.$store.state;
    },
    isContactSelected: function () {
      if (this.store.environment.contacts.selected.length > 0) {
        return true;
      }
      return false;
    },
    selected: {
      get() {
        return this.store.environment.contacts.selected;
      },
      set(value) {
        this.store.environment.contacts.selected = value;
      },
    },
    allSelected: {
      get() {
        return this.store.environment.contacts.clicked;
      },
      set(value) {
        this.store.environment.contacts.clicked = value;
      },
    },
    state: {
      get() {
        return this.store.environment.contacts.state;
      },
      set(value) {
        this.store.environment.contacts.state = value;
      },
    },
    contacts: {
      get() {
        return this.store.friends.contacts;
      },
      set(value) {
        this.store.friends.contacts = value;
      },
    },


  },
  methods: {
    async exportContacts() {
      let csvContent = "";
      var counter = 0;

      for (const properties of this.selected) {
        var header = "";
        var row = "";

        if (counter == 0) {
          csvContent = await this.setHeader(properties);
        }

        csvContent += await this.setBody(properties);
        counter += 1;
      }


      new CSV().downloadCSV(
        csvContent,
        "contacts_" + new Date() + ".csv",
        "text/csv;encoding:utf-8"
      );
    },

    async setHeader(properties) {
      var header = "";
      await Object.keys(properties).sort().forEach((name) => {
        if (name != 'uid') {
          header += name + ",";
        }
      });
      header += "\r\n";
      return header;
    },

    async setBody(properties) {
      var row = "";

      await Object.keys(properties).sort().forEach((name) => {
        if (name != 'uid') {
          row += properties[name] + ",";
        }

      });
      row += "\r\n";


      return row;
    },


    deleteContacts() {
      this.state = "delete_check";
    },

    selectAll() {
      if (this.allSelected) {
        this.selected = [];
        this.allSelected = false;
      } else {
        this.selected = methods.deepcopy(this.contacts);
        this.allSelected = true;

      }
    },
  },
};
</script>
