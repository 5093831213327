<template>
  <div class="bottom-layer2">
    <div
      v-if="rows.length <= 0"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 25%;
      "
    >
      <p>Er staan op dit moment geen tafels in je profiel.</p>
      <Button :text="'Vraag tafels aan'" @isClicked="sendEmail" />
    </div>
    <div v-for="(row, index) in rows" :key="index" class="full">
      <template v-if="lock">
        <Row :table="row" @clickTable="clickTable(index)" />
      </template>
      <!-- <template v-else>
        <RowEdit :table="row" @clickDelete="clickDelete" @clickEdit="clickEdit" />
      </template> -->
    </div>
  </div>
</template>

<script>
import Row from "@/general/Components/2.0/TableManagement/AllTables/Row.vue"
import RowEdit from "@/general/Components/2.0/TableManagement/AllTables/RowEdit.vue"

import Button from "@/general/Components/2.0/CategoryManagement/General/Button.vue"

export default {
  components: {
    Row,
    RowEdit,
    Button,
  },
  data: () => {
    return {
      index: -1,
    }
  },

  props: ["rows", "lock"],
  created() {},
  computed: {
    store: function () {
      return this.$store.state
    },
    tableID: {
      get() {
        return this.store.environment.tableID
      },
      set(value) {
        this.store.environment.tableID = value
      },
    },
  },
  methods: {
    clickTable(index) {
      this.index = index
      this.tableID = this.rows[index].id

      this.$router.push({
        path: "/table/" + this.tableID,
      })
    },

    clickDelete(index) {
      this.index = index
      console.log("Clicked Delete Table position: " + index)
    },
    clickEdit(index) {
      this.index = index
      console.log("Clicked Edit Table position: " + index)
    },
    sendEmail() {
      window.open("mailto:info@trymozo.com")
    },
  },
}
</script>

<style scoped>
.full {
  justify-content: flex-start;
}
</style>
