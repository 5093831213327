import store from "@/vuex/setup";

export default class View {
  setData(data) {
    this.data = data;
  }

  setUrl(url) {
    this.url = url
  }

  getUrl() {
    return this.url;
  }

  setName(name) {
    this.name = name;
  }

  getName() {
    return this.name;
  }

  setEnvironment(view) {
    if (view == "config") {
      store.state.environment.config = true;
      store.state.environment.isEdit = true;
      store.state.environment.isCurrentPage = true;
    } else {
      store.state.environment.config = false;
      store.state.environment.isEdit = false;
      store.state.environment.isCurrentPage = false;
    }
  }

  setUrlCorrect(router, url) {
    router.push("/" + url);
    return url;
  }
}
