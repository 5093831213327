<template>
  <div>
    <base target="_parent" />
    <div class="frame">
      <Header />
      <Frame :link="link" />
    </div>
  </div>
</template>

<script>
import Header from "@/general/Elements/2.0/Headers/inappHeader.vue"
import Frame from "@/general/Elements/2.0/OtherElements/Widgets/Frame.vue"

import WidgetCollection from "@/general/Objects/WidgetCollection"

export default {
  components: {
    Header,
    Frame,
  },
  async created() {
    const wCol = new WidgetCollection()
    const account = await wCol.getAccount(this.localWidget.item.account_uid)
    this.link = account.data[0].field
  },

  data() {
    return {
      link: "",
    }
  },

  methods: {},

  computed: {
    store: function () {
      return this.$store.state
    },
    localWidget: function () {
      return this.store.environment.localWidget
    },
  },
}
</script>

<style scoped>
.frame {
  background-color: transparent;
}
</style>
