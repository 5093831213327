import json from "@/general/GlobalValues.json";
import { db } from "@/db/Firebase";

export default {

    async setData(uid, data) {


        await db
            .collection(json["collections"].users)
            .doc(uid)
            .collection(json["collections"].accounts)
            .doc(data.uid)
            .set(data)
            .catch((err) => {
                throw err.message;
            });



    },

    async updateData(uid, data) {
        await db
            .collection(json["collections"].users)
            .doc(uid)
            .collection(json["collections"].accounts)
            .doc(data.uid)
            .update(data)
            .catch((err) => {
                throw err.message;
            });
    },

    async getData(uid, id) {
        return await db
            .collection(json["collections"].users)
            .doc(uid)
            .collection(json["collections"].accounts)
            .doc(id)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    var data = doc.data();
                    return data;
                }
            })
            .catch((err) => {
                throw err.message;
            });
    },

    async getAllData(uid) {
        return await db
            .collection(json["collections"].users)
            .doc(uid)
            .collection(json["collections"].accounts)
            .get()
            .then(async (snapshot) => {
                return await snapshot.docs.map((doc) => {

                    var data = doc.data()
                    data.uid = doc.id;

                    return data;
                })



            })
            .catch((err) => {
                throw err.message;
            });
    },

    async deleteData(uid, id) {
        await db
            .collection(json["collections"].users)
            .doc(uid)
            .collection(json["collections"].accounts)
            .doc(id)
            .delete()
            .catch((err) => {
                throw err.message;
            });
    },

    async deleteAllData(uid) {
        await db
            .collection(json["collections"].users)
            .doc(uid)
            .collection(json["collections"].accounts)
            .get()
            .then((querySnapshot) => {
                querySnapshot.docs.forEach((snapshot) => {
                    snapshot.ref.delete();
                });
            })
            .catch((err) => {
                throw err.message;
            });
    },

    async docExists(uid, id) {
        return await db
            .collection(json["collections"].users)
            .doc(uid)
            .collection(json["collections"].accounts)
            .doc(id)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    return true;
                } else {
                    return false;
                }
            })
            .catch((err) => {
                throw err.message;
            });
    },

    async updateProperty(uid, account_uid, property, value) {
        return await db
            .collection(json["collections"].users)
            .doc(uid)
            .collection(json["collections"].accounts)
            .doc(account_uid)
            .update({
                [property]: value,
            })
            .catch((err) => {
                throw err.message;
            });
    },



};
