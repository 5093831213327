// in folder
import vWidgets from "./visibleWidgets.js";
import vData from "./visibleData.js";

// in DB folder
import contacts from "./contacts.js";
import widgets from "./widgets.js";
import data from "./data.js";

export default {
  contacts,
  data,
  widgets,
  vWidgets,
  vData,

  // firebase.firestore.FieldValue.delete()

  async setOffline(uid, publicdata, publicwidgets) {
    //set public data
    delete publicdata.url;
    delete publicdata.uid;
    delete publicdata.score;

    await data.updateData(uid, publicdata, "public").catch((err) => {
      throw err;
    });

    //get private data
    const privatedata = await data.getData(uid, "private").catch((err) => {
      throw err;
    });

    //set public data & widgets
    await vData.updateData(uid, privatedata).catch((err) => {
      throw err;
    });

    await widgets.setData(uid, publicwidgets, "public").catch((err) => {
      throw err;
    });

    await vWidgets.resetWidgets(uid).catch((err) => {
      throw err;
    });

    const d = {
      bio: privatedata.bio,
      image: privatedata.image,
      nickname: privatedata.nickname,
      offline: privatedata.offline,
    };
    const s = { data: [...Array(9)].map(() => null), redirect: false };

    return { d, s };
  },

  async setOnline(uid, privatedata) {
    delete privatedata.url;
    delete privatedata.uid;
    delete privatedata.score;
    delete privatedata.email;
    delete privatedata.firstname;
    delete privatedata.lastname;
    //set private data
    await data.updateData(uid, privatedata, "private").catch((err) => {
      throw err;
    });

    //get public data
    const publicdata = await data.getData(uid, "public").catch((err) => {
      throw err;
    });

    //set public data & Widgets
    await vData.updateData(uid, publicdata).catch((err) => {
      throw err;
    });

    //get Widgets
    const publicwidgets = await widgets.getData(uid, "public").catch((err) => {
      throw err;
    });

    //set public Widgets
    await vWidgets.updateWidgets(uid, publicwidgets.data).catch((err) => {
      throw err;
    });

    const d = publicdata;
    const s = { data: publicwidgets.data, redirect: false };

    return { d, s };
  },

  async registerUser(data, uid) {
    return await this.addUser(data, uid).catch(async (err) => {
      await this.deleteUser(err, uid);
    });
  },

  async addUser(i_data, uid) {
    delete i_data.email;

    await vData.setData(uid, {});
    await data.setData(uid, i_data, "public");
    await data.setData(uid, {}, "private");

    await widgets.setData(uid, [], "small_tile_collection");
    await widgets.setData(uid, [], "image_collection");
    await widgets.setData(uid, [], "tile_collection");
    await widgets.setData(uid, [], "banner_collection");

    await data.setData(uid, {}, "b1", "branches");

    return true;
  },

  async deleteUser(err, uid) {
    if (err === "data") {
      return true;
    } else if (err === "widgets") {
      await vData.deleteData(uid);
      return true;
    } else if (err === "contacts") {
      await vData.deleteData(uid);
      await vWidgets.deleteWidgets(uid);
      return true;
    } else if (err === "privateData") {
      await vData.deleteData(uid);
      await vWidgets.deleteWidgets(uid);
      await contacts.deleteContacts(uid);
      return true;
    } else if (err === "privateWidgets") {
      await vData.deleteData(uid);
      await vWidgets.deleteWidgets(uid);
      await contacts.deleteContacts(uid);
      await data.deleteData(uid, "private");
      return true;
    } else if (err === "publicData") {
      await vData.deleteData(uid);
      await vWidgets.deleteWidgets(uid);
      await contacts.deleteContacts(uid);
      await data.deleteData(uid, "private");
      await widgets.deleteWidgets(uid, "private");
      return true;
    } else if (err === "publicWidgets") {
      await vData.deleteData(uid);
      await vWidgets.deleteWidgets(uid);
      await contacts.deleteContacts(uid);
      await data.deleteData(uid, "private");
      await widgets.deleteWidgets(uid, "private");
      await data.deleteData(uid, "public");
    } else if (err === "publicWidgets") {
      await vData.deleteData(uid);
      await vWidgets.deleteWidgets(uid);
      await contacts.deleteContacts(uid);
      await data.deleteData(uid, "private");
      await widgets.deleteWidgets(uid, "private");
      await data.deleteData(uid, "public");
      await widgets.deleteWidgets(uid, "public");
      return true;
    }

    return false;
  },
};
