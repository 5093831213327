<template>
  <div class="header-column-tm">
    <div class="logo-header" @click="goToHome">
       <template v-if="!hideLogo">
          <img
            src="/images/mozo_logo_nopadding-01.svg"
            loading="lazy"
            alt=""
            class="mozo-logo"
          />
      </template>
    </div>
    <div class="header-div">
      <div class="settings-div">
        <div @click="menuClicked" class="link-block w-inline-block">
          <img src="/images/hamburger-menu.svg" loading="lazy" class="settings-image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import methods from "@/general/HelperMethods/General.js"

export default {
  props: ["header_title", "showLock"],
  data: () => {
    return {
      lock: "/images/padlock.svg",
    }
  },
  created() {},
  methods: {
    goToHome() {
      this.$router.push({ name: "Main" })
    },
    menuClicked() {
      this.isCurrentPage = true
      this.menu = true
      this.changeWidget = null
    },
    changeLock() {
      if (this.lock == "/images/padlock.svg") {
        this.lock = "/images/unlock-padlock.svg"
        this.$emit("isLocked", false)
      } else {
        this.lock = "/images/padlock.svg"
        this.$emit("isLocked", true)
      }
    },
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2)
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    menu: {
      get() {
        return this.store.environment.menu
      },
      set(value) {
        this.store.environment.menu = value
      },
    },
    isCurrentPage: {
      get() {
        return this.store.environment.isCurrentPage
      },
      set(value) {
        this.store.environment.isCurrentPage = value
      },
    },
    changeWidget: {
      get() {
        return this.store.environment.editTableWidget
      },
      set(value) {
        this.store.environment.editTableWidget = value
      },
    },
    client_data: function () {
      return this.store.client_data
    },
 
        store: function () {
            return this.$store.state
        },
        hideLogo: function () {
            return this.store.client_data.hideMozoLogo
        },

    
  },
}
</script>

<style>
.lock-image {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
</style>
