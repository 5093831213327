<template>
    <div class="header-column w-row">
        <div class="left-col max-height w-col w-col-8">
            <div class="logo-header">
                <div class="logo-div">
                    <img class="logo-img" :src="brand.logo" loading="lazy">
                    <!-- <div class="logo-text">{{brand.name}}</div> -->
                </div>
                <!-- <div class="slogan-text">{{brand.slogan}}</div> -->
            </div>
        </div>
        <div class="right-col w-col w-col-6"></div>
    </div>
</template>


<script>
import json from "@/general/GlobalValues.json"
export default {
    data: () => {
        return {
            brand: json['brand'],
            slogan: json['brand']

        }
    }

}

</script>