<template>
  <div class="background mozo _100vh">
    <Header />
    <div class="header-logout">
      <div class="columns-2 w-row">
        <div class="w-col w-col-6">
          <div class="text-div _1">
            <a @click="goBack" class="button small w-inline-block">
              <img
                src="images/go-back.png"
                class="image-18"
                loading="lazy"
                sizes="(max-width: 991px) 20px, (max-width: 1279px) 2vw, (max-width: 1439px) 20px, 1vw"
                srcset="images/go-back-p-500.png 500w, images/go-back.png 512w"
            /></a>
          </div>
        </div>
        <div class="column w-col w-col-6">
          <div class="button-block logout"></div>
        </div>
      </div>
    </div>

    <div class="page-body-2 center">
      <div class="form-div width-cap">
        <h1 class="heading1">{{ title }}</h1>
        <p class="paragraph">{{ description }}</p>
        <div class="form-block w-form">
          <form>
            <input
              @keyup.enter="login"
              ref="email"
              placeholder="Email"
              type="text"
              class="text-field marge-between w-input"
              :maxlength="email_length"
            />
            <input
              @keyup.enter="login"
              ref="password"
              placeholder="Password"
              type="password"
              class="text-field marge-between w-input"
              :maxlength="password_length"
            />
            <div class="div-block">
              <div class="columns w-row">
                <div class="w-col w-col-6"></div>
                <div class="w-col w-col-6">
                  <div class="rightalign-div">
                    <a href="/reset-password" class="paragraph small">Forgot password?</a>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div>
          <a @click="login" class="submit-button w-button">{{ btn1 }}</a>
        </div>

        <template v-if="error !== ''">
          <div>
            <div class="error-message">{{ error }}</div>
          </div>
        </template>
      </div>
    </div>

    <LoginFooter />
  </div>
</template>

<script>
// db
import db_auth from "@/db/2.0/auth.js"
import db_url from "@/db/2.0/url.js"

// elements 2.0
import LoginFooter from "@/general/Elements/2.0/OtherElements/Login/LoginFooter.vue"
import Header from "@/general/Elements/2.0/Headers/Header1.vue"

export default {
  components: {
    LoginFooter,
    Header,
  },
  props: ["product_url", "activationcode"],
  data() {
    return {
      title: "Welcome again",
      description: "Login to your account",
      btn1: "Login",
      error: "",
      email_length: 50,
      password_length: 30,
    }
  },

  methods: {
    async login() {
      var user = await db_auth
        .login(this.$refs.email.value, this.$refs.password.value)
        .catch((err) => {
          this.error = err
        })

      if (user !== undefined) {
        await db_url.setUrl(
          this.product_url,
          user.uid,
          "product",
          this.activationcode,
          "default"
        )
        this.$router.push("/")
      }
    },

    goBack() {
      this.$emit("back", true)
    },
  },
}
</script>
