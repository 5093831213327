// in folder
import db_user from './user/main.js'

// 
import json from '@/general/GlobalValues.json'

export default {

    async deleteContacts(uid) {

        //get all profile contacts
        const contacts = await db_user.contacts.getContacts(uid)
        .catch((err) => {
            this.error = err;
        })

        //get number of contacts
        const score = await db_user.contacts.countContacts(contacts);


        //loop through all contacts
        for(var i=0; i<score; i++) {

            //adjust score of other user
            const new_score = await db_user.contacts.countContacts(contacts) - 1;
            await db_user.vData.updateData(contacts[i].uid, {"score": new_score})
            .catch(() => {
                throw "couldnt update score of " + contacts[i].uid
            })

        }

        //delete document
        await db_user.contacts.deleteContacts(uid)
        .catch((err) => {
            throw err;
        })
    },


    async deleteData(uid) {
        await db_user.data.deleteData(uid, json['docs'].public)
        .catch((err) => {
            this.error = err;
        })

        await db_user.data.deleteData(uid, json['docs'].private)
        .catch((err) => {
            this.error = err;
        })


        await db_user.vData.deleteData(uid)
        .catch((err) => {
            this.error = err;
        })

        


    },

}