<template>
    <div style="width:100%">
        <div class="field-div vertical">
            <div class="paragraph left">{{ field_names.email }}</div>
            <div class="account-column w-row">
                <div class="left-account-column w-col w-col-8">
                    <div class="url-div">
                        <input ref="email" :disabled="update" :value="temp_email" @change="setEmail()"
                            :maxlength="input_length.email" :placeholder="field_names.email" type="email"
                            class="text-field w-input" required="true" autocorrect="off" autocapitalize="off">
                    </div>
                </div>
                <div class="right-account-column w-col w-col-4">
                    <div>
                        <a v-if="!update" @click="state(true)" class="submit-button w-button">{{ btn_update }}</a>
                        <a v-if="update" @click="state(false)" class="submit-button delete w-button">{{ btn_cancel
                        }}</a>
                    </div>
                </div>
            </div>
            <div class="account-column up w-row">
                <div class="left-account-column w-col w-col-8">
                    <div>
                        <div v-if="error.email !== ''" class="error-message left">{{ error.email }}</div>
                        <div v-if="error.email === '' & success.email !== ''" class="success-message left">{{
                                success.email
                        }}
                        </div>
                    </div>
                </div>
                <div class="right-account-column w-col w-col-4"></div>
            </div>
        </div>


        <div v-if="update" class="field-div vertical">
            <div class="paragraph left">{{ field_names.confirm_password }}</div>
            <div class="account-column w-row">
                <div class="left-account-column w-col w-col-8">
                    <input ref="password" :maxlength="input_length.email" :placeholder="placeholders.password"
                        type="password" class="text-field w-input" required="true">
                </div>
                <div class="right-account-column w-col w-col-4">
                    <div>
                        <a @click="updateEmail()" class="submit-button w-button">{{ btn_confirm }}</a>
                    </div>
                </div>
            </div>
            <div class="account-column up w-row">
                <div class="column-24 w-col w-col-8">
                    <div>
                        <div v-if="error.password !== ''" class="error-message">{{ error.password }}</div>
                        <div v-if="error.password === '' & success.password !== ''" class="success-message">
                            {{ success.password }}</div>
                    </div>
                </div>
                <div class="right-account-column w-col w-col-4"></div>
            </div>
        </div>
    </div>
</template>

<script>
// db
import db_auth from '@/db/2.0/auth.js'

// general
import json from '@/general/GlobalValues.json'


export default {
    props: [
        'email',
        'user'
    ],
    data() {
        return {
            input_length: json["input_length"],
            field_names: json["field_names"],
            placeholders: json["placeholders"],

            btn_update: "Update",
            btn_cancel: "Cancel",
            btn_confirm: "Confirm",

            error: {
                email: "",
                password: "",
            },

            success: {
                email: "",
                password: "",
            },

            temp_email: "",
            update: false,

        }

    },
    created() {
        this.temp_email = this.email;
    },

    methods: {
        setEmail() {

            if (this.email === this.$refs.email.value) {
                this.error.email = "This is your current email";
                this.success.email = '';
                return false;
            } else {
                var available = db_auth.mailAvailable(this.$refs.email.value)
                if (available) {
                    this.temp_email = this.$refs.email.value;
                    this.success.email = 'Email available';
                    this.error.email = '';
                    return true;
                } else {
                    this.error.email = 'Email is not available, try another one';
                    this.success.email = '';
                    return false;
                }

            }

        },

        async updateEmail() {
            await db_auth.updateEmail(this.user, this.temp_email, this.$refs.password.value)
                .catch((err) => {
                    this.success.password = '';
                    this.error.password = err
                })
            this.$emit('email', this.temp_email)
            this.success.email = 'You succesfully updated your email'
            this.error.password = '';
            this.update = false;


        },


        async state(value) {
            if (await this.setEmail()) {
                this.update = value;
                this.$emit('update', value)
            }

        }
    }


};
</script>