<template>
  <div class="dropdown" :class="foldout" id="menu">
    <h1 id="name" class="heading-nd">{{ client_data.nickname }}</h1>
    <div class="txt-bio">{{ client_data.bio1 }}</div>
    <div class="menu-div-dropdown">
      <div class="w-layout-grid fold-out-menu max-650px" v-if="!hideLogo">
        <DownloadButton
          text="Save in contacts"
          :client_data="client_data"
          :items="
            Array.prototype.concat(
              ...[
                this.client_data.shortWidgets,
                this.client_data.imgSlider,
                this.client_data.widgetSlider,
              ]
            )
          "
        />
        <RememberButton />
      </div>
    </div>
  </div>
</template>

<script>
import DownloadButton from "./DownloadButton.vue"

import RememberButton from "./RememberButton.vue"

export default {
  components: {
    DownloadButton,
    RememberButton,
  },
  props: ["foldout"],
  data: () => {
    return {}
  },
  created() {},
  methods: {},

  computed: {
    store: function () {
      return this.$store.state
    },
    client_data: function () {
      return this.store.client_data
    },
    profileData: function () {
      return this.store.profileData
    },

    hideLogo: function () {
      return this.store.client_data.hideMozoLogo
    },

    menu: {
      get() {
        return this.store.environment.menu
      },
      set(value) {
        this.store.environment.menu = value
      },
    },
  },
}
</script>

<style scoped>
/* .w-layout-grid.fold-out-menu {
  grid-template-columns: auto;
} */

.fold-out-menu {
  -webkit-transition: 0.8s;
  transition: 0.8s;
}

.dropdown {
  width: 100%;
  display: flex;
  align-items: center;
  max-height: 0;
  -webkit-transition: max-height, 0.8s ease-in-out;
  transition: max-height, 0.8s ease-in-out;
  overflow: hidden;
  flex-direction: column;
}

@media only screen and (min-width: 900px) {
  .full {
    padding: 3% 50px;
  }
}
</style>
