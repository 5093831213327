import db_user from "@/db/2.0/user/main.js"
import methods from "@/general/HelperMethods/General.js"
import store from "@/vuex/setup"
import DataLayer from "@/general/Objects/DataLayer.js"

async function uploadToCloud(url, data, destination) {

  if (!store.state.user || !url) {
    return false
  }

  var uid = store.state.user.uid

  var isCurrentPage = false
  if (store.state.environment.data.uid != uid) {
    return false
  } else {
    isCurrentPage = true
  }



  if (isCurrentPage && uid) {
    var sorted = await methods.sortPosition(data, data.length)
    await db_user.vWidgets.updateWidgets(uid, sorted, destination)

  }
}

export default {
  async runWatchers(url) {
    store.watch(
      (state) => {
        return state.widgets.small_tile_collection
      },
      async (data) => {
        uploadToCloud(url, data, "small_tile_collection")
      },
      {
        deep: true,
      }
    )

    store.watch(
      (state) => {
        return state.widgets.image_collection
      },
      async (data) => {

        uploadToCloud(url, data, "image_collection")
      },
      {
        deep: true,
      }
    )

    store.watch(
      (state) => {
        return state.widgets.tile_collection
      },
      async (data) => {
        uploadToCloud(url, data, "tile_collection")
      },
      {
        deep: true,
      }
    )

    store.watch(
      (state) => {
        return state.widgets.banner_collection
      },
      async (data) => {
        uploadToCloud(url, data, "banner_collection")
      },
      {
        deep: true,
      }
    )
  },
}
