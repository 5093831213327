<template>
  <div class="menu-header">
    <div class="div-block-104">
      <div class="header-column-new-2">
        <div class="logo-header">
          <div class="logo-div">
            <img class="logo-img" :src="brand.logo" loading="lazy" />
            <!-- <div class="logo-text-2">
              {{ brand.name }}
            </div> -->
          </div>
        </div>
        <div class="center"></div>
        <div class="header-div">
          <div class="settings-div">
            <div @click="setMenu()" class="link-block w-inline-block">
              <img src="images/hamburger-menu.svg" loading="lazy" alt="mozo hospitality" class="settings-image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import json from "@/general/GlobalValues.json";

export default {
  data: () => {
    return {
      brand: json["brand"],
    };
  },

  computed: {
    store: function () {
      return this.$store.state;
    },
    menu: {
      get() {
        return this.store.environment.menu;
      },
      set(value) {
        this.store.environment.menu = value;
      },
    },
  },

  methods: {
    setMenu() {
      this.menu = true;
    },
  },
};
</script>

<style>
.menu-header {
  display: flex;
  align-items: center;
  min-height: 10vh;
}
</style>
