<template>
  <div class="left-contact-div">
    <div v-if="isSelected" class="div-block-103"></div>
  </div>
</template>

<script>
export default {
  props: [
    'isSelected'
  ]
};
</script>
