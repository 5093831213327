import store from "@/vuex/setup"

import connectWidgets from "@/vuex/widgets-watcher.js"
import connectUserdata from "@/vuex/variable-watcher.js"

import { analytics, logEvent } from "@/db/Firebase/index"

import DataLayer from "@/general/Objects/DataLayer"
import User from "@/general/Objects/User"
import View from "@/general/Objects/View"

interface DataModel {
  uid: string | null
  url: string | null
  branch: string | null
  group: string | null
  table: string | null
  client_name: string | null
}

export default class Main {
  private path: string
  private url: string

  constructor(path: string) {
    this.path = path
    this.url = path.split("/")[1]
  }

  async setPage(): Promise<string> {
    store.state.environment.view = "load"

    var db = await new DataLayer()
    await db.setUrlData(this.url)

    var user = await this.checkAuthState()

    var view = await this.setRoute(db, user)

    // initialize vuex & watchers & google analytics
    store.state.environment.view = view.getName()
    store.state.environment.hidePopUp = true

    await connectWidgets.runWatchers(view.getUrl())
    await connectUserdata.runWatchers(view.getUrl())
    logEvent(analytics, "client_stats", {
      client_name: store.state.client_data.nickname,
      url: view.getUrl(),
      branch: db.data.branch,
      group: db.data.group,
      table: db.data.table,
      uid: db.data.uid,
    })

    store.state.environment.dbSynced = true

    let faviconLink: any = document.getElementById('favicon');
    let maskIconLink: any = document.querySelector('link[rel="mask-icon"]');
    let webclipLink: any = document.getElementById('webclip');

    if (faviconLink) {
      faviconLink.href = store.state.client_data.image + "?v=2"
    } else {
      const newFaviconLink: any = document.createElement('link');
      newFaviconLink.href = store.state.client_data.image + "?v=2"
      newFaviconLink.rel = 'shortcut icon';
      newFaviconLink.type = 'image/x-icon';
      newFaviconLink.id = 'favicon';
      document.head.appendChild(newFaviconLink);
    }


    if (webclipLink) {
      webclipLink.href = store.state.client_data.image + "?v=2"
    } else {
      const newWebclipLink: any = document.createElement('link');
      newWebclipLink.href = store.state.client_data.image + "?v=2"
      newWebclipLink.rel = 'apple-touch-icon';
      newWebclipLink.id = 'webclip';
      document.head.appendChild(newWebclipLink);
    }


    if (maskIconLink) {
      maskIconLink.href = store.state.client_data.image + "?v=2"
    } else {
      maskIconLink = document.createElement('link');
      maskIconLink.href = store.state.client_data.image + "?v=2"
      maskIconLink.rel = 'mask-icon';
      maskIconLink.color = '#5bbad5'; // Replace with your desired mask-icon color
      document.head.appendChild(maskIconLink);
    }

    // Create the title element
    document.title = store.state.client_data.nickname

    store.state.environment.menu = false

    return "/" + view.getUrl()
  }

  async checkAuthState(): Promise<User> {
    const userClass = new User()

    await userClass.setUser()
    var user = userClass.getUser()

    userClass.storeUser(store.state)

    return user
  }

  async setRoute(db: DataLayer, user: any): Promise<View> {
    var isExistingProfile = await this.checkUrl(db)

    var view = new View()

    if (user) {
      view = await this.loggedInRoute(view, db, user, isExistingProfile)
    } else {
      view = await this.loggedOutRoute(view, db, isExistingProfile)
    }

    return view
  }

  async loggedInRoute(
    view: View,
    db: DataLayer,
    user: any,
    isExistingProfile: boolean
  ): Promise<View> {
    store.state.environment.loggedIn = true
    var url = null

    if (isExistingProfile) {
      var isCurrentUserProfile = await this.checkUid(db, user.uid)

      if (isCurrentUserProfile) {
        await db.setPublicProfile(db.data.uid)
        await view.setName("config")
        await view.setEnvironment("config")
        await view.setUrl(this.url)
      } else {
        await db.setPublicProfile(db.data.uid)
        await db.setTableAndGroup(db.data)
        await view.setName("public")
        await view.setEnvironment(null)
        await view.setUrl(this.url)
      }
    } else {
      db.setUID(user.uid)

      if (await db.hasPage(user.uid)) {
        await db.setPublicProfile(db.data.uid)
        await view.setName("config")
        await view.setEnvironment("config")
        await view.setUrl(db.getUrl())
      } else {
        await view.setName("404")
        await view.setEnvironment(null)
        await view.setUrl("404")
      }
    }
    return view
  }

  async loggedOutRoute(
    view: View,
    db: DataLayer,
    isExistingProfile: boolean
  ): Promise<View> {
    store.state.environment.loggedIn = false
    var url = null

    if (isExistingProfile) {
      await db.setPublicProfile(db.data.uid)
      await db.setTableAndGroup(db.data)
      await view.setName("public")
      await view.setEnvironment(null)
      await view.setUrl(this.url)
    } else {
      await view.setName("login")
      await view.setEnvironment(null)
      await view.setUrl("login")
    }
    return view
  }

  async checkUrl(db: DataLayer): Promise<boolean> {
    const data = (await db.getUrlData()) as DataModel | null

    return data != null ? true : false
  }

  async checkUid(db: DataLayer, input_uid: string): Promise<boolean> {
    const data = db.data as DataModel | null

    if (data == null) {
      return false
    }

    if (data.uid == null) {
      return false
    }

    return data.uid == input_uid ? true : false
  }
}
