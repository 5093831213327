export interface WidgetModel {
  title: string
  name: string
  view_icon: string
  options: string[][]
  days: number
  implemented: boolean
  category_id: string
  featured: boolean
  path: string
}

const general: Array<WidgetModel> = [
  {
    title: "URL",
    name: "url",
    view_icon: "url",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Banner Widget",
    name: "banner",
    view_icon: "banner",
    options: [["link"], ["body"]],
    days: 0,
    implemented: true,
    category_id: "banner",
    featured: false,
    path: "https://",
  },
  {
    title: "Webshop",
    name: "webshop",
    view_icon: "webshop",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Image",
    name: "image",
    view_icon: "image",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Phone",
    name: "phone",
    view_icon: "phone",
    options: [["number"]],
    days: 0,
    implemented: true,
    category_id: "social",
    featured: false,
    path: "tel:",
  },
  {
    title: "Email",
    name: "mail",
    view_icon: "mail",
    options: [["mail"]],
    days: 0,
    implemented: true,
    category_id: "social",
    featured: false,
    path: "mailto:",
  },
  {
    title: "Maps",
    name: "maps",
    view_icon: "maps",
    options: [["adress", "country", "zip code", "city"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://maps.google.com/?q=",
  },
  {
    title: "Company",
    name: "company",
    view_icon: "company",
    options: [["company"], ["job role"], ["location"], ["phone"], ["email"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Login",
    name: "login",
    view_icon: "login",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Register",
    name: "register",
    view_icon: "register",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "File",
    name: "file",
    view_icon: "file",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },

  {
    title: "BOB",
    name: "bob",
    view_icon: "bob",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Twitter",
    name: "twitter",
    view_icon: "twitter",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "social",
    featured: false,
    path: "https://",
  },
  {
    title: "Whatsapp",
    name: "whatsapp",
    view_icon: "whatsapp",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "social",
    featured: false,
    path: "https://",
  },
  {
    title: "Instagram",
    name: "instagram",
    view_icon: "instagram",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "social",
    featured: false,
    path: "https://",
  },
  {
    title: "Linkedin",
    name: "linkedin",
    view_icon: "linkedin",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "payment",
    featured: false,
    path: "https://",
  },
  {
    title: "TikTok",
    name: "tiktok",
    view_icon: "tiktok",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "social",
    featured: false,
    path: "https://",
  },
  {
    title: "Snapchat",
    name: "snapchat",
    view_icon: "snapchat",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "social",
    featured: false,
    path: "https://",
  },
  {
    title: "Facebook",
    name: "facebook",
    view_icon: "facebook",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "social",
    featured: false,
    path: "https://",
  },
  {
    title: "Pinterest",
    name: "pinterest",
    view_icon: "pinterest",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "social",
    featured: false,
    path: "https://",
  },
  {
    title: "YouTube",
    name: "youtube",
    view_icon: "youtube",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "social",
    featured: false,
    path: "https://",
  },
  {
    title: "Spotify",
    name: "spotify",
    view_icon: "spotify",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "social",
    featured: false,
    path: "https://",
  },
  {
    title: "SoundCloud",
    name: "soundcloud",
    view_icon: "soundcloud",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "social",
    featured: false,
    path: "https://soundcloud.com/",
  },
  {
    title: "Music",
    name: "applemusic",
    view_icon: "applemusic",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "social",
    featured: false,
    path: "https://",
  },
  {
    title: "Deezer",
    name: "deezer",
    view_icon: "deezer",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "social",
    featured: false,
    path: "https://",
  },
  {
    title: "Tidal",
    name: "tidal",
    view_icon: "tidal",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "social",
    featured: false,
    path: "https://",
  },

  {
    title: "Forms",
    name: "google-forms",
    view_icon: "google-forms",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
]

const hospitality: Array<WidgetModel> = [
  {
    name: "mozo_30seconds",
    title: "30 Seconds",
    view_icon: "mozo_30seconds",
    options: [[]],
    days: 0,
    implemented: true,
    category_id: "games",
    featured: true,
    path: "https://30seconds.themozo.app",
  },
  {
    name: "mozo_friends",
    title: "Friends",
    view_icon: "mozo_friends",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "loyalty",
    featured: true,
    path: "https://",
  },
  {
    name: "mozo_order",
    title: "Mozo Ordering",
    view_icon: "food",
    options: [[]],
    days: 7,
    implemented: true,
    category_id: "order",
    featured: true,
    path: "https://demo.orderli.com",
  },
  {
    name: "mozo_pay",
    title: "Pay",
    view_icon: "mozo_pay",
    options: [[]],
    days: 14,
    implemented: true,
    category_id: "pay",
    featured: true,
    path: "https://themozo.app/bill?id=brasserietop&table=1&redirectUrl=brasserietop",
  },
  {
    name: "mozo_wof",
    title: "Wheel Of Fortune",
    view_icon: "mozo_wof",
    options: [[]],
    days: 7,
    implemented: true,
    category_id: "games",
    featured: true,
    path: "https://wof.themozo.app/?id=test&table=1",
  },
  {
    name: "tipzy",
    title: "Tipzy",
    view_icon: "food",
    options: [[]],
    days: 7,
    implemented: true,
    category_id: "order",
    featured: false,
    path: "https://",
  },
  {
    name: "typeform_jobs",
    title: "Jobs",
    view_icon: "jobs",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    name: "typeform_feedback",
    title: "Feedback",
    view_icon: "feedback",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "reviews",
    featured: false,
    path: "https://",
  },
  {
    name: "typeform_loyalty",
    title: "Typeform",
    view_icon: "gift",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "loyalty",
    featured: false,
    path: "https://",
  },
  {
    title: "Tripadvisor",
    name: "tripadvisor",
    view_icon: "tripadvisor",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "reviews",
    featured: false,
    path: "https://",
  },
  {
    title: "Google Reviews",
    name: "googlereviews",
    view_icon: "googlereviews",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "reviews",
    featured: false,
    path: "https://",
  },
  {
    title: "Jamezz",
    name: "jamezz",
    view_icon: "food",
    options: [[]],
    days: 7,
    implemented: true,
    category_id: "order",
    featured: false,
    path: "https://qrv5.jamezz.app/v5/qr?qr=1234",
  },
  {
    title: "EasyOrder",
    name: "easyorder",
    view_icon: "easyorder",
    options: [["link"]],
    days: 7,
    implemented: true,
    category_id: "order",
    featured: false,
    path: "https://",
  },
  {
    title: "Butlaroo",
    name: "butlaroo",
    view_icon: "food",
    options: [[]],
    days: 7,
    implemented: true,
    category_id: "order",
    featured: false,
    path: "https://butl.nl",
  },
  {
    title: "Yelp",
    name: "yelp",
    view_icon: "yelp",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "reviews",
    featured: false,
    path: "https://",
  },
  {
    title: "Gevonden Voorwerpen",
    name: "gevonden_voorwerpen",
    view_icon: "gevonden_voorwerpen",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Thuisbezorgd",
    name: "thuisbezorgd",
    view_icon: "thuisbezorgd",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "order",
    featured: false,
    path: "https://",
  },
  {
    title: "Uber Eats",
    name: "ubereats",
    view_icon: "ubereats",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "order",
    featured: false,
    path: "https://",
  },
  {
    title: "Deliveroo",
    name: "deliveroo",
    view_icon: "deliveroo",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "order",
    featured: false,
    path: "https://",
  },
  {
    title: "Untappd",
    name: "untappd",
    view_icon: "untappd",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "menu",
    featured: false,
    path: "https://",
  },
  /*
    {
      title: "WiFi",
      name: "wifi",
      view_icon: "wifi",
      // options: [["WiFi name"], ["password"]],
      options: [["link"]],
      days: 0,
      implemented: true,
      category_id: "info",
      featured: false,
      path: "https://",
    },
    */
  {
    title: "Meatloverz",
    name: "meatloverz",
    view_icon: "meatloverz",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "menu",
    featured: false,
    path: "https://",
  },
  {
    title: "Favourites",
    name: "favourites",
    view_icon: "favourites",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Gluten Free",
    name: "glutenfree",
    view_icon: "glutenfree",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Halal",
    name: "halal",
    view_icon: "halal",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "menu",
    featured: false,
    path: "https://",
  },
  {
    title: "Vegan",
    name: "vegan",
    view_icon: "vegan",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "menu",
    featured: false,
    path: "https://",
  },

  {
    title: "Originates",
    name: "whereitcomesfrom",
    view_icon: "whereitcomesfrom",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Area Map",
    name: "areamap",
    view_icon: "areamap",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Reservation",
    name: "reservation",
    view_icon: "reservation",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Park",
    name: "park",
    view_icon: "park",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Public transport",
    name: "publictransport",
    view_icon: "publictransport",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Business hours",
    name: "businesshours",
    view_icon: "businesshours",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Ticket",
    name: "ticket",
    view_icon: "ticket",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Gift",
    name: "gift",
    view_icon: "gift",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "loyalty",
    featured: false,
    path: "https://",
  },
  {
    title: "Deal",
    name: "deal",
    view_icon: "deal",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Food",
    name: "food",
    view_icon: "food",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "menu",
    featured: false,
    path: "https://",
  },
  {
    title: "Event",
    name: "event",
    view_icon: "event",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Drinks",
    name: "drinks",
    view_icon: "drinks",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "menu",
    featured: false,
    path: "https://",
  },
  {
    title: "Text",
    name: "textbox",
    view_icon: "textbox",
    options: [["title"], ["description"], ["image"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Jobs",
    name: "jobs",
    view_icon: "jobs",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },

  {
    title: "Car",
    name: "car",
    view_icon: "car",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Covid-19",
    name: "covid",
    view_icon: "covid",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Calendar",
    name: "calendar",
    view_icon: "calendar",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Feedback",
    name: "feedback",
    view_icon: "feedback",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "reviews",
    featured: false,
    path: "https://",
  },

  // {
  //   title: "Menu",
  //   name: "menu",
  //   options: [["link"]],
  //   days: 0,
  //   implemented: true,
  //   category_id: "order",
  //   featured: false,
  //   path: "https://",
  // },
  {
    title: "Menu Breakfast",
    name: "menu_breakfast",
    view_icon: "menu_breakfast",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "menu",
    featured: false,
    path: "https://",
  },
  {
    title: "Menu Lunch",
    name: "menu_lunch",
    view_icon: "menu_lunch",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "menu",
    featured: false,
    path: "https://",
  },
  {
    title: "Menu Dinner",
    name: "menu_dinner",
    view_icon: "menu_dinner",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "menu",
    featured: false,
    path: "https://",
  },
  {
    title: "Menu Child",
    name: "menu_child",
    view_icon: "menu_child",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "menu",
    featured: false,
    path: "https://",
  },
  {
    title: "Menu Dessert",
    name: "menu_dessert",
    view_icon: "menu_dessert",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "menu",
    featured: false,
    path: "https://",
  },

  {
    title: "Fietsroute",
    name: "fietsroute",
    view_icon: "fietsroute",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
  {
    title: "Scover",
    name: "scover",
    view_icon: "scover",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "menu",
    featured: false,
    path: "https://",
  },

  {
    title: "Beer",
    name: "beer",
    view_icon: "beer",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "menu",
    featured: false,
    path: "https://",
  },
  {
    title: "Book",
    name: "book",
    view_icon: "book",
    options: [["link"]],
    days: 0,
    implemented: true,
    category_id: "info",
    featured: false,
    path: "https://",
  },
]

const username_addons = Object({
  twitter: "/",
  instagram: "/",
  linkedin: "/in/",
  behance: "/",
  tiktok: "/@",
  snapchat: "/add/",
  facebook: "/",
  youtube: "/",
  github: "/",
  pinterest: "/",
  twitch: "/",
  onlyfans: "/",
  soundcloud: "/",
  patreon: "/",
})

const local_widgets = ["xbox", "playstation", "company", "wifi"]

const maps_var = ["adress", "country", "zip code", "city"]

const not_local = [
  "phone",
  "mail",
  "file",
  "twitter",
  "instagram",
  "linkedin",
  "tiktok",
  "snapchat",
  "facebook",
  "pinterest",
  "soundcloud",
  "spotify",
  "applemusic",
  "deezer",
  "tidal",
]

export { general, hospitality, username_addons, local_widgets, maps_var, not_local }
