<template>
  <div class="section-wcategories" @click="setBGClicked(true, -1)">
    <BackgroundLayer :color1="colors[0]" :color2="colors[1]" />
    <div class="front-layer">
      <LogoHeader />
      <div class="scroll-area">
        <div class="white-background2">
          <div class="default-width center">
            <p style="color: red; font-size: 18px">{{ error }}</p>
            <Title2
              :input="'Widget'"
              :showback="'Widget'"
              :showCross="'Main'"
              :text="'Add ' + widget_title_placeholder"
              @return="titleOutput"
            />
            <template v-if="!isImgWidget()">
              <WidgetImage :widget_name="widget.name" />
            </template>
            <template v-else-if="widget.image">
              <ImgField
                :image="widget.image"
                :isChange="true"
                :group="widget.type"
                :widget_uid="widget.widget_uid"
                @returnImage="changeImage"
                @returnFile="setImageFile"
                @error="setImageError"
              />
            </template>
            <template v-if="widget.type != 'small_tile_collection'">
              <InputField
                :label="'Displayed text'"
                :input="widget.title"
                :placeholder="widget_title_placeholder"
                @setInput="setTitleInput"
              />
            </template>
            <div v-if="isLoaded && hasOptions" class="choose-account-div">
              <h2 class="label">Choose an account</h2>
              <div class="selectfield border">
                <div v-for="(account, index) in accounts" :key="index">
                  <Account
                    :account="account"
                    :widget_name="widget.name"
                    :bgClicked="bgClicked"
                    :index="index"
                    @compClicked="setBGClicked"
                    @accountSelected="getIndex"
                    @deleteAccount="deleteAccount(account)"
                  />
                </div>
                <AddAccount @next="goSetAccount" />
              </div>
            </div>
          </div>
          <div class="default-width center">
            <template v-if="showPreview">
              <Button
                :opacity="opacity_preview"
                :image="'link.png'"
                :text="'Preview'"
                @isClicked="goPreview"
              />
            </template>

            <Button
              :opacity="opacity_save"
              :text="connection_button"
              @isClicked="saveWidget"
            />

            <template v-if="widget.action == 'update'">
              <DeleteButton
                :opacity="''"
                :text="'Delete Widget'"
                @isClicked="deleteWidget"
              />
            </template>
          </div>
        </div>
      </div>
    </div>

    <template v-if="showPopup">
      <div class="popup-layer">
        <div class="are-you-sure-div">
          <Title2 text="Sorry !" :showCross="true" @return="closePopup" />

          <p class="text-block-8">
            You use this account on other widgets as well, please first unselect the
            account at the other widgets before you delete the account.
          </p>
          <p class="text-block-8" style="margin-bottom: 5%">Other widgets:</p>
          <div
            class="account-div wm"
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 100%;
              margin-bottom: 2%;
              justify-content: center;
            "
            v-for="(widget, index) in widgetsInUse"
            :key="index"
          >
            <div class="social-div-account zeromarge">
              <Icon
                :widget="widget.name"
                :colors_arr="colors[2]"
                :classInput="'social-icon'"
              />
            </div>
            <p style="margin-left: 5%" class="paragraph black-text">
              {{ widget.title }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BackgroundLayer from "@/general/Components/2.0/General/BackgroundLayer.vue"
import LogoHeader from "@/general/Components/2.0/CategoryManagement/General/LogoHeader.vue"
import Title from "@/general/Components/2.0/CategoryManagement/General/Title.vue"
import Title2 from "@/general/Components/2.0/CategoryManagement/General/Title2.vue"
import InputField from "@/general/Components/2.0/CategoryManagement/General/InputField.vue"
import ImgField from "@/general/Elements/2.0/OtherElements/Widgets/ImgField.vue"
import Account from "@/general/Components/2.0/CategoryManagement/General/Account.vue"
import AddAccount from "@/general/Components/2.0/CategoryManagement/AccountChosen/AddAccount.vue"
import Button from "@/general/Components/2.0/CategoryManagement/General/Button.vue"
import DeleteButton from "@/general/Components/2.0/CategoryManagement/General/ButtonNoBG.vue"

import WidgetImage from "../General/WidgetImage.vue"

import WidgetRepository from "@/general/Objects/Widget"
import WidgetCollection from "@/general/Objects/WidgetCollection"

import Icon from "@/general/Elements/2.0/OtherElements/Widgets/WidgetIcon2.vue"

export default {
  components: {
    Title,
    Title2,
    InputField,
    Account,
    Button,
    DeleteButton,
    BackgroundLayer,
    WidgetImage,
    AddAccount,
    LogoHeader,
    ImgField,
    Icon,
  },
  props: ["setLatest"],
  data: () => {
    return {
      bgClicked: false,
      widget_title_placeholder: null,
      accounts: [],
      selectedAccount: null,
      options: [],
      opacity_preview: "opacitylow",
      opacity_save: "opacitylow",
      currentNumber: -1,
      file: null,
      isLoaded: false,
      showPopup: false,
      widgetsInUse: [],
      error: "",
      hasOptions: false,
      connection_button: "Connect",
    }
  },

  async created() {
    const wObject = new WidgetRepository()
    this.widget_title_placeholder = await wObject.getTitle(this.widget.name)

    const wCol = new WidgetCollection()
    this.accounts = await wCol.getAllAccounts(this.widget.name)

    await this.setButton()

    if (this.widget.action == "update") {
      await this.getWidgetData()
    }

    if (this.account.action == "saved") {
      await this.setSavedAccount()
    }

    this.isLoaded = true

  },
  computed: {
    store: function () {
      return this.$store.state
    },
    colors: function () {
      return this.store.client_data.color
    },
    showPreview: function () {
      return this.options.length == 1 || this.options.length == 0 ? true : false
    },
    widget: {
      get() {
        return this.store.environment.setWidget.item
      },
      set(value) {
        this.store.environment.setWidget.item = value
      },
    },
    account: {
      get() {
        return this.store.environment.setAccount.item
      },
      set(value) {
        this.store.environment.setAccount.item = value
      },
    },
    uid: function () {
      return this.store.user.uid
    },
  },
  methods: {
    setImageError(error) {
      this.error = error
    },
    async setSavedAccount() {
      const wCol = new WidgetCollection()
      this.currentNumber = this.accounts.length - 1
      const account_uid = this.accounts[this.currentNumber].uid
      this.accounts = await wCol.set_accounts(true, this.accounts, this.currentNumber)
      this.currentNumber = await this.accounts.findIndex((el) => el.uid == account_uid)

      this.selectedAccount = this.accounts[this.currentNumber]

      this.setBGClicked(false, this.currentNumber)
      this.account.action = ""
    },
    closePopup() {
      this.showPopup = false
    },

    titleOutput(item) {
      if (
        (item == "cross" && this.widget.action == "create") ||
        (item == "cross" && this.currentNumber != -1)
      ) {
        // this check makes sure the widget is either new or the account is set
        // otherwise an error occurs
        this.$router.push({ name: "Main" })
      } else if (item == "back") {
        this.$router.push({ name: "Widget" })
      } else {
        this.error = "Sorry you first have to select an account!"
      }
    },

    async getWidgetData() {
      const wCol = new WidgetCollection()
      const widget = await wCol.get(this.widget.widget_uid, this.widget.type)
      if (widget != null) {
        this.currentNumber = await this.accounts.findIndex(
          (el) => el.uid == widget.account_uid
        )
        this.accounts = await wCol.set_accounts(true, this.accounts, this.currentNumber)
        this.currentNumber = await this.accounts.findIndex(
          (el) => el.uid == widget.account_uid
        )

        this.selectedAccount = this.accounts[this.currentNumber]
        this.opacity_preview = ""

        this.widget.title = widget.title
        this.widget.image = widget.image
        this.connection_button = "Update widget"
      }
    },

    async setButton() {
      const wObject = new WidgetRepository()
      this.widget_legend = await wObject.getWidgetByName(this.widget.name)
      if (this.widget_legend.options[0].length > 0) {
        this.hasOptions = true
      } else {
        this.opacity_preview = ""
        this.opacity_save = ""
        if (this.widget_legend.days > 0 && this.accounts.length <= 0) {
          this.connection_button = "Request access"
        }
      }
    },

    changeImage(value) {
      this.widget.image = value
    },
    setImageFile(file) {
      this.file = file
    },
    isImgWidget() {
      if (this.widget.type == "image_collection") {
        return true
      } else if (this.widget.type == "banner_collection") {
        return true
      } else {
        return false
      }
    },
    async getIndex(index) {
      this.currentNumber = index
      const wCol = new WidgetCollection()
      this.accounts = await wCol.set_accounts(false, this.accounts, this.currentNumber)
      this.selectedAccount = this.accounts[index]

      this.account.data = this.accounts[index].data
      this.account.uid = this.accounts[index].uid
    },
    async deleteAccount(account) {
      const wCol = new WidgetCollection()
      this.widgetsInUse = await wCol.accountIsInUse(account.uid)

      if (this.widgetsInUse.length == 0) {
        //no accounts are selected
        await wCol.deleteAccount(account.uid)
        const wCollectionObject = new WidgetCollection()
        this.accounts = await wCollectionObject.getAllAccounts(this.widget.name)
        this.currentNumber = -1
      } else if (
        this.widgetsInUse.length == 1 &&
        this.widgetsInUse[0].account_uid == this.selectedAccount.uid
      ) {
        //is current account
        await wCol.deleteAccount(account.uid)
        const wCollectionObject = new WidgetCollection()
        this.accounts = await wCollectionObject.getAllAccounts(this.widget.name)
        this.currentNumber = -1
      } else {
        // other widgets use the account, don't delete
        this.showPopup = true
      }
    },
    setBGClicked(value, index) {
      if (index == this.accounts.length - 1) {
        this.bgClicked = value
      } else if (index == -1) {
        this.bgClicked = value
      }

      if (this.selectedAccount != null) {
        this.opacity_preview = ""
        this.opacity_save = ""
      } else if (this.hasOptions) {
        this.opacity_preview = "opacitylow"
        this.opacity_save = "opacitylow"
      }
    },

    async goPreview() {
      let url
      const wObject = new WidgetRepository()
      if (this.hasOptions) {
        const account = this.accounts[this.currentNumber]
        url = await wObject.getField(
          account.widget,
          account.data[0].field,
          account.data[0].name
        )
      } else {
        url = await wObject.getWidgetByName(this.widget.name).path
      }

      window.open(url)
    },

    async saveAccountDirect() {
      const wCol = new WidgetCollection()
      this.accounts = await wCol.getAllAccounts(this.widget.name)

      const wObject = new WidgetRepository()

      if (this.accounts.length <= 0) {
        const data = [
          {
            index: 0,
            field: await wObject.getWidgetByName(this.widget.name).path,
            name: this.widget.name,
          },
        ]
        const account_uid = await wCol.addAccount(this.widget.name, data)

        return account_uid
      }

      return this.accounts[0].uid
    },

    setTitleInput(input) {
      this.widget.title = input
    },

    async saveWidget() {

      if (this.widget.title == null || this.widget.title == "") {
        const wObject = new WidgetRepository()
        this.widget.title = await wObject.getTitle(this.widget.name)
      }

      this.store.environment.setAccount.item.action == ""

      if (this.opacity_save == "") {
        if (this.hasOptions) {
          this.$emit("saveWidget", this.selectedAccount.uid, this.file)
        } else {
          const account_uid = await this.saveAccountDirect()
          this.$emit("saveWidget", account_uid, this.file)
        }
      }
    },

    async deleteWidget() {
      const wCol = new WidgetCollection()

      await wCol.delete(this.widget.type, this.widget.widget_uid)

      this.$router.push({ name: "Main" })
    },

    goSetAccount() {
      this.account.action = "create"

      this.$router.push({ name: "SetAccount" })
    },
  },
}
</script>

<style scoped>
.full {
  width: 100%;
  height: 100%;
}

.popup-layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
}

.social-div-account.zeromarge {
  margin-right: 0px;
}

@media screen and (max-width: 767px) {
  .front-layer {
    padding-top: 10%;
  }
}

@media screen and (max-width: 479px) {
  .icon-plate {
    height: 100px;
    padding: 5%;
    margin-bottom: 0%;
  }
}
</style>
