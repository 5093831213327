<template>
  <div class="full">
    <div class="lottie-animation">
      <lottie-animation path="logo_animation.json" />
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"

export default {
  components: {
    LottieAnimation,
  },

  created() {},
}
</script>
