<template>
  <div class="fullwidth">
    <template v-if="input_widgets[index] != null">
      <BannerItem :item="input_widgets[index]" :index="index" />
    </template>
  </div>
</template>
  

<script>

import BannerItem from "@/general/Elements/2.0/OtherElements/BannerItem.vue"


export default {
  components: {
    BannerItem,

  },
  props: ["index", "input_widgets"],
  computed: {

  },
  created() {
  },

  data: () => {
    return {

    };
  },

  methods: {},
};
</script>


<style scoped>
.fullwidth {
  width: 100%;
}
</style>

