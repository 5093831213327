<template>
  <div @click="isClicked" class="category-row lined">
    <div class="category-img-name">
      <!-- <img :src="'/images/' + Image1" loading="lazy" class="category-img" /> -->
      <Icon :category="Image1" :colors_arr="store.client_data.color[2].icons" />
      <h1 class="heading-3 black-text">{{ Title }}</h1>
    </div>
    <img src="/images/backbutton.svg" loading="lazy" class="arrow-img-widgetcategories" />
  </div>
</template>

<script>
import Icon from "@/general/Components/2.0/CategoryManagement/WidgetCategories/CategoryIcon.vue"
export default {
  components: { Icon },
  created() {},
  computed: {
    store: function () {
      return this.$store.state
    },
    setWidget: {
      get() {
        return this.store.environment.setWidget.item
      },
      set(value) {
        this.store.environment.setWidget.item = value
      },
    },
  },
  methods: {
    isClicked() {
      // console.log(this.Title)
      this.setWidget["category"] = this.Title
      this.$router.push({
        name: "Widget",
      })
    },
  },
  props: ["Title", "Image1", "Image2"],
}
</script>

<style scoped>
.category-row {
  cursor: pointer;
}

.category-text {
  text-transform: capitalize;
}
</style>
