<template>
  <div class="background mozo" :style="setBackground(addUser.color[0], addUser.color[1])">
    <template v-if="showPopup">
      <PopUp title="You are still logedin!"
        text="You cannot be friends with yourself! Please logout if you want be friends with another account!"
        :showCloseButton="true" button="Logout" @close="closePopup()" @next="setNext" />
    </template>

    <template v-if="view == 'login'">
      <Login />
    </template>
    <template v-else>
      <EmailSent />
    </template>

  </div>
</template>

<script>
import methods from "@/general/HelperMethods/General.js";

import db_auth from "@/db/2.0/auth.js"

import Login from "@/general/Components/2.0/FriendsOf/Login.vue";

import PopUp from "@/general/Components/2.0/FriendsOf/PopUp.vue";

import DataLayer from "@/general/Objects/DataLayer.js";

import EmailSent from "@/views/HandleEmailRequest/EmailSent.vue";



export default {
  components: {
    Login,
    PopUp,
    EmailSent
  },

  data: () => {
    return {
      isLoggedin: false,
      showPopup: false,
    };
  },

  props: [
    'input_user'
  ],

  async created() {

    if (this.input_user) {
      this.addUser = this.input_user;
      localStorage.setItem('addUser', JSON.stringify(this.input_user));
      methods.changeUrlWithoutReload("/loginfriend")
    } else {
      this.addUser = await JSON.parse(localStorage.getItem('addUser'));
      this.user = await db_auth.userState();
    }

    this.isSameProfile()
  },

  computed: {
    store: function () {
      return this.$store.state;
    },
    client_data: {
      get() {
        return this.store.client_data;
      },
      set(value) {
        this.store.client_data = value;
      },
    },
    view: {
      get() {
        return this.store.environment.addUser.view;
      },
      set(value) {
        this.store.environment.addUser.view = value;
      }
    },
    user: {
      get() {
        return this.store.user;
      },
      set(value) {
        this.store.user = value;
      }
    },


    addUser: {
      get() {
        return this.store.environment.addUser;
      },
      set(value) {
        this.store.environment.addUser = value;
      }
    },
    profile_url: function () {
      return this.addUser.profile_url;
    },
  },


  methods: {
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2);
    },

    async setNext(goNext) {
      if (goNext) {
        this.user = await db_auth.logout();
        this.isSameProfile();
      }
    },

    closePopup() {
      this.$router.push(this.profile_url);
    },

    async isSameProfile() {
      if (!this.user) {
        this.showPopup = false;
        return;
      }
      var urls = this.profile_url

      var db = await new DataLayer();
      await db.setUrlData(urls);

      var data = db.data;

      if (data == null) {
        this.showPopup = false;
        return;
      }

      if (this.user == null) {
        this.showPopup = false;
        return;
      }

      this.showPopup = data.uid == this.user.uid;
    }

  },
};
</script>

<style>
.div-block-90 {
  height: 100%;
  justify-content: center;
  min-height: 90vh;
}
</style>
