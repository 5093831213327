var small_tile_collection = [
  {
    data: [
      {
        index: 0,
        field: "https://www.facebook.com/BrasserieThuys/",
        name: "link",
      },
    ],
    position: 0,
    name: "facebook",
    title: "",
    uid: "widget_x_1",
    request_date: "1663765700",
    status: "active",
  },
  {
    data: [
      {
        index: 0,
        field: "https://www.instagram.com/Brasseriethuys/",
        name: "link",
      },
    ],
    position: 1,
    name: "instagram",
    title: "",
    uid: "widget_x_2",
    request_date: "1663765700",
    status: "active",
  },
  {
    data: [
      {
        index: 0,
        field:
          "https://www.tripadvisor.nl/Restaurant_Review-g656891-d17310086-Reviews-Thuys-Sint_Michielsgestel_North_Brabant_Province.html",
        name: "link",
      },
    ],
    position: 2,
    name: "tripadvisor",
    title: "",
    uid: "widget_x_3",
    request_date: "1663765700",
    status: "active",
  },
]
var image_collection = [
  {
    image:
      "https://brasseriethuys.nl/wp-content/uploads/2021/10/f3b9017a-215a-40b0-a703-a675a05b6ccd.jpg",
    data: [
      {
        field: "https://brasseriethuys.nl/solliciteren/#kt-layout-id_d727ff-7d",
        name: "link",
        index: 0,
      },
    ],
    position: 0,
    name: "url",
    title: "WERKEN BIJ THUYS?",
    uid: "widget_x_4",
    request_date: "1663765700",
    status: "active",
  },
  {
    image: "https://brasseriethuys.nl/wp-content/uploads/2021/10/IMG_6399.jpg",
    data: [
      {
        field: "https://brasseriethuys.nl/actief-bij-thuys",
        name: "link",
        index: 0,
      },
    ],
    position: 0,
    name: "url",
    title: "ACTIEF BIJ THUYS",
    uid: "widget_x_5",
    request_date: "1663765700",
    status: "active",
  },
  {
    image: "https://brasseriethuys.nl/wp-content/uploads/2021/10/IMG_6399.jpg",
    data: [
      {
        field: "https://brasseriethuys.nl/actief-bij-thuys",
        name: "link",
        index: 0,
      },
    ],
    position: 2,
    name: "url",
    title: "ACTIEF BIJ THUYS",
    uid: "widget_x_6",
    request_date: "1663765700",
    status: "active",
  },
]

var tile_collection = [
  {
    data: [
      {
        index: 0,
        field: "https://amuse-menu.nl/restaurant/brasserie-thuys-sint-michielsgestel",
        name: "link",
      },
    ],
    position: 0,
    name: "food",
    title: "Bestellen",
    uid: "widget_x_7",
    request_date: "1663765700",
    status: "active",
  },
  {
    data: [
      {
        index: 0,
        field: "https://brasseriethuys.nl/menu/arrangementen/",
        name: "link",
      },
    ],
    position: 1,
    name: "deal",
    title: "Arrangementen",
    uid: "widget_x_8",
    request_date: "1663765700",
    status: "active",
  },
  {
    data: [
      {
        index: 0,
        field: "https://brasseriethuys.nl/reserveren/",
        name: "link",
      },
    ],
    position: 2,
    name: "reservation",
    title: "Reserveren",
    uid: "widget_x_9",
    request_date: "1663765700",
    status: "active",
  },
]

var banner_collection = [
  {
    position: 0,
    name: "banner",
    title: "Wist je dat?",
    image: "/images/imagetest.svg",
    data: [
      {
        field: "",
        index: 0,
        name: "link",
      },
      {
        field: "Wij ook cadeaubonnen verkopen",
        index: 1,
        name: "body",
      },
    ],
    uid: "widget_x_10",
    request_date: "1663765700",
    status: "active",
  },
  {
    position: 0,
    name: "banner",
    title: "Wist je dat?",
    image: "/images/imagetest.svg",
    data: [
      {
        field: "",
        index: 0,
        name: "link",
      },
      {
        field: "Onze HalloMies salade vernoemd is naar onze chef Mies",
        index: 1,
        name: "body",
      },
    ],
    uid: "widget_x_11",
    request_date: "1663765700",
    status: "active",
  },
]

export default {
  small_tile_collection,
  image_collection,
  tile_collection,
  banner_collection,
}
