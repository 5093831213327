<template>
  <div
    style="
      width: 100%;
      height: 100%;
      min-height: 65vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    "
  >
    <div class="default-width center">
      <Title :showback="true" :text="'Replace Widget'" @return="goBack" />
      <LargeWidget @isClicked="setPopup" />
    </div>
    <Button text="Add Widget" :opacity="opacity_save" />
  </div>
</template>

<script>
import Title from "@/general/Components/2.0/CategoryManagement/General/Title2.vue"
import LargeWidget from "@/general/Components/2.0/TableManagement/EditWidget/LargeAddWidget.vue"
import Button from "@/general/Components/2.0/CategoryManagement/General/Button.vue"

export default {
  components: {
    Title,
    Button,
    LargeWidget,
  },

  props: ["table"],

  data: () => {
    return {
      selected_widget: "url",
      options: ["link"],
      color: [
        "#11d1ad",
        "#116c5b",
        {
          icons: ["#116c5b", "#116c5b", "#ffffff"],
        },
      ],
      widgetNames: [{ id: "default", name: "Choose an widget..", type: null }],
      itemType: null,
      widget_uid: null,
      showAddField: false,
      default_field: "Choose an widget..",
      isLoaded: false,
      accounts: [],
      opacity_save: "opacitylow",
    }
  },

  async created() {},

  computed: {
    store: function () {
      return this.$store.state
    },
    widgets: function () {
      return this.store.widgets
    },

    widget: {
      get() {
        return this.store.environment.editTableWidget
      },
      set(value) {
        this.store.environment.editTableWidget = value
      },
    },

    state: {
      get() {
        return this.store.environment.stateTableWidget
      },
      set(value) {
        this.store.environment.stateTableWidget = value
      },
    },

    tableID: function () {
      return this.store.environment.tableID
    },
  },

  methods: {
    setPopup() {
      this.$emit("setPopup", true)
    },

    goBack() {
      this.$router.push({ path: "/table/" + this.tableID })
    },
  },
}
</script>

<style scoped>
.default-width {
  justify-content: space-evenly;
}
</style>
