import { render, staticRenderFns } from "./ResetPass.vue?vue&type=template&id=20505be8&scoped=true&"
import script from "./ResetPass.vue?vue&type=script&lang=js&"
export * from "./ResetPass.vue?vue&type=script&lang=js&"
import style0 from "./ResetPass.vue?vue&type=style&index=0&id=20505be8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20505be8",
  null
  
)

export default component.exports