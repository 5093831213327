<template>
  <div class="background pop-up check">
    <div class="are-you-sure-div">
      <div class="text-block-8">
        <strong>You will </strong>
        <span class="text-span"><strong>delete</strong></span>
        <strong> {{ numberOfContacts }} contacts</strong>
      </div>
      <div class="text-block-8">are you sure?</div>
      <div class="div-block-16">
        <div @click="deleteUser" class="submit-button delete">Delete contact</div>
        <div @click="cancel" class="button delete cancel">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
import Friend from "@/general/Objects/Friend.js"

export default {
  data() {
    return {}
  },
  props: [],
  computed: {
    store: function () {
      return this.$store.state
    },
    numberOfContacts: function () {
      return this.store.environment.contacts.selected.length
    },
    state: {
      get() {
        return this.store.environment.contacts.state
      },
      set(value) {
        this.store.environment.contacts.state = value
      },
    },
    contacts: {
      get() {
        return this.store.friends.contacts
      },
      set(value) {
        this.store.friends.contacts = value
      },
    },
    selectedContacts: {
      get() {
        return this.store.environment.contacts.selected
      },
      set(value) {
        this.store.environment.contacts.selected = value
      },
    },
    clicked: {
      get() {
        return this.store.environment.contacts.clicked
      },
      set(value) {
        this.store.environment.contacts.clicked = value
      },
    },
  },

  methods: {
    async deleteUser() {
      // this.contacts = this.removeFromArray(this.contacts, this.selectedContacts);
      var friends = new Friend()
      await friends.deleteFriends(this.selectedContacts)
    },

    cancel() {
      this.state = null
    },
  },
}
</script>

<style scoped>
.check {
  margin-top: 0px;
  border: none;
}

.background.pop-up.check {
  width: 100%;
}
</style>
