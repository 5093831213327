<template>
  <div class="banner-slider w-slider">
    <slider ref="slider" :options="options" class="w-slider-mask">
      <slideritem v-for="index in chunkedArr()" :key="index" class="slide w-slide">
        <template v-if="!config">
          <BannerCollection :index="index" :input_widgets="input_widgets" />
        </template>
        <template v-else>
          <BannerCollectionConfig :index="index" :input_widgets="input_widgets" />
        </template>
      </slideritem>
    </slider>
  </div>
</template>
<script>
import { slider, slideritem } from "vue-concise-slider"

import BannerCollection from "@/general/Elements/2.0/NoConfig/CollectionBanner.vue"
import BannerCollectionConfig from "@/general/Elements/2.0/Config/CollectionBanner.vue"

export default {
  components: {
    slider,
    slideritem,

    BannerCollection,
    BannerCollectionConfig,
  },
  props: [],

  data: () => {
    return {
      options: {
        autoplay: false,
        loop: true,
        speed: 1000,
        pagination: false,
        slidesToScroll: 1,
        loopedSlides: 1,
        centeredSlides: false,
        preventDocumentMove: false,
        preventRebound: true,
      },
    }
  },

  methods: {
    numberOfSlides() {
      if (!this.config) {
        return this.input_widgets.length
      }
      return this.input_widgets.length + 1
    },

    chunkedArr() {
      const result = []
      for (let i = 0; i < this.numberOfSlides(); i += 1) result.push(i)
      return result
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    input_widgets: function () {
      return this.store.widgets.banner_collection
    },
    config: function () {
      var x = this.store.environment.config
      if (x) {
        this.options.autoplay = false
        if (this.input_widgets.length == 1) {
          this.options.loop = false
        }
        this.options.loop = true
      } else {
        this.options.loop = true
        this.options.autoplay = 5000
      }

      return x
    },
  },
}
</script>

<style scoped>
.banner-slider {
  margin: 5%;
  width: 90%;
}

.slider-container {
  max-width: 550px;
}

.icon-position {
  display: absolute;
}

@media screen and (min-width: 1280px) {
  .banner-slider {
    max-height: 125px;
  }
}

.slide {
  width: 100%;
}
</style>
