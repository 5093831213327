<template>
  <div class="footer">
    <div class="logo-header nd-footer">
      <div class="logo-div" style="color:white; font-weight:700">
        <!-- <img :src="brand['logo']" loading="lazy" alt="" class="logo-img small"> -->
        POWERED BY MOZO
        <!-- <div class="logo-text small">{{ brand["name"] }}</div> -->
      </div>
      <!-- <div class="slogan-text small">{{ brand["slogan"] }}</div> -->
    </div>
  </div>
</template>


<script>
import json from "@/general/GlobalValues.json"


export default {


  components: {

  },
  props: {},
  data: () => {
    return {
      brand: json["brand"],

    };
  },
  methods: {},
};
</script>

<style scoped>
@media screen and (max-width: 767px) {
  .slogan-text {
    display: block;
    margin-left: 10px;

  }
}

.footer {
  padding-right: 5%;
  padding-left: 5%;
  width: 100%;
}
</style>


