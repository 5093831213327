<template>
  <div class="header-column-tm padding">
    <div class="logo-header">
      <div class="logo-div">
        <img src="/images/mozo_logo_nopadding-01.svg" loading="lazy" class="mozo-logo" />
      </div>
    </div>

    <!-- used???? -->
    <div v-if="showLock" class="header-div">
      <h3 class="heading-31">{{ header_title }}</h3>
      <img @click="changeLock()" :src="lock" loading="lazy" class="lock-image" />
    </div>
  </div>

  <!-- used???? -->
</template>

<script>
export default {
  props: ["header_title", "showLock"],
  data: () => {
    return {
      lock: "/images/padlock.svg",
    }
  },
  methods: {
    menuClicked() {
      console.log("menu clicked")
    },
    changeLock() {
      if (this.lock == "/images/padlock.svg") {
        this.lock = "/images/unlock-padlock.svg"
        this.$emit("isLocked", false)
      } else {
        this.lock = "/images/padlock.svg"
        this.$emit("isLocked", true)
      }
    },
  },
}
</script>

<style scoped>
.lock-image {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.header-column-tm {
  margin-left: 0px;
}
</style>
