<template>
    <div class="background mozo pop-up">
        <div class="logo-header-2">
            <div class="logo-pop-up">
                <!-- <img src="images/logo-amigo-icoon-wit-hq.png" loading="lazy" alt="" class="amigo-logo" /> -->
                <div class="logo-text">{{ brand.name }}</div>
            </div>
        </div>
        <div class="page-body-2">
            <div class="pop-up-block">
                <img src="images/thanksflysparks.png" loading="lazy" class="image-9" />
                <div class="text-block pop-up">
                    <p class="text">There you go!</p>
                    <p class="paragraph">
                        We sent you an e-mail, <br /> with the link in the mail !
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import json from "@/general/GlobalValues.json";

export default {
    data: () => {
        return {
            brand: json['brand']
        }
    }
}
</script>