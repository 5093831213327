<template>
  <div>
    <template v-if="local.view == 'basic'">
      <LocalWidget :data="local.item" :color="client_data.color" @back="localBack" />
    </template>

    <template v-else-if="local.view == 'inapp'">
      <LocalWidget2 />
    </template>
    <template v-else>
      <HospitalityTemplate />
    </template>

    <template v-if="alertios">
      <Alert
        :link="'https://themozo.app/' + client_data.url"
        title="Sorry.."
        text="Apple blocks this function on Chrome 😒. You can use this function in your standard Apple browser: Safari"
        button="Copy link"
        @close="turnAlertOff()"
      />
    </template>
  </div>
</template>

<script>
// in folder
import HospitalityTemplate from "./Hospitality.vue"

// in elements 2.0
import LocalWidget from "@/general/Elements/2.0/OtherElements/Widgets/LocalWidget.vue"
import LocalWidget2 from "@/general/Elements/2.0/OtherElements/Widgets/LocalWidgetIframe.vue"

import { local_widgets } from "@/general/Elements/2.0/OtherElements/Widgets/widget_media.js"
import Alert from "@/general/Elements/2.0/OtherElements/Popups/Title_Text_CopyButton.vue"

export default {
  components: {
    LocalWidget,
    HospitalityTemplate,
    Alert,
    LocalWidget2,
  },
  props: ["client_data", "numberOfItems", "user"],
  data: () => {
    return {
      data: null,
      uid: null,
      view: "default",

      profileData: {
        borderColor: "",
        items: [...Array(0)].map(() => null),
        redirect: false,
      },
    }
  },

  async created() {
    if (this.user != null) {
      this.uid = this.user.uid
    }

    if (this.client_data != null && this.client_data.view != undefined) {
      this.view = this.client_data.view
    }
  },

  methods: {
    getLink(link, index) {
      if (index === this.numberOfItems - 1) {
        this.$emit("hidePopUp", true)
      }
      return link
    },

    getTarget(title) {
      if (title === "mail" || title === "phone") {
        return ""
      }
      return "_blank"
    },

    // setMenu() {
    //   this.$emit("setMenu", true);
    // },

    localBack(value) {
      this.isLocal = !value
    },
    setLocal(item) {
      this.data = item
      this.isLocal = true
    },
    isExternalWidget(item) {
      if (local_widgets.includes(item.name)) {
        return false
      }

      return true
    },
    setPopUp(index) {
      if (index === this.numberOfItems - 1) {
        this.$emit("hidePopUp", true)
      }
      return true
    },

    doNothing() {
      // method that runs in case something goes wrong, however doens't do anything
    },

    turnAlertOff() {
      this.alertios = false
    },
  },

  computed: {
    store: function () {
      return this.$store.state
    },
    menu: function () {
      return this.store.environment.menu
    },
    alertios: {
      get() {
        return this.store.environment.alertios
      },
      set(value) {
        this.store.environment.alertios = value
      },
    },

    local: function () {
      return this.store.environment.localWidget
    },
  },
}
</script>
