<template>
  <div class="tablechoice-div">
    <div @click="clickGroup()" class="extrapadding-custom">
      <h1 class="heading3 left">{{ group.groupName }}</h1>
    </div>
    <div class="html-embed-4 w-embed">
      <ActiveButton :input_active="group.active" @switched="switchButton" />
    </div>
  </div>
</template>

<script>
import ActiveButton from "@/general/Components/2.0/TableManagement/AllTables/ActiveButton.vue"
import GroupsRepository from "@/general/Objects/GroupsRepository"

export default {
  components: {
    ActiveButton,
  },
  props: ["group"],
  created() {},
  computed: {
    store: function () {
      return this.$store.state
    },

    created() {},
    group_id: {
      get() {
        return this.store.groups.groupCollection[this.group.position].id
      },
      set(value) {
        this.store.groups.groupCollection[this.group.position].id = value
      },
    },
  },

  methods: {
    async switchButton(response) {
      const groupRepo = new GroupsRepository([this.group_id])
      const isSet = await groupRepo.setActive(response)
    },
    clickGroup() {
      this.$emit("clickGroup", this.group.position)
    },
  },
}
</script>

<style scoped>
.div-block-160 {
  padding: 5%;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  cursor: pointer;
}
</style>
