<template>
  <div class="stretch center">
    <div class="img-div">
      <div class="small-img-2">
        <template v-if="config">
          <div
            class="setimage edit w-inline-block"
            :style="getStyle()"
            @click="$refs.inpFile.click()"
            @mouseover="img_hover = true"
            @mouseleave="img_hover = false"
          >
            <template v-if="img_hover || image == null">
              <a class="setimage w-inline-block">
                <div :style="'background-color:' + color_rgba()" class="div-block-23">
                  <img src="images/add-photo.png" loading="lazy" alt="" />
                </div>
              </a>
            </template>
          </div>
        </template>

        <template v-else>
          <div :style="getStyle()" class="setimage w-inline-block">
            <template v-if="img_hover">
              <div class="div-block-23">
                <img src="images/add-photo.png" loading="lazy" alt="" />
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
    <input
      ref="inpFile"
      @change="onFileChange"
      type="file"
      style="display: none"
      accept="image/*"
    />
  </div>
</template>

<script>
// helper
import methods from "@/general/HelperMethods/General.js"

export default {
  data: () => {
    return {
      img_hover: false,
    }
  },

  props: ["image", "color"],

  methods: {
    async onFileChange(e) {
      var image = e.target.files[0]
      if (!methods.validateSizeImage(image, 0.5)) {
        this.$emit(
          "error",
          "Sorry your file is too large! Add an image smaller than 500kB"
        )
      } else {
        this.$emit("returnImage", image)
        this.$emit("error", "")
      }
    },
    color_rgba() {
      return methods.hexToRgb(this.color, 0.4)
    },

    getStyle() {
      return this.image != null
        ? {
            "background-image": "url(" + this.image + ")",
            "background-position": "50% 50%",
            "background-size": "cover",
          }
        : ""
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },

    config: function () {
      return this.store.environment.config
    },
  },
}
</script>

<style>
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stretch {
  width: 100%;
  height: 100%;
}

.img-div {
  height: 100%;
  width: 100%;
}

.small-img-2 {
  width: 100%;
  height: 100%;
  border-radius: 0px;
  border-style: none;
  background-color: transparent;
  background-image: none;
}

.setimage {
  cursor: pointer;
}
</style>
