<template>
  <div class="page-body add-social">
    <div class="white-div large">
      <div class="allsocials">
        <div class="w-layout-grid gridsocials">
          <div v-for="(element, idx) in widgets.uids" :key="idx">
            <a
              @click="selectWidget(element, idx)"
              class="tile_newsocial _11vh"
              :class="{ buttonactive: element == selected_widget.item.name }"
            >
              <div class="tile auto">
                <Icon :widget="element" :colors_arr="color[2].icons" />
              </div>
              <div class="conf1-txt">{{ widgets.titles[idx] }}</div>
            </a>
          </div>
        </div>
      </div>

      <div v-if="selected_widget.item.name !== null" class="div-block-44">
        <a @click="next()" class="submit-button w-button">{{ button }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/general/Elements/2.0/Headers/PopUpHeader.vue"
import Icon from "@/general/Elements/2.0/OtherElements/Widgets/WidgetIcon.vue"

import methods from "@/general/HelperMethods/General.js"

export default {
  components: {
    Header,
    Icon,
  },
  props: ["heading", "button", "numberOfWidgets", "widgets", "itemType", "color"],
  created() {},

  data: () => {
    return {
      selected_widget: {
        item: {
          data: [
            {
              field: null,
              index: 0,
              name: "link",
            },
          ],
          name: null,
          title: null,
          image: null,
          uid: null,
        },
        type: null,
      },

      options: [],
    }
  },

  methods: {
    closeWindow() {
      this.$emit("state", 0)
    },

    async selectWidget(element, idx) {
      this.selected_widget.type = this.itemType
      this.selected_widget.item = {
        name: element,
        title: this.widgets.titles[idx],
        image: null,
        data: [],
      }

      this.options = this.widgets.options[idx]

      await this.options.forEach(async (x, index) => {
        this.selected_widget.item.data.push({
          name: x[0],
          field: null,
          index: index,
        })
      })

      // this.selected_widget.item.uid = "widget_" + this.numberOfWidgets + "_" + methods.randomGenerator(6);
    },

    next() {
      this.$emit("selected", {
        widget: this.selected_widget,
        options: this.options,
      })
    },
  },
}
</script>

<style scoped>
.allsocials {
  height: auto;
}

.white-div.large {
  height: auto;
}

.page-body.add-social {
  height: 50vh;
}
</style>
