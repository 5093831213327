<template>
  <div @click="isClicked" class="large-widget-div">
    <Icon :widget="widget_name" :colors_arr="widget_colors" />
    <h1 class="paragraph black-text">{{ widget_title }}</h1>
  </div>
</template>

<script>
import Icon from "@/general/Elements/2.0/OtherElements/Widgets/WidgetIcon2.vue"

export default {
  components: {
    Icon,
  },
  props: ["widget_name", "widget_title"],
  created() {},
  methods: {
    isClicked() {
      this.setWidget["name"] = this.widget_name
      this.$router.push({ name: "SetWidget" })
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    setWidget: {
      get() {
        return this.store.environment.setWidget.item
      },
      set(value) {
        this.store.environment.setWidget.item = value
      },
    },
    widget_colors: function () {
      return this.store.client_data.color[2].icons
    },
  },
}
</script>

<style scoped>
.large-widget-div {
  background-color: white;
  padding: 8% 5%;
}
</style>
