<template>
  <div class="tile_newsocial customize click">
    <div class="tile">
      <Icon :widget="item.name" :colors_arr="colors[2].icons" />
    </div>
    <div class="txt-div">
      <div class="white">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
// in folder
import Icon from "@/general/Elements/2.0/OtherElements/Widgets/WidgetIcon.vue"

export default {
  components: {
    Icon,
  },
  props: ["item"],
  created() {},

  computed: {
    store: function () {
      return this.$store.state
    },
    colors: function () {
      return this.store.client_data.color
    },
  },

  data: () => {
    return {}
  },

  methods: {},
}
</script>
