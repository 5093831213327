<template>
  <div @click="isClicked" class="text-nd" >
    <div
    class="maximum"
      :style="
        'padding:10px;border:1px solid ' +
        color +
        '; border-radius:28px;' +
        'background-color:' + bg_color
      "
    >
      <h1 class="heading-nd" style="margin:0px;" :style="'color:' + txt_color">{{ text }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  props: ["text", "color"],
  data: () => {
    return {
      bg_color:"",
      txt_color: ""
    };
  },
  created() {
    if (this.$store.state.client_data.loginNeeded) {
      this.bg_color = this.$store.state.client_data.color[2].icons[0]
    } else {
      this.txt_color = "#fff"
    }
  },
  methods: {
    isClicked() {
      this.$emit("isClicked", true)
    }

  },

};
</script>


<style scoped>
.text-nd {
  cursor:pointer;
  
}

.maximum {

  width:400px;
}

@media screen and (max-width: 768px) {
  .maximum {
    width:300px;
  }
}

</style>
