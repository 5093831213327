<template>
  <div class="header-card">
    <div class="_100 w-row" :style="getDivStyle(1)">
      <template v-if="widget.widget != 'banner'">
        <div class="left-col direction w-col w-col-6">
          <div class="tile small">
            <Icon :widget="widget.widget" :colors_arr="color[2].icons" />
          </div>
          <div class="icon-title marge">{{ initial_title }}</div>
        </div>
      </template>
      <div class="right-col w-col w-col-6" :style="getDivStyle(2)">
        <div class="socialname-div">
          <div>
            <div class="open-app-txt">Widget name:</div>
            <input
              ref="title"
              type="text"
              :maxlength="input_length.title"
              :placeholder="initial_title"
              :value="getTitle()"
              class="tile-title-txt w-input"
              @change="changeTitle()"
              @keyup="changeTitle()"
            />
          </div>

          <div class="edit-link-block w-inline-block">
            <img src="images/draw.png" loading="lazy" class="_15x15" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// general
import json from "@/general/GlobalValues.json"

// helper
import methods from "@/general/HelperMethods/General.js"

// elements 1.0
import Icon from "@/general/Elements/2.0/OtherElements/Widgets/WidgetIcon.vue"

export default {
  components: {
    Icon,
  },
  props: ["widget", "isNewWidget", "color"],

  async created() {
    this.initial_title = await methods.getWidgetTitle(this.widget.widget)
  },

  data: () => {
    return {
      input_length: json["input_length"],

      initial_title: null,
    }
  },

  methods: {
    changeTitle() {
      this.$emit("changeTitle", this.$refs.title.value)
    },

    length() {
      return this.$refs.title.value.length
    },

    getTitle() {
      if (this.isNewWidget) {
        return ""
      } else {
        return this.widget.title
      }
    },

    getDivStyle(value) {
      if (value === 1) {
        return "justify-content:center"
      } else if (value === 2) {
        return "justify-content:center"
      }
    },
  },
}
</script>
