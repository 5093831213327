<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { analytics, logEvent } from "@/db/Firebase/index"

export default {
  name: "app",
  computed: {
    store: function () {
      return this.$store.state
    },
  },
  created() {
    // this.store.environment.view = "load"
    logEvent(analytics, "initial_screen_view", {
      url: window.location.href,
    })
  },
}
</script>
