<template>
  <div>
    <template v-if="menu">
      <AccountMenu />
    </template>
    <template v-else>
      <div class="section-wcategories" @click="setBGClicked(true, -1)">
        <BackgroundLayer :color1="colors[0]" :color2="colors[1]" />
        <div class="front-layer">
          <Header :header_title="''" :showLock="false" />

          <div class="scroll-area">
            <div class="white-background2">
              <template v-if="state == 'create'">
                <CreateWidget @setPopup="setPopup(true)" />
              </template>
              <template v-else-if="state == 'update'">
                <EditWidget
                  :isNew="isNew"
                  :opacity_save="opacity_save"
                  :bgClicked="bgClicked"
                  @changeState="setState('create')"
                  @setPopup="setPopup(true)"
                  @compClicked="setBGClicked"
                />
              </template>
            </div>
          </div>
        </div>

        <template v-if="isPopup">
          <PopUp
            :state="widget.action"
            @hidePopup="setPopup(false)"
            @returnWidget="setWidget"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import AccountMenu from "@/views/Menu/LoggedIn.vue"

import BackgroundLayer from "@/general/Components/2.0/General/BackgroundLayer.vue"
import Header from "@/general/Components/2.0/TableManagement/General/Header2.vue"

import CreateWidget from "@/general/Components/2.0/TableManagement/EditWidget/CreateWidget.vue"
import EditWidget from "@/general/Components/2.0/TableManagement/EditWidget/EditWidget.vue"

import PopUp from "@/general/Components/2.0/TableManagement/EditWidget/PopUp.vue"

import WidgetCollection from "@/general/Objects/WidgetCollection"

export default {
  components: {
    Header,
    BackgroundLayer,
    CreateWidget,
    EditWidget,
    PopUp,
    AccountMenu,
  },

  data: () => {
    return {
      state: "create",
      isPopup: false,
      widget_title: null,
      customWidgetTitle: "",
      bgClicked: false,
      opacity_save: "opacitylow",
      isNew: false,
    }
  },
  async created() {
    this.state = this.widget.action
  },
  methods: {
    setPopup(value) {
      if (value && this.widget.action == "create") {
        this.state = "create"
      }
      this.isPopup = value
    },
    setWidget(widget) {
      this.widget = widget

      this.isNew = true
      this.state = "update"
    },

    setState(state) {
      this.state = state
    },

    async setBGClicked(value, index) {
      if (this.widget == null || this.widget.name == null) {
        return
      }

      const wCol = new WidgetCollection()
      this.accounts = await wCol.getAllAccounts(this.widget.name)
      if (index == this.accounts.length - 1) {
        this.bgClicked = value
      } else if (index == -1) {
        this.bgClicked = value
      }

      this.selectedAccount = this.accounts[index]

      if (this.selectedAccount != null) {
        this.opacity_preview = ""
        this.opacity_save = ""
      } else {
        this.opacity_preview = "opacitylow"
        this.opacity_save = "opacitylow"
      }
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    menu: function () {
      return this.store.environment.menu
    },
    colors: function () {
      return this.store.client_data.color
    },

    widget: {
      get() {
        return this.store.environment.editTableWidget
      },
      set(value) {
        this.store.environment.editTableWidget = value
      },
    },
  },
}
</script>
