<template>
  <div @click="isClicked" class="small-widget-div">
    <Icon
      :widget="widget_name"
      :colors_arr="widget_colors"
      :classInput="'small-widget-icon'"
    />
    <h1 class="paragraph black">{{ widget_title }}</h1>
  </div>
</template>

<script>
import Icon from "@/general/Elements/2.0/OtherElements/Widgets/WidgetIcon2.vue"

export default {
  components: {
    Icon,
  },
  props: ["widget_name", "widget_title"],
  computed: {
    store: function () {
      return this.$store.state
    },
    setWidget: {
      get() {
        return this.store.environment.setWidget.item
      },
      set(value) {
        this.store.environment.setWidget.item = value
      },
    },
    widget_colors: function () {
      return this.store.client_data.color[2].icons
    },
  },
  methods: {
    isClicked() {
      this.setWidget["name"] = this.widget_name
      this.$router.push({ name: "SetWidget" })
    },
  },
}
</script>

<style scoped>
.small-widget-icon {
  margin-bottom: 0px;
}
</style>
