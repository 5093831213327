<template>
  <div class="div-block-88">
    <div class="text-block-light title">{{ text }}</div>
    <div class="w-form">
      <label for="name" class="text-block-light">{{ label }}</label>
      <input @keyup="change" ref="field" type="text" class="text-field-2 w-input" :maxlength="max_field_length"
        :placeholder="placeholder" />
      <p v-if="error != ''" class="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import json from "@/general/GlobalValues.json";



export default {
  props: [
    'text',
    'label',
    'placeholder',
    'isError'
  ],
  data: () => {
    return {
      input_length: json["input_length"],
    }

  },
  components: {

  },

  computed: {
    label_lowercase: function () {
      return this.label.toLowerCase();
    },
    max_field_length: function () {
      return this.input_length[this.label_lowercase];
    },

    store: function () {
      return this.$store.state;
    },
    addUser: {
      get() {
        return this.store.environment.addUser;
      },
      set(value) {
        this.store.environment.addUser = value;
      },

    },

    error: function () {
      return this.isError ? "Sorry you forgot to fill in your " + this.label : ""
    }
  },

  methods: {
    change() {
      this.addUser.properties[this.label_lowercase] = this.$refs.field.value;
    },
  },
};
</script>

<style scoped>
.error {
  color: red;
  font-weight: 500;
  padding: 6px 20px 6px 20px;
  background-color: white;
  border-radius: 6px;
  margin-top: 5%;

}

.text-field-2 {
  border-radius: 12px;
  min-height: 50px
}
</style>
