export default {
    image: "/images/imagetest.svg",
    nickname: "Healthy Restaurant",
    firstname: "Andrea",
    lastname: "Li",
    bio1: "Yummy - Vegan - Comfort Food",
    bio2: "Hola! Payment via card only!",
    color: [
        "#11d1ad",
        "#116c5b",
        {
            icons: ["#116c5b", "#116c5b", "#ffffff"],
        },
    ],
    premium: true,
    view: "hospitality",
    url: "test",
    loginNeeded: true
}

