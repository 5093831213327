<template>
  <div v-if="borderColor" class="w-layout-grid nd-apps">
    <div class="full-width" v-for="j in getNumberOfWidgets()" :key="j">
      <template v-if="isExternalWidget(getStartValue() + j)">
        <div
          class="weblinks customiconstyle"
          :style="
            'background-color:' +
            store.client_data.color[0] +
            ';border-color:' +
            borderColor
          "
        >
          <Widgets
            :item="input_widgets[getStartValue() + j]"
            :index="getStartValue() + j"
            :text="true"
            type="tile_collection"
          />
        </div>
      </template>
      <template v-else>
        <div @click="setLocal(getStartValue() + j)">
          <LocalWidget
            :item="input_widgets[getStartValue() + j]"
            :index="getStartValue() + j"
            :text="true"
            type="tile_collection"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// external package
import draggable from "vuedraggable"

// in elements 2.0
import Widgets from "@/general/Elements/2.0/OtherElements/Widgets/Widgets.vue"
import LocalWidget from "@/general/Elements/2.0/OtherElements/Widgets/LocalWidget2.vue"

import { local_widgets } from "@/general/Elements/2.0/OtherElements/Widgets/widget_media.js"

import LocalTile from "@/general/Elements/2.0/OtherElements/Widgets/LocalLink.vue"

import methods from "@/general/HelperMethods/General"

export default {
  components: {
    draggable,
    Widgets,
    LocalTile,
    LocalWidget,
  },
  props: ["input_widgets", "index", "config", "widgetsPerPage"],
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      }
    },
    store: function () {
      return this.$store.state
    },

    localWidget: {
      get() {
        return this.store.environment.localWidget
      },
      set(widget) {
        this.store.environment.localWidget = widget
      },
    },
  },

  created() {
    this.borderColor = methods.hexToRgb(this.store.client_data.color[2].icons[0], 0.3)
  },
  data: () => {
    return {
      drag: false,
    }
  },

  methods: {
    getNumberOfWidgets() {
      var temp = this.input_widgets.length - this.index * this.widgetsPerPage
      if (temp < this.widgetsPerPage) {
        return temp
      } else {
        return this.widgetsPerPage
      }
    },

    item(index) {
      return this.input_widgets[index]
    },

    setLocal(index) {
      this.localWidget = this.item(index)
    },

    isExternalWidget(index) {
      if (local_widgets.includes(this.item(index).widget)) {
        return false
      }
      return true
    },

    getStartValue() {
      return this.index * this.widgetsPerPage - 1
    },
  },
}
</script>

<style scoped>
.full-width {
  width: 100%;
  max-width: 550px;
}

.weblinks.customiconstyle {
  border-color: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  background-color: white;
  padding-left:5%;
}
</style>
