<template>
  <div class="background-layer">
    <div class="background mozo short" :style="setBackground(color1, color2)"></div>
  </div>
</template>

<script>
import methods from "@/general/HelperMethods/General.js"

export default {
  components: {},
  props: ["color1", "color2"],
  methods: {
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2)
    },
  },
}
</script>
