export default {
    contacts: [
        // {
        //   firstname: "Gijs",
        //   lastname: "Daeli",
        //   email: "gijs.d@trymozo.com",
        //   created_date: "21/06/2022",
        //   uid: "CMbeSufbVuZKZeJSjYBovxyR7Kn1",
        // },
        // {
        //   firstname: "Henk",
        //   lastname: "Daeli",
        //   email: "gijs.d2@trymozo.com",
        //   created_date: "21/06/2022",
        //   uid: "CMbeSufbVuZKZeJSjYBovxyR7Kn2",
        // },
        // {
        //   firstname: "Frits",
        //   lastname: "Daeli",
        //   email: "gijs.d3@trymozo.com",
        //   created_date: "21/06/2022",
        //   uid: "CMbeSufbVuZKZeJSjYBovxyR7Kn3",
        // },
        // {
        //   firstname: "Anna",
        //   lastname: "Daeli",
        //   email: "gijs.d4@trymozo.com",
        //   created_date: "01/07/2022",
        //   uid: "CMbeSufbVuZKZeJSjYBovxyR7Kn4",
        // },
        // {
        //   firstname: "Nienke",
        //   lastname: "Daeli",
        //   email: "gijs.d5@trymozo.com",
        //   created_date: "21/05/2022",
        //   uid: "CMbeSufbVuZKZeJSjYBovxyR7Kn5",
        // },
    ],
};