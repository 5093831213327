<template>
  <div class="fullwidth">
    <div class="div-block-90">
      <div class="div-block-buttons">
        <InputField text="" label="Firstname" :placeholder="displayName" :isError="isError('firstname')" />
        <InputField text="" label="Lastname" placeholder="" :isError="isError('lastname')" />
      </div>
      <Button @clicked="setClick" />
    </div>
  </div>


</template>

<script>
import MenuHeader from "@/general/Elements/2.0/Headers/Header-FriendsOf.vue";
import methods from "@/general/HelperMethods/General.js";

import Header from "@/general/Elements/2.0/FriendsOf/Header.vue";
import Buttons from "@/general/Elements/2.0/FriendsOf/Buttons.vue";
import InputField from "@/general/Elements/2.0/FriendsOf/InputField.vue";

import Button from "@/general/Elements/2.0/OtherElements/Button.vue";

import db_friends from "@/db/2.0/user/friends.js"

import db_auth from "@/db/2.0/auth.js";

export default {
  components: {
    MenuHeader,
    Header,
    Buttons,
    InputField,
    Button,
  },

  async created() {
    this.user = await db_auth.userState();
  },

  computed: {
    store: function () {
      return this.$store.state;
    },
    addUser: {
      get() {
        return this.store.environment.addUser;
      },
      set(value) {
        this.store.environment.addUser = value;
      },
    },
    contacts: {
      get() {
        return this.store.friends.contacts;
      },
      set(value) {
        this.store.friends.contacts = value
      }
    },

    view: {
      get() {
        return this.store.environment.addUser.view;
      },
      set(value) {
        this.store.environment.addUser.view = value;
      }
    },
    profile_url: function () {
      return this.store.environment.addUser.profile_url;
    },

    displayName: function () {
      return this.user ? this.user.displayName : "";
    },
    user: {
      get() {
        return this.store.user;
      },
      set(value) {
        this.store.user = value;
      }
    },
  },

  data: () => {
    return {
      click: false
    }
  },

  methods: {

    isReady() {
      if (this.isError('firstname')) {
        return false;
      } else if (this.isError('lastname')) {
        return false;
      }
      return true;
    },

    async setClick(nextPage) {
      this.click = true;
      if (await this.isReady() && nextPage) {
        if (!this.addUser.user) {
          this.view = 'login'
          return;
        }

        var contact = {
          firstname: this.addUser.properties['firstname'],
          lastname: this.addUser.properties['lastname'],
          email: this.user.email,
          created_date: methods.getDateToday(),
          uid: this.user.uid
        }

        this.contacts.push(contact)
        this.addUser.popup = true;

        await db_friends.setData(this.addUser.user.uid, contact, this.addUser.profile_url);

        this.$router.push(this.profile_url);
      }
    },

    isError(property) {
      if (!this.click) {
        return false;
      }
      if ((this.addUser.properties[property] == "") || (this.addUser.properties[property] == null)) {
        return true;
      }

      if (this.addUser.properties[property].search(/^\s*$/) == 0) { //when all white spaces
        return true;
      }
      return false;
    }
  },
};
</script>

<style scoped>
.div-block-90 {
  height: 100%;
  justify-content: center;
  min-height: 90vh;
}

.fullwidth {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
