var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background menu height",style:('background:' +
    'linear-gradient(311deg, ' +
    _vm.color[0] +
    ',' +
    _vm.color[1] +
    ');')},[_c('div',{staticClass:"content"},[_c('div',[_c('div',{staticClass:"header-column w-row"},[_c('div',{staticClass:"left-col menu w-col w-col-9"},[_c('LeftHeader')],1),_c('div',{staticClass:"right-col close-add-tile w-col w-col-3"},[_c('div',{staticClass:"settings-div"},[_c('a',{staticClass:"link-block w-inline-block",on:{"click":function($event){return _vm.handleClose()}}},[_c('img',{staticClass:"settings-image",attrs:{"src":"images/close-white.png","loading":"lazy","alt":""}})])])])]),_c('div',{staticClass:"menu-upper"},[(!_vm.hideLogo)?[_vm._m(0)]:_vm._e(),(_vm.hideLogo)?[_vm._m(1),_vm._m(2)]:_vm._e()],2)]),_c('div',{staticClass:"menu-bottom"},[(!_vm.store.client_data.loginNeeded)?_c('a',{staticClass:"menu-items _2",attrs:{"href":"/login"}},[_c('img',{staticClass:"menu-icon small",attrs:{"src":"images/icon-log-out.svg","loading":"lazy","alt":""}}),_c('h1',{staticClass:"heading6 white left"},[_vm._v("Login")])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-items w-inline-block",attrs:{"href":"https://trymozo.com","target":"_blank"}},[_c('img',{staticClass:"menu-icon",attrs:{"src":"/images/information.svg","loading":"lazy","alt":""}}),_c('h1',{staticClass:"heading-3 white left"},[_vm._v("Information")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-items",attrs:{"href":"https://adamand.co/over-ons/","target":"_blank"}},[_c('img',{staticClass:"menu-icon",attrs:{"src":"/images/information.svg","loading":"lazy"}}),_c('h1',{staticClass:"heading-3 white left"},[_vm._v("About us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-items",attrs:{"href":"mailto:info@adamandco.nl","target":"_blank"}},[_c('img',{staticClass:"menu-icon",attrs:{"src":"/images/mail.svg","loading":"lazy"}}),_c('h1',{staticClass:"heading-3 white left"},[_vm._v("Contact")])])
}]

export { render, staticRenderFns }