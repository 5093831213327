<template>
  <div class="button-div wm">
    <div @click="isClicked('preview')" class="button-image margin-bottom">
      <img
        v-if="showpreview"
        src="/images/link.png"
        loading="lazy"
        alt=""
        class="button-icon"
      />
      <h1 class="heading-34 white">{{ text1 }}</h1>
    </div>
    <div v-if="text2" @click="isClicked('Add Widget')" class="button-image margin-bottom">
      <h1 class="heading-34 white">{{ text2 }}</h1>
    </div>
    <h1
      v-if="Delete"
      style="text-align: center"
      @click="isClicked('Delete Widget')"
      class="heading-34 underline"
    >
      Delete Widget
    </h1>
  </div>
</template>

<script>
export default {
  props: ["Delete", "text1", "text2", "showpreview"],
  methods: {
    isClicked(input) {
      if (input == "preview") {
        console.log("preview")
      } else if (input == "Add Widget") {
        console.log("Add Widget")
      } else {
        console.log("Delete Widget")
      }
    },
  },
}
</script>

<style>
.div-block-183 {
  cursor: pointer;
}

.heading-34.underline {
  cursor: pointer;
}
</style>
