import store from "@/vuex/setup";

import db_user from "@/db/2.0/user/main.js";
import db_url from "@/db/2.0/url.js";
import db_branch from "@/db/2.0/TableManagement/main.js";
import db_accounts from "@/db/2.0/accounts";

import ArrayRepository from "@/general/Objects/ArrayRepository";



export default class DataLayer {
  constructor() {
    this.data = {
      uid: null,
      url: null,
      branch: null,
      group: null,
      table: null,
      client_name: null,
    };
  }

  setUID(uid) {
    this.data.uid = uid;
  }

  async setUrlData(input_url) {
    // get all data from urls db
    // set all data in this.data
    this.data = await db_url.getPublic(input_url).catch(() => {
      return this.data;
    });

    store.state.environment.data = this.data;
    return this.data;
  }

  getUrlData() {
    return this.data == null ? null : this.data.url;
  }

  async setPublicProfile(uid) {

    store.state.saved_widgets = await db_accounts.getAllData(uid);

    // default profile
    store.state.client_data = await db_user.vData.getData(uid);
    store.state.widgets.tile_collection = await this.initialize(uid, "tile_collection");
    store.state.widgets.small_tile_collection = await this.initialize(uid, "small_tile_collection");
    store.state.widgets.image_collection = await this.initialize(uid, "image_collection");
    store.state.widgets.banner_collection = await this.initialize(uid, "banner_collection");

    this.data.client_name = store.state.client_data.nickname;
  }

  // async setAllTablesAndGroups(uid) { }

  async setWidgetAccounts(data) {
    // table data


  }

  async setTableAndGroup(data) {
    // table data
    const publicTable = await db_branch.getPublicTable(
      data.uid,
      data.branch,
      data.table
    );



    if (publicTable == undefined) {
      return;
    }

    var publicGroup = null;
    try {

      publicGroup = await db_branch.getPublicGroup(
        data.uid,
        data.branch,
        publicTable.group[0]
      );

      this.data.group = publicTable.group;
    } catch (err) {

      // do nothing
    }

    if (publicGroup != null) {
      if (publicTable.active && publicGroup.active) {
        store.state.client_data.bio2 = publicTable.bio2;
        this.replaceTableValues(store.state.widgets.tile_collection, publicTable.widgets);
        this.replaceTableValues(store.state.widgets.small_tile_collection, publicTable.widgets);
        this.replaceTableValues(store.state.widgets.image_collection, publicTable.widgets);
        this.replaceTableValues(store.state.widgets.banner_collection, publicTable.widgets);
      }

    } else if (publicTable.active) {
      store.state.client_data.bio2 = publicTable.bio2;
      this.replaceTableValues(store.state.widgets.tile_collection, publicTable.widgets);
      this.replaceTableValues(store.state.widgets.small_tile_collection, publicTable.widgets);
      this.replaceTableValues(store.state.widgets.image_collection, publicTable.widgets);
      this.replaceTableValues(store.state.widgets.banner_collection, publicTable.widgets);
    }



    store.state.environment.tableID;

  }

  async replaceTableValues(defaultWidgets, tableWidgets) {
    for (var i = 0; i < defaultWidgets.length; i++) {
      for (var j = 0; j < tableWidgets.length; j++) {
        if (defaultWidgets[i].uid == tableWidgets[j].uid) {
          // dont replace the position/index
          defaultWidgets[i].account_uid = tableWidgets[j].account_uid;
          defaultWidgets[i].image = tableWidgets[j].image;
          defaultWidgets[i].title = tableWidgets[j].title;
          defaultWidgets[i].date_added = tableWidgets[j].date_added;
        }
      }
    }
  }

  async hasPage(uid) {
    var data = await db_user.vData.getData(uid);
    if (data) {
      return true;
    } else {
      return false;
    }
  }

  async initialize(uid, name) {
    var widgetData = await db_user.vWidgets.getWidgets(uid, name);
    if (widgetData.data == 0) {
      widgetData.data = [];
    }

    var sItems = widgetData.data;

    var items = [...Array(sItems.length)].map(() => null);
    if (sItems[0] === null) {
      return items;
    }

    items = await [...Array(sItems.length)].map((el, i) => {
      let item = sItems.find((item) => item.position === i);
      if (item) {
        return item;
      }
      return null;
    });

    return items;
  }

  getUrl() {
    this.data.url = store.state.client_data.url;
    return store.state.client_data.url;
  }

  async getUrlDB(uid) {
    var url = await db_url.getAllUrls(uid).then(async (querySnapshot) => {
      var url = null;
      await querySnapshot.forEach((doc) => {
        url = doc.id;
      });
      return url;
    });
    this.data = {
      url: url,
      uid: uid,
    };

    store.state.environment.data = this.data;
    return url;
  }
}
