import json from "@/general/GlobalValues.json";
import { db } from "@/db/Firebase";

export default {
  async setData(uid, data, url) {
    var exists = await db
      .collection(json["collections"].friends)
      .doc(uid)
      .get()
      .then((query) => query.exists);

    if (!exists) {
      await db
        .collection(json["collections"].friends)
        .doc(uid)
        .set({ url: url })
        .catch((err) => {
          throw err.message;
        });
    } else {
      await db
        .collection(json["collections"].friends)
        .doc(uid)
        .collection(json["collections"].all)
        .doc(data.uid)
        .set(data)
        .catch((err) => {
          throw err.message;
        });
    }


  },

  async updateData(uid, data) {
    await db
      .collection(json["collections"].friends)
      .doc(uid)
      .collection(json["collections"].all)
      .doc(data.uid)
      .update(data)
      .catch((err) => {
        throw err.message;
      });
  },

  async getData(uid, id) {
    return await db
      .collection(json["collections"].friends)
      .doc(uid)
      .collection(json["collections"].all)
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          var data = doc.data();
          return data;
        }
      })
      .catch((err) => {
        throw err.message;
      });
  },

  async getAllData(uid) {
    return await db
      .collection(json["collections"].friends)
      .doc(uid)
      .collection(json["collections"].all)
      .get()
      .then((snapshot) => {
        return snapshot.docs.map((doc) => doc.data());
      })
      .catch((err) => {
        throw err.message;
      });
  },

  async deleteData(uid, id) {
    await db
      .collection(json["collections"].friends)
      .doc(uid)
      .collection(json["collections"].all)
      .doc(id)
      .delete()
      .catch((err) => {
        throw err.message;
      });
  },

  async deleteAllData(uid) {
    await db
      .collection(json["collections"].friends)
      .doc(uid)
      .collection(json["collections"].all)
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.forEach((snapshot) => {
          snapshot.ref.delete();
        });
      })
      .catch((err) => {
        throw err.message;
      });
  },

  async docExists(uid, id) {
    return await db
      .collection(json["collections"].friends)
      .doc(uid)
      .collection(json["collections"].all)
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          return true;
        } else {
          return false;
        }
      })
      .catch((err) => {
        throw err.message;
      });
  },
};
