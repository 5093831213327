<template>
    <div class="background mozo">
        <Popup title="Sorry!" text="This page doesn't exist! You can press the button to login to another account!"
            :showCloseButton="false" button="Login" @next="setNext" />
    </div>
</template>

<script>

import Popup from "@/general/Components/2.0/FriendsOf/PopUp.vue"
import auth from "@/db/2.0/auth.js"

export default {
    components: {
        Popup
    },
    props: [],
    data: () => {
        return {};
    },

    async created() { },

    methods: {
        async setNext(goNext) {
            if (goNext) {
                this.user = await auth.logout();
                this.$router.push('/login');
            }
        }
    },
};
</script>
