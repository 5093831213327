<template>
  <div>
    <div class="background mozo">
      <Header />
      <div class="page-body">
        <div class="title-block">
          <div class="columns-3 w-row">
            <div class="column-3 w-col w-col-6">
              <div class="text-div">
                <h1 class="heading1 left">{{ page_name }}</h1>
              </div>
            </div>
            <div class="column-2 w-col w-col-6"></div>
          </div>
        </div>
        <div class="transp-body data-fillin">
          <div class="header-logout">
            <div class="columns-2 w-row">
              <div class="w-col w-col-6">
                <div class="text-div _1">
                  <a @click="goBack" class="button small w-inline-block">
                    <img
                      src="images/go-back.png"
                      class="image-18"
                      loading="lazy"
                      sizes="(max-width: 991px) 20px, (max-width: 1279px) 2vw, (max-width: 1439px) 20px, 1vw"
                      srcset="images/go-back-p-500.png 500w, images/go-back.png 512w"
                    />
                  </a>
                </div>
              </div>
              <div class="column w-col w-col-6">
                <div class="button-block logout"></div>
              </div>
            </div>
          </div>
          <div class="register-step-div">
            <img src="images/steps2.png" loading="lazy" width="101" alt="" />
          </div>

          <div class="register-pp-div" :style="{ 'border-color': border.picture }">
            <div class="upload-pp-field">
              <div
                class="setimage"
                :style="{
                  'background-image': 'url(' + user_info.image + ')',
                  'background-position': '50% 50%',
                  'background-size': 'cover',
                }"
                @click="$refs.inpFile.click()"
                @mouseover="img_hover = true"
                @mouseleave="img_hover = false"
              >
                <img
                  v-if="user_info.image === '' || img_hover"
                  class="image-29"
                  src="images/add-photo.png"
                  loading="lazy"
                />
              </div>
            </div>
            <div class="text-div center-text adjust-marge">
              <div class="paragraph marge-up">{{ add_picture }}</div>
            </div>
          </div>
          <div class="form-div">
            <div class="form-block no-margin w-form">
              <div>
                <div class="last-div-form">
                  <div class="columns-13 w-row">
                    <div class="column-25 w-col w-col-6">
                      <div class="paragraph left">{{ field_names.firstname }}</div>
                      <input
                        :style="{ 'border-color': border.firstname }"
                        @change="setFName()"
                        :value="user_info.firstname"
                        class="text-field w-input"
                        ref="firstname"
                        type="text"
                        :maxlength="input_length.firstname"
                        :placeholder="field_names.firstname"
                        required="true"
                        autocorrect="off"
                        autocapitalize="off"
                      />
                    </div>
                    <div class="column-26 w-col w-col-6">
                      <div class="paragraph left">{{ field_names.lastname }}</div>
                      <input
                        :style="{ 'border-color': border.lastname }"
                        @change="setLName()"
                        :value="user_info.lastname"
                        class="text-field w-input"
                        ref="lastname"
                        type="text"
                        :maxlength="input_length.lastname"
                        :placeholder="field_names.lastname"
                        required="true"
                        autocorrect="off"
                        autocapitalize="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <a @click="next()" class="submit-button w-button">{{ btn }}</a>
            </div>
            <template v-if="error !== ''">
              <div>
                <div class="error-message">{{ error }}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <input
      ref="inpFile"
      @change="onFileChange"
      type="file"
      style="display: none"
      accept="image/*"
    />
  </div>
</template>
<script>
// general
import json from "@/general/GlobalValues.json"
// in elements 1.0
import Header from "@/general/Elements/2.0/Headers/Header1.vue"

// db
import db_auth from "@/db/2.0/auth.js"
import db_url from "@/db/2.0/url.js"
import db_storage from "@/db/2.0/storage.js"
import db_user from "@/db/2.0/user/main.js"

// helper
import validate from "@/general/HelperMethods/Validate.js"

export default {
  components: {
    Header,
    // ProfilePicture,
  },
  props: ["product_url", "custom_url", "userInfo", "input_image"],
  data() {
    return {
      page_name: "Register",
      add_picture: "Add profile picture",
      btn: "Register",
      img_hover: false,
      error: "",

      field_names: json["field_names"],
      input_length: json["input_length"],
      placeholders: json["placeholdrs"],

      image: "",
      user_info: {
        image: "",

        email: "",
        password: "",
        url: "",

        firstname: "",
        lastname: "",
        nickname: "",

        score: 0,
        bio: "Welcome to mozo!",

        offline: false,
      },

      border: {
        picture: "",
        firstname: "",
        lastname: "",
        day: "",
        month: "",
        year: "",
      },

      client_data: {
        image: "../images/mozo-logo_1.png",
        //   nickname: "Healthy Restaurant",
        //   firstname: "Andrea",
        //   lastname: "Li",
        bio1: "Yummy - Vegan - Comfort Food",
        bio2: "Yummy - Vegan - Comfort Food",
        color: [
          "#11d1ad",
          "#116c5b",
          {
            icons: ["#116c5b", "#116c5b", "#ffffff"],
          },
        ],
        premium: true,
        view: "hospitality",
        //   url: "test",
      },
    }
  },

  computed: {
    store: function () {
      return this.$store.state
    },
  },

  created() {
    this.user_info.email = this.userInfo.email
    this.user_info.password1 = this.userInfo.password1
    this.user_info.url = this.userInfo.url
    this.image = this.input_image

    this.setOtherFields()
  },

  methods: {
    onFileChange(e) {
      this.image = e.target.files[0]
      this.user_info.image = URL.createObjectURL(this.image)
    },

    goBack() {
      this.$emit("back", [this.user_info, this.image])
    },

    setOtherFields() {
      if (this.userInfo.image !== undefined) {
        this.user_info.image = this.userInfo.image
      }
      if (this.userInfo.firstname !== undefined) {
        this.user_info.firstname = this.userInfo.firstname
      }
      if (this.userInfo.lastname !== undefined) {
        this.user_info.lastname = this.userInfo.lastname
      }
    },

    setProfile() {
      this.user_info.image = this.$refs.image.value
      if (this.user_info.image === "") {
        this.border.picture = "#ff0000"
        return false
      }
      return true
    },

    setFName() {
      this.user_info.firstname = this.$refs.firstname.value

      if (this.user_info.firstname === "") {
        this.border.firstname = "#ff0000"
        return false
      }
      return true
    },

    setLName() {
      this.user_info.lastname = this.$refs.lastname.value
      if (this.user_info.lastname === "") {
        this.border.lastname = "#ff0000"
        return false
      }
      return true
    },

    async next() {
      if (await this.checkVariables()) {
        await this.registerUser()
      }
    },

    async registerUser() {
      await db_auth.logoutNoRedirect()

      var user = await db_auth
        .createUser(this.user_info.email, this.user_info.password1)
        .catch((err) => {
          this.error = err
          return null
        })

      this.user_info.image = await db_storage
        .addProfilePicture(user.uid, this.image, "profile_picture", "header")
        .catch(async (err) => {
          this.error = err
          return null
        })

      this.user_info.nickname = this.user_info.firstname
      var data = Object.assign({}, this.user_info)

      this.client_data.firstname = data.firstname
      this.client_data.lastname = data.lastname
      this.client_data.image = data.image
      this.client_data.nickname = data.nickname
      this.client_data.url = data.url

      await db_user.registerUser(this.client_data, user.uid).catch((err) => {
        this.error = err
        return null
      })

      await this.addUserUrl(user.uid)
        .then(() => {
          this.$router.push("/")
        })
        .catch((err) => {
          this.error = err
          return null
        })
    },

    async addUserUrl(uid) {
      if (this.product_url === null && this.custom_url.value !== null) {
        //just link
        await db_url
          .setUrl(
            this.custom_url.value,
            uid,
            this.custom_url.type,
            this.custom_url.code,
            "default"
          )
          .catch((err) => {
            this.error = err
            return null
          })
      } else if (this.product_url.value !== null && this.custom_url.value === null) {
        //just product, no custom link
        await db_url
          .setUrl(
            this.product_url.value,
            uid,
            this.product_url.type,
            this.product_url.code,
            "default"
          )
          .catch((err) => {
            this.error = err
            return null
          })
      } else if (this.product_url.value !== null && this.custom_url.value !== null) {
        //product + custom link

        await db_url
          .setUrl(
            this.product_url.value,
            uid,
            this.product_url.type,
            this.product_url.code,
            "default"
          )
          .catch((err) => {
            this.error = err
          })

        await db_url
          .setUrl(
            this.custom_url.value,
            uid,
            this.custom_url.type,
            this.custom_url.code,
            "default"
          )
          .catch(async (err) => {
            await db_url.resetUrl()
            this.error = err
          })
      } else {
        this.error = "Something went wrong!"
        return
      }
    },

    checkVariables() {
      if (!this.setFName()) {
        return false
      }
      if (!this.setLName()) {
        return false
      }

      return true
    },
  },
}
</script>

<style scoped>
.button {
  width: auto;
}
.button.small {
  width: auto;
  min-width: 40px;
}

.form-div {
  max-width: 800px;
}
</style>
