<template>
  <div>
    <draggable
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
      group="group3"
      handle=".handle"
      :list="input_widgets"
    >
      <transition-group
        class="w-layout-grid nd-grid-shortapps"
        type="transition"
        :name="!drag ? 'flip-list1' : null"
        :style="getGridStyle(input_widgets.length + 1)"
      >
        <div v-for="index in input_widgets.length + 1" :key="index">
          <template v-if="!checkIndex(index)">
            <Widgets
              :item="input_widgets[index - 1]"
              :index="index - 1"
              :text="false"
              type="small_tile_collection"
            />
          </template>
          <template v-else-if="input_widgets.length < 4">
            <AddWidget type="small_tile_collection" />
          </template>
        </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
// external package
import draggable from "vuedraggable"

// in elements 2.0
import Widgets from "@/general/Elements/2.0/OtherElements/Widgets/Widgets.vue"
import AddWidget from "@/general/Elements/2.0/OtherElements/Widgets/AddWidget.vue"

export default {
  components: {
    Widgets,
    draggable,
    AddWidget,
  },
  props: ["input_widgets", "config"],
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      }
    },
  },

  created() {
    // this.list = methods.deepcopy(this.input_widgets);
  },
  data: () => {
    return {
      drag: false,
    }
  },
  methods: {
    checkIndex(index) {
      if (index > this.input_widgets.length) {
        return true
      } else {
        return false
      }
    },

    getGridStyle(numberOfItems) {
      var frs = "grid-template-columns: "
      for (var i = 0; i < numberOfItems; i++) {
        frs = frs + "1fr "
      }
      return frs
    },
  },
}
</script>

<style scoped>
.horizontal-list {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.full-width {
  width: 100%;
}
</style>
