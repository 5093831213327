const category = [
    { id: 0, name: "social", image: "social", type: ['tile_collection', 'small_tile_collection', 'image_collection', ] },
    { id: 1, name: "reviews", image: "reviews", type: ['small_tile_collection', 'tile_collection', 'image_collection'] },
    { id: 2, name: "games", image: "games", type: ['small_tile_collection', 'tile_collection', 'image_collection'] },
    { id: 3, name: "menu", image: "menu", type: ['tile_collection', 'image_collection'] },
    { id: 4, name: "order", image: "order", type: ['tile_collection', 'image_collection'] },
    { id: 5, name: "pay", image: "pay", type: ['tile_collection', 'image_collection'] },
    { id: 6, name: "loyalty", image: "loyalty", type: ['small_tile_collection', 'tile_collection', 'image_collection'] },
    { id: 7, name: "info", image: "info", type: ['small_tile_collection', 'tile_collection', 'image_collection'] },
    { id: 8, name: "banner", image: "info", type: ['banner_collection'] },
]

export default category
