<template>
  <div class="">
    <h2 class="label">{{ label }}</h2>
    <div>
      <!-- <div class="subheading black-border"> -->
      <input
        @keyup="changeInput()"
        ref="inputField"
        :value="input"
        class="inputfield margin-bottom"
        :placeholder="'Left Corner Outside'"
      />
      <!-- </div> -->
      <p style="color: red" v-if="error.show">{{ error.message }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["input", "label", "error"],
  methods: {
    changeInput() {
      this.$emit("setInput", this.$refs.inputField.value)
    },
  },
}
</script>

<style scoped>
.subheading.black-border {
  width: 100%;
  border-radius: 12px;
  padding: 12px 10px 12px 10px;
}
</style>
