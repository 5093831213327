import store from "@/vuex/setup";

import connectWidgets from "@/vuex/widgets-watcher";
import connectUserdata from "@/vuex/variable-watcher";

import URLHelper from "@/general/Objects/URLHelper";
import DataLayer from "@/general/Objects/DataLayer";
import User from "@/general/Objects/User";
import View from "@/general/Objects/View";

interface DataModel {
  uid: string | null,
  url: string | null,
  branch: string | null,
  group: string | null,
  table: string | null
  client_name: string | null,
}

export default class Account {
  private path: string;
  private url: string;
  private data: DataModel | null;

  constructor(path: string) {
    this.path = path;
    this.url = path.split("/")[1];
    this.data = null;
  }

  async setPage(): Promise<string> {
    store.state.environment.view = "load";

    var user = await this.checkAuthState();

    var db = await new DataLayer();
    await db.getUrlDB(user.uid);

    var view = await this.setRoute(db, user);

    if (view.getUrl() == "login") {
      return "/" + "login";
    }

    store.state.environment.view = view.getName();
    store.state.environment.hidePopUp = true;

    await connectUserdata.runWatchers(view.getUrl());

    store.state.environment.dbSynced = true;

    return "/" + view.getUrl();
  }

  async setRoute(db: DataLayer, user: any): Promise<View> {
    var view = new View();

    if (user) {
      store.state.environment.loggedIn = true;
      db.setUID(user.uid);
      await db.setPublicProfile(db.data.uid);
      // await db.setPrivateProfile();
      await view.setName("account");
      await view.setEnvironment("config");
      await view.setUrl(this.url);

    } else {
      await view.setName("login");
      await view.setEnvironment(null);
      await view.setUrl("login");
    }
    return view;
  }

  async checkAuthState(): Promise<any> {
    const userClass = new User();

    await userClass.setUser();
    var user = userClass.getUser();

    userClass.storeUser(store.state);

    return user;
  }

  async checkUrl(db: DataLayer): Promise<boolean> {
    this.data = await db.getUrlData();

    return this.data != null ? true : false;
  }

  async checkUid(db: DataLayer, input_uid: string): Promise<boolean> {
    this.data = db.data;

    if (this.data == null) {
      return false;
    }

    if (this.data.uid == null) {
      return false;
    }

    return this.data.uid == input_uid ? true : false;
  }
}
