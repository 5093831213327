import json from "@/general/GlobalValues.json";
import { db } from "@/db/Firebase";

import widgets from "./widgets.js";

export default {
  //Widgets
  async setWidgets(uid, data) {
    await db
      .collection(json["collections"].users)
      .doc(uid)
      .collection(json["collections"].widgets)
      .doc(json["docs"].widgets)
      .set({
        data: data,
        redirect: false,
      })
      .catch((err) => {
        throw err.message;
      });
  },

  async deleteWidgets(uid) {
    await db
      .collection(json["collections"].users)
      .doc(uid)
      .collection(json["collections"].widgets)
      .doc(json["docs"].widgets)
      .delete()
      .catch((err) => {
        throw err.message;
      });

    await db
      .collection(json["collections"].users)
      .doc(uid)
      .collection(json["collections"].widgets)
      .doc(json["docs"].public)
      .delete()
      .catch((err) => {
        throw err.message;
      });
  },

  async updateWidgets(uid, input, group) {
    return await widgets.updateData(uid, input, group).catch((err) => {
      throw "Error: " + err.message;
    });
  },

  async getWidgets(uid, group) {

    return await widgets
      .getData(uid, group)
      .then((data) => {
        return data;
      })
      .catch(() => {
        throw "It seems that you have no widgets";
      });
  },

  async updateRedirect(uid, value) {
    await db
      .collection(json["collections"].users)
      .doc(uid)
      .collection(json["collections"].widgets)
      .doc(json["docs"].widgets)
      .update({
        redirect: value,
      })
      .catch((err) => {
        throw "Error: " + err.message;
      });
  },

  async resetWidgets(uid) {
    await db
      .collection(json["collections"].users)
      .doc(uid)
      .collection(json["collections"].widgets)
      .doc(json["docs"].widgets)
      .set({
        data: [],
        redirect: false,
      })
      .catch((err) => {
        throw "Error: " + err.message;
      });
  },
};
