<template>
  <div class="bottom-layer2">
    <div
      v-if="tables.length <= 0 && isLoaded"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 25%;
      "
    >
      <p>Er staan op dit moment geen tafels in je profiel.</p>
      <Button :text="'Vraag tafels aan'" @isClicked="sendEmail" />
    </div>
    <div v-for="(row, index) in rows" :key="index" class="full-width">
      <template v-if="lock">
        <Row :group="row" @clickGroup="clickGroup" />
      </template>
      <template v-else>
        <RowEdit :group="row" @clickDelete="clickDelete" />
      </template>
    </div>
    <template v-if="tables.length > 0">
      <AddButton @isClicked="addGroup" />
    </template>
  </div>
</template>

<script>
import Row from "@/general/Components/2.0/TableManagement/AllGroups/Row.vue"
import RowEdit from "@/general/Components/2.0/TableManagement/AllGroups/RowEdit.vue"
import AddButton from "@/general/Components/2.0/TableManagement/AllGroups/AddButton.vue"

import BranchRepository from "@/general/Objects/BranchRepository"
import Button from "@/general/Components/2.0/CategoryManagement/General/Button.vue"

import InitiateTables from "@/controller/brancheAndTables"

export default {
  components: {
    Row,
    RowEdit,
    AddButton,
    Button,
  },
  data: () => {
    return {
      index: -1,
      tables: [],
      isLoaded: false,
    }
  },

  props: ["rows", "lock"],
  async created() {
    const initiatePage = new InitiateTables(this)
    this.tables = await initiatePage.getAllTables()
    this.isLoaded = true
  },
  methods: {
    clickGroup(index) {
      this.index = index
      this.groupID = this.rows[index].id

      this.$router.push({
        path: "/group/" + this.groupID,
      })
    },
    addGroup() {
      this.$router.push({
        path: "/group",
      })
    },
    async clickDelete(index) {
      this.index = index
      this.$emit("deletegroup", index)
    },
    sendEmail() {
      window.open("mailto:info@trymozo.com")
    },
  },
}
</script>

<style scoped>
.full-width {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}
</style>
