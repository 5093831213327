<template>
  <svg :viewBox="`0 0 ${UNITS} ${UNITS}`" :height="'100%'" :width="'100%'">
    <linearGradient
      id="linear-gradient"
      x1="0.851"
      y1="0.079"
      x2="0.074"
      y2="0.968"
      gradientUnits="objectBoundingBox"
    >
      <stop offset="0" :stop-color="colors[0]" />
      <stop offset="1" :stop-color="colors[1]" />
    </linearGradient>
    <g :transform="`rotate(-90,${UNITS / 2},${UNITS / 2}) ${''}`">
      <circle
        :cx="UNITS / 2"
        :cy="UNITS / 2"
        :r="getRadius()"
        :stroke="'lightgrey'"
        :stroke-width="getStrokeWidth(strokeWidth)"
        fill="none"
        :stroke-dasharray="getLengths()"
      />
      <path
        :d="describeArc(UNITS / 2, UNITS / 2, getRadius(), 0, activeEnd())"
        fill="none"
        stroke="url(#linear-gradient)"
        :stroke-width="getStrokeWidth(20)"
        :stroke-dasharray="getLengths()"
      />
    </g>
    <text
      id="DAYS_LEFT"
      data-name="DAYS LEFT"
      fill="url(#linear-gradient)"
      font-size="8"
      font-family="WorkSans-Bold, Work Sans"
      font-weight="700"
    >
      <tspan x="30%" y="70%">DAYS LEFT</tspan>
    </text>
    <text
      id="_7"
      data-name="7"
      fill="url(#linear-gradient)"
      font-size="50"
      font-family="WorkSans-Bold, Work Sans"
      font-weight="700"
    >
      <tspan x="35%" y="60%">{{ days_left.toFixed(0) }}</tspan>
    </text>
  </svg>
</template>

<script>
export default {
  props: {
    request_date: {
      type: Number,
    },
    total_days: {
      type: Number,
    },
    colors: {
      type: Array,
    },
  },
  data: function () {
    return {
      activeCount: 0,
      dashCount: 100,
      dashSpacing: 0,
      strokeWidth: 20,
      percentageElapsed: 0,
      activeWidth: 20,
      days_left: -1,
      animationFrame: null,
      percentage: 0.01,
    }
  },
  created() {
    this.UNITS = 100
    const from = new Date(this.request_date * 1000)
    const total_days = this.total_days
    const target = new Date((this.request_date + total_days * 24 * 60 * 60) * 1000)
    const now = new Date()
    const completed = Math.min((((now - from) / (target - from)) * 100).toFixed(0), 99.99)
    this.activeCount = completed

    //calculate time difference
    var time_difference = target.getTime() - now.getTime()

    //calculate days difference by dividing total milliseconds in a day
    this.days_left = time_difference / (1000 * 60 * 60 * 24)
  },
  beforeDestroy() {
    cancelAnimationFrame(this.animationFrame)
  },
  mounted() {
    let self = this

    setTimeout(function () {
      self.animationFrame = requestAnimationFrame(self.animate)
    }, 1000)
  },

  methods: {
    // Stroke is provided as a percentage of the radius, translate into user space units
    getStrokeWidth(stroke) {
      return (stroke * this.UNITS) / 200
    },

    // Determine the 'end' angle of the path for the active dashes in degrees.
    activeEnd() {
      if (this.percentage == 0) {
        return 0
      }
      return (
        360 *
        (this.percentage * this.dashPerc() + (this.percentage - 1) * this.spacePerc())
      )
    },

    // An array of the length of the dash & the length of the space between dashes
    getLengths() {
      return [
        2 * Math.PI * this.getRadius() * this.dashPerc(),
        2 * Math.PI * this.getRadius() * this.spacePerc(),
      ]
    },

    // The space beween dashes as a percentage of the total length
    spacePerc() {
      return this.dashSpacing / this.dashCount
    },

    // The length of a dash as a percentage of the total length
    dashPerc() {
      return (1 - this.dashSpacing) / this.dashCount
    },

    // Radius of the circle arc
    getRadius() {
      return (
        (this.UNITS -
          Math.max(
            this.getStrokeWidth(this.strokeWidth),
            this.getStrokeWidth(this.activeWidth)
          )) /
        2
      )
    },

    // SVG path definition requires points in cartesian space
    polarToCartesian(cx, cy, radius, degrees) {
      const radians = (degrees * Math.PI) / 180.0
      return {
        x: cx + radius * Math.cos(radians),
        y: cy + radius * Math.sin(radians),
      }
    },

    // Path definition for circular arc
    describeArc(cx, cy, radius, startDegrees, endDegrees) {
      const start = this.polarToCartesian(cx, cy, radius, startDegrees)
      const end = this.polarToCartesian(cx, cy, radius, endDegrees)

      let largeArc = Math.abs(endDegrees - startDegrees) < 180 ? 0 : 1
      let sweep = endDegrees < startDegrees ? 0 : 1

      return `M${start.x} ${start.y} A${radius} ${radius} 0 ${largeArc} ${sweep} ${end.x} ${end.y}`
    },
    async animate() {
      if (this.percentage.toFixed(0) == this.activeCount) {
        cancelAnimationFrame(this.animationFrame)
        return
      }
      this.percentage = (this.percentage + 1) % 100
      this.animationFrame = requestAnimationFrame(this.animate)
    },
  },
}
</script>
