<template>
  <div @click="isClicked" class="tablechoice-div add">
    <div class="add-button-account">
      <div class="plus-sign add-widget">+</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    isClicked() {
      this.$emit("isClicked", true)
    },
  },
}
</script>
