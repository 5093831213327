
<template>
    <div class="background mozo">
        <Header/>
        <div class="page-body">
            <div class="title-block">
                <div class="columns-3 w-row">
                <div class="left w-col w-col-4">
                    <a @click="goBack" class="account-button w-inline-block">
                    <img src="images/left-arrow.png" loading="lazy" class="image-20">
                    <div class="arrow-txt">{{btn_back}}</div>
                    </a>
                </div>
                <div class="w-col w-col-4"></div>
                <div class="right w-col w-col-4">
                    
                </div>
                </div>
            </div>
            <div class="transp-body data-fillin">
                <h1 class="heading-4 white center smaller">{{page_name}}</h1>
                <div class="form-div step1">
                    <div class="form-block marge w-form">
                        <div>

                            <div class="div-block-31">
                                <div class="paragraph left">Current password</div>
                                <input ref="oldpassword" :maxlength="input_length.password" :placeholder="placeholders.password" required="true" type="password" class="text-field marge-between w-input" autocorrect="off" autocapitalize="off">
                                <div class="div-block-60">
                                    <img v-if="error.old==='' & success.old===''" src="images/check-white.svg" loading="lazy" height="" class="image-26">
                                    <img v-if="error.old!==''" src="images/close-01.svg" loading="lazy" height="" class="image-26">
                                    <img v-if="success.old!==''" src="images/check.svg" loading="lazy" height="" class="image-26">
                                    <div class="message">correct password</div>
                                </div>
                            </div>

                            <div class="paragraph left">{{field_names.password}}</div>
                            <input @change="checkPassword()" :style="{ 'border-color': border.password1 }" ref="password1" type="password" class="text-field marge-between w-input" :maxlength="input_length.password" :placeholder="placeholders.password" required="true" autocorrect="off" autocapitalize="off">
                            <div class="div-block-59">
                                <div class="div-block-60">
                                <img v-if="error.password_min==='' & success.password_min===''" src="images/check-white.svg" loading="lazy" height="" class="image-26">
                                <img v-if="error.password_min!==''" src="images/close-01.svg" loading="lazy" height="" class="image-26">
                                <img v-if="success.password_min!==''" src="images/check.svg" loading="lazy" height="" class="image-26">
                                <div class="message">at least 8 characters</div>
                                </div>
                                <div class="div-block-60">
                                <img v-if="error.password_digit==='' & success.password_digit===''" src="images/check-white.svg" loading="lazy" height="" class="image-26">
                                <img v-if="error.password_digit!==''" src="images/close-01.svg" loading="lazy" height="" class="image-26">
                                <img v-if="success.password_digit!==''" src="images/check.svg" loading="lazy" height="" class="image-26">
                                <div class="message">contains one digit</div>
                                </div>
                                <div class="div-block-60">
                                <img v-if="error.password_lower==='' & success.password_lower===''" src="images/check-white.svg" loading="lazy" height="" class="image-26">
                                <img v-if="error.password_lower!==''" src="images/close-01.svg" loading="lazy" height="" class="image-26">
                                <img v-if="success.password_lower!==''" src="images/check.svg" loading="lazy" height="" class="image-26">
                                <div class="message">contains one lower character</div>
                                </div>
                                <div class="div-block-60">
                                <img v-if="error.password_upper==='' & success.password_upper===''" src="images/check-white.svg" loading="lazy" height="" class="image-26">
                                <img v-if="error.password_upper!==''" src="images/close-01.svg" loading="lazy" height="" class="image-26">
                                <img v-if="success.password_upper!==''" src="images/check.svg" loading="lazy" height="" class="image-26">
                                <div class="message">contains one UPPER case character</div>
                                </div>
                            </div>
                            <div class="paragraph left">{{field_names.repeat_password}}</div>
                            <input v-on:keyup.enter="next_page" @change="checkPass2()" :style="{ 'border-color': border.password2 }" ref="password2" type="password" class="text-field marge-between w-input" :maxlength="input_length.password" :placeholder="placeholders.password" required="true" autocorrect="off" autocapitalize="off">
                            <div class="div-block-59">
                                <div class="div-block-60">
                                <img v-if="error.password_equal==='' & success.password_equal===''" src="images/check-white.svg" loading="lazy" height="" class="image-26">
                                <img v-if="error.password_equal!==''" src="images/close-01.svg" loading="lazy" height="" class="image-26">
                                <img v-if="success.password_equal!==''" src="images/check.svg" loading="lazy" height="" class="image-26">
                                <div class="message">passwords are equal</div>
                                </div>
                            </div>
                           

                            

                            <!-- <div class="field-div">
                                <div class="paragraph left">New Password</div>
                                <input ref="password1" :maxlength="input_length.password" :placeholder="placeholders.password" required="true" type="password" class="text-field account w-input">
                            </div>
                            <div class="field-div">
                                <div class="paragraph left">Repeat password</div>
                                <input ref="password2" :maxlength="input_length.password" :placeholder="placeholders.password" required="true" type="password" class="text-field account w-input">
                            </div> -->
                        </div>
                    </div>
                    <div>
                        <a v-if="success.old===''" @click="updatePassword()" class="submit-button w-button">{{btn_change}}</a>
                    </div>
                    <div v-if="error.old!==''" class="error-message">{{error.old}}</div>
                    
                </div>
            </div>
        </div>
        <template v-if="deleteCheck">
            <DeleteUser
            :url="url"
            @getCheckValue="setDelete"/>
        </template>
    </div>

    
</template>


<script>
// in folder
import DeleteUser from './DeleteAccount.vue'

// general
import json from '@/general/GlobalValues.json'
// elements 1.0
import Header from '@/general/Elements/2.0/Headers/Header1.vue'

// db
import db_auth from '@/db/2.0/auth.js'

// helper
import validate from '@/general/HelperMethods/Validate.js'

export default {
    components: {
        DeleteUser,
        Header,
    },
    data() {
        return {
            btn_back: "Account",
            page_name: "Change Password",
            btn_change: "Change Password",
            btn_delete: "Delete account",

            field_names: json["field_names"],
            input_length: json["input_length"],
            placeholders: json["placeholders"],


            deleteCheck: false,

            password1: '',
            password2: '',

            border: {
                old: "",            
                password_min: "",
                password_upper: "",
                password_lower: "",
                password_digit: "",
                password_equal: "",
            },

            error: {
                old: "",  
                password_min: "",
                password_upper: "",
                password_lower: "",
                password_digit: "",
                password_equal: "",

            },

            success:{
                old: "",  
                password_min: "",
                password_upper: "",
                password_lower: "",
                password_digit: "",
                password_equal: "",

            },
        };
    },
    props: [
        'url',
        'user'
    ],

    methods: {
        goBack(){
            this.$emit('goAccount', false);
        },

        setDelete(value) {
            this.deleteCheck = value
        },

        async checkPassword(){
        
            this.password1 = this.$refs.password1.value;

            var password_check = await validate.password(this.$refs.password1.value)

            var isCorrect = await this.validatePass1(password_check)
                
            await this.checkPass2();
            
            return isCorrect;
        
        },

    async validatePass1(password_check) {
        var password = true;
        if (password_check.includes('upper')) {
            this.error.password_upper = "The password doesnt contain upper"
            this.success.password_upper = ""
            password = false;
        } else {
            this.error.password_upper = ""
            this.success.password_upper = "ys"
        }

        if (password_check.includes('lower')) {
            this.error.password_lower = "The passwords doesnt contain lower"
            this.success.password_lower = ""
            password = false;
        } else {
            this.error.password_lower = ""
            this.success.password_lower = "ys"
        }


        if (password_check.includes('min')) {
            this.error.password_min = "err"
            this.success.password_min = ""
            password = false;
        } else {
            
            this.error.password_min = ""
            this.success.password_min = "yes"
        }

        if (password_check.includes('digit')) {
            this.error.password_digit = "err"
            this.success.password_digit = ""
            password = false;
        } else {
            this.error.password_digit = ""
            this.success.password_digit = "yes"
        }

       
        
        if (password) {
            this.border.password1 = "#00c9aa" 
            return true;
        }  else {
            this.border.password1 = "#ff0000"
            return false;
        }

        
    },

    async checkPass2() {
        this.password2 = this.$refs.password2.value;
        var password1 = this.$refs.password1.value;
        var password2 = this.$refs.password2.value;

        if (password2 === '') {
            return false;
            
        } else if ( password1 !== password2){
            
            this.error.password_equal = "The passwords are not equal!"
            this.border.password2 = "#ff0000"
            this.success.password_equal = ""
            
            return false;
        } else {
            this.error.password_equal = "";
            this.border.password2= "#00c9aa"
            this.success.password_equal = "yes"
        }

        return true;
    },


        async updatePassword() {
            // var oldPassword = this.$refs.oldpassword.value
            // var password1 = this.$refs.password1.value;
            // var password2 = this.$refs.password2.value;

            // var password_check = validate.password(password1)
            // if ( oldPassword === '') {
            //     this.error = "Error: you did not give us your old password!"
            // } else if (password1 !== password2) {
            //     this.error = "Error: you did not give us equal password!"
            // } else if (password_check !== '') {
            //     this.error = password_check
            // } else if( (password1 !== '') || (password2 !== '')) {
                
            //     if(password1 === password2) {

                   
            if (await this.checkVariables()) {
                var oldp = this.$refs.oldpassword.value
                
                await db_auth.changePassword(this.user, oldp, this.password1)
                .then(() => {
                 
                    this.error.old = "";
                    this.success.old = "You succesfully changed your password!";

                })
                .catch((err) => {
                  
                    this.error.old = err;
                    return false;
                })

            }   
                
  
            

        },

        async checkVariables() {
            if (!await this.checkPassword()) {
                 return false
            }
            

            if (!await this.checkPass2()) {
                return false
            }


            return true

        },

    }
};
</script>
