<template>
  <div
    @click="isClicked"
    class="button-div wm button-image margin-bottom"
    :class="opacity"
  >
    <img v-if="image" :src="'/images/' + image" class="arrow-small" />
    <h1 v-if="text" class="button-text2 underline">{{ text }}</h1>
  </div>
</template>

<script>
export default {
  props: ["text", "image", "opacity"],
  methods: {
    isClicked() {
      this.$emit("isClicked", true)
    },
  },
}
</script>

<style scoped>
.button-div {
  cursor: pointer;
}

.opacitylow {
  opacity: 80%;
  cursor: default;
}
</style>
