<template>
    <div class="logo-header-2">
        <div class="logo-pop-up">
            <img :src="brand.logo" loading="lazy" class="logo-img">
            <!-- <div class="logo-text">{{brand.name}}</div> -->
        </div>
    </div>
</template>


<script>

import json from "@/general/GlobalValues.json"

export default {
    data: () => {
        return {
            brand: json['brand']

        }
    }

}

</script>