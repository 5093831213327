const groupCollection = [
    {
        id: "g_1",
        groupName: "group1",
        active: true,
        position: 0,
    },
    {
        id: "g_2",
        groupName: "group2",
        active: true,
        position: 1,
    },
]

export default { groupCollection }