<template>
  <div class="icon-plate">
    <Icon :widget="widget_name" :colors_arr="widget_colors" />
  </div>
</template>

<script>
import Icon from "@/general/Elements/2.0/OtherElements/Widgets/WidgetIcon2.vue"

export default {
  components: {
    Icon,
  },
  props: ["widget_name"],
  data: () => {
    return {}
  },
  created() {},
  computed: {
    store: function () {
      return this.$store.state
    },
    widget_colors: function () {
      return this.store.client_data.color[2].icons
    },
  },
}
</script>

<style scoped></style>
