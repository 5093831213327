<template>
  <div>
    <template v-if="menu">
      <AccountMenu />
    </template>
    <template v-else-if="isLoaded">
      <BackgroundLayer :header_title="'All Groups'" @isLocked="setLock" />
      <template v-if="isLoaded">
        <Rows :rows="groups" :lock="lock" />
      </template>
    </template>
    <template v-else>
      <WaitPage />
    </template>
  </div>
</template>

<script>
import Header from "@/general/Components/2.0/TableManagement/General/Header.vue"

import AddButton from "@/general/Components/2.0/TableManagement/General/Button.vue"
import Rows from "@/general/Components/2.0/TableManagement/AllGroups/Rows.vue"

import AccountMenu from "@/views/Menu/LoggedIn.vue"

import AddGroup from "@/general/Components/2.0/TableManagement/AllGroups/EditGroup.vue"

import InitiateTables from "@/controller/brancheAndTables"

import BranchRepository from "@/general/Objects/BranchRepository"
import WaitPage from "@/general/Elements/2.0/OtherElements/Popups/WaitPage.vue"

import BackgroundLayer from "@/general/Components/2.0/TableManagement/General/Header.vue"

export default {
  components: {
    AddButton,
    Header,
    Rows,
    AccountMenu,
    AddGroup,
    WaitPage,
    BackgroundLayer,
  },

  data: () => {
    return {
      lock: true,
      addgroup: false,
      editgroup: false,
      group: null,
      groups: [],
      isLoaded: false,
    }
  },

  async created() {
    const initiatePage = new InitiateTables()
    this.groups = await initiatePage.getAllGroups()
    this.isLoaded = true
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    menu: function () {
      return this.store.environment.menu
    },
  },

  methods: {
    isClicked() {
      this.addgroup = true
    },
    setLock(lock) {
      this.lock = lock
    },
    setAddGroup(value) {
      this.addgroup = value
    },
    setEditGroup(value, index) {
      this.editgroup = value
      this.group = this.groups[index]
    },
    async deleteGroup(index) {
      const branchRepo = new BranchRepository("b1")
      await branchRepo.removeGroups([this.groups[index].id])
    },
  },
}
</script>

<style scoped>
.grey-body {
  background-color: rgb(241, 241, 241);
  min-height: 100vh;
}
</style>
