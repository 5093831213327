
import db_auth from "@/db/2.0/auth.js";



export default class User {
  private user: any;
  private url: any;
  public uid: any;

  constructor() { }

  async setUser() {
    this.user = await db_auth.userState().then((user) => {
      return user;
    });
  }

  getUser() {
    return this.user;
  }

  storeUser(store: any) {
    store.user = this.user;
  }

  setUrl(url: any) {
    this.url = url;
  }
}

