<template>
  <div>
    <div class="full">
      <div class="paragraph black middle margin-top">
        Don&#x27;t have an account?
        <span @click="isClicked" class="text-span-4">Register free</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => {
    return {}
  },
  methods: {
    isClicked() {
      this.$router.push({ name: "Register" })
    },
  },
  computed: {},
}
</script>

<style scoped>
.text-span-4 {
  cursor: pointer;
}
</style>
