import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex)

import widgets from "@/vuex/setup_data/widgets.js"
import saved_widgets from "@/vuex/setup_data/saved_widgets.js"
import environment from "@/vuex/setup_data/environment.js"
import client_data from "@/vuex/setup_data/client.js"
import groupCollection from "@/vuex/setup_data/groups.js"
import tableCollection from "@/vuex/setup_data/tables.js"
import friends from "@/vuex/setup_data/friends.js"

var user = null

var groups = groupCollection

var tables = tableCollection

export default new Vuex.Store({
  state: {
    client_data,
    widgets,
    environment,
    user,
    tables,
    groups,
    saved_widgets,
    friends,
  },
  getters: {
    getWidgetById: (state) => (id) => {
      var category = []
      state.saved_widgets.forEach((w) => {
        if (w.widget === id) {
          category.push(w)
        }
      })
      return category
    },
  },
  mutations: {
    resetWidgetState(state) {
      state.widgets = widgets
    },
  },
})
