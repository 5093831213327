<template>
    <div class="error-div">
        <div class="error-message">{{ error }}</div>
    </div>
</template>

<script>


export default {
  props: ["error"],
}


</script>