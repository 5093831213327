<template>
  <div class="default-width center">
    <template v-if="widget.action == 'create'">
      <Title :showback="true" :text="'Replace Widget'" @return="goBack" />
    </template>
    <template v-else>
      <Title
        :showback="false"
        :showCross="true"
        :text="'Replace Widget'"
        @return="goTable"
      />
    </template>
    <LargeWidget :widget="widget" @isClicked="setPopup" />
    <template v-if="widget.type != 'small_tile_collection'">
      <InputField
        :label="'Displayed text'"
        :input="widget.title"
        @setInput="setTitleInput"
      />
    </template>
    <div class="choose-account-div">
      <div class="choose-account-div">
        <h2 class="label">Choose an account</h2>
        <div v-if="isLoaded" class="selectfield border">
          <div v-for="(account, index) in accounts" :key="index">
            <Account
              :account="account"
              :widget_name="widget.name"
              :bgClicked="bgClicked"
              :index="index"
              :currentNumber="currentNumber"
              @compClicked="setBGClicked"
              @accountSelected="getIndex"
              @deleteAccount="deleteAccount(account)"
            />
          </div>

          <AddAccount @next="setAccount" />
        </div>
      </div>
    </div>
    <div class="default-width center">
      <Button text="Add Widget" :opacity="opacity_save" @isClicked="saveWidget" />
      <template v-if="!isNew">
        <DeleteButton :opacity="''" :text="'Delete Widget'" @isClicked="deleteWidget" />
      </template>
    </div>
  </div>
</template>

<script>
import Title from "@/general/Components/2.0/CategoryManagement/General/Title2.vue"
import LargeWidget from "@/general/Components/2.0/TableManagement/EditWidget/LargeWidget.vue"
import InputField from "@/general/Components/2.0/CategoryManagement/General/InputField.vue"

import AddAccount from "@/general/Components/2.0/CategoryManagement/AccountChosen/AddAccount.vue"
import Account from "@/general/Components/2.0/CategoryManagement/General/Account.vue"

import Button from "@/general/Components/2.0/CategoryManagement/General/Button.vue"
import DeleteButton from "@/general/Components/2.0/CategoryManagement/General/ButtonNoBG.vue"

import WidgetRepository from "@/general/Objects/Widget"
import WidgetCollection from "@/general/Objects/WidgetCollection"

import TablesRepository from "@/general/Objects/TablesRepository"

export default {
  components: {
    Title,
    Button,
    LargeWidget,
    AddAccount,
    Account,
    DeleteButton,
    InputField,
  },

  props: ["isNew", "table", "opacity_save", "bgClicked"],

  data: () => {
    return {
      selected_widget: "url",
      options: ["link"],
      color: [
        "#11d1ad",
        "#116c5b",
        {
          icons: ["#116c5b", "#116c5b", "#ffffff"],
        },
      ],
      widgetNames: [{ id: "default", name: "Choose an widget..", type: null }],
      itemType: null,
      widget_uid: null,
      showAddField: false,
      default_field: "Choose an widget..",
      isLoaded: false,
      options: [],
      accounts: [],
      currentNumber: -1,
    }
  },

  async created() {
    const wObject = new WidgetRepository()
    this.widget_title = await wObject.getTitle(this.widget.name)
    this.customWidgetTitle = this.widget.name

    const wCol = new WidgetCollection()

    this.accounts = await wCol.getAllAccounts(this.widget.name)

    this.options = await wObject.getWidgetByName(this.widget.name).options

    if (this.widget != null && this.widget != 0) {
      this.currentNumber = await this.accounts.findIndex(
        (el) => el.uid == this.widget.account_uid
      )

      this.accounts = await wCol.set_accounts(true, this.accounts, this.currentNumber)
      this.image = this.widget.image
      this.setBGClicked(false, this.currentNumber)
    }

    if (this.isNew) {
      this.widget.action = "create"
    } else {
      this.widget.action = "update"
    }

    this.isLoaded = true
  },

  methods: {
    goBack(item) {
      this.$emit("changeState", true)
    },

    goTable() {
      this.$router.push({ path: "/table/" + this.tableID })
    },
    async getIndex(index) {
      this.currentNumber = index
      const wCol = new WidgetCollection()
      this.accounts = await wCol.set_accounts(false, this.accounts, this.currentNumber)

      this.selectedAccount = this.accounts[index]

      this.account.data = this.accounts[index].data
      this.account.uid = this.accounts[index].uid
      this.widget.account_uid = this.account.uid
    },

    async deleteAccount(account) {
      const wCol = new WidgetCollection()
      await wCol.deleteAccount(account.uid)

      const wCollectionObject = new WidgetCollection()
      this.accounts = await wCollectionObject.getAllAccounts(this.widget.name)
    },

    setTitleInput(input) {
      this.customWidgetTitle = input
    },

    async saveWidget() {
      const wCol = new WidgetCollection()
      const response = await wCol.getAccountStatus(this.account.uid)

      if (response.status == "requested") {
        this.screen = "waitPage"
      } else {
        const tableRepo = new TablesRepository()
        if (this.widget.action == "create") {
          await tableRepo.addProperties(
            [this.tableID],
            [{ key: "widgets", value: [this.widget] }]
          )
        } else if (this.widget.action == "update") {
          await tableRepo.updateProperties(
            [this.tableID],
            [{ key: "widgets", value: [this.widget] }]
          )
        }

        this.$router.push({ path: "/table/" + this.tableID })
      }
    },

    setPopup() {
      this.$emit("setPopup", true)
    },
    async deleteWidget() {
      const tableRepo = new TablesRepository()
      await tableRepo.removeWidget(this.tableID, this.widget.uid)
      this.$router.push({ path: "/table/" + this.tableID })
    },

    setAccount() {
      this.account.action = "create"

      this.$router.push({
        name: "SetAccount",
        params: {
          return_path: "EditTableWidget",
        },
      })
    },

    setBGClicked(value, index) {
      this.$emit("compClicked", value, index)
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    widgets: function () {
      return this.store.widgets
    },

    widget: {
      get() {
        return this.store.environment.editTableWidget
      },
      set(value) {
        this.store.environment.editTableWidget = value
      },
    },

    account: {
      get() {
        return this.store.environment.setAccount.item
      },
      set(value) {
        this.store.environment.setAccount.item = value
      },
    },

    setWidget: {
      get() {
        return this.store.environment.addTableWidget
      },
      set(value) {
        this.store.environment.addTableWidget = value
      },
    },

    state: {
      get() {
        return this.store.environment.stateTableWidget
      },
      set(value) {
        this.store.environment.stateTableWidget = value
      },
    },
    tableID: function () {
      return this.store.environment.tableID
    },
  },
}
</script>

<style scoped>
.white-background {
  padding: 5% 10%;
  height: 130vh;
}

.grey-body {
  min-height: 150vh !important;
}
</style>
