import store from "@/vuex/setup";
import db_bgt from "@/db/2.0/TableManagement/main";
import User from "@/general/Objects/User";
import db_accounts from "@/db/2.0/accounts";

import json from "@/general/GlobalValues.json";
import DataLayer from "@/general/Objects/DataLayer";
import View from "@/general/Objects/View";

export default class BT {
  constructor(vue) {
    this.vue = vue;
    this.setPage();
  }

  setPage() { }

  async checkAuthState() {
    this.userClass = new User();
    await this.userClass.setUser();

    var user = this.userClass.getUser();

    this.userClass.storeUser(store.state);

    return user;
  }

  async getAllTables() {
    const user = await this.checkAuthState();
    if (user == null) {
      var view = new View();
      await view.setUrlCorrect(this.vue.$router, "login");
      return;
    }
    const tables = await db_bgt.getAllPublicTables(
      user.uid,
      json["docs"]["branch"]
    );

    const groups = await db_bgt.getAllPublicGroups(
      user.uid,
      json["docs"]["branch"]
    );

    //get all data to construct tables and their corresponding groups
    store.state.groups.groupCollection = groups;
    store.state.tables.tableCollection = tables;


    //get all data to construct custom widgets per table
    // background Color etc..
    var db = await new DataLayer();
    await db.setPublicProfile(user.uid);

    return tables;
  }

  async getAllGroups() {
    const user = await this.checkAuthState();
    if (user == null) {
      var view = new View();
      await view.setUrlCorrect(this.vue.$router, "login");
      return;
    }

    const tables = await db_bgt.getAllPublicTables(
      user.uid,
      json["docs"]["branch"]
    );

    const groups = await db_bgt.getAllPublicGroups(
      user.uid,
      json["docs"]["branch"]
    );

    store.state.groups.groupCollection = groups;
    store.state.tables.tableCollection = tables;

    //get all data to construct custom widgets per table
    // background Color etc..
    var db = await new DataLayer();
    await db.setPublicProfile(user.uid);

    return groups;
  }

  getAllWidgets() { }
}
