<template>
  <div class="_100 nobg">
    <div class="register-pp-div">
      <div class="upload-pp-field">
        <div v-if="newImage != ''" class="setImage">
          <AddPicture
            :image="this.newImage"
            color="#e3e3e3"
            @returnImage="uploadToCloud"
            @error="setError"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddPicture from "@/general/Elements/2.0/Config/ChangePicture.vue"

import json from "@/general/GlobalValues.json"

import WidgetCollection from "@/general/Objects/WidgetCollection"

export default {
  components: {
    AddPicture,
  },
  props: ["image", "isChange", "group", "widget_uid"],

  data() {
    return {
      field_names: json["field_names"],
      input_length: json["input_length"],
      placeholders: json["placeholders"],

      placeholder: null,
      newImage: "",
    }
  },
  created() {
    this.config = true
    this.newImage = this.image
  },
  computed: {
    store: function () {
      return this.$store.state
    },

    widgets: function () {
      return this.store.widgets
    },
    config: {
      get() {
        return this.store.environment.config
      },
      set(value) {
        this.store.environment.config = value
      },
    },
    uid: function () {
      return this.store.user.uid
    },
  },

  methods: {
    async uploadToCloud(image) {
      const wCol = new WidgetCollection()
      this.newImage = await wCol.addImageToWidget(this.uid, "temp", "0", image)

      this.$emit("returnImage", this.newImage)
      this.$emit("returnFile", image)
    },

    setError(error) {
      this.$emit("error", error)
    },
  },
}
</script>

<style>
.setImage {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 12px;
  overflow: hidden;
}
</style>
