<template>
  <div class="tile_newsocial nd-apps handle">
    <div class="tile nd-small">
      <template v-if="config">
        <div @click="edit()" class="delete-tile-div">
          <div class="change">
            <img src="images/edit-button-white.png" loading="lazy" class="edit-img" />
          </div>
        </div>
      </template>

      <Icon :widget="item.name" :colors_arr="colors[2].icons" />
    </div>
    <div v-if="text" class="nd-div-leftmarge">
      <div class="white nd-design">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
import Icon from "@/general/Elements/2.0/OtherElements/Widgets/WidgetIcon.vue"

export default {
  props: ["item", "index", "text", "type"],
  components: {
    Icon,
  },
  created() {},
  computed: {
    store: function () {
      return this.$store.state
    },

    changeWidget: {
      get() {
        return this.store.environment.changeWidget
      },
      set(value) {
        this.store.environment.changeWidget = value
      },
    },
    config: function () {
      return this.store.environment.config
    },
    colors: function () {
      return this.store.client_data.color
    },
  },

  methods: {
    edit() {
      this.changeWidget = {
        item: this.item,
        type: this.type,
      }
    },
  },
}
</script>

<style>
.tile.nd-imgapps {
  box-shadow: 0px 0px 0px 0px;
}
</style>
