import json from "@/general/GlobalValues.json";
import { db } from "@/db/Firebase";

export default {
  //public data

  async initPrivate() {
    return await {
      nickname: "Offline Profile",
      bio: "I am currently unavailable..",
      image: "../images/MOZO_Logo_Groen_Vierkant.jpg",
      offline: true,
      email: "",
      firstname: "",
      lastname: "",
    };
  },

  async setData(uid, data, group, collection = "data") {
    await db
      .collection(json["collections"].users)
      .doc(uid)
      .collection(json["collections"][collection])
      .doc(group)
      .set(data)
      .catch((err) => {
        throw err.message;
      });
  },

  async updateData(uid, data, group, collection = "data") {
    await db
      .collection(json["collections"].users)
      .doc(uid)
      .collection(json["collections"][collection])
      .doc(group)
      .update(data)
      .catch((err) => {
        throw err.message;
      });
  },

  async deleteData(uid, group, collection = "data") {
    await db
      .collection(json["collections"].users)
      .doc(uid)
      .collection(json["collections"][collection])
      .doc(group)
      .delete()
      .catch((err) => {
        throw err.message;
      });
  },

  async getData(uid, group, collection = "data") {
    return await db
      .collection(json["collections"].users)
      .doc(uid)
      .collection(json["collections"][collection])
      .doc(group)
      .get()
      .then((doc) => {
        if (doc.exists) {
          var data = doc.data();
          data["uid"] = uid
          return data;
        }
      })
      .catch((err) => {
        throw err.message;
      });
  },

  async getPrivateUserData(uid, collection = "data") {
    return await db
      .collection(json["collections"].users)
      .doc(uid)
      .collection(json["collections"][collection])
      .doc("public")
      .get()
      .then((doc) => {
        if (doc.exists) {
          var email = doc.data().email;
          var firstname = doc.data().firstname;
          var lastname = doc.data().lastname;

          return { email, firstname, lastname };
        }
      })
      .catch((err) => {
        throw err.message;
      });
  },
};
