import { render, staticRenderFns } from "./EditGroup.vue?vue&type=template&id=60c7f7e8&scoped=true&"
import script from "./EditGroup.vue?vue&type=script&lang=js&"
export * from "./EditGroup.vue?vue&type=script&lang=js&"
import style0 from "./EditGroup.vue?vue&type=style&index=0&id=60c7f7e8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60c7f7e8",
  null
  
)

export default component.exports