<template>
  <div>
    <div class="div-block-173 smalle">
      <h2 class="heading-32">{{ Title }}</h2>
      <div class="subheading black-border cirkel"></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["Title"],
}
</script>

<style></style>
