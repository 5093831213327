<template>
  <div>
    <WidgetPopUp :data="data" :color="color" @back="setBack" />
  </div>
</template>

<script>
// in folder
import WidgetPopUp from "./WidgetPopUp.vue"

export default {
  components: {
    WidgetPopUp,
  },
  props: ["data", "color"],

  methods: {
    setBack(value) {
      if (value) {
        this.$emit("back", value)
      }
    },
  },
}
</script>
