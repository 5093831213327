<template>
  <div>
    <div class="error-login">
      <div class="paragraph black-text">{{ error }} <br /></div>
      <div @click="isClicked" class="paragraph black-text underline">
        Forgot password?
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["error"],
  data: () => {
    return {}
  },
  methods: {
    isClicked() {
      this.$router.push({ name: "ResetPassword" })
    },
  },
}
</script>

<style scoped>
.paragraph.black-text.underline {
  cursor: pointer;
}
</style>
