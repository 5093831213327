<template>
  <div class="popup-layer">
    <div v-if="isLoaded" class="popup-selectfield">
      <Title :showCross="true" :text="'Replace Widget'" @return="hidePopup" />
      <div class="selectfield border fullscreen">
        <div v-for="(widget, index) in widgets" :key="index">
          <Widget :widget="widget" @returnWidget="setWidget" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Widget from "@/general/Components/2.0/TableManagement/EditWidget/Widget.vue"
import Title from "@/general/Components/2.0/CategoryManagement/General/Title2.vue"

import WidgetCollection from "@/general/Objects/WidgetCollection"
import TablesRepository from "@/general/Objects/TablesRepository"

export default {
  components: {
    Widget,
    Title,
  },
  data: () => {
    return {
      widgets: [],
      isLoaded: false,
    }
  },
  props: ["state"],
  computed: {
    store: function () {
      return this.$store.state
    },
    tableID: function () {
      return this.store.environment.tableID
    },
  },
  async created() {
    const tableRepo = new TablesRepository()
    const usedWidgets = (await tableRepo.getTables([this.tableID]))[0].widgets

    const wCol = new WidgetCollection()
    const allWidgets = await wCol.getAllUsedWidgets("all")

    if (this.state == "create" && usedWidgets != 0) {
      allWidgets.forEach((widget) => {
        if (!usedWidgets.find((el) => el.uid == widget.uid)) {
          this.widgets.push(widget)
        }
      })
    } else {
      this.widgets = allWidgets
    }

    this.isLoaded = true
  },

  methods: {
    hidePopup() {
      this.$emit("hidePopup", true)
    },
    setWidget(widget) {
      this.hidePopup()
      this.$emit("returnWidget", widget)
    },
  },
}
</script>
