<template>
  <a @click="redirectWebsite()" target="_blank" class="open-app w-inline-block">
    <div class="icon-title">{{ button_text }}</div>
    <img src="images/link.png" loading="lazy" class="open-app-icon" />
  </a>
</template>

<script>
// helper
import methods from "@/general/HelperMethods/General.js"

export default {
  props: ["button_text", "info", "fields"],

  created() {},

  methods: {
    redirectWebsite() {
      var link = methods.getLink(
        this.info.widget,
        this.fields[0].field,
        this.fields[0].name
      )
      window.open(link)
    },
  },
}
</script>
