<template>
  <div @click="rememberProfile()" class="button nd-button w-inline-block">
    <img
      src="images/remember.png"
      loading="lazy"
      alt="mozo hospitality"
      class="image-17"
    />
    <div class="button-txt">{{ text }}</div>
  </div>
</template>

<script>
import db_url from "@/db/2.0/url.js"

export default {
  data: () => {
    return {
      text: "Become a friend",
    }
  },

  computed: {
    store: function () {
      return this.$store.state
    },
    addUser: {
      get() {
        return this.store.environment.addUser
      },
      set(value) {
        this.store.environment.addUser = value
      },
    },
    client_data: {
      get() {
        return this.store.client_data
      },
      set(value) {
        this.store.client_data = value
      },
    },
  },

  methods: {
    async rememberProfile() {
      this.addUser.profile_url = this.store.client_data.url
      this.addUser["color"] = [this.client_data.color[0], this.client_data.color[1]]
      this.addUser["name"] = this.client_data.nickname
      this.addUser["user"] = {
        uid: await db_url.getUID(this.addUser.profile_url),
      }

      this.$router.push({
        name: "loginFriend",
        params: {
          input_user: this.addUser,
        },
      })
    },
  },
}
</script>
