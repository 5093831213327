<template>
  <div>
    <template v-if="menu">
      <AccountMenu />
    </template>
    <template v-else-if="checkDelete">
      <Check />
    </template>
    <div
      class="background mozo"
      :style="setBackground(client_data.color[0], client_data.color[1])"
    >
      <div class="contact-view">
        <Header :header_title="'Profile QR code'" :showLock="false" />
        <div class="contact-div" style="max-width: 750px">
          <Contacts />
          <Panel />
        </div>
        <div class="div-block-98"></div>
      </div>
    </div>
  </div>
</template>

<script>
import methods from "@/general/HelperMethods/General.js"

import Check from "@/general/Components/2.0/FriendsOf/Check.vue"
import Contacts from "@/general/Components/2.0/FriendsOf/Contacts.vue"
import Panel from "@/general/Components/2.0/FriendsOf/Panel.vue"
import Header from "@/general/Components/2.0/TableManagement/General/Header2.vue"

import db_friend from "@/db/2.0/user/friends.js"
import db_auth from "@/db/2.0/auth.js"
import AccountMenu from "@/views/Menu/LoggedIn.vue"

export default {
  components: {
    Check,
    Contacts,
    Panel,
    Header,
    AccountMenu,
  },

  props: ["input_userData"],

  data() {
    return {}
  },

  async created() {
    if (this.input_userData) {
      this.client_data = this.input_userData
      localStorage.setItem("client_data", JSON.stringify(this.client_data))
      methods.changeUrlWithoutReload("/friends")
    } else {
      this.client_data = await JSON.parse(localStorage.getItem("client_data"))
      this.user = await db_auth.userState()
    }
    this.contacts = await db_friend.getAllData(this.user.uid)
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    client_data: {
      get() {
        return this.store.client_data
      },
      set(value) {
        this.store.client_data = value
      },
    },
    contacts: {
      get() {
        return this.store.friends.contacts
      },
      set(value) {
        this.store.friends.contacts = value
      },
    },
    user: {
      get() {
        return this.store.user
      },
      set(value) {
        this.store.user = value
      },
    },
    menu: function () {
      return this.store.environment.menu
    },

    checkDelete: function () {
      return this.store.environment.contacts.state == "delete_check"
    },
  },

  methods: {
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2)
    },
  },
}
</script>
