<template>
  <a @click="createWidget()" class="tile_newsocial nd-apps">
    <div class="tile nd-small" :class="isShortWidgets" :style="getColor()">+</div>
  </a>
</template>

<script>
export default {
  props: ["type"],
  created() {},
  computed: {
    store: function () {
      return this.$store.state
    },

    isShortWidgets: function () {
      return this.type == "small_tile_collection" ? "smaller-icon" : ""
    },

    setWidget: {
      get() {
        return this.store.environment.setWidget
      },
      set(value) {
        this.store.environment.setWidget = value
      },
    },

    color: function () {
      return this.store.client_data.color[0]
    },
  },

  methods: {
    createWidget() {
      this.setWidget = {
        item: {
          type: this.type,
          category: null,
          name: null,
          widget_uid: null,
          action: "create",
          account_uid: null,
          image: "/images/MOZO_Logo_Zwart_Vierkant.png",
        },
      }

      this.$router.push({
        name: "WidgetCategories",
      })
    },

    getColor() {
      return "color:" + this.color
    },
  },
}
</script>

<style scoped>
.tile.nd-small {
  background-image: none;
  font-size: 45px;
  font-weight: semi-bold;
  color: green;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.tile.nd-small.smaller-icon {
  min-width: 70px;
  min-height: 70px;
  width: 70px;
  height: 70px;
}

@media only screen and (max-width: 479px) {
  .tile.nd-small.smaller-icon {
    min-width: 60px;
    min-height: 60px;
    width: 60px;
    height: 60px;
  }
}
</style>
