import json from "@/general/GlobalValues.json";
import { db } from "@/db/Firebase";

export default {
    async updateTable(uid, branch_id, table_id, property, value) {
        return await db
            .collection(json["collections"].users)
            .doc(uid)
            .collection(json["collections"].branches)
            .doc(branch_id)
            .collection(json["collections"].tablesPublic)
            .doc(table_id)
            .update({
                [property]: value,
            })
            .catch((err) => {
                throw err.message;
            });
    },

    async updateTableAllData(uid, branch_id, table_id, data) {


        return await db
            .collection(json["collections"].users)
            .doc(uid)
            .collection(json["collections"].branches)
            .doc(branch_id)
            .collection(json["collections"].tablesPublic)
            .doc(table_id)
            .update(data)
            .catch((err) => {

                throw err.message;
            });
    }



}