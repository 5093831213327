import json from "@/general/GlobalValues.json";

export default {
  async setCustomCard(
    lastname,
    firstname,
    nickname,
    organisation,
    job_role,
    email,
    phone,
    urls,
    image
  ) {
    var org_line = "";
    var title_line = "";
    var title_line = "";
    var phone_line = "";

    var head = "BEGIN:VCARD\nVERSION:3.0" + "\n";
    var N = "N:" + lastname + ";" + firstname + ";;;" + "\n";
    var FN = "FN:" + firstname + " " + lastname + "\n";
    var nickname_line = "NICKNAME:" + nickname + "\n";

    if (organisation !== null) {
      org_line = "ORG:" + organisation + ";" + "\n";
    }
    if (job_role != null) {
      title_line = "TITLE:" + job_role + "" + "\n";
    }

    if (email != null) {
      var email_line =
        "item1.EMAIL;type=INTERNET;type=pref:" +
        email +
        "\nitem1.X-ABLabel:E-mail" +
        "\n";
    }

    if (phone !== null) {
      phone_line =
        "item2.TEL;type=pref:" + phone + "\nitem2.X-ABLabel:Phone" + "\n";
    }

    var urls_line = "";
    for (var i = 0; i < urls.length; i++) {
      var url =
        "item" +
        i +
        3 +
        ".URL:" +
        urls[i].link +
        "\n" +
        "item" +
        i +
        3 +
        ".X-ABLabel:" +
        urls[i].name;

      urls_line += url + "\n";
    }

    var img_line =
      "PHOTO;ENCODING=b;TYPE=JPEG:" + (await this.toDataURL(image)) + "\n";
    var end = "END:VCARD";

    return (
      head +
      N +
      FN +
      nickname_line +
      org_line +
      title_line +
      email_line +
      phone_line +
      urls_line +
      img_line +
      end
    );
  },

  async setCard(client_data, oItems) {
    if (client_data.view == "hospitality") {
      var organisation = client_data.nickname;
      var email = null;
      if (client_data.email) {
        email = client_data.email;
      }

      var lastname = "";
      var firstname = client_data.nickname;
    } else {
      var organisation = this.getWidgetData("company", oItems);
      var email = this.getWidgetData("mail", oItems);

      var lastname = client_data.lastname;
      var firstname = client_data.firstname;
    }

    var job_role = null;

    var phone = this.getWidgetData("phone", oItems);
    var urls = [
      {
        link: json["brand"]["domain"] + "/" + client_data.url,
        name: json["brand"]["name"],
      },
    ];

    return await this.setCustomCard(
      lastname,
      firstname,
      client_data.nickname,
      organisation,
      job_role,
      email,
      phone,
      urls,
      client_data.image
    );
  },

  getWidgetData(widget, oItems) {
    for (var i = 0; i < oItems.length; i++) {
      if (oItems[i].widget == "mail" && widget == "mail") {
        return oItems[i].data.field1.split(":").pop();
      } else if (oItems[i].widget == "phone" && widget == "phone") {
        return oItems[i].data.field1.split(":").pop();
      } else if (widget == "company" && oItems[i].widget == "company") {
        return oItems[i].data.field1;
      }
    }
    return null;
  },

  async toDataURL(url) {
    const fetchAsBlob = (url) => fetch(url).then((response) => response.blob());

    const convertBlobToBase64 = (blob) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });

    const blob = await fetchAsBlob(url);
    const doubleBase64EncodedFile = await convertBlobToBase64(blob);
    const doubleEncodedBase64String = doubleBase64EncodedFile.split(",")[1];

    return doubleEncodedBase64String;
  },

  getWidgetsOnCard(vCard, oItems) {
    for (var i = 0; i < oItems.length; i++) {
      if (oItems[i].widget == "mail") {
        vCard.email = oItems[i].data.field1.split(":").pop();
      } else if (oItems[i].widget == "phone") {
        vCard.cellPhone = oItems[i].data.field1.split(":").pop();
      } else {
        vCard.note += oItems[i].data.field1 + "\n";
      }
    }

    return vCard;
  },

  async downloadCard_Desktop(text, name) {
    var filename = name + ".vcf";

    var a = document.createElement("a");
    var blob = new Blob([text], { type: "text/vcard" });
    var blobURL = URL.createObjectURL(blob);
    a.download = filename;
    a.href = blobURL;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  },

  async downloadCard_Phone(text, name) {
    var filename = name + ".vcf";
    var url = "data:text/vcard;charset=utf-8," + encodeURIComponent(text);

    $(document).ready(function () {
      var d = new Date().toISOString().slice(0, 19).replace(/-/g, "");
      $("#downloadlink")
        .attr("href", url)
        .attr("download", "file-" + d + ".vcf");
    });
  },
};
