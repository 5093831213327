<template>
  <div class="section-wcategories" v-if="isLoaded">
    <BackgroundLayer :color1="colors[0]" :color2="colors[1]" />
    <div class="front-layer">
      <Header :header_title="''" :showLock="false" />

      <div class="scroll-area">
        <div class="white-background2">
          <div class="default-width full-length">
            <div class="default-width">
              <Title :showback="'AllGroups'" :text="'New Group'" />
              <div class="inputfield-div">
                <InputField
                  :input="''"
                  :error="error.inputfield"
                  :label="'Group name'"
                  @setInput="setGroupName"
                />
                <template v-if="isLoaded">
                  <SelectTables
                    :tables="availableTables"
                    :selectedTables="selectedTables"
                    :error="error.selecttables"
                    @selected_tables="setSelectedTables"
                  />
                </template>
              </div>

              <Button :text="'Save'" @isClicked="save()" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/general/Components/2.0/TableManagement/General/Header2.vue"
import InputField from "@/general/Components/2.0/TableManagement/General/InputField.vue"
import Button from "@/general/Components/2.0/TableManagement/General/Button.vue"
import Title from "@/general/Components/2.0/CategoryManagement/General/Title.vue"

import SelectTables from "@/general/Components/2.0/TableManagement/NewGroup/SelectTables.vue"

import TablesRepository from "@/general/Objects/TablesRepository"
import ArrayRepository from "@/general/Objects/ArrayRepository"
import BranchRepository from "@/general/Objects/BranchRepository"
import GroupsRepository from "@/general/Objects/GroupsRepository"

import BackgroundLayer from "@/general/Components/2.0/General/BackgroundLayer.vue"

export default {
  components: {
    Header,
    InputField,
    Button,
    SelectTables,
    BackgroundLayer,
    Title,
  },
  data: () => {
    return {
      groupName: "",
      selectedTables: [],
      availableTables: [],

      label: "New Group",
      isLoaded: false,
      error: {
        inputfield: {
          show: false,
          message: "You didn't gave a name!",
        },
        selecttables: {
          show: false,
          message: "You didn't select any tables!",
        },
      },
    }
  },

  async created() {
    this.availableTables = await this.getNotUsedTables()

    this.isLoaded = true
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    colors: function () {
      return this.store.client_data.color
    },
    tables: {
      get() {
        return this.store.tables.tableCollection
      },
      set(value) {
        this.store.tables.tableCollection = value
      },
    },
  },

  methods: {
    async save() {
      this.error.inputfield.show = false
      this.error.selecttables.show = false
      if (this.group) {
        if (this.groupName == null || this.groupName == "") {
          this.error.inputfield.show = true
          return
        } else {
          this.error.inputfield.show = false
        }
        if (this.selectedTables == null || this.selectedTables.length <= 0) {
          this.error.selecttables.show = true
          return
        } else {
          this.error.selecttables.show = false
        }

        const groupRepo = new GroupsRepository(this.group.id)
        await groupRepo.removeAllTables()
        await groupRepo.addTables(this.selectedTables)
      } else {
        if (this.groupName == null || this.groupName == "") {
          this.error.inputfield.show = true
          return
        } else {
          this.error.inputfield.show = false
        }
        if (this.selectedTables == null || this.selectedTables.length <= 0) {
          this.error.selecttables.show = true
          return
        } else {
          this.error.selecttables.show = false
        }

        const branchRepo = new BranchRepository()
        await branchRepo.addGroup(this.groupName, this.selectedTables)
      }

      this.closeWindow()
    },
    setGroupName(name) {
      this.groupName = name
    },
    closeWindow() {
      this.$router.push({ name: "AllGroups" })
    },

    setSelectedTables(table_ids) {
      this.selectedTables = table_ids
    },

    async getNotUsedTables() {
      var array = []
      const tableRepo = new TablesRepository()
      const tables = await tableRepo.getAllTables()

      for (var i = 0; i < tables.length; i++) {
        if (
          tables[i].group == 0 ||
          (await new ArrayRepository(tables[i].group).isEmpty())
        ) {
          array.push(tables[i])
        }
      }

      return array
    },
  },
}
</script>

<style scoped></style>
