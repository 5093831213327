<template>
  <div>
    <template v-if="!isSuccess && !isFailure">
      <div class="background mozo">
        <Header />
        <div class="page-body-2 center">
          <div class="form-div">
            <h1 class="heading1">{{ title }}</h1>
            <p class="paragraph">{{ description }}</p>

            <div class="form-block marge w-form">
              <div>
                <div class="paragraph left">{{ field_names.password }}</div>
                <input
                  @change="checkPassword()"
                  :style="{ 'border-color': border.password1 }"
                  ref="password1"
                  type="password"
                  class="text-field marge-between w-input"
                  :maxlength="input_length.password"
                  :placeholder="placeholders.password"
                  required="true"
                  autocorrect="off"
                  autocapitalize="off"
                />
                <div class="div-block-59">
                  <div class="div-block-60">
                    <img
                      v-if="(error.password_min === '') & (success.password_min === '')"
                      src="images/check-white.svg"
                      loading="lazy"
                      class="image-26"
                    />
                    <img
                      v-if="error.password_min !== ''"
                      src="images/close-01.svg"
                      loading="lazy"
                      class="image-26"
                    />
                    <img
                      v-if="success.password_min !== ''"
                      src="images/check.svg"
                      loading="lazy"
                      class="image-26"
                    />
                    <div class="message">at least 8 characters</div>
                  </div>
                  <div class="div-block-60">
                    <img
                      v-if="
                        (error.password_digit === '') & (success.password_digit === '')
                      "
                      src="images/check-white.svg"
                      loading="lazy"
                      class="image-26"
                    />
                    <img
                      v-if="error.password_digit !== ''"
                      src="images/close-01.svg"
                      loading="lazy"
                      class="image-26"
                    />
                    <img
                      v-if="success.password_digit !== ''"
                      src="images/check.svg"
                      loading="lazy"
                      class="image-26"
                    />
                    <div class="message">contains one digit</div>
                  </div>
                  <div class="div-block-60">
                    <img
                      v-if="
                        (error.password_lower === '') & (success.password_lower === '')
                      "
                      src="images/check-white.svg"
                      loading="lazy"
                      class="image-26"
                    />
                    <img
                      v-if="error.password_lower !== ''"
                      src="images/close-01.svg"
                      loading="lazy"
                      class="image-26"
                    />
                    <img
                      v-if="success.password_lower !== ''"
                      src="images/check.svg"
                      loading="lazy"
                      class="image-26"
                    />
                    <div class="message">contains one lower character</div>
                  </div>
                  <div class="div-block-60">
                    <img
                      v-if="
                        (error.password_upper === '') & (success.password_upper === '')
                      "
                      src="images/check-white.svg"
                      loading="lazy"
                      class="image-26"
                    />
                    <img
                      v-if="error.password_upper !== ''"
                      src="images/close-01.svg"
                      loading="lazy"
                      class="image-26"
                    />
                    <img
                      v-if="success.password_upper !== ''"
                      src="images/check.svg"
                      loading="lazy"
                      class="image-26"
                    />
                    <div class="message">contains one UPPER case character</div>
                  </div>
                </div>
                <div class="paragraph left">
                  {{ field_names.repeat_password }}
                </div>
                <input
                  v-on:keyup.enter="next_page"
                  @change="checkPass2()"
                  :style="{ 'border-color': border.password2 }"
                  ref="password2"
                  type="password"
                  class="text-field marge-between w-input"
                  :maxlength="input_length.password"
                  :placeholder="placeholders.password"
                  required="true"
                  autocorrect="off"
                  autocapitalize="off"
                />
                <div class="div-block-59">
                  <div class="div-block-60">
                    <img
                      v-if="
                        (error.password_equal === '') & (success.password_equal === '')
                      "
                      src="images/check-white.svg"
                      loading="lazy"
                      height=""
                      class="image-26"
                    />
                    <img
                      v-if="error.password_equal !== ''"
                      src="images/close-01.svg"
                      loading="lazy"
                      height=""
                      class="image-26"
                    />
                    <img
                      v-if="success.password_equal !== ''"
                      src="images/check.svg"
                      loading="lazy"
                      height=""
                      class="image-26"
                    />
                    <div class="message">passwords are equal</div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <a
                @click="savePassword"
                class="submit-button w-button"
                style="margin-bottom: 10%"
                >Save password</a
              >
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="isSuccess">
      <div class="background mozo pop-up succeeded">
        <Header />
        <div class="page-body-2 center">
          <div class="pop-up-block width-cap">
            <img src="images/thumbs-up.png" loading="lazy" class="image-10" />
            <div class="text-block pop-up">
              <p class="text">Succeeded!</p>
              <p class="paragraph">
                You have succesfully reset your password.<br /><br />From now on use this
                password to login to your mozo account.
              </p>
              <div class="div-block-67" style="margin-top: 5%">
                <a href="/login" class="submit-button w-button">Go back to login</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="!isSuccess">
      <div class="background mozo pop-up failed">
        <Header />
        <div class="page-body-2 center">
          <div class="pop-up-block width-cap">
            <img src="images/sad-face.png" loading="lazy" class="image-10" />
            <div class="text-block pop-up">
              <p class="text">Oh something went wrong</p>
              <p class="paragraph">
                Check if you copied the correct link!<br />Otherwise , try to reset your
                password again<br />
              </p>
              <div class="div-block-67">
                <a @click="tryAgain()" class="submit-button orange w-button"
                  >Reset password</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// DB
import db_auth from "@/db/2.0/auth.js"

// general
import json from "@/general/GlobalValues.json"

// helpers
import validate from "@/general/HelperMethods/Validate.js"

// in elements 1.0
import Header from "@/general/Elements/2.0/Headers/Header1.vue"

export default {
  components: {
    Header,
  },

  data() {
    return {
      title: "Set a new password!",
      description:
        "Please, think of a new password of your account! And don't forget it!",

      isSuccess: false,
      isFailure: false,

      field_names: json["field_names"],
      input_length: json["input_length"],
      placeholders: json["placeholders"],

      deleteCheck: false,

      password1: "",
      password2: "",

      border: {
        old: "",
        password_min: "",
        password_upper: "",
        password_lower: "",
        password_digit: "",
        password_equal: "",
      },

      error: {
        old: "",
        password_min: "",
        password_upper: "",
        password_lower: "",
        password_digit: "",
        password_equal: "",
      },

      success: {
        old: "",
        password_min: "",
        password_upper: "",
        password_lower: "",
        password_digit: "",
        password_equal: "",
      },
    }
  },
  created() {
    // console.log(this.$route.query.oobCode)
  },
  methods: {
    setDelete(value) {
      this.deleteCheck = value
    },

    async checkPassword() {
      this.password1 = this.$refs.password1.value

      var password_check = await validate.password(this.$refs.password1.value)

      var isCorrect = await this.validatePass1(password_check)

      await this.checkPass2()

      return isCorrect
    },

    async validatePass1(password_check) {
      var password = true
      if (password_check.includes("upper")) {
        this.error.password_upper = "The password doesnt contain upper"
        this.success.password_upper = ""
        password = false
      } else {
        this.error.password_upper = ""
        this.success.password_upper = "ys"
      }

      if (password_check.includes("lower")) {
        this.error.password_lower = "The passwords doesnt contain lower"
        this.success.password_lower = ""
        password = false
      } else {
        this.error.password_lower = ""
        this.success.password_lower = "ys"
      }

      if (password_check.includes("min")) {
        this.error.password_min = "err"
        this.success.password_min = ""
        password = false
      } else {
        this.error.password_min = ""
        this.success.password_min = "yes"
      }

      if (password_check.includes("digit")) {
        this.error.password_digit = "err"
        this.success.password_digit = ""
        password = false
      } else {
        this.error.password_digit = ""
        this.success.password_digit = "yes"
      }

      if (password) {
        this.border.password1 = "#00c9aa"
        return true
      } else {
        this.border.password1 = "#ff0000"
        return false
      }
    },

    async checkPass2() {
      this.password2 = this.$refs.password2.value
      var password1 = this.$refs.password1.value
      var password2 = this.$refs.password2.value

      if (password2 === "") {
        return false
      } else if (password1 !== password2) {
        this.error.password_equal = "The passwords are not equal!"
        this.border.password2 = "#ff0000"
        this.success.password_equal = ""

        return false
      } else {
        this.error.password_equal = ""
        this.border.password2 = "#00c9aa"
        this.success.password_equal = "yes"
      }

      return true
    },

    async savePassword() {
      if (this.$route.query.oobCode) {
        if (await this.checkVariables()) {
          const code = this.$route.query.oobCode

          this.isSuccess = await db_auth.resetPassword(code, this.password1)
          if (!this.isSuccess) {
            this.isFailure = true
          }
        }
      } else {
        this.isFailure = true
      }
    },

    tryAgain() {
      this.isSuccess = false
      this.isFailure = false
    },

    async checkVariables() {
      if (!(await this.checkPassword())) {
        return false
      }

      if (!(await this.checkPass2())) {
        return false
      }

      return true
    },
  },
}
</script>

<style scoped>
.page-body-2 {
  width: 100%;
}
</style>
