<template>
  <div>
    <template v-if="menu">
      <AccountMenu />
    </template>
    <template v-else-if="isLoaded">
      <BackgroundLayer :header_title="'All Tables'" :showLock="false" />
      <template v-if="isLoaded">
        <Rows :rows="tables" :lock="lock" />
      </template>
    </template>
    <template v-else>
      <WaitPage />
    </template>
  </div>
</template>

<script>
import BackgroundLayer from "@/general/Components/2.0/TableManagement/General/Header.vue"

import AddButton from "@/general/Components/2.0/TableManagement/General/Button.vue"
import Rows from "@/general/Components/2.0/TableManagement/AllTables/Rows.vue"

import AccountMenu from "@/views/Menu/LoggedIn.vue"

import InitiateTables from "@/controller/brancheAndTables"

import WaitPage from "@/general/Elements/2.0/OtherElements/Popups/WaitPage.vue"
import methods from "@/general/HelperMethods/General.js"

export default {
  components: {
    AddButton,
    BackgroundLayer,
    Rows,
    AccountMenu,
    WaitPage,
  },

  data: () => {
    return {
      lock: true,
      tables: [],
      isLoaded: false,
    }
  },

  async created() {
    const initiatePage = new InitiateTables(this)
    this.tables = await initiatePage.getAllTables()
    this.tables = this.tables.sort(function (a, b) {
      return a.tableNumber - b.tableNumber
    })
    this.isLoaded = true
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    menu: function () {
      return this.store.environment.menu
    },
    client_data: function () {
      return this.store.client_data
    },
  },

  methods: {
    isClicked() {
      console.log("add group")
    },
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2)
    },
  },
}
</script>

<style scoped></style>
