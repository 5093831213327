import Vue from "vue";
import App from "./App.vue";
import store from "./vuex/setup";
import router from "./router";
import VueGtag from "vue-gtag";
import VueClipboard from "vue-clipboard2";
import axios from "axios";
import VueAxios from "vue-axios";

const url = window.location.href.split("/").pop()?.split("?")[0]
const redirecturl_silverstone = ["dFfyqXEWaIrWig", "6uPncGN116fqUK", "hC9svT8hO2xGaO", "znhDocHawYvkLV", "skHcOa2rUBJ67P", "5AVixD1jxVxdzk", "xJ8WFpFuEgwD2u", "hutxnG9p8JL9mG", "j3Vagi94jPy8CY", "h29g8hnGLPD7h0", "jW2SXhqDsYyR0t", "bGGxFkgoVh42nT", "YXIrFEHaBiVfOY", "rOIZwlHu2gLq1J", "7kjbDugvwysogv", "8nLdwlOFFHP4ls", "qyKQsD2S1pFAUY", "aul9VwJELorAKG", "0VKDfGRp61TiVT", "ZI7PCluk36BBIt", "ddHkpLxMIJ3NEG", "7PWkE5I4V0zSHL", "zF97fCjXrqkqQD", "7qwfx7jeaKdxAw", "GT0RzSh1XQoHmW", "9gxw0AxA9Wej4Q", "LCCKmenlwOGySN", "fXEkSczO2zVD0T", "g8ZfVzxh64113T", "2v9p2fDcHsakhF", "kh3gn8Xvl1i4rl", "HlxNsLGBGEnVsI", "3yAVpRQDdmx7sz", "bH1qKp2cgN9yaD", "34HlSmJKJplC7V", "6mWSqAFcYYvCiW", "yIPwpGKpgd7E7R", "eVq6lmmY6D43mn", "pMwLCFqWkIowpR", "HvzKyhmChLtuvq", "iD7gmQjx8LfI7M", "6Au2prgUcbAkhM"]
const redirecturl_fest: any = [
  "xfy6oPWb9el389",
  "PZ6o3tETfECU8Z",
  "GX4LsAMrMnGZhV",
  "hMQHre4ThihLWN",
  "7LH3UhlwhULTZd",
  "CETrEn5NjzbViW",
  "EjW8CqZyAomR2Z",
  "uN7e3Eg4nf3sFP",
  "His9jP37g6bZon",
  "L2SSibXkfDmjfF",
  "Ey2b1yoAB39pUV",
  "z1lNxaQhBxRciW",
  "PujwP7Py4PAZLF",
  "TE2HEaYrRZilXB",
  "7pilOGqnfQKgbe",
  "aWBujATsrXcs5d",
  "MIKTInGqxtPcfV",
  "v7EXEfjHrxB6OR",
  "hBa5Gb4Z9l8Ut5",
  "J7uIeEyMdvHfOd",
  "A6YVBqwNleNxBo",
  "rLPwLnCVVESVoa",
  "5bfjwqqGSNJYIK",
  "FQ488Dj1axXXEm",
  "NiAuNFSd3KggFe",
  "fHdTqDVSWyKENe",
  "CJ1HhYXbKA5u7d",
  "1EFA7jmsygtalz",
  "9sgTXC0aNs5rhv",
  "JxZOKkveQyeTu7",
  "FjAO5aBpAfsYlQ",
  "Af7huvXtSaiObb",
  "zskhruVLv2QaSH",
  "uJX6YRA63NfvNU",
  "xMJOIcVlJzDbg6",
  "Qxd1Aswg8LSQ0H",
  "lmwlV0iSDvY8G3",
  "WD7b7kkVJaWHgY",
  "qVEWkR9jwoVasH",
  "i79CUYdFsH3uOT"
]

const redirect_bisonbowling = [
  "F0W23Ph2xqobFM",
  "aSf0bJABEMeii7",
  "73kjimqkebR8nX",
  "S9sL4KY1JwAfmR",
  "Bx7X93j8SW4YYv",
  "lAd8uY9Z0qDDOO",
  "XR3vuWLVYJ8oZE",
  "bnN426ppo6GGwk",
  "FnbBvPzrchDZ21",
  "VgcbU2wjAwj4qi",
  "pD3xgV0XCrCj6B",
  "19zMaoJS1FWQKP"
]

if (url && 
  (redirecturl_silverstone.includes(url) 
  || (redirecturl_fest.includes(url)))
 ) {
  window.open("https://guestpage.themozo.app/" + url + "?qr=true", "_self")

} else if (url && (redirect_bisonbowling.includes(url))) {
  window.open("https://arrangementorder.themozo.app/" + url, "_self")

} else {
  Vue.use(VueGtag, {
    config: {
      id: "G-955NLXRSY7",
    },
  });


  VueClipboard.config.autoSetContainer = true;
  Vue.use(VueClipboard);


  Vue.use(VueAxios, axios);

  Vue.config.productionTip = false;

  new Vue({
    el: "#app",
    router,
    store,
    render: (h) => h(App),
  });

}


