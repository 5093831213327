<template>
  <div>
    <div
      class="background menu height"
      :style="
        'background:' +
        'linear-gradient(311deg, ' +
        color[0] +
        ',' +
        color[1] +
        ');'
      "
    >
      <div class="content">
        <div>
          <div class="header-column w-row">
            <div class="left-col menu w-col w-col-9">
              <LeftHeader />
            </div>
            <div class="right-col close-add-tile w-col w-col-3">
              <div class="settings-div">
                <div @click="handleClose()" class="link-block w-inline-block">
                  <img
                    src="/images/close-white.png"
                    loading="lazy"
                    class="settings-image"
                  />
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top: 5%" class="menu-upper">
            <a v-if="isAdmin" @click="goPage" class="menu-items">
              <img
                src="/images/home.svg"
                loading="lazy"
                alt=""
                class="menu-icon"
              />
              <h1 class="heading-3 white left">Home</h1>
            </a>
            <a v-if="isAdmin" @click="goAccount" class="menu-items">
              <img
                src="/images/icon-account.svg"
                loading="lazy"
                class="menu-icon"
              />
              <h1 class="heading-3 white left">Account</h1>
            </a>
            <a
              v-if="isAdmin && !showOnboarding"
              @click="goFriends"
              class="menu-items"
            >
              <img
                src="/images/friends.svg"
                loading="lazy"
                alt=""
                class="menu-icon"
              />
              <h1 class="heading-3 white left">Friends of {{ client_name }}</h1>
            </a>


            <a v-if="showOnboarding" @click="goOnboarding" class="menu-items">
              <img src="/images/edit.svg" loading="lazy" class="menu-icon" />
              <h1 class="heading-3 white left">Onboarding</h1>
            </a>

            <a
              v-if="isAdmin && !showOnboarding"
              @click="goTables"
              class="menu-items"
            >
              <img src="/images/Tables.svg" loading="lazy" class="menu-icon" />
              <h1 class="heading-3 white left">Tables</h1>
            </a>


            <a
              v-if="isAdmin && !showOnboarding"
              @click="goTableGroups"
              class="menu-items"
            >
              <img
                src="/images/TableGroups.svg"
                loading="lazy"
                class="menu-icon"
              />
              <h1 class="heading-3 white left">Table Groups</h1>
            </a>
            <a
              v-if="isAdmin && showOnboarding"
              @click="goQRReader"
              class="menu-items"
            >
              <img
                src="/images/icon-qrcode.svg"
                loading="lazy"
                alt=""
                class="menu-icon"
              />
              <h1 class="heading-3 white left">QR Reader</h1>
            </a>

            <a @click="goQR" class="menu-items">
              <img
                src="/images/icon-qrcode.svg"
                loading="lazy"
                alt=""
                class="menu-icon"
              />
              <h1 class="heading-3 white left">QR code</h1>
            </a>
            <a v-if="isCurrentPage" @click="doCopy" class="menu-items">
              <img
                src="/images/icon-short-link.svg"
                loading="lazy"
                alt=""
                class="menu-icon"
              />
              <h1 class="heading-3 white left">Account link</h1>
            </a>

            <a @click="goCommunity" class="menu-items">
              <img
                src="/images/group.svg"
                loading="lazy"
                alt=""
                class="menu-icon"
              />
              <h1 class="heading-3 white left">Community</h1>
            </a>

            <template v-if="hideLogo">
              <a @click="goSignVisitor" class="menu-items">
                <img src="/images/edit.svg" loading="lazy" class="menu-icon" />
                <h1 class="heading-3 white left">Sign In Visitor</h1>
              </a>
              <a @click="goMeetingRoom" class="menu-items">
                <img src="/images/meeting.svg" loading="lazy" class="menu-icon" />
                <h1 class="heading-3 white left">Meeting Room</h1>
              </a>


              <a
                v-if="!isAdmin"
                href="https://adamand.co/over-ons/"
                class="menu-items"
              >
                <img
                  src="/images/information.svg"
                  loading="lazy"
                  class="menu-icon"
                />
                <h1 class="heading-3 white left">About us</h1>
              </a>

              <a
                v-if="!isAdmin"
                href="mailto:info@adamandco.nl"
                class="menu-items"
              >
                <img src="/images/mail.svg" loading="lazy" class="menu-icon" />
                <h1 class="heading-3 white left">Contact</h1>
              </a>
            </template>


            
          </div>
        </div>
        <div class="menu-bottom">
          <a @click="logout" class="menu-items _2">
            <img
              src="/images/icon-log-out.svg"
              loading="lazy"
              alt=""
              class="menu-icon small"
            />
            <h1 class="heading6 white left">Log out</h1>
          </a>
        </div>
      </div>
    </div>
    <template v-if="copy">
      <Copy
        text1="You copied the link of this page: "
        :text2="'themozo.app/' + this.client_data.url"
        :copy="true"
        @back="copy = false"
      />
    </template>
  </div>
</template>

<script>
// general
import db_auth from "@/db/2.0/auth.js";

// in elements 2.0
import LeftHeader from "@/general/Elements/2.0/Headers/LeftHeader.vue";
import Copy from "@/general/Elements/2.0/OtherElements/Popups/Alert.vue";

export default {
  components: {
    LeftHeader,
    Copy,
  },
  created() {},
  data() {
    return {
      copy: false,
    };
  },
  computed: {
    store: function () {
      return this.$store.state;
    },
    showOnboarding: function () {
      return (
        this.store.client_data.menuShowOnboarding &&
        this.store.user.uid == this.store.client_data.uid
      );
    },
    isAdmin: function () {
      return this.store.user.uid == this.store.client_data.uid;
    },

    hideLogo: function () {
      return this.store.client_data.hideMozoLogo;
    },

    menu: {
      get() {
        return this.store.environment.menu;
      },
      set(menu_state) {
        this.store.environment.menu = menu_state;
      },
    },
    showQR: {
      get() {
        return this.store.environment.showQR;
      },
      set(value) {
        this.store.environment.showQR = value;
      },
    },

    isCurrentPage: function () {
      return this.store.environment.isCurrentPage;
    },

    client_data: function () {
      return this.store.client_data;
    },
    config: {
      get() {
        return this.store.environment.config;
      },
      set(value) {
        this.store.environment.config = value;
      },
    },
    isEdit: {
      get() {
        return this.store.environment.isEdit;
      },
      set(value) {
        this.store.environment.isEdit = value;
      },
    },
    loggedIn: {
      get() {
        return this.store.environment.loggedIn;
      },
      set(value) {
        this.store.environment.loggedIn = value;
      },
    },

    client_name: function () {
      return this.store.client_data.nickname;
    },

    color: function () {
      return this.store.client_data.color;
    },

    user: {
      get() {
        return this.store.user;
      },
      set(value) {
        this.store.user = value;
      },
    },
  },
  methods: {
    doCopy() {
      this.copy = true;
    },
    async goCommunity() {
      const axios = require("axios");


      const data = {
        user_id: this.store.user.uid,
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://apiv2.themozo.app/mini/create-session",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      try {
        const response = await axios.request(config);

        const token = response.data.session_uuid;
        window.open(
          "https://community.themozo.app/" +
            "i4AH0ci2IfYZ61FcCIWe2kZA3mh2" +
            "?token=" +
            token,
          "_self"
        );
      } catch (error) {
        console.log(error);
      }
    },
    handleClose() {
      this.menu = false;
    },
    async logout() {
      this.user = await db_auth.logout();
      this.loggedIn = false;
      this.menu = false;
      this.config = false;
      this.isEdit = false;

      const route = window.location.href.split("/").pop();
      if (route == "alltables" || route == "allgroups" || route == "table") {
        this.$router.push("/" + this.client_data.url);
      }
    },

    goPage() {
      this.$router.push("/");
      this.$router.go("/");
    },

    goQRReader() {
      window.open("https://qr-reader.themozo.app/", "_self");

    },

    goSignVisitor() {
      window.open("https://adamtoren.nl/aanmelden-gasten/", "_self");
    },

    goAccount() {
      this.$router.push("/account");
      this.menu = false;
    },

    goQR() {
      this.$router.push("/qr-code");
      this.menu = false;
    },

    goProfile() {
      this.$emit("goProfile", true);
    },

    goOnboarding() {
      window.open("https://community.themozo.app/i4AH0ci2IfYZ61FcCIWe2kZA3mh2/admin", "_self");
    },

    goMeetingRoom() {
      window.open("https://integrations.themozo.app/reflex/i4AH0ci2IfYZ61FcCIWe2kZA3mh2", "_self")
    },

    goFriends() {
      this.$router.push({
        name: "friends",
        params: {
          input_userData: this.client_data,
        },
      });
      this.menu = false;
    },

    goTables() {
      if (window.location.href.split("/").pop() != "alltables") {
        this.$router.push({
          name: "AllTables",
          params: {
            store: this.store,
          },
        });
      }

      this.menu = false;
    },

    goTableGroups() {
      if (window.location.href.split("/").pop() != "allgroups") {
        this.$router.push({
          name: "AllGroups",
          ams: {
            store: this.store,
          },
        });
      }

      this.menu = false;
    },
  },
};
</script>

<style scoped>
.header-column {
  padding-right: 5%;
  padding-left: 0%;
  width: 100%;
  display: flex;
}
</style>
