import { render, staticRenderFns } from "./DeleteButton.vue?vue&type=template&id=68cef308&scoped=true&"
import script from "./DeleteButton.vue?vue&type=script&lang=js&"
export * from "./DeleteButton.vue?vue&type=script&lang=js&"
import style0 from "./DeleteButton.vue?vue&type=style&index=0&id=68cef308&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68cef308",
  null
  
)

export default component.exports