<template>
  <div class="div-block-6">
    <div class="header-column-new">
      <div class="logo-header">
        <img
          src="/images/left-arrow.svg"
          @click="close()"
          style="height: 60px; width: 60px; cursor: pointer; filter: invert(1)"
        />
      </div>
      <div class="center">
        <div class="logo-div">
          <div class="logo-img" :style="logostyle"></div>
        </div>
      </div>
      <div class="header-div"></div>
    </div>
  </div>
</template>

<script>
import LocalWidget from "@/general/Objects/localWidget.js"
import json from "@/general/GlobalValues.json"

export default {
  data: () => {
    return {
      brand: json["brand"],
      logostyle: "",
    }
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    view: {
      get() {
        return this.store.environment.view
      },
      set(value) {
        this.store.environment.view = value
      },
    },
    localWidget: {
      get() {
        return this.store.environment.localWidget
      },
      set(value) {
        this.store.environment.localWidget = value
      },
    },
  },
  created() {
    this.brand.logoblack = this.store.client_data.image

    this.logostyle =
      "background-image: url(" +
      this.brand.logoblack +
      ");" +
      "background-position: 50% 50%;" +
      "background-size: 100% auto;" +
      "background-repeat: no-repeat;"
  },
  methods: {
    close() {
      new LocalWidget().forgetLocalWidget()
    },
  },
}
</script>

<style scoped>
.logo-img {
  width: 75px;
  height: 75px;
  border-radius: 100%;
}

.frame-header {
  grid-template-columns: 1fr 1fr;
}

.logo-img {
  height: 60px;
  width: 60px;
  max-height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border-style: solid;
  border-color: #f4f4f4;
  border-width: 2px;
}

.small-img {
  width: 90px;
  height: 90px;
  border: 1px solid black;
}

.header-column-new {
  background-image: none;
  background-color: transparent;
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0% 5%;
  width: 100%;
  height: 100%;
}

.div-block-6 {
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-12 {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.header-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
