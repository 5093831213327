<template>
  <div class="configapp-div">
    <!-- <div style="cursor:pointer;" @click="goPreviousState"> {{ 'go back' }}</div> -->
    <template v-if="isImgWidget()">
      <ImgField
        :uid="user.uid"
        :info="tempItem"
        :isChange="widget_exists"
        :group="tempWidget.type"
        @returnImage="changeImage"
        @returnFile="setImageFile"
      />
    </template>

    <WidgetTitle
      :widget="tempItem"
      :isNewWidget="!widget_exists"
      :color="color"
      @changeTitle="changeTitle"
    />

    <div v-for="(selectfield, index) in tempItem.data" in :key="index">
      <ItemField
        :widget="tempItem.name"
        :field_input="selectfield"
        :options="options[index]"
        :isChange="widget_exists"
        :index="index"
        @changeOption="changeOption"
        @changeField="changeField"
        @submit="doSubmit"
      />
    </div>

    <template v-if="doRedirect && tempItem.data.length == 1">
      <Redirect button_text="preview" :info="tempItem" :fields="tempItem.data" />
    </template>

    <div class="div-block-45">
      <a @click="doSubmit" class="submit-button w-button">{{ button }}</a>
      <template v-if="error !== ''">
        <Error :error="error" />
      </template>
    </div>

    <template v-if="changeWidget != null">
      <div @click="deleteWidget()" class="delete-app w-inline-block">
        <div class="icon-title delete">delete widget</div>
      </div>
    </template>
  </div>
</template>

<script>
import { local_widgets } from "@/general/Elements/2.0/OtherElements/Widgets/widget_media.js"

import WidgetTitle from "@/general/Elements/2.0/OtherElements/Widgets/WidgetTitle.vue"
import ItemField from "@/general/Elements/2.0/OtherElements/Widgets/NewWidgetField.vue"
import Redirect from "@/general/Elements/2.0/OtherElements/Widgets/Redirect.vue"
import ImgField from "@/general/Elements/2.0/OtherElements/Widgets/ImgField.vue"
import Error from "@/general/Elements/2.0/OtherElements/Error-messages/StandardError.vue"
import methods from "@/general/HelperMethods/General.js"

import TablesRepository from "@/general/Objects/TablesRepository"

import db_storage from "@/db/2.0/storage.js"
import ArrayRepository from "@/general/Objects/ArrayRepository"

import db_table from "@/db/2.0/TableManagement/TablePublic"

export default {
  components: {
    WidgetTitle,
    ItemField,
    Error,
    Redirect,
    ImgField,
  },

  props: ["button", "widget", "options", "widget_exists", "color"],

  data: () => {
    return {
      // widget: {
      //   item: {
      //     uid: null,
      //     title: null,
      //     img: null,
      //     local: false
      //     },
      //     data: [
      //         {
      //           value: '',
      //           option: '',
      //           index: 0,
      //           link: null //computed property
      //         }
      //     ]

      // },

      doRedirect: false,

      isLoaded: false,

      error: "",
      state: 0,

      file: null,

      tempWidget: null,
    }
  },

  created() {
    this.tempWidget = methods.deepcopy(this.widget)
    this.doRedirect = this.showRedirect()
  },

  computed: {
    fields() {
      return this.widget.item.data
    },
    tempItem: {
      get() {
        return this.tempWidget.item
      },
      set(value) {
        this.tempWidget.item = value
      },
    },

    store: function () {
      return this.$store.state
    },
    widgets: function () {
      return this.store.widgets
    },

    user: function () {
      return this.store.user
    },

    tableID: function () {
      return this.store.environment.tableID
    },
    tables: function () {
      return this.store.tables.tableCollection
    },
    changeWidget: {
      get() {
        return this.store.environment.editTableWidget
      },
      set(value) {
        this.store.environment.editTableWidget = value
      },
    },
    setWidget: {
      get() {
        return this.store.environment.addTableWidget
      },
      set(value) {
        this.store.environment.addTableWidget = value
      },
    },
  },

  methods: {
    closeWindow(value) {
      this.$emit("state", value)
    },
    changeOption(option) {
      this.tempItem.data[option.index].name = option.name
    },

    changeField(field) {
      this.tempItem.data[field.index].field = field.field

      this.error = methods.emptyFieldError(field.field, field.option, false)
      this.doRedirect = this.showRedirect()
    },

    changeImage(value) {
      if (this.tempItem.image != value) {
        this.tempItem.image = value
      }
    },

    changeTitle(value) {
      if (this.tempItem.title != value) {
        this.tempItem.title = value
      }
    },

    async checkTitle() {
      if (this.tempItem.title == "") {
        this.tempItem.title = await methods.getWidgetTitle(this.tempItem.name)
      }
    },

    async showRedirect() {
      if (local_widgets.includes(this.tempItem.name)) {
        return false
      } else if (await methods.isFieldEmpty(this.tempItem.data)) {
        return false
      }

      return true
    },

    async deleteWidget() {
      const tableRepo = new TablesRepository()
      const table = (await tableRepo.getAllTables([this.tableID]))[0]
      const widgets = table.widgets
      const array = new ArrayRepository(widgets)
      await array.removeItems([this.changeWidget], "uid")

      const newWidgets = await array.getAllItems()

      await db_table.updateTable(
        this.store.user.uid,
        "b1",
        this.tableID,
        "widgets",
        newWidgets
      )

      this.changeWidget = null
    },

    async doSubmit() {
      this.error = ""
      for (var i = 0; i < this.tempItem.data.length; i++) {
        if (
          this.tempItem.data[i].field == null ||
          this.tempItem.data[i].field.replace(/\s/g, "").length <= 0
        ) {
          this.error = "You forgot to fill in an inputfield!"
          return
        }
      }

      if (
        this.tempItem.title == null ||
        this.tempItem.title.replace(/\s/g, "").length <= 0
      ) {
        this.error = "You forgot to fill in a title!"
        return
      }

      if (this.file != null) {
        this.tempItem.image = await db_storage.addProfilePicture(
          this.user.uid,
          this.file,
          this.tempWidget.type,
          this.tempItem.position,
          this.tableID
        )
      }

      if (this.changeWidget == null) {
        const tableRepo = new TablesRepository()
        await tableRepo.addProperties(
          [this.tableID],
          [{ key: "widgets", value: [this.tempItem] }]
        )
        this.setWidget = false
      } else {
        const tableRepo = new TablesRepository()

        const table = (await tableRepo.getAllTables([this.tableID]))[0]
        const widgets = table.widgets
        const array = new ArrayRepository(widgets)
        // const widget = (await array.getItems([this.changeWidget], "uid"))[0];

        await array.updateItems([this.changeWidget], this.tempItem, "uid")

        const newWidgets = await array.getAllItems()
        await db_table.updateTable(
          this.store.user.uid,
          "b1",
          this.tableID,
          "widgets",
          newWidgets
        )

        this.changeWidget = null
      }

      // this.$emit("returnItem", true);
    },

    isImgWidget() {
      if (this.tempWidget.type == "image_widgets") {
        return true
      } else if (this.tempWidget.type == "banner") {
        return true
      } else {
        return false
      }
    },

    setImageFile(file) {
      this.file = file
    },

    goPreviousState() {
      this.$emit("state", 1)
    },
  },
}
</script>
