<template>
  <div class="background whitebg" style="height:100vh;width:100vw;background-color: white;">

    <div class="page-body-2 full">
      <div class="pop-up-block">
        <div id="icon-container" class="lottie-animation">
          <lottie-animation path="logo_animation.json" />
        </div>
        <div class="text-block" style="z-index:9">
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  components: {
    LottieAnimation
  },

  created() {

  },
};
</script>


