import Main from "@/controller/main"
import Account from "@/controller/account"
import SetWidget from "@/controller/setWidget"
import EditTableWidget from "@/controller/editTableWidgets"

import store from "@/vuex/setup"

export default class Loader {
    constructor() {

    }

    async import_data(page: string, path: string): Promise<string> {

        const pageObject = await this.importViewData(page, path);

        path = await this.rememberOnReload(path, pageObject)

        return path;

    }

    importViewData(page: string, path: string): any {
        var pageObject = null;

        if (page == "Main") {
            pageObject = new Main(path)

        } else if (page == "Account") {
            pageObject = new Account(path)
        } else if (page == "SetWidget") {
            pageObject = new SetWidget();
        } else if (page == "EditTableWidget") {
            pageObject = new EditTableWidget();
        }

        return pageObject;
    }

    async rememberOnReload(path: string, pageObject: any): Promise<string> {

        if (store.state.environment.dbSynced == true) {
            localStorage.setItem('store', JSON.stringify(store.state))
        }

        if (!store.state.environment.dbSynced && localStorage.getItem('store') != null) {
            store.replaceState(JSON.parse(localStorage.getItem('store')!))
        }

        if (pageObject != null) {
            path = await pageObject.setPage()
        }

        return path;
    }
}
