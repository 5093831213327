<template>
  <div class="vv-login-buttons">
    <div style="width:100%">
      <div @click="loginWithFacebook()" class="button-popup white w-inline-block"><img src="images/facebook.svg"
          loading="lazy" class="vv-login-logo" />
        <div class="text-block-fat">Inloggen met Facebook</div>

      </div>
      <p v-if="error.fb != ''" class="error">{{ error.fb }}</p>
    </div>

    <div style="width:100%">
      <div @click="loginWithGoogle()" class="button-popup white w-inline-block"><img src="images/google.svg"
          loading="lazy" class="vv-login-logo" />
        <div class="text-block-fat">Inloggen met Google</div>

      </div>
      <p v-if="error.google != ''" class="error">{{ error.google }}</p>
    </div>
  </div>
</template>

<script>
import auth from "@/db/2.0/auth.js"
import methods from "@/general/HelperMethods/General.js";

export default {
  data: () => {
    return {
      error: {
        fb: "",
        google: ""
      }
    }
  },
  created() {

  },
  computed: {
    store: function () {
      return this.$store.state;
    },
    addUser: {
      get() {
        return this.store.environment.addUser;
      },
      set(value) {
        this.store.environment.addUser = value;
      }
    },
    contacts: {
      get() {
        return this.store.friends.contacts;
      },
      set(value) {
        this.store.friends.contacts = value;
      }
    }
  },
  methods: {

    async loginWithFacebook() {
      const user = await auth.loginFB();
      if (user) {
        await this.checkEmail(user, 'fb');
      }
    },
    async loginWithGoogle() {
      const user = await auth.loginGoogle();
      if (user) {
        await this.checkEmail(user, 'google');
      }

    },
    async checkEmail(user, provider) {
      if (!await methods.checkEmailExists(this.contacts, user.email)) {
        this.addUser.view = 'name'
        this.$router.push({
          name: 'addFriend',
          params: {
            input_user: this.addUser
          }
        })

      } else {
        if (provider == 'fb') {
          this.error.fb = "You are already friends!"
        } else if (provider == 'google') {
          this.error.google = "You are already friends!"
        }

      }

    }
  }
};
</script>

<style scoped>
.text-block-fat {
  cursor: pointer
}

.error {
  color: #116c5b;
  font-weight: 500;
  padding: 6px 20px 6px 20px;
  background-color: white;
  border-radius: 6px;
  margin-top: 5%;

}
</style>
