<template>
  <div class="field-div-collection">
    <div class="field-div">
      <h2 class="label">{{ label }}</h2>
      <div class="input-text-div">
        <input
          :placeholder="placeholder"
          :value="input"
          class="input-text"
          @keyup="changeInput"
          ref="inputfield"
        />
        <img src="/images/draw.png" loading="lazy" class="pencil-icon opacity50" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["input", "label", "index", "placeholder"],
  methods: {
    changeInput() {
      this.$emit("setInput", this.$refs.inputfield.value, this.label, this.index)
    },
  },
}
</script>

<style scoped>
input {
  outline: none;
  border-width: 0px;
  width: 100%;
}

/* .input-text {
  text-transform: capitalize;
} */
</style>
