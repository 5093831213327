export default {
    dbSynced: false,
    isUserB2C: false,
    tableID: null,
    stateTableWidget: 1,
    editTableWidget: null,
    addTableWidget: false,
    config: false,
    loggedIn: false,
    isEdit: false, //isEditView
    menu: false,
    alertios: false,
    setWidget: {
        item: {
            account_uid: null,
            action: null,
            category: null,
            image: null,
            name: null,
            type: null,
            widget_uid: null
        },
    },
    setAccount: {
        item: {
            action: 'new',
            data: [
                {
                    index: 0,
                    field: "",
                    name: "",
                },
            ]
        }

    },

    localWidget: {
        item: null,
        view: null,
    },
    colorChanged: false,

    contacts: {
        selected: [],
        state: null,
        clicked: false,
    },

    addUser: {
        user: null,
        properties: {
            firstname: null,
            lastname: null,
        },
        view: "login",
        profile_url: "/test",
        remembered: false,
        color: [],
        name: null,
        popup: false,
    },

    data: {
        url: null,
        uid: null,
    },

    showQR: false,
    hidePopUp: false,
    view: "load",
    isCurrentPage: false,
}
