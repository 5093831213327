<template>
  <div>
    <template v-if="menu">
      <AccountMenu />
    </template>
    <template v-else-if="showPage">
      <div class="section-wcategories">
        <BackgroundLayer :color1="colors[0]" :color2="colors[1]" />
        <div class="front-layer">
          <Header :header_title="''" :showLock="false" />
          <div class="scroll-area">
            <div class="white-background2">
              <div class="default-width full-length">
                <div class="default-width">
                  <Title :showback="'AllTables'" :text="'Edit ' + table.tableName" />
                  <div class="inputfield-div">
                    <InputField
                      :input="table.tableName"
                      :error="error.inputfield"
                      :label="'Table name'"
                      @setInput="setTableName"
                    />

                    <template v-if="default_groupName != null">
                      <SelectField
                        :label="'Group'"
                        :items="groups"
                        :property="'groupName'"
                        :default="default_groupName"
                        @setInput="setGroup"
                      />
                    </template>
                  </div>

                  <Widgets />
                </div>
                <Button :text="'Save'" @isClicked="save()" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Header from "@/general/Components/2.0/TableManagement/General/Header2.vue"
import Title from "@/general/Components/2.0/CategoryManagement/General/Title.vue"
import InputField from "@/general/Components/2.0/TableManagement/General/InputField.vue"
import Button from "@/general/Components/2.0/TableManagement/General/Button.vue"

import SelectField from "@/general/Components/2.0/TableManagement/General/SelectField.vue"
import Widgets from "@/general/Components/2.0/TableManagement/EditTable/Widgets.vue"
import Widget from "@/general/Components/2.0/TableManagement/EditWidget/Main.vue"

import AccountMenu from "@/views/Menu/LoggedIn.vue"

import PopUp from "@/general/Components/2.0/TableManagement/EditTable/PopUp.vue"

import BrancheRepository from "@/general/Objects/BranchRepository.ts"
import ArrayRepository from "@/general/Objects/ArrayRepository.ts"

import GroupsRepository from "@/general/Objects/GroupsRepository"
import TablesRepository from "@/general/Objects/TablesRepository"

import InitiateTables from "@/controller/brancheAndTables"

import BackgroundLayer from "@/general/Components/2.0/General/BackgroundLayer.vue"

export default {
  components: {
    Header,
    Title,
    InputField,
    Button,
    SelectField,
    Widgets,
    AccountMenu,
    Widget,
    PopUp,
    BackgroundLayer,
  },
  data: () => {
    return {
      showPage: false,
      groupNames: [],
      default_groupName: null,
      tableName: "",
      group_id: "",
      groups: [
        { groupName: "No Group Selected", active: true, id: "default", position: -1 },
      ],
      error: {
        inputfield: {
          show: false,
          message: "You didn't provide a correct table name!",
        },
      },
    }
  },

  async created() {
    this.tableID = window.location.href.split("/").pop()
    const tableObject = new TablesRepository()
    this.table = (await tableObject.getTables([this.tableID]))[0]
    this.tableName = this.table.tableName

    const brancheRepo = new BrancheRepository("b1")
    const array = new ArrayRepository(this.groups)
    this.groups = await array.addItems(await brancheRepo.getAllGroups())
    if (this.table.group != null && this.table.group.length > 0) {
      this.default_groupName = (await array.getItems(this.table.group))[0].groupName
    } else {
      this.default_groupName = "No Group Selected"
    }

    this.showPage = true
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    colors: function () {
      return this.store.client_data.color
    },

    tables: {
      get() {
        return this.store.tables.tableCollection
      },
      set(value) {
        this.store.tables.tableCollection = value
      },
    },
    tableID: {
      get() {
        return this.store.environment.tableID
      },
      set(value) {
        this.store.environment.tableID = value
      },
    },
    menu: function () {
      return this.store.environment.menu
    },
    changeWidget: function () {
      return this.store.environment.editTableWidget
    },
    setWidget: function () {
      return this.store.environment.addTableWidget
    },
  },
  methods: {
    async save() {
      const table = new TablesRepository()

      if (this.tableName == null || this.tableName == "") {
        this.error.inputfield.show = true
        return
      } else {
        this.error.inputfield.show = false
      }

      // set tableName
      if (this.tableName != "") {
        await table.addProperties(
          [this.table.id],
          [{ key: "tableName", value: this.tableName }]
        )
      }

      // set groupName
      if (this.group_id != "") {
        var group = new GroupsRepository(this.table.group[0])
        await group.removeTables([this.table.id])

        group = new GroupsRepository(this.group_id)
        await group.addTables([this.table.id])
      }

      this.$router.push({
        name: "AllTables",
      })
    },
    setTableName(name) {
      this.tableName = name
    },

    goToAllTAbles() {
      this.$router.push({
        name: "AllTables",
      })
    },
    setGroup(value) {
      this.group_id = value.id
    },

    closeWindow() {
      this.$router.push({
        name: "AllTables",
      })
    },
  },
}
</script>
