<template>
  <div class="account-div-wm" :class="account.bgColor" @click="accountClicked">
    <div class="social-div-account">
      <Icon
        :widget="widget_name"
        :colors_arr="widget_colors"
        :classInput="'social-icon'"
      />
    </div>
    <div class="account-details-div">
      <div v-for="(line, row_index) in account.data" :key="row_index" class="linerow">
        <h2 class="paragraph black-text">{{ line.name }}:</h2>
        <div class="max-width-100 max-height-20px">
          <h2 class="paragraph black-text margin-left">{{ line.field }}</h2>
        </div>
      </div>
    </div>

    <ChangeAccount
      :account="account"
      :bgClicked="bgClicked"
      @compClicked="setCompClicked"
      @showBox="setShowBox"
      @deleteAccount="deleteAccount"
    />
  </div>
</template>

<script>
import ChangeAccount from "./ChangeAccount.vue"
import Icon from "@/general/Elements/2.0/OtherElements/Widgets/WidgetIcon2.vue"

export default {
  components: { ChangeAccount, Icon },
  props: ["account", "widget_name", "bgClicked", "index"],
  data: () => {
    return {
      button_clicked: false,
      showBox: false,
      bgColor: "",
    }
  },
  created() {},
  computed: {
    store: function () {
      return this.$store.state
    },
    widget_colors: function () {
      return this.store.client_data.color[2].icons
    },
  },
  methods: {
    deleteAccount() {
      this.$emit("deleteAccount", true)
    },
    accountClicked() {
      this.button_clicked = true
      this.$emit("accountSelected", this.index)
    },

    setShowBox(value) {
      this.showBox = value

      if (value) {
        this.$emit("accountSelected", this.index)
      }

      if (this.button_clicked && !value) {
        this.$emit("accountSelected", this.index)
      }

      this.button_clicked = false
    },

    setCompClicked(value) {
      this.$emit("compClicked", value, this.index)
    },
  },
}
</script>

<style scoped></style>
