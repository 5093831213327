<template>
  <div class="full-width">
    <template v-if="input_widgets[index] != undefined">
      <Widgets :item="input_widgets[index]" :index="index - 1" type="image_collection" />
    </template>
    <template v-else>
      <AddWidget type="image_collection" />
    </template>
  </div>
</template>

<script>
// external packages

// in elements 2.0
import Widgets from "@/general/Elements/2.0/OtherElements/Widgets/ImageWidgets.vue"
import AddWidget from "@/general/Elements/2.0/OtherElements/Widgets/AddWidget.vue"

export default {
  components: {
    Widgets,
    AddWidget,
  },
  props: ["index", "input_widgets", "config"],
  computed: {
    lastSlide: function () {
      return this.input_widgets.length == this.index ? true : false
    },

    isOdd: function () {
      return this.input_widgets.length + (1 % 2) != 0 ? true : false
    },
  },
  created() {},

  data: () => {
    return {
      drag: false,
    }
  },

  methods: {
    numberPerPage() {
      // if (this.input_widgets.length == 0) {
      //   return 1;
      // } else if ( (this.lastSlide) && (this.isOdd) ) {
      //   return 1;
      // } else {

      return 1
      // }
    },

    handleChange() {
      this.drag = false
      this.$emit("returnWidgets", this.input_widgets)
    },
  },
}
</script>

<style scoped>
.horizontal-list {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.full-width {
  width: 100%;
}

.w-layout-grid {
  grid-column-gap: 2%;
}
</style>
