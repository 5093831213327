<template>
  <div>
    <div v-if="screen == 'Delete'" class="div-block-213">
      <div class="div-block-212">
        <div class="text-block-28">Cancel</div>
      </div>
      <div class="div-block-211">
        <div class="text-block-29">Delete account</div>
      </div>
    </div>
    <div v-else-if="screen == 'Edit'" class="div-block-213">
      <div class="div-block-212 orange">
        <div class="text-block-28 orange">Cancel</div>
      </div>
      <div class="div-block-211 orange">
        <div class="text-block-29">Delete account</div>
      </div>
    </div>
  </div>
</template>

<script>
// import test from "@/..."

export default {
  components: {
    // test
  },
  props: ["input_var"],
  data: () => {
    return {
      screen: "Edit",
    }
  },
  methods: {
    // clickMethod( input_var ) {
    // return output
    // }
  },
  computed: {},
  watcher: {},
}
</script>

<style scoped>
.test {
  cursor: pointer;
}
</style>
