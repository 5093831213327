<template>
  <div class="search-div">
    <img src="/images/search.svg" loading="lazy" class="information-image opacity-50" />
    <input
      @keyup="returnInput"
      ref="field"
      type="text"
      :placeholder="placeholder"
      class="search-field"
    />
  </div>
</template>

<script>
export default {
  components: {},
  data: () => {
    return {
      placeholder: "Search for widgets...",
    }
  },
  methods: {
    returnInput() {
      this.$emit("return", this.$refs.field.value)
    },
  },
}
</script>

<style scoped>
input {
  background-color: transparent;
  border-width: 0px;
  outline: none;
}
</style>
