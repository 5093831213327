<template>
  <div>
    <template v-if="!isSuccess">
      <div class="background mozo" :style="setBackground()">
        <Header />
        <div class="page-body-2 center">
          <div class="form-div width-cap">
            <h1 class="heading1">Reset your password</h1>
            <p class="paragraph">No problemo, we wil send<br />you an email</p>
            <div class="form-block w-form" style="margin-bottom: 5%">
              <form>
                <div
                  class="paragraph left"
                  style="margin-bottom: 1%; margin-left: 1%"
                >
                  {{ field_names.email }}
                </div>
                <InputField
                  :type="'text'"
                  placeholder="Email"
                  @pressEnter="resetEmail"
                  @returnfield="setValue('email', $event)"
                />
              </form>
            </div>
            <div>
              <Button
                :color="client_data.color[2].icons[0]"
                :title="'Send me an email!'"
                @isClicked="resetEmail"
              />
            </div>
            <template v-if="error !== ''">
              <div>
                <div class="error-message">{{ error }}</div>
              </div>
            </template>
          </div>
        </div>
        <div class="footer-login">
          <div class="text-div center horizontal">
            <p class="paragraph">Go back to</p>
            <a href="\login" class="paragraph bold marge">
              login
              <img
                src="images/right-arrow.png"
                loading="lazy"
                class="image-20"
              />
            </a>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="background mozo pop-up" :style="setBackground()">
        <div class="logo-header-2">
          <div class="logo-pop-up">
             <Header />
          </div>
        </div>
        <div class="page-body-2">
          <div class="pop-up-block">
            <img
              src="images/thanksflysparks.png"
              loading="lazy"
              class="image-9"
            />
            <div class="text-block pop-up">
              <p class="text">There you go!</p>
              <p class="paragraph center">
                We sent you an e-mail, <br />with the link in the mail you can
                change your password!
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// db
import db_auth from "@/db/2.0/auth.js";

// general
import json from "@/general/GlobalValues.json";

// in elements 1.0
import Header from "@/general/Elements/2.0/Headers/Header1.vue";

import InputField from "@/general/Components/2.0/PrivateProfile/InputField.vue";

import methods from "@/general/HelperMethods/General";

import Button from "@/general/Components/2.0/PrivateProfile/GreenButton.vue"

export default {
  components: {
    Header,
    InputField,
    Button
  },
  data() {
    return {
      error: "",
      isSuccess: false,
      email: null,

      field_names: json["field_names"],
      input_length: json["input_length"],
      placeholders: json["placeholdrs"],
    };
  },
  computed: {
    store: function () {
      return this.$store.state;
    },
    client_data() {
      return this.store.client_data;
    },
  },

  methods: {

    setValue(key, value) {
      this[key]=value;
      console.log(key, value)
    },
    async resetEmail() {
      this.isSuccess = await db_auth.resetEmail(this.email);


      if (this.isSuccess) {
        this.error = "Sorry something went wrong, please try it again!";
      }
    },
    setBackground() {
      if (
        this.client_data &&
        this.client_data.color[0] &&
        this.client_data.color[1]
      ) {
        return methods.setBackground(
          this.client_data.color[0],
          this.client_data.color[1]
        );
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.page-body-2 {
  width: 100%;
}

.text-div.center.horizontal {
  display: flex;
  flex-direction: column;
}

.paragraph.center {
  text-align:center;

}
</style>
