export default [
    {
        data: [
            {
                index: 0,
                field: "https://www.facebook.com/test/",
                name: "link",
            },
        ],
        position: 0,
        widget: "facebook",
        request_date: "1663765700",
        status: "active",
        uid: "xxxtest",
    },
]


