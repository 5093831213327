<template>
  <div class="section-wcategories wf-section">
    <BackgroundLayer :color1="colors[0]" :color2="colors[1]" />
    <div class="front-layer oneview">
      <LogoHeader />
      <div class="white-background2 top">
        <div class="default-width center margin-bottom">
          <Title :text="'Categories'" :showCross="'Main'" />
          <SearchBlock @return="setSearch" />
        </div>
        <div class="wb-bottom-div top">
          <div class="default-width">
            <div v-for="(category, index) in categories" :key="index" class="full">
              <Categorie :Title="category.name" :Image1="category.image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBlock from "@/general/Components/2.0/CategoryManagement/General/SearchBlock.vue"
import Title from "@/general/Components/2.0/CategoryManagement/General/Title.vue"
import BackgroundLayer from "@/general/Components/2.0/General/BackgroundLayer.vue"
import Categorie from "@/general/Components/2.0/CategoryManagement/WidgetCategories/Categorie.vue"

import CategoryCollection from "@/general/Objects/Category"
import LogoHeader from "@/general/Components/2.0/CategoryManagement/General/LogoHeader.vue"

export default {
  methods: {},

  components: {
    SearchBlock,
    Title,
    BackgroundLayer,
    Categorie,
    LogoHeader,
  },
  data: () => {
    return {
      categories: [],
    }
  },
  props: [],

  async created() {
    const categoryObject = new CategoryCollection()
    this.categories = await categoryObject.getAll(this.setWidget.item.type)
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    colors: function () {
      return this.store.client_data.color
    },

    setWidget: {
      get() {
        return this.store.environment.setWidget
      },
      set(value) {
        this.store.environment.setWidget = value
      },
    },
  },

  methods: {
    async setSearch(searchTerm) {
      const category = new CategoryCollection()
      this.categories = await category.search(searchTerm, this.setWidget.item.type)
    },
  },
}
</script>

<style scoped>
.full {
  width: 100%;
}

.wb-bottom-div.top {
  max-width: 100%;
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.wb-bottom-div.top::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>
