import db_user from "@/db/2.0/user/main.js";
import store from "@/vuex/setup";
import DataLayer from "@/general/Objects/DataLayer.js";

async function uploadToCloud(url, label, data, destination) {
  if (!store.state.user || !url) {
    return false;
  }

  var uid = store.state.user.uid;

  var isCurrentPage = false;

  if (store.state.environment.data.uid != uid) {
    return false;
  } else {
    isCurrentPage = true;
  }

  if (data != undefined && isCurrentPage && uid) {
    db_user.vData.updateData(uid, { [label]: data }, destination);
  }
}

export default {
  async runWatchers(url) {
    store.watch(
      (state) => {
        return state.client_data.image;
      },
      async (data) => {
        uploadToCloud(url, "image", data, "public");
      },
      {
        deep: true,
      }
    );

    store.watch(
      (state) => {
        return state.client_data.nickname;
      },
      async (data) => {
        uploadToCloud(url, "nickname", data, "public");
      },
      {
        deep: true,
      }
    );

    store.watch(
      (state) => {
        return state.client_data.firstname;
      },
      async (data) => {
        uploadToCloud(url, "firstname", data, "public");
      },
      {
        deep: true,
      }
    );

    store.watch(
      (state) => {
        return state.client_data.lastname;
      },
      async (data) => {
        uploadToCloud(url, "lastname", data, "public");
      },
      {
        deep: true,
      }
    );

    store.watch(
      (state) => {
        return state.client_data.bio1;
      },
      async (data) => {
        uploadToCloud(url, "bio1", data, "public");
      },
      {
        deep: true,
      }
    );

    store.watch(
      (state) => {
        return state.client_data.bio2;
      },
      async (data) => {
        uploadToCloud(url, "bio2", data, "public");
      },
      {
        deep: true,
      }
    );

    store.watch(
      (state) => {
        return state.environment.colorChanged;
      },
      async (data) => {
        uploadToCloud(url, "color", store.state.client_data.color, "public");
      },
      {
        deep: true,
      }
    );

    store.watch(
      (state) => {
        return state.client_data.url;
      },
      async (data) => {
        uploadToCloud(url, "url", data, "public");
      },
      {
        deep: true,
      }
    );
  },
};
