<template>
  <div class="_100 nobg appfieldinput">
    <div class="field-collection">
      <div class="field-div">
        <h1 class="app-title">{{ title }}</h1>
        <div class="addsocial-info-div">
          <input
            class="text-field copy-link w-input"
            type="text"
            disabled="true"
            :value="data"
            :maxlength="input_length[widget]"
            :placeholder="placeholders[widget]"
            required="true"
            :style="'cursor:' + cursor"
          />
          <a @click="doCopy()" class="submit-button copy w-button">{{ button }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// general
import json from "@/general/GlobalValues.json"

// helper
import methods from "@/general/HelperMethods/General.js"

export default {
  props: ["data", "widget", "button", "index"],
  data() {
    return {
      field_names: json["field_names"],
      input_length: json["input_length"],
      placeholders: json["placeholders"],

      cursor: "default",

      title: "",
    }
  },

  async created() {
    this.title =
      methods.capitalizeFirstLetter(await methods.getOptions(this.widget, this.index)) +
      ":"
  },

  methods: {
    doCopy() {
      this.$emit("copy", true)
    },
  },
}
</script>

<style>
@media screen and (max-width: 479px) {
  .text-field.copy-link:disabled {
    margin-right: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 100%;
  }
}
</style>
