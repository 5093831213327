<template>
    <div>
        <template v-if="mode == 'resetPassword'">
            <NewPass />
        </template>
        <template v-else-if="mode == 'recoverEmail'">
            <!-- todo -->
        </template>
        <template v-else-if="mode == 'verifyEmail'">

        </template>
        <template v-else-if="mode == 'signIn'">
            <!-- start: friends function -->
            <AddFriend :input_user="null" :eResponse="true" />
            <!-- end: friends function -->
        </template>
    </div>
</template>

<script>
import NewPass from "./Options/NewPassword.vue";
import AddFriend from "@/views/Friends/AddFriend.vue";


export default {
    components: {
        NewPass,
        AddFriend
    },
    data: () => {
        return {
            mode: "",
            actionCode: "",
            continueUrl: "",
            lang: ""
        }
    },
    computed: {

    },

    created() {
        this.init();

    },
    methods: {
        init() {
            document.addEventListener(
                "DOMContentLoaded",
                () => {
                    // Get the action to complete.
                    this.mode = this.getParameterByName("mode");
                    // Get the one-time code from the query parameter.
                    this.actionCode = this.getParameterByName("oobCode");
                    // (Optional) Get the continue URL from the query parameter if available.
                    this.continueUrl = this.getParameterByName("continueUrl");
                    // (Optional) Get the language code if available.
                    this.lang = this.getParameterByName("lang") || "en";
                },
                false
            );
        },
        getParameterByName(parameter) {
            var url = new URL(window.location.href);
            return url.searchParams.get(parameter);
        },

    }

}
</script>