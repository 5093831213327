<template>
  <div v-if="store.environment.dbSynced">
    <template v-if="screen == 'waitPage'">
      <IsRequested />
    </template>
    <template v-else-if="screen == 'chooseAccount'">
      <ChooseAccount :widget_name="widget.name" @saveWidget="saveWidget" />
    </template>
  </div>
</template>

<script>
import ChooseAccount from "@/general/Components/2.0/CategoryManagement/SetWidget/ChooseAccount.vue"
import IsRequested from "@/general/Components/2.0/CategoryManagement/SetWidget/IsRequested.vue"

import WidgetCollection from "@/general/Objects/WidgetCollection"

import WidgetRepository from "@/general/Objects/Widget"

export default {
  components: {
    ChooseAccount,

    IsRequested,
  },
  props: [],
  async created() {
    const wCol = new WidgetCollection()
    this.accounts = await wCol.getAllAccounts(this.widget.name)

    if (
      this.widget.account_uid != null &&
      !(await wCol.isActive(this.widget.account_uid))
    ) {
      // account is requested and example profile is visible in the profile
      this.screen = "waitPage"
    } else if (
      this.accounts.length > 0 &&
      this.accounts[0].status == "requested" &&
      this.accounts.length > 0 &&
      this.widget.account_uid != null
    ) {
      // account is already requested but not visible in the profile
      this.screen = "waitPage"
    }
  },
  data: () => {
    return {
      screen: "chooseAccount",

      accounts: [],
    }
  },
  methods: {
    async saveWidget(account_uid, image_file) {

      const wCol = new WidgetCollection()

      const response = await wCol.getAccountStatus(account_uid)

      if (this.widget.action == "create") {
        const widget = await wCol.add(
          this.widget.type,
          account_uid,
          this.widget.name,
          this.widget.title,
          this.widget.image
        )

        this.widget.widget_uid = widget.uid
        if (image_file != null) {
          await wCol.addImageToWidget(
            this.uid,
            this.widget.type,
            this.widget.widget_uid,
            image_file
          )
        }

        const wObject = new WidgetRepository()
        const widget_legend = await wObject.getWidgetByName(this.widget.name)

        if (widget_legend.days > 0) {
          await this.sendEmail(widget.uid)
          this.widget.account_uid = widget.account_uid

          this.screen = "waitPage"
          return
        }
      } else if (this.widget.action == "update") {
        let image_url = this.widget.image;
        if(image_file != null) {
          image_url = await wCol.addImageToWidget(
            this.uid,
            this.widget.type,
            this.widget.widget_uid,
            image_file
          )
        }

        await wCol.modify(
          this.widget.type,
          this.widget.widget_uid,
          account_uid,
          this.widget.name,
          this.widget.title,
          image_url
        )
      }
      const x = await wCol.get(this.widget.widget_uid, this.widget.widget_type)

      this.$router.push({ name: "Main" })
    },

    async sendEmail(uid) {
      const wObject = new WidgetRepository()
      let googledoc
      try {
        googledoc = (await wObject.getEmailInstructions(this.widget.name)).googledoc
      } catch (err) {
        // do nothing
      }

      const days_left = (await wObject.getWidgetByName(this.widget.name)).days

      var response = await this.axios.post(
        "https://api.themozo.app/general/email/sendEmail",
        {
          auth_token: "12345",
          email: "widget-request@trymozo.com",
          title:
            "CREATED: #" +
            uid +
            " Widget Request - " +
            this.widget.name +
            " - " +
            this.store.client_data.url,
          message:
            "Hi hi!<br>" +
            "We have a new feature request from our client <b>" +
            this.store.client_data.url +
            "</b> to add a new widget to their account <b>" +
            this.widget.name +
            "</b>. <br>You got <b>" +
            days_left +
            " days</b> left! <br> Waste no time your client is waiting for you! You need to perform the following steps: <a>" +
            googledoc +
            "</a><br><br>" +
            "Good Luck! If there are any questions just sent an email to gijs@trymozo.com." +
            "<br><br>Yours faithfully,<br>Gijs de Leeuw",
        }
      )
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    widget: function () {
      return this.store.environment.setWidget.item
    },
    uid: function () {
      return this.store.user.uid
    },
  },
}
</script>

<style scoped></style>
