<template>
  
    <div @click="isClicked" class="largewidget add">
      <div class="div-block-249">
        <div class="add-button-account large">
          <div class="plus-sign large">+</div>
        </div>
        <h1 class="heading2 black-text" style="text-transform:uppercase; font-size:22px; font-weight:600;">Choose widget</h1>
      </div>
    </div>
  </template>
</template>

<script>
export default {
    methods: {
        isClicked() {
            this.$emit('isClicked', true)
        }
    }
}
</script>
